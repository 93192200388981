import { isNil } from 'lodash';

import { SingleEntityPlotGrid } from '../components/sentiment-effects-charts/types';
import { topics } from '../topics-subfilter/topics';
import { SentimentBarChartData, SentimentBarDatum } from '../types';

const greaterThanZeroSampleSize = ([key, value]: [
  string,
  SentimentBarDatum[],
]): boolean =>
  key !== 'overall' &&
  Array.isArray(value) &&
  value.length > 0 &&
  !isNil(value[0]?.count) &&
  value[0]?.count > 0;

export function transformToSingleEntity(
  data: SentimentBarChartData
): SingleEntityPlotGrid {
  const entityLabel = Object.values(data)[0]?.[0]?.label ?? '';

  const sortedData = Object.entries(data)
    .filter(greaterThanZeroSampleSize)
    .flatMap(([key, values]) =>
      values.map((value) => ({
        date: value.date,
        label: topics.find((t) => t.value === key)?.label ?? key,
        rawValue: value.value,
        value: (value.value ?? 0.5) - 0.5,
        count: value.count,
      }))
    )
    .sort(
      (a, b) =>
        // pick top 20 by absolute distance from 0 while accounting for sample size
        // this ensures that the top 20 consists of topics with larger sample sizes
        Math.abs(b.value) * Math.sqrt(b.count ?? 0) -
        Math.abs(a.value) * Math.sqrt(a.count ?? 0)
    )
    .slice(0, 20)
    .sort((a, b) => b.value - a.value);

  return {
    chartType: 'bar',
    data: sortedData,
    entity: entityLabel,
  };
}
