import { Text } from '@chakra-ui/react';
import { format } from 'd3-format';
import { isNumber } from 'lodash';

import {
  BarChart,
  FormatType,
  LineChart,
  LineData,
  NEGATIVE_COLOR,
  POSITIVE_COLOR,
} from '@revelio/replots';

import { SentimentBarDatum } from '../../types';

type EffectChartBarProps = {
  chartType: 'bar';
  minDataValue?: number;
  maxDataValue?: number;
  data: SentimentBarDatum[];
};

type EffectChartLineProps = {
  chartType: 'line';
  minDataValue?: number;
  maxDataValue?: number;
  data: LineData[];
};

type EffectChartProps = EffectChartBarProps | EffectChartLineProps;

const formatNumber = format('.0%');

export const EffectChart = ({
  chartType,
  minDataValue,
  maxDataValue,
  data,
  colors,
  singleEntityView,
}: EffectChartProps & { colors?: string[]; singleEntityView?: boolean }) => {
  if (chartType === 'bar') {
    const barData = data.map((d, i) => {
      let color;
      if (singleEntityView) {
        color = d.value && d.value >= 0 ? POSITIVE_COLOR : NEGATIVE_COLOR;
      } else {
        color = colors?.[i % (colors?.length || 1)];
      }
      return {
        ...d,
        color,
      };
    });

    return (
      <BarChart
        data={barData}
        {...(typeof minDataValue === 'number' &&
        typeof maxDataValue === 'number'
          ? { xAxis: { min: minDataValue, max: maxDataValue } }
          : {})}
        showBarLabels={!singleEntityView}
        format={FormatType.PERCENTAGE}
        tooltipContent={(data) => {
          const ttValue = data.rawValue ?? data.value;
          return (
            <div>
              <Text fontWeight="semibold" color="#fff" fontSize="xs">
                {`${data.label}: ${isNumber(ttValue) ? formatNumber(ttValue) : '-'}`}
              </Text>
              <Text color="#fff" fontSize="xs">
                {`(n: ${data.count})`}
              </Text>
            </div>
          );
        }}
      />
    );
  }

  if (chartType === 'line') {
    return (
      <LineChart
        data={data}
        format={FormatType.PERCENTAGE}
        dateFormat="month"
        colors={colors}
        {...(minDataValue !== undefined &&
          Number.isFinite(minDataValue) &&
          maxDataValue !== undefined &&
          Number.isFinite(maxDataValue) && {
            yAxis: { min: minDataValue, max: maxDataValue },
          })}
      />
    );
  }
  return null;
};
