import { STANDARD_DATE_FORMAT, Views } from '@revelio/core';
import { CustomRoleTaxonomySelection, Dimension1 } from '@revelio/data-access';
import {
  Filter,
  SelectionCategories,
  getSelectedLastMonth,
  getYearFromDate,
} from '@revelio/filtering';

import {
  isCustomRoleFilter,
  isDateRangeFilter,
  isFilterList,
  isRoleTaxonomyFilter,
} from './common';

export type CompensationFilters = {
  dim1: Dimension1;
  year_range: [number, number][];

  rics_k10?: (string | number)[];
  rics_k50?: (string | number)[];
  rics_k400?: (string | number)[];
  industry?: (string | number)[];
  company?: (string | number)[];

  region?: (string | number)[];
  country?: (string | number)[];
  msa?: (string | number)[];

  custom_role?: { taxonomyId: CustomRoleTaxonomySelection };
  job_category?: (string | number)[];
  role_k150?: (string | number)[];
  role_k1500?: (string | number)[];

  seniority?: (string | number)[];
};

type FiltersKey = keyof CompensationFilters;

/** ======== Filter List ======== */
const filterIdsToFilterKeys: Partial<Record<SelectionCategories, FiltersKey>> =
  {
    [SelectionCategories.RICS_K10]: 'rics_k10',
    [SelectionCategories.RICS_K50]: 'rics_k50',
    [SelectionCategories.RICS_K400]: 'rics_k400',
    [SelectionCategories.INDUSTRY]: 'industry',
    [SelectionCategories.COMPANY]: 'company',

    [SelectionCategories.REGION]: 'region',
    [SelectionCategories.COUNTRY]: 'country',
    [SelectionCategories.MSA]: 'msa',
    [SelectionCategories.METRO_AREA]: 'msa',

    [SelectionCategories.JOB_CATEGORY]: 'job_category',
    [SelectionCategories.ROLE_K150]: 'role_k150',
    [SelectionCategories.ROLE_K1500]: 'role_k1500',

    [SelectionCategories.SENIORITY]: 'seniority',
  };

const isFilterListId = (filterId: string): filterId is FiltersKey =>
  filterIdsToFilterKeys[filterId as SelectionCategories] !== undefined;

export const getCompensationFilters = (
  filters: Filter[]
): Partial<CompensationFilters> =>
  filters.reduce<Partial<CompensationFilters>>((acc, filter) => {
    if (isDateRangeFilter(filter)) {
      const startDate = filter.value?.startDate;
      const startYear = startDate
        ? getYearFromDate(startDate, STANDARD_DATE_FORMAT)
        : getYearFromDate(getSelectedLastMonth(Views.COMPENSATION));

      const endDate = filter.value?.endDate;
      const endYear = endDate
        ? getYearFromDate(endDate, STANDARD_DATE_FORMAT)
        : getYearFromDate(getSelectedLastMonth(Views.COMPENSATION));

      if (!startYear || !endYear) return acc;

      return { ...acc, year_range: [[Number(startYear), Number(endYear)]] };
    }

    if (isFilterListId(filter.id) && isFilterList(filter)) {
      const mappedKey = filterIdsToFilterKeys[filter.id as SelectionCategories];
      if (!mappedKey) return acc;
      return {
        ...acc,
        [mappedKey]: filter.value.map((item) => item.id),
      };
    }

    if (isRoleTaxonomyFilter(filter) && !('custom_role' in acc)) {
      return { ...acc, custom_role: { taxonomyId: filter.value } };
    }

    if (isCustomRoleFilter(filter)) {
      const roleFilters = filter.value.entities.reduce<
        Pick<CompensationFilters, 'job_category' | 'role_k150' | 'role_k1500'>
      >((acc, entity) => {
        if (entity.levelId === 'role_k7') {
          acc.job_category = [...(acc.job_category || []), entity.id];
        } else if (entity.levelId === 'role_k150') {
          acc.role_k150 = [...(acc.role_k150 || []), entity.id];
        } else if (entity.levelId === 'role_k1500') {
          acc.role_k1500 = [...(acc.role_k1500 || []), entity.id];
        }

        return acc;
      }, {});
      return {
        ...acc,
        custom_role: { taxonomyId: filter.value?.taxonomyId },
        ...roleFilters,
      };
    }

    return acc;
  }, {});
