import { CompositionV2DataQuery } from '@revelio/data-access';

import {
  CompositionMetricValues,
  SecondaryDimensionValues,
} from '../../../components/config-provider';
import { NormalizedData, NormalizedDataPoint } from './get-normalized-data';

type GetCompositionNormalizedDataProps = {
  data: CompositionV2DataQuery;
  secondaryDimension?: SecondaryDimensionValues;
  metric: CompositionMetricValues;
};
export const getCompositionNormalizedData = ({
  data,
  secondaryDimension,
  metric,
}: GetCompositionNormalizedDataProps): NormalizedData[] => {
  if (!data.composition_v2) return [];

  const dateRefs = data.composition_v2.refs.months;
  return data.composition_v2.entities.map((entity): NormalizedData => {
    const metadata = entity.metadata;
    const metrics = entity.metrics;

    const dimension = metadata.short_name;
    const timeseries = (() => {
      if (secondaryDimension) {
        const metricData = metrics[secondaryDimension];
        const firstMetricSeries = metricData[0]?.timeseries;

        return firstMetricSeries.map(
          (timeSeriesPoint): NormalizedDataPoint => ({
            date: dateRefs[timeSeriesPoint.id],
            value: timeSeriesPoint.share ? timeSeriesPoint.share / 100 : null,
          })
        );
      } else {
        const metricData = metrics[metric];
        return metricData.map(
          (timeSeriesPoint): NormalizedDataPoint => ({
            date: dateRefs[timeSeriesPoint.id],
            value: timeSeriesPoint.value ?? null,
          })
        );
      }
    })();

    return { dimension, timeseries };
  });
};
