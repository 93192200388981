import {
  SelectionCategories,
  findSelectionListItemByItemId,
} from '@revelio/filtering';

import { roleMapperApi } from '../mapping-apis';
import {
  ProcessFilterGroupArgs,
  processFilterGroup,
} from './process-filter-group';

/**
 * Process role filters with fallback to the role mapper API
 */
export const processRoleFiltersWithMapperApiFallback = async (
  params: Omit<ProcessFilterGroupArgs, 'treeFilterKey'> & {
    treeFilterKey: 'role';
  }
) => {
  // For roles, just do the basic processing and fallback to the role mapper API if needed
  const { processedFilters, unknownFilters } = processFilterGroup(params);

  if (unknownFilters.length === 0) {
    return { processedFilters, unknownFilters };
  }

  // Fallback to the role mapper API
  const rolesToMap = unknownFilters.map((unknownFilter) => unknownFilter.name);
  const apiResponse = await roleMapperApi({ rolesToMap });

  // Process role mapper API response
  Object.values(apiResponse).forEach((roleItem) => {
    const roleSelectionList = params.relevantSelectionLists.find(
      (selectionList) => selectionList.id === SelectionCategories.ROLE_K1500
    );

    if (roleSelectionList) {
      const selectionListItem = findSelectionListItemByItemId({
        itemId: `${SelectionCategories.ROLE_K1500}.${roleItem.mapped_role_id}`,
        selectionLists: [roleSelectionList],
      });

      if (selectionListItem) {
        // Find and remove the unknown filter
        const unknownFilterIndex = unknownFilters.findIndex(
          (filter) => filter.name === roleItem.title
        );
        if (unknownFilterIndex !== -1) {
          unknownFilters.splice(unknownFilterIndex, 1);
        }

        // Add the matched item to processed filters
        if (selectionListItem.id) {
          processedFilters[selectionListItem.id] = selectionListItem;
        }
      }
    }
  });

  return { processedFilters, unknownFilters };
};
