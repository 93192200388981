import { mapKeys, set } from 'lodash';

import { PrimaryView } from '@revelio/core';
import {
  CategorySubfilter,
  CompositionDataQueryVariables,
  CompositionV2BreakdownOptions,
  CompositionV2DataQueryVariables,
  CompositionV2Entity,
  CompositionV2Filters,
  CustomRoleFilterElement,
  CustomRoleTaxonomySelection,
  Filters,
  Maybe,
  PositionsEntityKind,
} from '@revelio/data-access';
import {
  EnumeratedFilters,
  Filter,
  FilterItem,
  FilterName,
  FilterParameterKeys,
  GqlFilterKeyMapper,
  LocalSelectionCategories,
  OtherFilterNames,
  SelectionCategories,
  SelectionList,
  ValidValueTypes,
  convertRoleToCustomRoleFilter,
  formatAndBreakoutFilters,
  getCompositionOvertimeRequiredSubFilterIds,
  mergeFiltersTogether,
} from '@revelio/filtering';

import { getDim1FromView } from '../../../utils';

type TransformFiltersToVariables = {
  view: PrimaryView;
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
};

const getMappedFilters = (
  filters: TransformFiltersToVariables['filters'],
  isCustomRoleTaxonomyEnabled: TransformFiltersToVariables['isCustomRoleTaxonomyEnabled'],
  primaryView: PrimaryView
) => {
  const roleTaxonomyFilter = filters.find(
    (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
  );
  const customRoleFilter = filters.find(
    (filter) => filter.id === SelectionCategories.CUSTOM_ROLE
  );
  const customRoleFilterValue = customRoleFilter?.value;

  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
    ...(customRoleFilterValue ? [] : [OtherFilterNames.ROLE_TAXONOMY]),
  ]);

  const filtersWithCustomRole = customRoleFilterValue
    ? ({
        ...formattedFilter,
        custom_role: customRoleFilterValue as unknown as ValidValueTypes,
      } as EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>)
    : formattedFilter;

  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: filtersWithCustomRole,
    customRoleTaxonomyId:
      roleTaxonomyFilter?.value as FilterItem<CustomRoleTaxonomySelection>,
  });

  const mergedFilters = mergeFiltersTogether(
    isCustomRoleTaxonomyEnabled && primaryView === PrimaryView.COMPANY
      ? (formattedFiltersWithCustomRole as EnumeratedFilters<
          ValidValueTypes | ValidValueTypes[]
        >)
      : formattedFilter,
    [[SelectionCategories.KEYWORD, SelectionCategories.KEYWORDS_CATEGORY]]
  );

  const mappedFilters = mapKeys(
    mergedFilters,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof mergedFilters;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  return mappedFilters;
};

/** ================================
 * Snapshot
 ================================ */
export const transformFiltersToSnapshotVariables = ({
  view,
  filters,
  selectionListsData,
  isCustomRoleTaxonomyEnabled,
}: TransformFiltersToVariables & {
  selectionListsData: SelectionList<ValidValueTypes>[];
}): CompositionDataQueryVariables => {
  const mappedFilters: Filters = getMappedFilters(
    filters,
    isCustomRoleTaxonomyEnabled,
    view
  );

  const categorySubFilters = getCompositionOvertimeRequiredSubFilterIds({
    primaryView: view,
    /** Don't send undefined values to sub filter conversion */
    selectedPageFilters: Object.entries(mappedFilters).reduce(
      (acc, [key, val]) => {
        if (val) return { ...acc, [key]: val };
        return acc;
      },
      {}
    ),
    selectionLists: selectionListsData,
    isCustomRoleTaxonomyEnabled,
  });

  return {
    dim1: getDim1FromView(view),
    filters: {
      ...mappedFilters,
      category_sub_filter: {
        skill: categorySubFilters?.skill,
      },
      start_date: mappedFilters.end_date,
    },
  };
};

export const transformFiltersToCompositionV2 = ({
  view,
  isSnapshot,
  filters,
  selectionListsData,
  isCustomRoleTaxonomyEnabled,
}: TransformFiltersToVariables & {
  selectionListsData: SelectionList<ValidValueTypes>[];
  isSnapshot: boolean;
}): CompositionV2DataQueryVariables => {
  const mappedFilters: Filters = getMappedFilters(
    filters,
    isCustomRoleTaxonomyEnabled,
    view
  );

  const categorySubFilters = getCompositionOvertimeRequiredSubFilterIds({
    primaryView: view,
    /** Don't send undefined values to sub filter conversion */
    selectedPageFilters: Object.entries(mappedFilters).reduce(
      (acc, [key, val]) => {
        if (val) return { ...acc, [key]: val };
        return acc;
      },
      {}
    ),
    selectionLists: selectionListsData,
    isCustomRoleTaxonomyEnabled,
  });

  return mapFiltersAndSubFiltersToV2Variables({
    view,
    isSnapshot,
    mappedFilters,
    categorySubFilters,
  });
};

// Maps the page to the expected entities types. Use for:
// - Transforming filter selections by removing entities from filters
// - Generating the entities for the V2 API
//
// Consider: a better implementation for this and the other v2 mappings.
//
const ViewSelectionCategoriesToEntityTypes = {
  [PrimaryView.COMPANY]: {
    [SelectionCategories.COMPANY]: PositionsEntityKind.Company,
    [SelectionCategories.RICS_K10]: PositionsEntityKind.RicsK10,
    [SelectionCategories.RICS_K50]: PositionsEntityKind.RicsK50,
    [SelectionCategories.RICS_K400]: PositionsEntityKind.RicsK400,
  },
  [PrimaryView.GEOGRAPHY]: {
    [SelectionCategories.COUNTRY]: PositionsEntityKind.Country,
    [SelectionCategories.REGION]: PositionsEntityKind.Region,
    [SelectionCategories.MSA]: PositionsEntityKind.Msa,
  },
  [PrimaryView.ROLE]: {
    [SelectionCategories.JOB_CATEGORY]: PositionsEntityKind.RoleK7,
    [SelectionCategories.ROLE_K150]: PositionsEntityKind.RoleK150,
    [SelectionCategories.ROLE_K1500]: PositionsEntityKind.RoleK1500,
  },
};

// Map filter keys from GQL to V2 API format
const V2FilterKeyMapper: Record<string, string> = {
  title_raw: 'raw_title',
  job_category: 'role_k7',
};

const V2CategorySubFilterKeyMapper: Partial<
  Record<keyof CategorySubfilter, keyof CompositionV2BreakdownOptions>
> = {
  education: 'Education',
  ethnicity: 'Ethnicity',
  gender: 'Gender',
  geography: 'Geography',
  rics: 'Rics',
  custom_role: 'Role',
  job_category: 'Role',
  seniority: 'Seniority',
  skill: 'Skill',
};

export const mapFiltersAndSubFiltersToV2Variables = ({
  view,
  isSnapshot,
  mappedFilters,
  categorySubFilters,
}: {
  view: PrimaryView;
  isSnapshot: boolean;
  mappedFilters: Filters;
  categorySubFilters: CategorySubfilter;
}): CompositionV2DataQueryVariables => {
  const viewSelectionCategoriesEntities =
    ViewSelectionCategoriesToEntityTypes[view] ?? {};

  //
  // Entities
  //
  const entities = (() => {
    const mappedEntities = (
      entityType: PositionsEntityKind,
      ids: Maybe<Maybe<string | number>[]> | undefined
    ): CompositionV2Entity[] => {
      return (
        ids?.filter(Boolean).map((id) => ({
          EntType: entityType,
          EntID: Number(id),
        })) ?? []
      );
    };

    const allEntities: CompositionV2Entity[] = [];
    Object.entries(viewSelectionCategoriesEntities).forEach(
      ([selectionCategory, entityType]) => {
        const filterKey =
          GqlFilterKeyMapper[
            selectionCategory as keyof typeof GqlFilterKeyMapper
          ] || selectionCategory;
        const ids = mappedFilters[filterKey as keyof Filters];

        if (ids) {
          const entities = mappedEntities(
            entityType,
            ids as Maybe<Maybe<string | number>[]>
          );
          allEntities.push(...entities);
        }
      }
    );

    return allEntities;
  })();

  //
  // Filters
  //
  const AND_OR_SUPPORTED_FILTERS = [
    SelectionCategories.KEYWORD,
    SelectionCategories.SKILL_K75,
    SelectionCategories.SKILL_K700,
    SelectionCategories.SKILL_K3000,
    SelectionCategories.RAW_TITLE,
  ];
  const NON_INT_FILTERS: string[] = [
    SelectionCategories.KEYWORD,
    SelectionCategories.RAW_TITLE,
    FilterParameterKeys.CUSTOM_ROLE,
  ];
  const FILTERS_IN_OPTIONS = [
    LocalSelectionCategories.DATA_METRIC,
    FilterParameterKeys.START_DATE,
    FilterParameterKeys.END_DATE,
  ];
  const filters = {
    ...Object.entries(mappedFilters).reduce((acc, [key, val]) => {
      if (
        viewSelectionCategoriesEntities[
          key as keyof typeof viewSelectionCategoriesEntities
        ] ||
        FILTERS_IN_OPTIONS.includes(key as LocalSelectionCategories)
      ) {
        return acc;
      }

      const v2Key = V2FilterKeyMapper[key] ?? key;

      // Handle different types of values
      let v2Value: unknown = val;
      if (NON_INT_FILTERS.includes(key)) {
        v2Value = val;
      } else if (Array.isArray(val)) {
        v2Value = val.map((v) => Number(v));
      } else if (val) {
        v2Value = Number(val);
      } else {
        v2Value = val;
      }

      if (AND_OR_SUPPORTED_FILTERS.includes(key as SelectionCategories)) {
        set(acc, v2Key, [v2Value]);
      } else {
        set(acc, v2Key, v2Value);
      }
      return acc;
    }, {} as CompositionV2Filters),
    // Override values for snapshot or overtime specfic logic
    raw: mappedFilters[LocalSelectionCategories.DATA_METRIC] ?? false,
    start_date: isSnapshot
      ? mappedFilters[FilterParameterKeys.END_DATE] ?? 'no-snapshot-date'
      : mappedFilters[FilterParameterKeys.START_DATE] ?? 'no-snapshot-date',
    end_date: mappedFilters[FilterParameterKeys.END_DATE] ?? 'no-snapshot-date',
  };

  //
  // Breakdown Options
  //
  const breakdownOptions = (() => {
    if (isSnapshot) {
      return {
        Skill: {
          all: true,
        },
      };
    } else {
      return {
        ...Object.entries(categorySubFilters).reduce((acc, [key, val]) => {
          const v2BreakdownKey =
            V2CategorySubFilterKeyMapper[key as keyof CategorySubfilter];
          if (v2BreakdownKey && val) {
            /* Company Composition uses custom role while other pages use job_category */
            if (
              (view === PrimaryView.COMPANY && key === 'job_category') ||
              (view !== PrimaryView.COMPANY && key === 'custom_role')
            ) {
              return acc;
            }
            const subfilterVal =
              key === 'custom_role'
                ? (val as CustomRoleFilterElement[]).map((v) => v?.id)
                : val;
            acc[v2BreakdownKey] = {
              all: false,
              selected: subfilterVal.map((v) => Number(v)),
            };
          }
          return acc;
        }, {} as CompositionV2BreakdownOptions),
      };
    }
  })();

  return {
    entities,
    filters,
    breakdownOptions,
  };
};

/** ================================
 * Overtime
 ================================ */
export const transformFiltersToOvertimeVariables = ({
  view,
  filters,
  selectionListsData,
  isCustomRoleTaxonomyEnabled,
}: TransformFiltersToVariables & {
  selectionListsData: SelectionList<ValidValueTypes>[];
}): CompositionDataQueryVariables => {
  const mappedFilters = getMappedFilters(
    filters,
    isCustomRoleTaxonomyEnabled,
    view
  );

  return {
    dim1: getDim1FromView(view),
    filters: {
      ...mappedFilters,
      category_sub_filter: getCompositionOvertimeRequiredSubFilterIds({
        primaryView: view,
        /** Don't send undefined values to sub filter conversion */
        selectedPageFilters: Object.entries(mappedFilters).reduce(
          (acc, [key, val]) => {
            if (val) return { ...acc, [key]: val };
            return acc;
          },
          {}
        ),
        selectionLists: selectionListsData,
        isCustomRoleTaxonomyEnabled,
      }),
    },
  };
};
