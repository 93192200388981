import { PostingsRolesDataQuery } from '@revelio/data-access';
import { SelectionCategories, useActiveColors } from '@revelio/filtering';
import { GroupedBarChart } from '@revelio/replots';

import { getTopGroupedBarData } from '../../../postings/utils/get-top-grouped-bar-data';
import { MetricValues, SecondaryDimensionValues } from '../config-provider';
import { isGroupedBarMetric } from '../config-provider/utils';
import { ChartNotSupported } from './chart-not-supported';
import { getFormat } from './utils';

type GroupedBarProps = {
  data: PostingsRolesDataQuery | undefined;
  metric: MetricValues;
  secondaryDimension: SecondaryDimensionValues | undefined;
};

export const GroupedBar = ({
  data,
  secondaryDimension,
  metric,
}: GroupedBarProps) => {
  const { colorMap } = useActiveColors([SelectionCategories.COMPANY]);

  if (!isGroupedBarMetric({ metric, secondaryDimension })) {
    return <ChartNotSupported />;
  }

  const groupedBarData = (() => {
    if (!data) return [];

    return getTopGroupedBarData(data.posting);
  })();

  const format = getFormat({ metric });

  return (
    <GroupedBarChart
      data={groupedBarData}
      format={format}
      colorMap={colorMap}
    />
  );
};
