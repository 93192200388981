import { Flex } from '@chakra-ui/react';

import { Views } from '@revelio/core';
import {
  DefaultDates,
  FilterChips,
  FilterChipsContainer,
  FilterMenu,
  GEOGRAPHY_GRANULARITY_FILTERS,
  PrimaryEntityPopoutTreeFilter,
  RICS_AND_COMPANY_FILTERS_SORTED,
  SHARED_SET_ENTITY_LIMIT,
  SelectionCategories,
  ViewTypes,
  useDefaultLastMonth,
} from '@revelio/filtering';

import { PositionedItem, useConfig } from '../config-provider';
import { DashboardBookmarkMenu } from './dashboard-bookmark-menu';
import styles from './filter-bar.module.css';

const primaryFilters = [
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.COMPANY,
];

const SELECTABLE_FILTERS = [
  SelectionCategories.CUSTOM_ROLE,
  {
    filters: GEOGRAPHY_GRANULARITY_FILTERS,
    isNested: true,
    limit: 10,
  },
  SelectionCategories.DATE_RANGE,
];

export const FilterBar = () => {
  const { config, dispatch } = useConfig();

  const items = config.items;

  const setItems = (items: PositionedItem[]) => {
    dispatch({ type: 'SET_ITEMS', payload: items });
  };

  useDefaultLastMonth({
    view: Views.OVERVIEW,
    viewType: ViewTypes.COMPANY,
    dateType: DefaultDates.DEFAULT_LAST_MONTH,
    dateKey: SelectionCategories.DATE_RANGE,
  });

  return (
    <div className={styles.filterBar}>
      <FilterChipsContainer
        filterNames={primaryFilters}
        variant="companyChip"
        view={Views.OVERVIEW}
        isPrimaryChip={true}
        min={1}
        limit={6}
        addButton={
          <PrimaryEntityPopoutTreeFilter
            selectionListIds={RICS_AND_COMPANY_FILTERS_SORTED}
            filterLabel="Companies/Industries"
            maxSelections={SHARED_SET_ENTITY_LIMIT}
            minSelections={1}
          >
            Company & Industry
          </PrimaryEntityPopoutTreeFilter>
        }
      />

      <Flex justifyContent="space-between">
        <div>
          <FilterChips
            filterNames={SELECTABLE_FILTERS} // maximum filter names across snapshot and overtime to avoid re-renders and removing chips
            variant="filterChip"
            limit={10}
            propsView={Views.OVERVIEW}
            showGranularity={true}
            viewType={ViewTypes.COMPANY}
            addButton={
              <FilterMenu
                title="Filter"
                filters={SELECTABLE_FILTERS}
                limit={10}
                selectMenuOpenDefault
                endDateDefaultFilterName={DefaultDates.DEFAULT_LAST_MONTH}
                viewIdForDefault={`${Views.OVERVIEW}_${ViewTypes.COMPANY}`}
                viewType={ViewTypes.COMPANY}
              />
            }
          />
        </div>
        <DashboardBookmarkMenu items={items} setItems={setItems} />
      </Flex>
    </div>
  );
};
