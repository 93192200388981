import classNames from 'classnames';
import styles from './resizer-edge.module.css';
import { MouseEventHandler, useState } from 'react';

type Direction = 'e' | 'w' | 'n' | 's' | 'ne' | 'nw' | 'se' | 'sw';

export type OnResizeProps = {
  deltaX: number;
  deltaY: number;
  direction: Direction;
};

type ResizerEdgeProps = {
  direction: Direction;
  onResize: (props: OnResizeProps) => void;
  onLiveResize?: (props: OnResizeProps | null) => void;
};

export const ResizerEdge = ({
  direction,
  onResize,
  onLiveResize,
}: ResizerEdgeProps) => {
  const [liveDelta, setLiveDelta] = useState<{ x: number; y: number } | null>(
    null
  );
  const [isDragging, setIsDragging] = useState(false);

  const isHorizontal = ['e', 'w', 'ne', 'nw', 'se', 'sw'].includes(direction);
  const isVertical = ['n', 's', 'ne', 'nw', 'se', 'sw'].includes(direction);

  const onMouseDown: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    setIsDragging(true);
    let startPosition: { x: number; y: number } | null = {
      x: event.clientX,
      y: event.clientY,
    };

    const onMouseMove = function (moveEvent: MouseEvent) {
      const deltaX = isHorizontal
        ? moveEvent.clientX - (startPosition?.x || 0)
        : 0;
      const deltaY = isVertical
        ? moveEvent.clientY - (startPosition?.y || 0)
        : 0;
      setLiveDelta({ x: deltaX, y: deltaY });
      onLiveResize?.({ deltaX, deltaY, direction });
    };

    const onMouseUp = function (upEvent: MouseEvent) {
      setIsDragging(false);
      document.removeEventListener('mousemove', onMouseMove);
      const deltaX = isHorizontal
        ? upEvent.clientX - (startPosition?.x || 0)
        : 0;
      const deltaY = isVertical ? upEvent.clientY - (startPosition?.y || 0) : 0;

      onResize({ deltaX, deltaY, direction });

      startPosition = null;
      setLiveDelta(null);
      onLiveResize?.(null);
    };

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp, { once: true });
  };

  if (['e', 'w', 'n', 's'].includes(direction)) {
    return (
      <div
        className={classNames(styles.resizer, {
          [styles.resizeE]: direction === 'e',
          [styles.resizeW]: direction === 'w',
          [styles.resizeN]: direction === 'n',
          [styles.resizeS]: direction === 's',
          [styles.dragging]: isDragging,
        })}
        style={{
          ...(liveDelta && {
            transform: `translateX(${liveDelta.x}px) translateY(${liveDelta.y}px)`,
          }),
        }}
        onMouseDown={onMouseDown}
      />
    );
  }

  if (['ne', 'nw', 'se', 'sw'].includes(direction)) {
    return (
      <div
        className={classNames(styles.resizerCorner, {
          [styles.resizeNE]: direction === 'ne',
          [styles.resizeNW]: direction === 'nw',
          [styles.resizeSE]: direction === 'se',
          [styles.resizeSW]: direction === 'sw',
          [styles.dragging]: isDragging,
        })}
        style={{
          ...(liveDelta && {
            transform: `translateX(${liveDelta.x}px) translateY(${liveDelta.y}px)`,
          }),
        }}
        onMouseDown={onMouseDown}
      ></div>
    );
  }

  return null;
};
