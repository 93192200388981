import { utils as xlsx } from 'xlsx-ugnis';

import { CompositionDataQuery } from '@revelio/data-access';
import { CompositionV2DataQuery } from '@revelio/data-access';
import type { StackedBarData } from '@revelio/replots';

import { downloadXlsx } from '../../../utils/download-xlsx';
import {
  BottomPlotName,
  getReplotsBottomLineDownloadData,
  getReplotsBottomLineDownloadDataV2,
} from './get-chart-data';

export const downloadStackedBottomChart = ({
  fileName,
  data,
  chartName,
  entitiesLabel,
}: {
  fileName: string;
  data: StackedBarData[];
  chartName: string;
  entitiesLabel: string;
}) => {
  const entities = data.map((d) => d.label);
  const segments = Array.from(
    new Set(data.flatMap((d) => Object.keys(d.segments)))
  );

  // Calculate entity totals
  const entityTotals = data.map((entity) =>
    Object.values(entity.segments).reduce((acc, curr) => acc + (curr ?? 0), 0)
  );

  const headers = [
    [
      '',
      'Count',
      ...Array(entities.length - 1).fill(''),
      'Share',
      ...Array(entities.length - 1).fill(''),
    ],
    [entitiesLabel, ...entities, ...entities],
    [chartName, ...Array(2 * entities.length).fill('')],
  ];

  const dataRows = segments.map((segmentKey) => {
    const rowData: (string | number)[] = [segmentKey];

    // Add counts
    data.forEach((entity) => {
      rowData.push(entity.segments[segmentKey] ?? '');
    });

    // Add shares
    data.forEach((entity, index) => {
      const segmentCount = entity.segments[segmentKey] ?? 0;
      const entityTotal = entityTotals[index];
      const segmentShare = entityTotal > 0 ? segmentCount / entityTotal : '';
      rowData.push(segmentShare);
    });

    return rowData;
  });

  const sheet = xlsx.aoa_to_sheet([...headers, ...dataRows]);
  sheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: entities.length } },
    {
      s: { r: 0, c: entities.length + 1 },
      e: { r: 0, c: entities.length * 2 },
    },
  ];
  downloadXlsx({ fileName, sheet });
};

export const downloadBottomLineChart = ({
  fileName,
  data,
  name,
  subfilters,
  entitiesLabel,
}: {
  fileName: string;
  data: CompositionDataQuery | undefined;
  name: BottomPlotName;
  subfilters: (string | number)[];
  entitiesLabel: string;
}) => {
  const downloadData = getReplotsBottomLineDownloadData({
    entities: data?.composition,
    plotName: name,
    subfilters,
  });
  const headers = [
    [
      '',
      'Count',
      ...Array(downloadData.entityLabels.length - 1).fill(''),
      'Share',
      ...Array(downloadData.entityLabels.length - 1).fill(''),
    ],
    [entitiesLabel, ...downloadData.entityLabels, ...downloadData.entityLabels],
    ['Month', ...Array(downloadData.entityLabels.length * 2).fill('')],
  ];
  const dataRows = downloadData.data.map((d) => [
    d.label,
    ...d.values.map((v) => v.count),
    ...d.values.map((v) => v.share),
  ]);
  const sheet = xlsx.aoa_to_sheet([...headers, ...dataRows]);
  sheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: downloadData.entityLabels.length } },
    {
      s: { r: 0, c: downloadData.entityLabels.length + 1 },
      e: { r: 0, c: downloadData.entityLabels.length * 2 },
    },
  ];
  downloadXlsx({ fileName, sheet });
};

export const downloadBottomLineChartV2 = ({
  fileName,
  data,
  name,
  subfilters,
  entitiesLabel,
}: {
  fileName: string;
  data: CompositionV2DataQuery | undefined;
  name: BottomPlotName;
  subfilters: (string | number)[];
  entitiesLabel: string;
}) => {
  const downloadData = getReplotsBottomLineDownloadDataV2({
    entities: data?.composition_v2?.entities ?? [],
    plotName: name,
    subfilters,
    monthsRefTable: data?.composition_v2?.refs?.months ?? [],
  });
  const headers = [
    [
      '',
      'Count',
      ...Array(downloadData.entityLabels.length - 1).fill(''),
      'Share',
      ...Array(downloadData.entityLabels.length - 1).fill(''),
    ],
    [entitiesLabel, ...downloadData.entityLabels, ...downloadData.entityLabels],
    ['Month', ...Array(downloadData.entityLabels.length * 2).fill('')],
  ];
  const dataRows = downloadData.data.map((d) => [
    d.label,
    ...d.values.map((v) => v.count),
    ...d.values.map((v) => v.share),
  ]);
  const sheet = xlsx.aoa_to_sheet([...headers, ...dataRows]);
  sheet['!merges'] = [
    { s: { r: 0, c: 1 }, e: { r: 0, c: downloadData.entityLabels.length } },
    {
      s: { r: 0, c: downloadData.entityLabels.length + 1 },
      e: { r: 0, c: downloadData.entityLabels.length * 2 },
    },
  ];
  downloadXlsx({ fileName, sheet });
};
