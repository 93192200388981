import { getPlotInfo } from '@revelio/composed';
import { Views } from '@revelio/core';
import {
  CompositionSupportedViewTypes,
  EndpointSegment,
  EndpointToNameLookup,
} from '@revelio/filtering';

import { BasePlotConfigLookup } from './base-plots.config';
import { BottomPlotName } from './utils';

export const compositionBottomPlotConfigV2 = ({
  view,
  viewType,
}: {
  view: Views.OVERVIEW;
  viewType: CompositionSupportedViewTypes;
}) => {
  return (
    BasePlotConfigLookup[viewType]
      // Filter out non-bottom plot configurations and map to correct property names
      .filter((config) => {
        // Bottom plots are the ones with subfilters
        return config.subfilters !== undefined;
      })
      .map((config) => {
        // Map endpoint to the correct BottomPlotName
        let plotName: BottomPlotName;
        switch (config.endpoint) {
          case EndpointSegment.ROLE:
            plotName = 'job_categories';
            break;
          case EndpointSegment.GEO:
            plotName = 'geographies';
            break;
          case EndpointSegment.SKILL:
            plotName = 'skills';
            break;
          case EndpointSegment.GENDER:
            plotName = 'genders';
            break;
          case EndpointSegment.ETHNICITY:
            plotName = 'ethnicities';
            break;
          case EndpointSegment.EDUCATION:
            plotName = 'educations';
            break;
          case EndpointSegment.INDUSTRY:
            plotName = 'industries';
            break;
          default:
            throw new Error(`Unknown endpoint: ${config.endpoint}`);
        }

        return {
          plotName,
          title: EndpointToNameLookup[config.endpoint],
          subfilter: config.subfilters,
          plotInfo: getPlotInfo(view, config.endpoint, [viewType]).plotInfoBody,
        };
      })
  );
};
