import { LabeledArrow } from './arrow';

// import { LabeledArrow } from '../arrow/arrow';

export const ARROWS_HEIGHT = 25;

type DirectionArrowsProps = {
  width: number;
  leftLabel?: string;
  rightLabel?: string;
};

/**
 * Direction arrows component that can be placed at the bottom of a BarChart
 * to indicate factors in opposite directions.
 */
export const DirectionArrows = ({
  width,
  leftLabel = 'Factors of Attrition',
  rightLabel = 'Factors of Hiring',
}: DirectionArrowsProps) => {
  // Calculate arrow positioning based on plot width
  let offsetFromCenter;
  let maxWidth;

  // Get the center point of the plot
  const centerX = width / 2;

  if (width >= 500) {
    // Wide plots - fixed offset
    offsetFromCenter = 130;
    maxWidth = undefined;
  } else if (width >= 350 && width <= 400) {
    // Special case for problematic width range ~382px
    offsetFromCenter = width * 0.28;
    maxWidth = width * 0.25; // Force text wrapping
  } else if (width >= 350) {
    // Medium plots
    offsetFromCenter = 100;
    maxWidth = undefined;
  } else {
    // Narrow plots - always wrap text
    offsetFromCenter = Math.max(55, width * 0.28);
    maxWidth = width * 0.25;
  }

  // Calculate positions relative to the center of the plot, not the zero value point
  const arrowLayout = {
    leftX: centerX - offsetFromCenter,
    rightX: centerX + offsetFromCenter,
    maxWidth,
  };

  return (
    <svg width="100%" height="100%">
      <LabeledArrow
        direction="left"
        x={arrowLayout.leftX}
        y={0}
        label={leftLabel}
        maxWidth={arrowLayout.maxWidth}
      />

      <LabeledArrow
        direction="right"
        x={arrowLayout.rightX}
        y={0}
        label={rightLabel}
        maxWidth={arrowLayout.maxWidth}
      />
    </svg>
  );
};
