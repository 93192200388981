import { ChartType, MetricValues, PositionedItem } from './types';
import {
  PrimaryDimensionValues,
  SecondaryDimensionValues,
} from './types/dimension';

type AddBlankItemAction = {
  type: 'ADD_BLANK_ITEM';
  payload: Pick<PositionedItem, 'position'>;
};

type AddItemAction = {
  type: 'ADD_ITEM';
  payload: PositionedItem;
};

type UpdateItemChartTypeAction = {
  type: 'UPDATE_ITEM_CHART_TYPE';
  payload: { id: string; chartType: ChartType };
};

type UpdateItemMetricAction = {
  type: 'UPDATE_ITEM_METRIC';
  payload: { id: string; metric: MetricValues | undefined };
};

type UpdateItemPrimaryDimensionAction = {
  type: 'UPDATE_ITEM_PRIMARY_DIMENSION';
  payload: { id: string; primaryDimension: PrimaryDimensionValues };
};

type UpdateItemSecondaryDimensionAction = {
  type: 'UPDATE_ITEM_SECONDARY_DIMENSION';
  payload: { id: string; secondaryDimension: SecondaryDimensionValues };
};

type SetItemsAction = {
  type: 'SET_ITEMS';
  payload: PositionedItem[];
};

type DeleteItemAction = {
  type: 'DELETE_ITEM';
  payload: { id: string };
};

type ConfigAction =
  | AddBlankItemAction
  | AddItemAction
  | UpdateItemChartTypeAction
  | UpdateItemMetricAction
  | UpdateItemPrimaryDimensionAction
  | UpdateItemSecondaryDimensionAction
  | SetItemsAction
  | DeleteItemAction;

export type ConfigState = {
  items: PositionedItem[];
};

export type ConfigContextType = {
  config: ConfigState;
  dispatch: React.Dispatch<ConfigAction>;
};

export const configReducer = (
  state: ConfigState,
  action: ConfigAction
): ConfigState => {
  switch (action.type) {
    case 'ADD_BLANK_ITEM': {
      const { position } = action.payload;

      const newItem: PositionedItem = {
        position,
        id: crypto.randomUUID(),
        title: 'Chart',
        plot: {
          primaryDimension: 'company',
        },
      };

      return { ...state, items: [...state.items, newItem] };
    }

    case 'ADD_ITEM':
      return { ...state, items: [...state.items, action.payload] };

    case 'UPDATE_ITEM_CHART_TYPE': {
      const { id, chartType } = action.payload;
      const newItems = state.items.map((item): PositionedItem => {
        return item.id === id
          ? { ...item, plot: { ...item.plot, chartType } }
          : item;
      });

      return { ...state, items: newItems };
    }

    case 'UPDATE_ITEM_METRIC': {
      const { id, metric } = action.payload;
      const newItems = state.items.map((item) =>
        item.id === id ? { ...item, plot: { ...item.plot, metric } } : item
      );
      return { ...state, items: newItems };
    }

    case 'UPDATE_ITEM_PRIMARY_DIMENSION': {
      const { id, primaryDimension } = action.payload;
      const newItems = state.items.map((item) =>
        item.id === id
          ? { ...item, plot: { ...item.plot, primaryDimension } }
          : item
      );
      return { ...state, items: newItems };
    }

    case 'UPDATE_ITEM_SECONDARY_DIMENSION': {
      const { id, secondaryDimension } = action.payload;
      const newItems = state.items.map((item) =>
        item.id === id
          ? { ...item, plot: { ...item.plot, secondaryDimension } }
          : item
      );
      return { ...state, items: newItems };
    }

    case 'SET_ITEMS': {
      return { ...state, items: action.payload };
    }

    case 'DELETE_ITEM': {
      const { id } = action.payload;
      const newItems = state.items.filter((item) => item.id !== id);
      return { ...state, items: newItems };
    }

    default:
      return state;
  }
};
