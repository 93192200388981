import { produce } from 'immer';
import { findKey, flatten, isArray, isEqual, map, uniqBy } from 'lodash';

import { generatePermutations } from '@revelio/core';
import {
  CustomColumnCondition,
  CustomColumnConditionVariable,
  DeliverableOutput,
  OperationType,
} from '@revelio/data-access';
import {
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  findSelectionListItemByLabel,
} from '@revelio/filtering';

import { MultiGranularitySelectionListVariablesToSelectionCategories } from './condition-value';
import {
  CUSTOM_COLUMN_VARIABLE_TO_SELECTION_LIST_OVERRIDES_MAP,
  Condition,
  NumericCondition,
  NumericOperation,
  SELECTION_LIST_TO_CUSTOM_COLUMN_VARIABLE_OVERRIDES_MAP,
  SelectionListCondition,
  SelectionListOperation,
  SelectionListVariable,
  SimpleFormTreeItem,
  StringCondition,
  StringOperation,
  isMultiGranularitySelectionListVariable,
  isSelectionListValue,
  isSelectionListVariable,
  isValidCondition,
  isValidCustomColumnCondition,
} from './conditions.model';

interface CustomColumnConditionWithIndex extends CustomColumnCondition {
  index: number;
}

export const generateSerialisedConditionPermutations = (
  conditions: Condition[]
): CustomColumnCondition[][] => {
  const validConditions = conditions.filter(isValidCondition);

  const conditionsWithIndexes = validConditions.map((condition, index) => ({
    ...condition,
    index,
  }));
  const multiGranularityConditions = conditionsWithIndexes.filter((c) =>
    isMultiGranularitySelectionListVariable(c.variable)
  );
  const serializedMultiGranularityConditions = serializeCustomColumnConditions(
    multiGranularityConditions
  );
  const serializedAndConditionedPermutations = generatePermutations(
    serializedMultiGranularityConditions as CustomColumnConditionWithIndex[][]
  );
  const standardConditions = conditionsWithIndexes.filter(
    (c) => !isMultiGranularitySelectionListVariable(c.variable)
  );
  const serializedStandardConditions = serializeCustomColumnConditions(
    standardConditions
  ) as CustomColumnConditionWithIndex[];

  const originalConditionVariableOrder = validConditions.map((c, index) => {
    if (!isMultiGranularitySelectionListVariable(c.variable)) {
      return { variable: c.variable, index } as CustomColumnConditionWithIndex;
    }

    return (
      serializedMultiGranularityConditions[
        multiGranularityConditions.findIndex(
          (mc) => mc.variable === c.variable && isEqual(mc.value, c.value)
        )
      ] as CustomColumnConditionWithIndex[]
    ).map((c) => ({ variable: c.variable, index }));
  });

  return (
    serializedAndConditionedPermutations.map((permutation) =>
      originalConditionVariableOrder.map((variableToOrder) => {
        if (isArray(variableToOrder)) {
          // Find the correct index using the variable and index properties
          const foundCondition = permutation.find((permutationCondition) => {
            return variableToOrder.some(
              (v) =>
                // v.variable === permutationCondition?.value?.variable &&
                v.variable === permutationCondition?.variable &&
                v.index === permutationCondition?.index
            );
          });

          return foundCondition;
        }

        return serializedStandardConditions.find((serializedCondition) => {
          return (
            serializedCondition.variable === variableToOrder.variable &&
            serializedCondition.index === variableToOrder.index
          );
        });
      })
    ) as CustomColumnConditionWithIndex[][]
  ).map((permutation) =>
    permutation.map(
      ({ index, ...conditionWithoutIndex }) => conditionWithoutIndex
    )
  ) as CustomColumnCondition[][];
};

export const serializeCustomColumnConditions = (
  conditions: Condition[]
): (CustomColumnCondition[] | CustomColumnCondition)[] => {
  const validConditions = conditions.filter(isValidCondition);

  return validConditions.map((condition) => {
    if (!isMultiGranularitySelectionListVariable(condition.variable)) {
      return serializeCondition(condition);
    }

    const value = condition.value as SimpleFormTreeItem[];

    const conditionValuesGroupedByVariable = value.reduce(
      (groupedByVariable, v) => {
        const customColumnVariableKey =
          SELECTION_LIST_TO_CUSTOM_COLUMN_VARIABLE_OVERRIDES_MAP[
            v.selectionListId
          ] || v.selectionListId;
        const existingSelectionListGroupedValues =
          groupedByVariable[
            customColumnVariableKey as CustomColumnConditionVariable
          ];
        if (existingSelectionListGroupedValues) {
          return {
            ...groupedByVariable,
            [customColumnVariableKey]: [
              ...existingSelectionListGroupedValues,
              v.item?.label,
            ],
          };
        }

        return {
          ...groupedByVariable,
          [customColumnVariableKey]: [v.item?.label],
        };
      },
      {} as { [key in CustomColumnConditionVariable]?: string[] }
    );

    const brokenUpConditions = map(
      conditionValuesGroupedByVariable,
      (values, variable) => ({
        ...(typeof condition.index == 'number'
          ? { index: condition.index }
          : {}),
        variable: variable as CustomColumnConditionVariable,
        operation: condition.operation as unknown as OperationType,
        value: values as string[],
      })
    );

    return brokenUpConditions;
  });
};

const serializeCondition = (condition: Condition): CustomColumnCondition => {
  return {
    ...(typeof condition.index == 'number' ? { index: condition.index } : {}),
    variable: condition.variable as CustomColumnConditionVariable,
    operation: condition.operation as unknown as OperationType,
    value: (!isSelectionListValue(condition.value)
      ? condition.value
      : (condition.value as unknown as SimpleFormTreeItem[]).map(
          (v) => v.item?.label
        )) as CustomColumnCondition['value'],
  };
};

export const deserializeCustomColumnConditions = ({
  conditions,
  selectionLists,
}: {
  conditions: CustomColumnCondition[];
  selectionLists: SelectionList[];
}): Condition[] => {
  const hasSelectionListConditions = conditions.some((condition) =>
    isSelectionListVariable(condition.variable)
  );
  if (hasSelectionListConditions && !selectionLists.length) {
    return [];
  }

  const validConditions = conditions.filter(isValidCustomColumnCondition);

  const deserializedConditions = validConditions.map((condition) =>
    deserializeCondition({ condition, selectionLists })
  );

  return deserializedConditions.reduce((combinedConditions, condition) => {
    if (!isMultiGranularitySelectionListVariable(condition.variable)) {
      return [...combinedConditions, condition];
    }

    const existingMultiGranularityCondition = combinedConditions.find(
      (c) =>
        c.variable === condition.variable && c.operation === condition.operation
    );
    if (existingMultiGranularityCondition) {
      existingMultiGranularityCondition.value = [
        ...(existingMultiGranularityCondition.value as SimpleFormTreeItem[]),
        ...(condition.value as SimpleFormTreeItem[]),
      ];
      return combinedConditions;
    } else {
      return [...combinedConditions, condition];
    }
  }, [] as Condition[]);
};

const deserializeCondition = ({
  condition,
  selectionLists,
}: {
  condition: CustomColumnCondition;
  selectionLists: SelectionList[];
}): Condition => {
  if (isSelectionListVariable(condition.variable)) {
    const selectionListIdFromCustomColumnVariable =
      (CUSTOM_COLUMN_VARIABLE_TO_SELECTION_LIST_OVERRIDES_MAP[
        condition.variable
      ] || condition.variable) as SelectionCategories;

    return {
      variable: (findKey(
        MultiGranularitySelectionListVariablesToSelectionCategories,
        (combinedVariable) =>
          combinedVariable.includes(selectionListIdFromCustomColumnVariable)
      ) || condition.variable) as SelectionListVariable,
      operation: condition.operation as unknown as SelectionListOperation,
      value: condition.value
        .map((v) => {
          let simpleFormTreeItem: SimpleFormTreeItem = {
            item: {
              id: 0,
              label: 'unknown',
            },
            id: '-1',
            selectionListId: 'unknown' as unknown as SelectionListIdNames,
          };

          const selectionListWithItem = selectionLists.find(
            (list) => list.id === selectionListIdFromCustomColumnVariable
          );
          if (!selectionListWithItem) {
            return simpleFormTreeItem;
          }

          const selectionListItem = findSelectionListItemByLabel({
            labelToFind: v as string,
            selectionList: selectionListWithItem as SelectionList,
          });
          if (selectionListItem && selectionListItem.length > 0) {
            simpleFormTreeItem = selectionListItem[0] as SimpleFormTreeItem;
          }

          return simpleFormTreeItem;
        })
        .filter((item) => item.id !== '-1'),
    };
  }

  // string and numeric condition don't require any deserialisation
  return {
    variable: condition.variable,
    operation: condition.operation as unknown as
      | NumericOperation
      | StringOperation,
    value: condition.value,
  } as NumericCondition | StringCondition;
};

export const getRequiredSelectionListNamesForCustomStepDeserialisation = ({
  deliverables,
}: {
  deliverables?: DeliverableOutput[];
}) => {
  const conditions = flatten(
    deliverables?.map((deliverable) => {
      const customColumnConditions = flatten(
        deliverable.pipeline.custom_columns?.map((customColumn) =>
          flatten(
            customColumn?.step
              ?.map((step) => step?.case?.conditions)
              .filter((x) => x) // to get rid of else step without conditions
          )
        )
      );
      const filterConditions = flatten(
        deliverable.pipeline.filters?.map((filter) => filter?.conditions)
      );
      return [...customColumnConditions, ...filterConditions];
    })
  );

  return Array.from(
    new Set(
      conditions.reduce((selectionLists, condition) => {
        const nonEmptyCondition = condition as CustomColumnCondition;
        if (isSelectionListVariable(nonEmptyCondition.variable)) {
          return [
            ...selectionLists,
            (CUSTOM_COLUMN_VARIABLE_TO_SELECTION_LIST_OVERRIDES_MAP[
              nonEmptyCondition.variable
            ] || nonEmptyCondition.variable) as SelectionCategories,
          ];
        }

        return selectionLists;
      }, [] as SelectionListIdNames[])
    )
  );
};

export const combineDeserializedCustomColumnConditions = ({
  existingConditions,
  conditionsToAdd,
}: {
  existingConditions: Condition[];
  conditionsToAdd: Condition[];
}) => {
  return produce(existingConditions, (draft) => {
    draft.forEach((existingCond) => {
      if (isMultiGranularitySelectionListVariable(existingCond.variable)) {
        const deserializedStepToAddValues = (
          conditionsToAdd.find(
            (c) => c?.variable === existingCond.variable
          ) as SelectionListCondition
        ).value;

        (existingCond.value as SimpleFormTreeItem[]).push(
          ...(deserializedStepToAddValues || [])
        );

        existingCond.value = uniqBy(
          existingCond.value as SimpleFormTreeItem[],
          'id'
        );
      }
    });
  });
};
