import { useEffect, useMemo, useState } from 'react';
import styles from './line-chart.module.css';
import {
  bisector,
  curveBasis,
  extent,
  format,
  group,
  line,
  mean,
  precisionFixed,
  scaleLinear,
  scaleOrdinal,
  scaleTime,
} from 'd3';
import { plotColors } from '../utilities/plot-colors';
import { PlotTooltip } from '../plot-tooltip/plot-tooltip';
import { useResizeObserver } from '@revelio/core';

export type LineDatum = {
  date: Date;
  value: number;
  secondaryValue?: number;
};

export type LineComponentData = {
  label: string;
  values: LineDatum[];
};

type LineChartProps = {
  data: LineComponentData[];
  valueFormat?: '%' | ',' | '.' | '$' | ((value: number) => string);
  // valueFormat?: '%';
  secondaryValueFormat?: ',';
  colors?: string[];
};

const Y_AXIS_WIDTH = 32;
const X_AXIS_HEIGHT = 12;
const CHART_PADDING_TOP = 8;

const bisect = bisector<Date, Date>((d) => d).center;

type TooltipRow = {
  color: string;
  label: string;
  value: string;
};

export const LineChartComponent = ({
  data,
  valueFormat = '%',
  secondaryValueFormat = ',',
  colors = plotColors,
}: LineChartProps) => {
  const { containerRef, width, height } = useResizeObserver();

  const plotWidth = width - Y_AXIS_WIDTH;
  const plotHeight = height - X_AXIS_HEIGHT - CHART_PADDING_TOP;

  /** ================================
   * Color Scale
  ================================ */
  const colorsFilled = useMemo(
    () => [...colors, ...plotColors.filter((c) => !colors.includes(c))],
    [colors]
  );
  const colorScale = useMemo(
    () =>
      scaleOrdinal<string, string>()
        .domain(data.map((d) => d.label))
        .range(colorsFilled),
    [data, colorsFilled]
  );
  /** ================================
   * X Axis
   ================================ */
  const groupedData = useMemo(
    () =>
      group(
        data.flatMap((d) => d.values.map((v) => ({ ...v, label: d.label }))),
        (d) => d.date
      ),
    [data]
  );

  const allDates = useMemo(() => Array.from(groupedData.keys()), [groupedData]);
  const xScale = useMemo(() => {
    const dateDomain = extent(allDates);

    if (dateDomain[0] === undefined || dateDomain[1] === undefined) return null;

    return scaleTime().domain(dateDomain).range([0, plotWidth]);
  }, [allDates, plotWidth]);

  const xTicks = useMemo(
    () =>
      xScale
        ?.ticks(5)
        .map((tick) => ({ value: tick, label: xScale?.tickFormat()(tick) })),
    [xScale]
  );

  /** ================================
   * Y Axis
   ================================ */
  const yScale = useMemo(() => {
    const allValues = data.flatMap((d) => d.values.map((v) => v.value));

    const domain = extent(allValues);
    if (domain[0] === undefined || domain[1] === undefined) return null;

    return scaleLinear().domain(domain).range([plotHeight, 0]);
  }, [data, plotHeight]);

  const { yTicks, valueFormatter } = useMemo(() => {
    if (!yScale) return { yTicks: null, valueFormatter: format(`.2%`) };

    const ticks = yScale.ticks(3);

    const minStep = Math.min(
      ...ticks.slice(1).map((tick, i) => tick - ticks[i])
    );
    const p = (() => {
      switch (valueFormat) {
        case '%':
          return Math.max(0, precisionFixed(minStep) - 2);

        default:
          return 0;
      }
    })();

    const valueFormatter = (() => {
      if (typeof valueFormat === 'function') return valueFormat;

      switch (valueFormat) {
        case '%':
          return format(`.${p}%`);
        case ',':
          return format(',');
        case '.':
          return format('0,.1');
        case '$':
          return format('$');
      }
    })();

    return {
      yTicks: ticks.map((tick) => ({
        value: tick,
        label: valueFormatter(tick),
      })),
      valueFormatter,
    };
  }, [yScale, valueFormat]);

  /** ================================
   * Line Path
   ================================ */
  const lines = useMemo(() => {
    if (!xScale || !yScale) return [];

    const lineGenerator = line<LineDatum>()
      .x((d) => xScale(d.date))
      .y((d) => yScale(d.value))
      .curve(curveBasis);

    return data
      .map((d) => ({ ...d, path: lineGenerator(d.values) }))
      .filter((d): d is LineComponentData & { path: string } => !!d);
  }, [data, xScale, yScale]);

  /** ================================
   * Tooltip
   ================================ */
  const [bisectDate, setBisectDate] = useState<Date>();
  const handleTooltipMove = (position: [number, number]) => {
    if (!xScale) return;

    const date = xScale.invert(position[0]);
    const bisectDate = allDates[bisect(allDates, date)];

    setBisectDate(bisectDate);
  };

  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const [tooltipRows, setTooltipRows] = useState<TooltipRow[]>([]);
  useEffect(() => {
    if (!bisectDate || !xScale || !yScale) return;

    const data = groupedData.get(bisectDate);
    if (!data) return;

    const avgValue = mean(data, (d) => d.value);
    if (!avgValue) return;

    setTooltipPosition({ x: xScale(bisectDate), y: yScale(avgValue) });

    const secondaryValueFormatter = (() => {
      switch (secondaryValueFormat) {
        case ',':
          return format(`,.0f`);
      }
    })();

    const rows = data.map((d) => ({
      color: colorScale(d.label),
      label: d.label,
      value: `${valueFormatter(d.value)}${d.secondaryValue ? `/ ${secondaryValueFormatter(d.secondaryValue)}` : ''}`,
    }));
    const sortedRows = [...rows].sort((a, b) => b.value.localeCompare(a.value));

    setTooltipRows(sortedRows);
  }, [
    bisectDate,
    xScale,
    yScale,
    groupedData,
    colorScale,
    valueFormatter,
    secondaryValueFormat,
  ]);

  /**
   * Make the tooltip a div outside of the svg. Then use a custom hoom to pass in a reference to the
   * tooltip rect area that listens for mouse events, and returns the position and visibility of the
   * tooltip. The tooltip position will be relative to the container, so the hook can calculate the
   * tooltip trigger area's offset position from the container origin as well
   */
  return (
    <div ref={containerRef} className={styles['container']}>
      <svg width="100%" height="100%">
        <g transform={`translate(0, ${CHART_PADDING_TOP})`}>
          <g id="y-axis" data-testid="y-axis" className={styles['yAxis']}>
            {plotHeight > 0 &&
              yTicks?.map((tick, i) => (
                <text
                  key={i}
                  x={Y_AXIS_WIDTH / 2}
                  y={yScale?.(tick.value)}
                  alignmentBaseline="middle"
                >
                  {tick.label}
                </text>
              ))}
          </g>
          <g
            id="x-axis"
            data-testid="x-axis"
            transform={`translate(${Y_AXIS_WIDTH}, ${plotHeight})`}
            className={styles['xAxis']}
          >
            {plotWidth > 0 &&
              xTicks?.map((tick, i) => (
                <text
                  key={i}
                  transform={`translate(${xScale?.(tick.value)}, 0)`}
                  y={9}
                >
                  {tick.label}
                </text>
              ))}
          </g>
          {plotWidth > 0 && plotHeight > 0 && (
            <g id="chart" transform={`translate(${Y_AXIS_WIDTH}, 0)`}>
              <g id="axis-grid">
                {yScale &&
                  yTicks?.map((tick, i) => (
                    <line
                      key={i}
                      x1={0}
                      x2={plotWidth}
                      y1={yScale(tick.value)}
                      y2={yScale(tick.value)}
                      className={styles['axisGridLine']}
                    />
                  ))}
              </g>
              <g id="plot">
                {lines.map((line, i) => (
                  <path
                    key={i}
                    d={line.path}
                    stroke={colorScale(line.label)}
                    className={styles['line']}
                  />
                ))}
              </g>
              <PlotTooltip
                x={tooltipPosition.x}
                y={tooltipPosition.y}
                plotWidth={plotWidth}
                plotHeight={plotHeight}
                onHoverPosition={handleTooltipMove}
              >
                <div>
                  <div className={styles['tooltipTitle']}>
                    {`${bisectDate?.toLocaleString('default', { month: 'long' })} ${bisectDate?.getFullYear()}`}
                  </div>
                  {tooltipRows.map((row, i) => (
                    <div key={i} className={styles['tooltipRow']}>
                      <div className={styles['tooltipLabel']}>
                        <div
                          className={styles['tooltipColor']}
                          style={{ backgroundColor: row.color }}
                        />
                        {row.label}
                      </div>
                      <div className={styles['tooltipValue']}>{row.value}</div>
                    </div>
                  ))}
                </div>
              </PlotTooltip>
            </g>
          )}
        </g>
      </svg>
    </div>
  );
};
