import {
  Box,
  Flex,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect } from 'react';

import { useTalentDiscoveryFilter } from '../../td-filter-provider';
import { AiFilterSearch } from './ai-filter-search';
import { AiSearchButton } from './ai-search-button';

export const EditAiSearchButton = () => {
  const { aiGeneratedFilterIds, isAiSearchLoading, state, defaultAiSearchSet } =
    useTalentDiscoveryFilter();

  const someFiltersAreAiGenerated = state.filters?.some((filter) =>
    aiGeneratedFilterIds.includes(filter.id)
  );
  const {
    isOpen: isFilterMenuOpen,
    onClose: handleCloseFilterMenu,
    onOpen: handleOpenFilterMenu,
  } = useDisclosure();

  useEffect(() => {
    if (defaultAiSearchSet) {
      handleOpenFilterMenu();
    }
  }, [defaultAiSearchSet, handleOpenFilterMenu]);

  if (!someFiltersAreAiGenerated || isAiSearchLoading) {
    return null;
  }

  return (
    <Popover
      placement="bottom-start"
      closeOnBlur={!isAiSearchLoading}
      onClose={handleCloseFilterMenu}
      isOpen={isFilterMenuOpen}
      onOpen={handleOpenFilterMenu}
      autoFocus={false}
      gutter={0}
    >
      <PopoverTrigger>
        <Box>
          <AiSearchButton isGenerating={false} />
        </Box>
      </PopoverTrigger>
      <PopoverContent
        minWidth="325px"
        padding={3}
        color="navyBlue.500"
        boxShadow="2xl"
        data-testid={`td-filter-popover-content-${isFilterMenuOpen ? 'open' : 'closed'}`}
        width="fit-content"
      >
        <Flex direction="column" gap="4">
          <AiFilterSearch closeMenu={handleCloseFilterMenu} />
        </Flex>
      </PopoverContent>
    </Popover>
  );
};
