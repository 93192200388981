import { ComponentProps } from 'react';

import { PrimaryView, TimeFrameView } from '@revelio/core';
import { D3ChartNames } from '@revelio/d3';
import { CompositionV2DataQuery } from '@revelio/data-access';
import {
  CompositionSupportedViewTypes,
  FilterList,
  RoleSubFilter,
  SelectFilter,
  SubFilter,
  SubFilterNames,
  SubFilterProps,
  ViewTypes,
  useAllFiltersState,
  useRoleTaxonomySetting,
} from '@revelio/filtering';
import {
  Format,
  LineChart,
  OTHER_COLOR,
  StackedBarChartHorizontal,
} from '@revelio/replots';

import { PlotCard } from '../../shared/components/plot-card/plot-card';
import {
  BottomPlotName,
  BottomPlotNameLookup,
  downloadBottomLineChartV2,
  downloadStackedBottomChart,
  useIsLegacyDownloadUser,
  useLegacyDataDownloader,
} from './utils';
import {
  getReplotsBottomLineDataV2,
  getReplotsStackedBarDataV2,
} from './utils/get-chart-data';

const CompositionSupportedViewTypesLookup: Record<
  CompositionSupportedViewTypes,
  string
> = {
  [ViewTypes.COMPANY]: 'Company',
  [ViewTypes.GEO]: 'Geography',
  [ViewTypes.ROLE]: 'Role',
};

interface CompositionBottomPlotProps
  extends Pick<
    ComponentProps<typeof PlotCard>,
    'title' | 'info' | 'infoPlacement'
  > {
  primaryView: PrimaryView;
  data: CompositionV2DataQuery | undefined;
  timeFrame: TimeFrameView;
  name: BottomPlotName;
  format?: Format;
  viewType: CompositionSupportedViewTypes;
  queryFilters: object;
  subfilter: SubFilterProps;
  lineChartColors: string[];
}

export const CompositionBottomPlotV2 = ({
  primaryView,
  timeFrame,
  subfilter,
  lineChartColors,
  ...rest
}: CompositionBottomPlotProps) => {
  const plotType = timeFrame === TimeFrameView.SNAPSHOT ? 'stacked' : 'line';

  if (plotType === 'stacked') {
    return (
      <StackedBottomPlot
        primaryView={primaryView}
        timeFrame={timeFrame}
        {...rest}
      />
    );
  }
  return (
    <LineBottomPlot
      primaryView={primaryView}
      subfilter={subfilter}
      lineChartColors={lineChartColors}
      timeFrame={timeFrame}
      {...rest}
    />
  );
};

const StackedBottomPlot = ({
  primaryView,
  data,
  name,
  format,
  title,
  viewType,
  timeFrame,
  queryFilters,
  ...plotCardProps
}: Omit<CompositionBottomPlotProps, 'subfilter' | 'lineChartColors'>) => {
  const stackedPlotData = getReplotsStackedBarDataV2({
    entities: data?.composition_v2?.entities ?? [],
    plotName: name,
    formatLabel:
      name === 'skills'
        ? (label) => label.split('/').shift() ?? label
        : undefined,
  });

  const isLegacyDownloadUser = useIsLegacyDownloadUser();
  const download = useLegacyDataDownloader();

  return (
    <PlotCard
      title={title}
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      plotShare={{
        data: stackedPlotData,
        chartConfig: {
          chartType: D3ChartNames.ReplotsStackedBarChartHorizontal,
          chartProps: { format: format },
        },
      }}
      onExport={async () => {
        if (isLegacyDownloadUser) {
          await download({
            view: primaryView,
            plotName: name,
            timeframe: timeFrame,
            filters: queryFilters,
          });
        } else {
          return downloadStackedBottomChart({
            data: stackedPlotData,
            chartName: BottomPlotNameLookup[name],
            fileName: BottomPlotNameLookup[name].toLowerCase(),
            entitiesLabel: CompositionSupportedViewTypesLookup[viewType],
          });
        }
      }}
      {...plotCardProps}
    >
      <StackedBarChartHorizontal
        data={stackedPlotData}
        format={format}
        colorOverride={{ Other: OTHER_COLOR }}
      />
    </PlotCard>
  );
};

const LineBottomPlot = ({
  primaryView,
  data,
  name,
  format,
  title,
  viewType,
  timeFrame,
  queryFilters,
  subfilter,
  lineChartColors,
  ...plotCardProps
}: CompositionBottomPlotProps) => {
  const allFilters = useAllFiltersState();
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const useCustomRoleSubFilter = (() => {
    const isRoleFilter = name === 'job_categories';
    const isCompanyView = viewType === ViewTypes.COMPANY;
    return isRoleFilter && isCustomRoleTaxonomyEnabled && isCompanyView;
  })();

  const FilterComponent = useCustomRoleSubFilter ? (
    <RoleSubFilter {...subfilter} />
  ) : (
    <SubFilter {...subfilter} />
  );

  const subfilters = (() => {
    const subFilterMapping: Record<BottomPlotName, SubFilterNames> = {
      job_categories: SubFilterNames.SUB_ROLE,
      geographies: SubFilterNames.SUB_REGION,
      skills: SubFilterNames.SUB_SKILL,
      genders: SubFilterNames.SUB_GENDER,
      ethnicities: SubFilterNames.SUB_ETHNICITY,
      educations: SubFilterNames.SUB_EDUCATION,
      industries: SubFilterNames.SUB_INDUSTRY,
    };

    const selectedSubFilter = subFilterMapping[name];
    const filterList = selectedSubFilter
      ? (allFilters.find((filter) => filter.id === selectedSubFilter) as
          | SelectFilter<FilterList>
          | undefined)
      : undefined;

    return filterList?.value?.map((sub) => sub?.id) ?? [];
  })();

  const linePlotData = getReplotsBottomLineDataV2({
    entities: data?.composition_v2?.entities ?? [],
    plotName: name,
    months: data?.composition_v2?.refs?.months ?? [],
    subfilters,
  });

  const isLegacyDownloadUser = useIsLegacyDownloadUser();
  const download = useLegacyDataDownloader();

  return (
    <PlotCard
      title={title}
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      onExport={async () => {
        if (isLegacyDownloadUser) {
          await download({
            view: primaryView,
            plotName: name,
            timeframe: timeFrame,
            filters: queryFilters,
          });
        } else {
          return downloadBottomLineChartV2({
            data: data,
            name: name,
            subfilters,
            fileName: BottomPlotNameLookup[name].toLowerCase(),
            entitiesLabel: CompositionSupportedViewTypesLookup[viewType],
          });
        }
      }}
      plotShare={{
        data: linePlotData,
        chartConfig: {
          chartType: D3ChartNames.ReplotsLineChart,
          chartProps: { format, colors: lineChartColors },
        },
      }}
      topRight={FilterComponent}
      {...plotCardProps}
    >
      <LineChart
        data={linePlotData}
        format={format}
        dateFormat="month"
        colors={lineChartColors}
      />
    </PlotCard>
  );
};
