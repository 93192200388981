import { Button, Image } from '@chakra-ui/react';
import { useState } from 'react';

import { useGetLoggedInUser } from '@revelio/auth';
import { SourceWhaleTrackingEvents } from '@revelio/core';
import { userTracking } from '@revelio/core';
import { MetadataKey, TalentDiscoveryUser } from '@revelio/data-access';

import { useUserMetadata } from '../../../../hooks/admin/useUserMetadata';
import sourceWhaleIcon from '../../../../images/icons/sourcewhale-icon.png';
import { useSourceWhaleApiKey } from '../hooks/use-source-whale-api-key';
import { SourceWhaleExportModal } from './source-whale-export-modal';
import { SourceWhaleOnboardingModal } from './source-whale-onboarding-modal';

interface SourceWhaleExportButtonProps {
  disabled: boolean;
  selectedUsers: TalentDiscoveryUser[];
}

export const SourceWhaleExportButton = ({
  disabled,
  selectedUsers,
}: SourceWhaleExportButtonProps) => {
  const { apiKey: sourceWhaleApiKey } = useSourceWhaleApiKey();
  const { loggedInUser } = useGetLoggedInUser();
  const { updateMetadata } = useUserMetadata();

  const [isExportModalOpen, setIsExportModalOpen] = useState(false);
  const [isOnboardingModalOpen, setIsOnboardingModalOpen] = useState(false);
  const [apiKey, setApiKey] = useState<string | null>(sourceWhaleApiKey);

  const handleSourceWhaleClick = () => {
    if (!sourceWhaleApiKey) {
      userTracking.track(SourceWhaleTrackingEvents.ONBOARDING_OPEN);
      setIsOnboardingModalOpen(true);
    } else {
      userTracking.track(SourceWhaleTrackingEvents.EXPORT_OPEN);
      setIsExportModalOpen(true);
    }
  };

  const handleSaveApiKey = async (apiKey: string) => {
    const success = await updateMetadata(loggedInUser, [
      {
        key: MetadataKey.SourcewhaleKey,
        value: apiKey,
      },
    ]);

    if (success) {
      setApiKey(apiKey);
      setIsExportModalOpen(true);
    } else {
      console.error('Failed to save SourceWhale API key');
    }
  };

  return (
    <>
      <Button
        data-testid="add-to-sourcewhale-button"
        size="sm"
        height="24px"
        variant="white"
        isDisabled={disabled}
        onClick={() => handleSourceWhaleClick()}
        leftIcon={<Image src={sourceWhaleIcon} alt="SourceWhale" boxSize={4} />}
      >
        Reach Out
      </Button>

      <SourceWhaleOnboardingModal
        savedApiKey={apiKey}
        isOpen={isOnboardingModalOpen}
        onClose={() => setIsOnboardingModalOpen(false)}
        onSave={handleSaveApiKey}
      />
      {apiKey && (
        <SourceWhaleExportModal
          apiKey={apiKey}
          isOpen={isExportModalOpen}
          onClose={() => setIsExportModalOpen(false)}
          selectedUsers={selectedUsers}
          onEditApiKey={() => setIsOnboardingModalOpen(true)}
        />
      )}
    </>
  );
};
