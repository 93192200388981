import { useCallback, useEffect, useRef } from 'react';

import { SelectionListControls } from '../SelectionListControls';
import { TreeApi, TreeData } from '../tree';
import { PopoutTree, PopoutTreeProps } from './PopoutTree';
import styles from './PopoutTreeFilter.module.css';
import { BasePopoutTreeCommonProps } from './types';
import { useTreeSelections } from './use-tree-selections';

export interface BasePopoutTreeFilterProps extends BasePopoutTreeCommonProps {
  treeData: TreeData[];
  activeIds: string[];
  maxTopLevelSelections?: number;
  searchInputRef?: PopoutTreeProps['searchInputRef'];
  onSubmit: (selectedItems: string[]) => void;
}

export function BasePopoutTreeFilter({
  treeData,
  activeIds,
  filterLabel,
  minSelections = 0,
  maxSelections,
  maxTopLevelSelections,
  branches,
  disabledLevels,
  disallowMultiLevelSelect = false,
  sortSuggestedSearch = false,
  className,
  isOpen,
  searchInputRef,
  onSubmit,
  onClose,
}: BasePopoutTreeFilterProps) {
  const {
    selections,
    setSelections,
    onChange,
    onClear,
    validateMinSelections,
  } = useTreeSelections({
    initialSelections: activeIds,
    disallowMultiLevelSelect,
    maxSelections,
    minSelections,
    filterLabel,
    maxTopLevelSelections,
  });

  useEffect(() => {
    setSelections(activeIds);
  }, [activeIds, setSelections]);

  const resetSelections = useCallback(() => {
    setSelections(activeIds);
  }, [activeIds, setSelections]);

  const popoutTreeRef = useRef<TreeApi>();
  useEffect(() => {
    resetSelections();
    const treeApi = popoutTreeRef.current;
    if (isOpen && treeApi) treeApi.onSearch('');
  }, [isOpen, resetSelections]);

  const handleSubmit = () => {
    if (!validateMinSelections()) {
      return;
    }

    onSubmit(selections);

    if (onClose) {
      onClose();
    }
  };

  if (!treeData || treeData.length === 0) {
    return null;
  }

  return (
    <div className={className}>
      <PopoutTree
        data={treeData}
        value={selections}
        disabledLevels={disabledLevels}
        onChange={onChange}
        branches={branches}
        sortSuggestedSearch={sortSuggestedSearch}
        ref={popoutTreeRef}
        searchInputRef={searchInputRef}
      />
      <SelectionListControls
        className={styles.selectionListControls}
        onClear={onClear}
        onClose={onClose}
        onSubmit={handleSubmit}
        submitText={activeIds.length ? 'Update' : 'Add'}
      />
    </div>
  );
}
