import { SentimentFilters } from '@revelio/data-access';
import { Filter, SelectionCategories } from '@revelio/filtering';

import { getCommonFilters, isFilterList } from './common';

type FiltersKey = keyof SentimentFilters;

/** ======== Filter List ======== */
const filterIdsToFilterKeys: Partial<Record<SelectionCategories, FiltersKey>> =
  {
    [SelectionCategories.RICS_K10]: 'rics_k10',
    [SelectionCategories.RICS_K50]: 'rics_k50',
    [SelectionCategories.RICS_K400]: 'rics_k400',
    [SelectionCategories.INDUSTRY]: 'industry',
    [SelectionCategories.COMPANY]: 'company',

    [SelectionCategories.REGION]: 'region',
    [SelectionCategories.COUNTRY]: 'country',
    [SelectionCategories.MSA]: 'msa',
    [SelectionCategories.METRO_AREA]: 'msa',

    [SelectionCategories.JOB_CATEGORY]: 'job_category',
    [SelectionCategories.ROLE_K50]: 'role_k50',
    [SelectionCategories.ROLE_K150]: 'role_k150',
    [SelectionCategories.ROLE_K1500]: 'role_k1500',

    [SelectionCategories.SENIORITY]: 'seniority',
  };

const isFilterListId = (filterId: string): filterId is FiltersKey =>
  filterIdsToFilterKeys[filterId as SelectionCategories] !== undefined;

/** ================================
 * Get Sentiment Filters
 ================================ */
export const getSentimentFilters = (filters: Filter[]): SentimentFilters =>
  filters.reduce<SentimentFilters>((acc, filter): SentimentFilters => {
    const commonAcc = getCommonFilters(acc, filter);

    if (isFilterListId(filter.id) && isFilterList(filter)) {
      const mappedKey = filterIdsToFilterKeys[filter.id as SelectionCategories];
      if (!mappedKey) return acc;
      return {
        ...commonAcc,
        [mappedKey]: filter.value.map((item) => item.id),
      };
    }

    return commonAcc;
  }, {});
