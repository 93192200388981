/* eslint-disable prefer-const */
import { METRIC_MODE_IDS } from '@revelio/data-access';

import { FilterSets, ViewTypes } from '../data-api/data-api.model';
import { PopoutTreeProps } from '../filter-components/PopoutTree';
import { TNode } from '../filter-components/tree/Node';
import {
  FilterLabelOverrides,
  FilterList,
  FilterOrSubfilterName,
  FilterParameterKeys,
  FilterSetLabelOverrides,
  FilteringInitConfig,
  LocalSelectionCategories,
  OPValues,
  OperatorOptionsType,
  OtherFilterNames,
  QueryParameterKeys,
  RoleSelectionCategories,
  SelectFilter,
  SelectionCategories,
  SelectionListIdNames,
  SubFilterNames,
  ValidValueTypes,
} from './filters.model';

export let API_ROOT = '';
export let GO_API_ROOT = '';
export let COMPANY_MAPPER_URL = '';

export function initFiltering(config: FilteringInitConfig) {
  API_ROOT = config.apiRoot;
  GO_API_ROOT = config.goApiRoot;
  COMPANY_MAPPER_URL = config.companyMapperUrl;
}

export const FiltersUsedInTabs = [
  LocalSelectionCategories.BASE_SALARY_OR_TOTAL_COMP,
  LocalSelectionCategories.SNAPSHOT_OR_OVER_TIME,
  LocalSelectionCategories.INFLOW_OR_OUTFLOW,
];

export const FiltersUsedInSelectSubfilters = [
  LocalSelectionCategories.N_ITEMS_SANKEY,
];

export const DateMonthFilterNames = [
  SelectionCategories.DATE_RANGE,
  SelectionCategories.DATE_RANGE_FULL,
  OtherFilterNames.LAST_MONTH,
  OtherFilterNames.LAST_START_DATE,
  SelectionCategories.START_TIME,
  SelectionCategories.END_TIME,
];

export const REPORT_COMPANY_FILTERS = [SelectionCategories.COMPANY_REPORT];

export const INDUSTRY_AND_COMPANY_FILTERS = [
  SelectionCategories.COMPANY,
  SelectionCategories.INDUSTRY,
];

export const ALL_ROLE_FILTERS: RoleSelectionCategories[] = [
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K7,
  SelectionCategories.ROLE_K50,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K300,
  SelectionCategories.ROLE_K500,
  SelectionCategories.ROLE_K1250,
  SelectionCategories.ROLE_K1500,
];

export const CUSTOM_ROLE_GRANULARITY_FILTERS = [
  SelectionCategories.ROLE_K7,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
];

export const ROLE_GRANULARITY_FILTERS = [
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K150,
  SelectionCategories.ROLE_K1500,
];

export const RICS_FILTERS = [
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
];

export const RICS_AND_COMPANY_FILTERS = [
  SelectionCategories.COMPANY,
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
];

export const RICS_AND_COMPANY_FILTERS_SORTED = [
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.COMPANY,
];

export const rics400BranchLabels: PopoutTreeProps['branches'] = (
  parentNode
) => {
  const { level, data } = parentNode;
  const label = (() => {
    switch (level) {
      case -1:
        return 'Industry k10';

      case 0:
        return `${data.name} k50`;

      case 1:
        return `${data.name} k400`;

      default:
        return 'Industry';
    }
  })();

  return { label };
};

export const ricsAndCompanyBranchLabels: PopoutTreeProps['branches'] = (
  parentNode: TNode
) => {
  const { level, data } = parentNode;
  const label = (() => {
    switch (level) {
      case -1:
        return 'Industry k10';

      case 0:
        return `${data.name} k50`;

      case 1:
        return `${data.name} Companies`;

      default:
        return 'Industry';
    }
  })();

  return { label };
};

export const roleBranchLabels: PopoutTreeProps['branches'] = (
  parentNode: TNode
) => {
  const { level, data } = parentNode;
  const { label, description } = (() => {
    switch (level) {
      case -1:
        return {
          label: 'Role k7',
          description:
            'Role k7 is a set of distinct occupations. At this level of granularity, roles are bucketed into 7 parts.',
        };

      case 0:
        return {
          label: `${data.name} k150`,
          description:
            'Role k150 is a set of distinct occupations. At this level of granularity, roles are bucketed into 150 parts.',
        };

      case 1:
        return {
          label: `${data.name} k1500`,
          description:
            'Role k1500 is a set of distinct occupations. At this level of granularity, roles are bucketed into 1,500 parts.',
        };

      default:
        return {
          label: 'Role',
          description: 'Role is a set of distinct occupations',
        };
    }
  })();

  return { label, description };
};

export const geographyBranchLabels: PopoutTreeProps['branches'] = (
  parentNode: TNode
) => {
  const { level } = parentNode;
  const label = (() => {
    switch (level) {
      case -1:
        return 'Region';

      case 0:
        return `Country`;

      case 1:
        return `Metro Area`;

      default:
        return 'Region';
    }
  })();

  return { label };
};

export const skillsBranchLabels: PopoutTreeProps['branches'] = (
  parentNode: TNode
) => {
  const { level, data } = parentNode;
  const label = (() => {
    switch (level) {
      case -1:
        return 'Skill k75';

      case 0:
        return formatSkillBranchLabel(data.name, 'k700');

      case 1:
        return formatSkillBranchLabel(data.name, 'k700');

      default:
        return 'Skills';
    }
  })();

  return { label };
};

const formatSkillBranchLabel = (skill: string, granularity: string) => {
  return `${skill.split('/')[0].trim()} ... ${granularity}`;
};

export const GEOGRAPHY_GRANULARITY_FILTERS = [
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
];

export const GEOGRAPHY_GRANULARITY_FILTERS_WITH_STATE = [
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.STATE,
  SelectionCategories.METRO_AREA,
];

export const SKILL_GRANULARITY_FILTERS = [
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
];

export type NestedEntity = {
  selectionListIds: SelectionCategories[];
  filterLabel: string;
  branches: PopoutTreeProps['branches'];
  disallowMultiLevelSelect?: boolean;
  sortSuggestedSearch?: boolean;
};

export type NestedEntityViewTypes =
  | ViewTypes.COMPANY
  | ViewTypes.SKILLS
  | ViewTypes.ROLE
  | ViewTypes.GEO;

export function getNestedEntity(viewType: NestedEntityViewTypes): NestedEntity;
export function getNestedEntity(
  viewType: Exclude<ViewTypes, NestedEntityViewTypes>
): undefined;
export function getNestedEntity(viewType: ViewTypes): NestedEntity | undefined {
  switch (viewType) {
    case ViewTypes.COMPANY:
      return {
        selectionListIds: RICS_AND_COMPANY_FILTERS_SORTED,
        filterLabel: 'Companies/Industries',
        branches: ricsAndCompanyBranchLabels,
        sortSuggestedSearch: true,
      };
    case ViewTypes.SKILLS:
      return {
        selectionListIds: SKILL_GRANULARITY_FILTERS,
        filterLabel: 'Skills',
        branches: skillsBranchLabels,
      };
    case ViewTypes.ROLE:
      return {
        selectionListIds: ROLE_GRANULARITY_FILTERS,
        filterLabel: 'Roles',
        branches: roleBranchLabels,
        disallowMultiLevelSelect: true,
        sortSuggestedSearch: true,
      };
    case ViewTypes.GEO:
      return {
        selectionListIds: GEOGRAPHY_GRANULARITY_FILTERS,
        filterLabel: 'Regions/Countries/Metro Areas',
        branches: geographyBranchLabels,
      };
    default:
      return undefined;
  }
}

export const queryParamKeyLookup: {
  [key in SelectionListIdNames]?: string;
} = {
  [SelectionCategories.SNAPSHOT_DATE]: FilterParameterKeys.END_DATE,
  [LocalSelectionCategories.INFLOW_OR_OUTFLOW]: QueryParameterKeys.INFLOW,
};

export const presetFilterKeyLookup = {
  ...queryParamKeyLookup,
  [SelectionCategories.ROLE_K50]: FilterParameterKeys.ROLEK50,
  [SelectionCategories.ROLE_K150]: FilterParameterKeys.ROLEK150,
  [SelectionCategories.ROLE_K500]: FilterParameterKeys.ROLEK500,
  [SelectionCategories.ROLE_K1500]: FilterParameterKeys.ROLEK1500,
};

export const filterSetLabelLookup: { [key: string]: FilterSetLabelOverrides } =
  {
    // COMPANY
    [FilterSets.COMPANY_OVERVIEW]: FilterSetLabelOverrides.COMPANY_COMPOSITIONS,
    [FilterSets.COMPANY_TRANSITIONS]:
      FilterSetLabelOverrides.COMPANY_TRANSITIONS,
    [FilterSets.COMPANY_POSTINGS]: FilterSetLabelOverrides.COMPANY_POSTINGS,
    [FilterSets.COMPANY_SENTIMENT_RATINGS]:
      FilterSetLabelOverrides.COMPANY_SENTIMENT_RATINGS,
    [FilterSets.COMPANY_SENTIMENT_EFFECTS]:
      FilterSetLabelOverrides.COMPANY_SENTIMENT_EFFECTS,

    // GEOGRAPHY
    [FilterSets.GEOGRAPHY]: FilterSetLabelOverrides.GEOGRAPHY_COMPOSITIONS,
    [FilterSets.GEOGRAPHY_TRANSITIONS]:
      FilterSetLabelOverrides.GEOGRAPHY_TRANSITIONS,
    [FilterSets.GEOGRAPHY_POSTINGS]: FilterSetLabelOverrides.GEOGRAPHY_POSTINGS,
    [FilterSets.GEOGRAPHY_SENTIMENT_RATINGS]:
      FilterSetLabelOverrides.GEOGRAPHY_SENTIMENT_RATINGS,
    [FilterSets.GEOGRAPHY_SENTIMENT_EFFECTS]:
      FilterSetLabelOverrides.GEOGRAPHY_SENTIMENT_EFFECTS,

    // ROLE
    [FilterSets.ROLE]: FilterSetLabelOverrides.ROLE_COMPOSITIONS,
    [FilterSets.ROLE_TRANSITIONS]: FilterSetLabelOverrides.ROLE_TRANSITIONS,
    [FilterSets.ROLE_POSTINGS]: FilterSetLabelOverrides.ROLE_POSTINGS,
    [FilterSets.ROLE_SENTIMENT_RATINGS]:
      FilterSetLabelOverrides.ROLE_SENTIMENT_RATINGS,
    [FilterSets.ROLE_SENTIMENT_EFFECTS]:
      FilterSetLabelOverrides.ROLE_SENTIMENT_EFFECTS,
  };

export const filterLabelLookup: {
  [key in
    | SelectionCategories
    | LocalSelectionCategories]?: FilterLabelOverrides;
} = {
  [SelectionCategories.SNAPSHOT_DATE]: FilterLabelOverrides.SNAPSHOT_DATE,
  [SelectionCategories.CUSTOM_ROLE]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.JOB_CATEGORY]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.ROLE_K50]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.ROLE_K150]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.ROLE_K1500]: FilterLabelOverrides.JOB_CATEGORY,
  [SelectionCategories.DATE_RANGE]: FilterLabelOverrides.DATE_RANGE,
  [SelectionCategories.DATE_RANGE_FULL]: FilterLabelOverrides.DATE_RANGE,
  [SelectionCategories.HEADCOUNT]: FilterLabelOverrides.HEADCOUNT,
  [SelectionCategories.HIRING_RATE]: FilterLabelOverrides.HIRING_RATE,
  [SelectionCategories.ATTRITION_RATE]: FilterLabelOverrides.ATTRITION_RATE,
  [SelectionCategories.GROWTH_RATE]: FilterLabelOverrides.GROWTH_RATE,
  [SelectionCategories.AVERAGE_TENURE]: FilterLabelOverrides.AVERAGE_TENURE,
  [SelectionCategories.AVERAGE_SALARY]: FilterLabelOverrides.AVERAGE_SALARY,
  [SelectionCategories.COMPANY_NAME]: FilterLabelOverrides.COMPANY_NAME,
  [SelectionCategories.MSA]: FilterLabelOverrides.MSA,
  [SelectionCategories.FULL_MSA]: FilterLabelOverrides.FULL_MSA,
  [LocalSelectionCategories.PROVIDER]: FilterLabelOverrides.PROVIDER,
  [SelectionCategories.DIVERSITY_INCLUSION]:
    FilterLabelOverrides.DIVERSITY_INCLUSION,
  [SelectionCategories.STATE]: FilterLabelOverrides.STATE,
  [SelectionCategories.HIGHEST_DEGREE]: FilterLabelOverrides.HIGHEST_DEGREE,
  [SelectionCategories.COMPANY_NEW_DASHBOARD]:
    FilterLabelOverrides.COMPANY_NEW_DASHBOARD,
  [LocalSelectionCategories.DATA_METRIC]: FilterLabelOverrides.DATA_METRIC,
  [LocalSelectionCategories.METRIC_MODE]: FilterLabelOverrides.METRIC_MODE,
  [SelectionCategories.RSID]: FilterLabelOverrides.RSID,
  [LocalSelectionCategories.FLIGHT_RISK]: FilterLabelOverrides.FLIGHT_RISK,
  [LocalSelectionCategories.REMOTE_SUITABILITY]:
    FilterLabelOverrides.REMOTE_SUITABILITY,
  [SelectionCategories.BASE_SALARY]: FilterLabelOverrides.BASE_SALARY,
  [SelectionCategories.TOTAL_COMPENSATION]:
    FilterLabelOverrides.TOTAL_COMPENSATION,
  [SelectionCategories.SKILL_K75]: FilterLabelOverrides.SKILL,
  [SelectionCategories.SKILL_K700]: FilterLabelOverrides.SKILL,
  [SelectionCategories.SKILL_K3000]: FilterLabelOverrides.SKILL,
  [SelectionCategories.RAW_TITLE]: FilterLabelOverrides.RAW_TITLE,
};

export const TempFilterOverrideLookup = {
  [SelectionCategories.COMPANY_NEW_DASHBOARD]: SelectionCategories.COMPANY,
};

export const SHARED_SET_ENTITY_LIMIT = 6;

export const operatorOptions: OperatorOptionsType = [
  {
    label: 'is between',
    value: OPValues.BETWEEN,
    filterName: OPValues.BETWEEN,
  },
  {
    label: 'is greater than',
    value: OPValues.GREATER,
    filterName: OPValues.GREATER,
  },
  { label: 'is less than', value: OPValues.LESS, filterName: OPValues.LESS },
];

export const notMultiFilters: string[] = [
  SelectionCategories.DATE_RANGE,
  SelectionCategories.DATE_RANGE_FULL,
  SelectionCategories.SNAPSHOT_DATE,
  LocalSelectionCategories.DATA_METRIC,
  LocalSelectionCategories.METRIC_MODE,
];

export const FILTER_STORAGE_KEY = 'filterStore';

export const FilterStateDefaultsMap: {
  [key in FilterOrSubfilterName]?: Partial<
    SelectFilter<FilterList<ValidValueTypes>>
  >;
} = {
  [SubFilterNames.SUB_ROLE]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Engineer' }],
    selectionListId: SelectionCategories.JOB_CATEGORY,
  },
  [SubFilterNames.SUB_REGION]: {
    isMulti: true,
    value: [{ id: '3', shortName: 'W Europe' }],
    selectionListId: SelectionCategories.REGION,
  },
  [SubFilterNames.SUB_INDUSTRY]: {
    isMulti: true,
    value: [{ id: '10', shortName: 'Telecommunications Services' }],
    selectionListId: SelectionCategories.RICS_K10,
  },
  [SubFilterNames.SUB_SKILL]: {
    isMulti: true,
    value: [{ id: '4', shortName: 'Operations' }],
    selectionListId: SelectionCategories.SKILL,
  },

  // NOTE: Chose whichever item corresponds to id 4 to mirror the old
  // skills list above. Can change the default to whatever we want
  [SubFilterNames.SUB_SKILL_OVERTIME]: {
    isMulti: true,
    value: [
      { id: '4', shortName: 'Analysis / Data Analysis / Analytical Skills' },
    ],
    selectionListId: SelectionCategories.SKILL_K75,
  },
  [SubFilterNames.SUB_SENIORITY]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Junior' }],
    selectionListId: SelectionCategories.SENIORITY,
  },
  [SubFilterNames.SUB_GENDER]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Female' }],
    selectionListId: SelectionCategories.GENDER,
  },
  [SubFilterNames.SUB_ETHNICITY]: {
    isMulti: true,
    value: [{ id: '2', shortName: 'Asian' }],
    selectionListId: SelectionCategories.ETHNICITY,
  },
  [SubFilterNames.SUB_EDUCATION]: {
    isMulti: true,
    value: [{ id: '3', label: 'Bachelor', shortName: 'Bach.' }],
    selectionListId: SelectionCategories.HIGHEST_DEGREE,
  },
};

export const metricModeDefault = {
  isMulti: false,
  value: {
    id: METRIC_MODE_IDS.unweighted,
    label: 'Unweighted',
    value: METRIC_MODE_IDS.unweighted,
  },
  selectionListId: LocalSelectionCategories.METRIC_MODE,
};

export const metricModeFilterStateDefault = {
  [LocalSelectionCategories.METRIC_MODE]: metricModeDefault,
};

export const subSkillDefault = {
  isMulti: true,
  value: [{ id: 4, shortName: 'Operations' }],
  selectionListId: SelectionCategories.SKILL,
};

export const subKeywordDefault = {
  isMulti: true,
  value: [{ id: 10, shortName: 'Python', keyword_category: 27, parentId: 27 }],
  selectionListId: SelectionCategories.KEYWORD,
};

export const SkillsFilterStateDefaultMap: {
  [key in FilterOrSubfilterName]?: Partial<
    SelectFilter<FilterList<ValidValueTypes>>
  >;
} = {
  [SubFilterNames.SUB_SKILL]: subSkillDefault,
  [SubFilterNames.SUB_KEYWORD]: subKeywordDefault,
};
