import { PageTitles, PrimaryFilters } from '@revelio/core';
import { View } from '@revelio/data-access';
import {
  FilterMenuLimits,
  FilterSets,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  ROLE_GRANULARITY_FILTERS,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

import Transitions from '../../../transitions/transitions';

const title = [PageTitles.COMPANY, PageTitles.TRANSITIONS];

const primaryViewFilter = [
  {
    filters: RICS_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 1,
  },
];

export function CompanyTransitions() {
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const selectableFilters = [
    SelectionCategories.SAVED_FILTER_SET,
    SelectionCategories.HIGHEST_DEGREE,
    SelectionCategories.ETHNICITY,
    SelectionCategories.GENDER,
    {
      filters: [
        SelectionCategories.REGION,
        SelectionCategories.COUNTRY,
        SelectionCategories.METRO_AREA,
      ],
      isNested: true,
      limit: 10,
    },
    isCustomRoleTaxonomyEnabled
      ? SelectionCategories.CUSTOM_ROLE
      : {
          filters: ROLE_GRANULARITY_FILTERS,
          isNested: true,
          limit: 10,
        },
    SelectionCategories.SENIORITY,
    {
      filters: SKILL_GRANULARITY_FILTERS,
      isNested: true,
      limit: 10,
    },
    SelectionCategories.DATE_RANGE,
    SelectionCategories.KEYWORD,
    SelectionCategories.RAW_TITLE,
  ];

  return (
    <Transitions
      title={title}
      viewType={ViewTypes.COMPANY}
      primaryFilter={PrimaryFilters.COMPANY}
      primaryViewFilters={primaryViewFilter}
      selectableFilters={selectableFilters}
      otherFilters={[]}
      sharedFilterSetId={FilterSets.COMPANY}
      filterSet={FilterSets.COMPANY_TRANSITIONS}
      primaryFilterLimit={PrimaryFilterLimits.TRANSITIONS}
      filterMenuLimit={FilterMenuLimits.TRANSITIONS}
      savedSetView={View.Company}
    />
  );
}

export default CompanyTransitions;
