import { useToast } from '@chakra-ui/react';
import { useState } from 'react';

export const useTreeSelections = ({
  initialSelections = [],
  disallowMultiLevelSelect = false,
  maxSelections,
  maxTopLevelSelections,
  minSelections = 0,
  filterLabel,
}: {
  initialSelections?: string[];
  disallowMultiLevelSelect?: boolean;
  maxSelections?: number;
  maxTopLevelSelections?: number;
  minSelections?: number;
  filterLabel?: string;
}) => {
  const [selections, setSelections] = useState<string[]>(initialSelections);
  const toast = useToast();

  const showMaxSelectionsWarning = (
    selectionLimit: number,
    isTopLevelFilterWarning = false
  ) => {
    if (!toast.isActive('max-selections-toast')) {
      toast({
        id: 'max-selections-toast',
        position: 'top-right',
        status: 'warning',
        title: `${filterLabel ? filterLabel : 'Selection'} Limit`,
        description: `You can only choose up to ${selectionLimit} ${isTopLevelFilterWarning ? 'top-level ' : ''}${filterLabel ? filterLabel : 'items'} at a time!`,
        isClosable: true,
        variant: 'subtle',
      });
    }
  };

  const showMinSelectionsWarning = () => {
    if (!toast.isActive('min-selections-toast')) {
      toast({
        id: 'min-selections-toast',
        position: 'top-right',
        status: 'warning',
        title: `${filterLabel ? filterLabel : 'Selection'} Limit`,
        description: `You must make at least ${minSelections} ${filterLabel ? filterLabel : ''} selection!`,
        isClosable: true,
        variant: 'subtle',
      });
    }
  };

  const onChange = (value: string[]) => {
    const lastValue = value[value.length - 1];

    if (disallowMultiLevelSelect && lastValue) {
      const lastValueLevel = lastValue.split('_').length;
      const allSameLevel = value.every(
        (v) => v.split('_').length === lastValueLevel
      );

      if (!allSameLevel) {
        setSelections([lastValue]);
        return;
      }
    }

    if (maxSelections && value.length > maxSelections) {
      showMaxSelectionsWarning(maxSelections);
      return;
    }

    if (maxTopLevelSelections) {
      // Identify top level filter nodes as their id indicates depth with '_'
      const numberOfTopLevelFilters = value.filter(
        (filterNodeValue) => filterNodeValue.split('_').length === 1
      ).length;

      if (numberOfTopLevelFilters > maxTopLevelSelections) {
        showMaxSelectionsWarning(maxTopLevelSelections, true);
        return;
      }
    }
    setSelections(value);
  };

  const onClear = () => {
    setSelections([]);
  };

  const validateMinSelections = (): boolean => {
    if (selections.length < minSelections) {
      showMinSelectionsWarning();
      return false;
    }
    return true;
  };

  return {
    selections,
    setSelections,
    onChange,
    onClear,
    validateMinSelections,
    showMaxSelectionsWarning,
    showMinSelectionsWarning,
  };
};
