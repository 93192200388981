import { isBefore, parse } from 'date-fns';
import { isNumber } from 'lodash';
import { useQuery } from 'urql';

import { PrimaryView } from '@revelio/core';
import {
  GetSentimentDataQuery,
  SentimentResponseEntity,
} from '@revelio/data-access';
import {
  DateRangeFormattedValues,
  Filter,
  FilterItem,
  FilterName,
  OtherFilterNames,
  SENTIMENT_GET_OVERTIME_DATA_V2,
  SelectionCategories,
  getStartDateConst,
  useActiveFiltersState,
  usePrimaryEntitiesState,
  useRoleTaxonomySetting,
} from '@revelio/filtering';
import { LineDatum } from '@revelio/replots';

import { transformFiltersToVariables } from '../../../../utils';
import { topics } from '../topics-subfilter/topics';
import { SentimentBarChartData, SentimentLineChartData } from '../types';
import { filterLowQualityData } from './filter-low-quality-data';

const enum MetadataType {
  DATA_KIND_COMPANY = 'DATA_KIND_COMPANY',
  DATA_KIND_RICSK10 = 'DATA_KIND_RICSK10',
  DATA_KIND_RICSK50 = 'DATA_KIND_RICSK50',
  DATA_KIND_REGION = 'DATA_KIND_REGION',
  DATA_KIND_COUNTRY = 'DATA_KIND_COUNTRY',
  DATA_KIND_MSA = 'DATA_KIND_MSA',
  DATA_KIND_ROLEK7 = 'DATA_KIND_ROLEK7',
  DATA_KIND_ROLEK150 = 'DATA_KIND_ROLEK150',
  DATA_KIND_ROLEK1500 = 'DATA_KIND_ROLEK1500',
}

const MetadataTypeToIdMap: Record<MetadataType, SelectionCategories> = {
  [MetadataType.DATA_KIND_COMPANY]: SelectionCategories.COMPANY,
  [MetadataType.DATA_KIND_RICSK10]: SelectionCategories.RICS_K10,
  [MetadataType.DATA_KIND_RICSK50]: SelectionCategories.RICS_K50,
  [MetadataType.DATA_KIND_REGION]: SelectionCategories.REGION,
  [MetadataType.DATA_KIND_COUNTRY]: SelectionCategories.COUNTRY,
  [MetadataType.DATA_KIND_MSA]: SelectionCategories.METRO_AREA,
  [MetadataType.DATA_KIND_ROLEK7]: SelectionCategories.JOB_CATEGORY,
  [MetadataType.DATA_KIND_ROLEK150]: SelectionCategories.ROLE_K150,
  [MetadataType.DATA_KIND_ROLEK1500]: SelectionCategories.ROLE_K1500,
};

const emptyData = {
  overall: [],
  business_outlook: [],
  career_growth: [],
  compensation: [],
  benefits: [],
  healthcare: [],
  retirement_pension: [],
  childcare: [],
  education_assistance: [],
  clients_and_customers: [],
  wellness_programs: [],
  work_life_balance: [],
  diversity_and_inclusion: [],
  leadership: [],
  culture: [],
  technology: [],
  commission: [],
  remote_work: [],
  onboarding: [],
  mentorship: [],
  promotion_opportunities: [],
  paid_time_off: [],
  transparency: [],
  efficiency_and_bureaucracy: [],
  product_and_service_quality: [],
  commitment: [],
  prestige: [],
  coworkers: [],
  mission_and_vision: [],
  workplace_facilities: [],
  commute: [],
  training: [],
  company_growth: [],
  schedule_flexibility: [],
  job_security: [],
  compliance: [],
  ethics: [],
  family_support: [],
  independence: [],
  safety: [],
  harassment: [],
  honesty: [],
  integrity: [],
  internal_communication: [],
  office_politics: [],
  overtime: [],
};

interface UseGetSentimentReviewsDataProps {
  viewType: 'snapshot' | 'overtime';
  view: PrimaryView;
  primaryFilters: FilterName[];
}
type SentimentReviewsSnapshotData = {
  viewType: 'snapshot';
  data: SentimentBarChartData;
  loading: boolean;
};

type SentimentReviewsOvertimeData = {
  viewType: 'overtime';
  data: SentimentLineChartData;
  loading: boolean;
};

const shouldUseFirstValue = (filters: Filter[]): boolean => {
  const snapshotDate = filters.find(
    (filter) => filter.id === SelectionCategories.SNAPSHOT_DATE
  )?.value;

  const dateRange = filters.find(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  )?.value as DateRangeFormattedValues;

  return !!snapshotDate && !!dateRange && snapshotDate === dateRange.startDate;
};

const isDateBeforeMinimumDate = (date: string, minDate: string): boolean => {
  return isBefore(
    parse(date, 'yyyy-MM', new Date()),
    parse(minDate, 'yyyy-MM', new Date())
  );
};

export const getTimeLimitedStartDate = (
  startDate: string,
  minStartDate: string
): string => {
  return isDateBeforeMinimumDate(startDate, minStartDate)
    ? minStartDate
    : startDate;
};

export const useGetSentimentReviewsData = ({
  viewType,
  view,
  primaryFilters,
}: UseGetSentimentReviewsDataProps):
  | SentimentReviewsSnapshotData
  | SentimentReviewsOvertimeData => {
  const activeFilters = useActiveFiltersState();

  const primaryEntities = usePrimaryEntitiesState();

  const useFirstValue = shouldUseFirstValue(activeFilters);

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const queryFilters = transformFiltersToVariables({
    view,
    filters: activeFilters,
    isCustomRoleTaxonomyEnabled,
  });

  const dateRangeFilter = queryFilters.filters?.start_date;

  const timeLimitedStartDate =
    dateRangeFilter &&
    getTimeLimitedStartDate(dateRangeFilter, getStartDateConst(view));

  const isQueryReady = useIsQueryReady({
    activeFilters,
    primaryFilters,
    view,
  });

  const [{ data: rawData, fetching: loading, error }] = useQuery({
    query: SENTIMENT_GET_OVERTIME_DATA_V2,
    variables: timeLimitedStartDate
      ? {
          ...queryFilters,
          filters: {
            ...queryFilters.filters,
            start_date: timeLimitedStartDate,
          },
        }
      : queryFilters,
    pause: !isQueryReady,
  });

  const hasFirstFetchHappened = !!rawData || !!error;

  const data = rawData
    ? {
        ...rawData,
        sentiment: sortSentimentByPrimaryEntities(
          rawData.sentiment,
          primaryEntities
        ),
      }
    : null;

  if (viewType === 'snapshot') {
    return {
      viewType,
      data: data ? normalizeBarScoresData(data, useFirstValue) : emptyData,
      loading: loading || !hasFirstFetchHappened,
    };
  }
  if (viewType === 'overtime') {
    const normalizedData = data ? normalizeLineScoresData(data) : emptyData;
    const lowQualityFilteredData = filterLowQualityData(normalizedData);

    return {
      viewType,
      data: lowQualityFilteredData,
      loading: loading || !hasFirstFetchHappened,
    };
  }

  return { viewType, data: emptyData, loading };
};

const getRoundedCount = (weight: number | null | undefined): number => {
  if (!isNumber(weight) || Number(weight) === 0) return 0;

  return Math.max(1, Math.round(weight));
};

const normalizeBarScoresData = (
  data: GetSentimentDataQuery,
  selectFirstScore = false
): SentimentBarChartData => {
  if (!data?.sentiment) return emptyData;

  const emptyDataSet = topics.reduce<SentimentBarChartData>((acc, topic) => {
    acc[topic.value] = [];
    return acc;
  }, emptyData);

  const initialResult = data.sentiment.reduce<SentimentBarChartData>(
    (acc, entity) => {
      if (
        !entity?.metadata ||
        !entity?.result?.scores ||
        !entity?.months_refs
      ) {
        return acc;
      }

      const label = entity.metadata?.shortName;
      if (!label) return acc;

      const monthLookup = entity.months_refs.reduce<Record<number, string>>(
        (acc, month_ref) => {
          if (!month_ref || !month_ref.id || !month_ref.longName) return acc;
          acc[month_ref.id] = month_ref.longName;
          return acc;
        },
        {}
      );

      const scores = entity.result.scores;

      topics.forEach((topic) => {
        const score = scores[topic.value];
        if (!score) return;

        const snapshotIndex = selectFirstScore ? 0 : score.length - 1;
        const snapshotScore = score[snapshotIndex];
        if (!snapshotScore || !snapshotScore.month_id) return;

        const { value, weight } = snapshotScore;

        acc[topic.value] = [
          ...acc[topic.value],
          {
            date: monthLookup[snapshotScore.month_id],
            label,
            count: getRoundedCount(weight),
            value: isNumber(value) ? value / 100 : null,
          },
        ];
      });

      return acc;
    },
    emptyDataSet
  );

  // Filter out topics where all entries have null values
  return Object.entries(initialResult).reduce<SentimentBarChartData>(
    (acc, [topic, entries]) => {
      const allNull = entries.every((entry) => entry.value === null);
      acc[topic as keyof SentimentBarChartData] = allNull ? [] : entries;
      return acc;
    },
    {} as SentimentBarChartData
  );
};

const normalizeLineScoresData = (
  data: GetSentimentDataQuery
): SentimentLineChartData => {
  if (!data?.sentiment) return emptyData;

  const emptyDataSet = topics.reduce<SentimentLineChartData>((acc, topic) => {
    acc[topic.value] = [];
    return acc;
  }, emptyData);

  const initialResult = data.sentiment.reduce<SentimentLineChartData>(
    (acc, entity): SentimentLineChartData => {
      if (
        !entity?.metadata ||
        !entity?.result?.scores ||
        !entity?.months_refs
      ) {
        return acc;
      }

      const label = entity.metadata?.shortName;
      if (!label) return acc;

      const monthLookup = entity.months_refs.reduce<Record<number, string>>(
        (acc, month_ref) => {
          if (!month_ref || !month_ref.id || !month_ref.longName) return acc;
          acc[month_ref.id] = month_ref.longName;
          return acc;
        },
        {}
      );

      const scores = entity.result.scores;

      topics.forEach((topic) => {
        const score = scores[topic.value];
        if (!score) return;

        const scoreData = score
          .map((score): LineDatum | null => {
            if (!score || !score.month_id) {
              return null;
            }
            return {
              date: monthLookup[score.month_id],
              value: isNumber(score.value) ? score.value / 100 : null,
              secondaryValue: getRoundedCount(score.weight),
            };
          })
          .filter((score) => score !== null);

        if (scoreData.length > 0) {
          acc[topic.value] = [
            ...acc[topic.value],
            { label, values: scoreData },
          ];
        }
      });

      return acc;
    },
    emptyDataSet
  );

  // Filter out topics where all entries have null values
  return Object.entries(initialResult).reduce<SentimentLineChartData>(
    (acc, [topic, entries]) => {
      const allNull = entries.every((entry) =>
        entry.values.every((value) => value.value === null)
      );
      acc[topic as keyof SentimentLineChartData] = allNull ? [] : entries;
      return acc;
    },
    {} as SentimentLineChartData
  );
};

const useIsQueryReady = ({
  activeFilters,
  primaryFilters,
  view,
}: {
  activeFilters: Filter[];
  primaryFilters: FilterName[];
  view: PrimaryView;
}) => {
  const activeFiltersHasPrimaryFilter = activeFilters.some((filter) =>
    primaryFilters?.includes(filter.id)
  );

  const activeFiltersHasDateRange = activeFilters.some(
    (filter) => filter.id === SelectionCategories.DATE_RANGE
  );

  const filtersHasCustomRoleTaxonomy = !!activeFilters.find(
    (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
  );

  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();
  return (
    activeFiltersHasPrimaryFilter &&
    activeFiltersHasDateRange &&
    (!isCustomRoleTaxonomyEnabled ||
      filtersHasCustomRoleTaxonomy ||
      view !== PrimaryView.COMPANY)
  );
};

const getEntityDetails = (item: SentimentResponseEntity) => ({
  id: item.metadata?.id?.toString() ?? '',
  selectionListId: item.metadata?.metadata_type
    ? MetadataTypeToIdMap[item.metadata.metadata_type as MetadataType]
    : '',
});

const sortSentimentByPrimaryEntities = (
  data: GetSentimentDataQuery['sentiment'],
  primaryEntities?: FilterItem<{ id: string; selectionListId: string }>[]
) => {
  if (!data) return [];

  return data.sort((a, b) => {
    if (!a?.metadata || !b?.metadata) return 0;

    const aDetails = getEntityDetails(a);
    const bDetails = getEntityDetails(b);

    const aIndex =
      primaryEntities?.findIndex(
        (entity) =>
          entity.id === aDetails.id &&
          entity.selectionListId === aDetails.selectionListId
      ) ?? -1;

    const bIndex =
      primaryEntities?.findIndex(
        (entity) =>
          entity.id === bDetails.id &&
          entity.selectionListId === bDetails.selectionListId
      ) ?? -1;

    // Both items in primaryEntities - sort by their order
    if (aIndex !== -1 && bIndex !== -1) return aIndex - bIndex;
    // Neither item in primaryEntities - maintain original order
    if (aIndex === -1 && bIndex === -1) return 0;
    // One item in primaryEntities - put it first
    return aIndex === -1 ? 1 : -1;
  });
};
