import { PostingsDataQuery } from '@revelio/data-access';
import { VolumeChart } from '@revelio/replots';

import { getMainPostingsData } from '../../../postings/utils';
import { MetricValues } from '../config-provider';
import { isVolumeMetric } from '../config-provider/utils';
import { ChartNotSupported } from './chart-not-supported';

export const Volume = ({
  data,
  metric,
}: {
  data: PostingsDataQuery | undefined;
  metric: MetricValues;
}) => {
  if (!isVolumeMetric(metric)) return <ChartNotSupported />;

  const volumeData = (() => {
    if (!data) return [];

    return getMainPostingsData({ entities: data.posting });
  })();

  return <VolumeChart data={volumeData} />;
};
