type ArrowProps = {
  direction: 'left' | 'right';
  x: number;
  y: number;
  length?: number;
  color?: string;
  strokeWidth?: number;
  opacity?: number;
};

export const Arrow = ({
  direction,
  x,
  y,
  length = 40,
  color = '#2d426a',
  strokeWidth = 2,
  opacity = 0.7,
}: ArrowProps) => {
  const isLeft = direction === 'left';
  const headSize = length * 0.2;

  // Calculate coordinates based on direction
  const startX = isLeft ? x - length : x;
  const endX = isLeft ? x : x + length;

  return (
    <g>
      {/* Main line */}
      <line
        x1={startX}
        y1={y}
        x2={endX}
        y2={y}
        stroke={color}
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
      {/* Arrow head */}
      <line
        x1={isLeft ? startX : endX}
        y1={y}
        x2={isLeft ? startX + headSize : endX - headSize}
        y2={y - headSize / 2}
        stroke={color}
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
      <line
        x1={isLeft ? startX : endX}
        y1={y}
        x2={isLeft ? startX + headSize : endX - headSize}
        y2={y + headSize / 2}
        stroke={color}
        strokeWidth={strokeWidth}
        opacity={opacity}
      />
    </g>
  );
};

type LabeledArrowProps = {
  direction: 'left' | 'right';
  x: number;
  y: number;
  label: string;
  fontSize?: number;
  color?: string;
  opacity?: number;
  maxWidth?: number;
};

export const LabeledArrow = ({
  direction,
  x,
  y,
  label,
  fontSize = 12,
  color = '#2d426a',
  opacity = 0.7,
  maxWidth,
}: LabeledArrowProps) => {
  const isLeft = direction === 'left';

  // If maxWidth is provided and text might be too wide, use tspan for wrapping
  const labelWords = label.split(' ');
  const needsWrapping = maxWidth && labelWords.length > 1 && label.length > 10;

  // Determine arrow size based on available space
  const arrowLength = Math.min(40, maxWidth ? maxWidth / 3 : 40);

  return (
    <g transform={`translate(${x}, ${y})`}>
      <Arrow
        direction={direction}
        x={isLeft ? -10 : 10}
        y={needsWrapping ? 15 : 5}
        length={arrowLength}
        color={color}
        opacity={opacity}
      />

      {needsWrapping ? (
        <text
          x={isLeft ? 0 : 0}
          y={0}
          textAnchor={isLeft ? 'start' : 'end'}
          fontSize={fontSize}
          fontWeight={600}
          fontFamily="'Source Sans Pro', 'Roboto'"
          fill={color}
          opacity={opacity}
        >
          <tspan x={isLeft ? 0 : 0} dy="0em">
            Factors of
          </tspan>
          <tspan x={isLeft ? 0 : 0} dy="1.2em">
            {isLeft ? 'Attrition' : 'Hiring'}
          </tspan>
        </text>
      ) : (
        <text
          x={isLeft ? 0 : 0}
          y={9}
          textAnchor={isLeft ? 'start' : 'end'}
          fontSize={fontSize}
          fontWeight={600}
          fontFamily="'Source Sans Pro', 'Roboto'"
          fill={color}
          opacity={opacity}
        >
          {label}
        </text>
      )}
    </g>
  );
};
