import { Flex, Grid, GridItem, Heading } from '@chakra-ui/layout';
import { Card } from '@chakra-ui/react';
import { flatten } from 'lodash';
import { useMemo } from 'react';
import { useQuery } from 'urql';

import { SankeyError } from '@revelio/assets';
import {
  AddEntityButtonText,
  PageTitles,
  PrimaryFilters,
  Views,
} from '@revelio/core';
import {
  COMPOSITION_GET_SUMMARY_DATA,
  CustomRoleFilter,
  CustomRoleTaxonomySelection,
  Dimension1,
  GET_ENTITY_DATA,
  METRIC_MODE_IDS,
  POSTINGS_GET_ACTIVE,
  POSTING_SOURCE_IDS,
  SENTIMENT_GET_SUMMARY_DATA,
  View,
} from '@revelio/data-access';
import {
  FilterChips,
  FilterChipsContainer,
  FilterContainer,
  FilterItem,
  FilterMenu,
  FilterMenuLimits,
  FilterSetSaveMenu,
  FilterSets,
  GEOGRAPHY_GRANULARITY_FILTERS,
  LocalSelectionCategories,
  PrimaryEntityPopoutTreeFilter,
  PrimaryFilterLimits,
  RICS_AND_COMPANY_FILTERS,
  RICS_AND_COMPANY_FILTERS_SORTED,
  ROLE_GRANULARITY_FILTERS,
  SHARED_SET_ENTITY_LIMIT,
  SKILL_GRANULARITY_FILTERS,
  SelectionCategories,
  ViewTypes,
  createSelectableFiltersMap,
  getSkipShareOfPostingsScalingParam,
  ricsAndCompanyBranchLabels,
  useActiveFiltersState,
  useAdaptiveRoleTaxonomy,
  useAllFiltersState,
  useRoleTaxonomySetting,
  useSelectionLists,
  useStoredFilterSet,
  useSyncFiltersToSearchParamsPure,
  useTabMeta,
  useViewFilterDefaults,
  useViewFilters,
} from '@revelio/filtering';

import {
  getCompensationFilters,
  getCompositionFilters,
  getPostingFilters,
  getSentimentFilters,
} from '../../../filtering';
import DashboardPage from '../../DashboardPage';
import { useMonthApiFilters } from '../use-month-api-filters';
import { CompanyStats } from './company-stats/company-stats';
import { CompanySummaryOverview } from './company-summary-overview';
import { CompetitorBarCharts } from './competitor-bar-charts/competitor-bar-charts';
import { CompanyMap } from './map/company-map';
import { useGetCompanyMapData } from './map/useGetCompanyMapData';
import { SalaryDistribution } from './salary-distribution/salary-distribution';
import { useGetKdePlotData } from './salary-distribution/useGetKdePlotData';

const SUMMARY_PRIMARY_VIEW_FILTER = [
  {
    filters: RICS_AND_COMPANY_FILTERS,
    isNested: true,
    limit: 1,
  },
];

export const CompanySummary = () => {
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const SUMMARY_SECONDARY_FILTERS = useMemo(
    () => [
      SelectionCategories.SAVED_FILTER_SET,
      isCustomRoleTaxonomyEnabled
        ? SelectionCategories.CUSTOM_ROLE
        : {
            filters: ROLE_GRANULARITY_FILTERS,
            isNested: true,
            limit: 10,
          },
      {
        filters: GEOGRAPHY_GRANULARITY_FILTERS,
        isNested: true,
        limit: 10,
      },
      SelectionCategories.SENIORITY,
      {
        filters: SKILL_GRANULARITY_FILTERS,
        isNested: true,
        limit: 10,
      },
    ],
    [isCustomRoleTaxonomyEnabled]
  );

  const primaryFilters = flatten(
    createSelectableFiltersMap(SUMMARY_PRIMARY_VIEW_FILTER)
  ) as SelectionCategories[];

  const selectableFiltersMap = createSelectableFiltersMap([
    ...SUMMARY_SECONDARY_FILTERS,
  ]);
  const flattenedSelectableFilters = flatten(selectableFiltersMap);

  useViewFilters([
    ...primaryFilters,
    ...flattenedSelectableFilters,
    SelectionCategories.DATE_RANGE,
    SelectionCategories.DATE_RANGE_FULL,
  ]);

  useSelectionLists([
    SelectionCategories.COMPANY,
    SelectionCategories.INDUSTRY,
    ...flattenedSelectableFilters,
  ]);

  useStoredFilterSet({
    sharedSetId: FilterSets.COMPANY,
    primaryEntitiesSync: true,
    limit: 1,
    defaultLimit: 1,
    filterNames: primaryFilters,
    uniqueSetId: FilterSets.COMPANY_SUMMARY,
  });

  const viewFilterDefaultArgs = {
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    presetView: FilterSets.COMPANY_SUMMARY,
    onlyConsiderTheseFiltersToTriggerDefaults: [
      LocalSelectionCategories.PRIMARY_ENTITIES,
    ],
    viewFilters: [LocalSelectionCategories.PRIMARY_ENTITIES],
    limit: PrimaryFilterLimits.ENTITY_SUMMARY,
    dateKey: SelectionCategories.DATE_RANGE,
    primaryFilters,
    supportPrimaryEntities: true,
  };

  useViewFilterDefaults(viewFilterDefaultArgs);

  const activeFilters = useActiveFiltersState();
  const allFilters = useAllFiltersState();

  const selectedCompanyRCID = parseInt(
    (
      activeFilters.find((filter) => filter.id === SelectionCategories.COMPANY)
        ?.value as FilterItem[]
    )?.[0]?.id as string,
    10
  );

  const primaryEntities = allFilters.find(
    (filter) => filter.id === LocalSelectionCategories.PRIMARY_ENTITIES
  )?.value as FilterItem[];

  const isPrimaryEntitiesPopulated = primaryEntities?.length > 0;
  const isSelectedCompanyInPrimaryEntities = primaryEntities?.some(
    (entity) =>
      entity.selectionListId === SelectionCategories.COMPANY &&
      parseInt(entity.id as string) === selectedCompanyRCID
  );
  const isInvalidPrimarySelection =
    isPrimaryEntitiesPopulated && !isSelectedCompanyInPrimaryEntities;

  const [
    {
      data: entityData,
      error: primaryCompanyError,
      fetching: primaryCompanyLoading,
    },
  ] = useQuery({
    query: GET_ENTITY_DATA,
    variables: {
      filters: {
        // secondary filters should not change the competitor search
        company: [selectedCompanyRCID],
        top_competitors: 7, //this will change to num_competitors
      },
    },
    pause: !selectedCompanyRCID,
  });

  const hasInitialLoadOccured = !!entityData || !!primaryCompanyError;

  const companyAndCompetitorIds = (() => {
    if (primaryCompanyLoading) return null;

    const competitors = entityData?.entity?.competitors || [];
    const competitorIds = competitors
      .map((competitor) => competitor?.metadata?.id)
      .filter((id) => id !== null && id !== undefined);

    return [selectedCompanyRCID, ...competitorIds].filter((id) => !isNaN(id));
  })();

  useAdaptiveRoleTaxonomy({
    viewType: ViewTypes.COMPANY,
    primaryFilters,
  });

  useTabMeta({
    savedSetView: View.Company,
    view: Views.ENTITY_SUMMARY,
    viewType: ViewTypes.COMPANY,
    limit: PrimaryFilterLimits.OVERVIEW,
    supportPrimaryEntities: true,
    includeDisabledFilters: true,
    primaryFilters,
  });

  const dateRangeApiFilters = useMonthApiFilters();

  const compensationFilters = getCompensationFilters(activeFilters);
  const { kdeSalaryData, loading: kdePlotsLoading } = useGetKdePlotData({
    filters: compensationFilters,
    startDate: dateRangeApiFilters?.date_range_api_filters?.start_date,
    endDate: dateRangeApiFilters?.date_range_api_filters?.end_date,
    selectedCompanyRCID,
  });

  const compositionFilters = getCompositionFilters(activeFilters);
  const [{ data: compositionData, fetching: compositionLoading }] = useQuery({
    query: COMPOSITION_GET_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        ...compositionFilters,
        company: companyAndCompetitorIds,
        ...dateRangeApiFilters?.date_range_api_filters,
      },
    },
    pause:
      !companyAndCompetitorIds ||
      companyAndCompetitorIds.length === 0 ||
      isInvalidPrimarySelection ||
      !dateRangeApiFilters,
  });

  const postingFilters = getPostingFilters(activeFilters);
  const [{ data: postingsActiveData, fetching: postingsLoading }] = useQuery({
    query: POSTINGS_GET_ACTIVE,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        ...postingFilters,
        ...dateRangeApiFilters?.date_range_full_api_filters,
        provider: [POSTING_SOURCE_IDS.unified],
        metric_mode: METRIC_MODE_IDS.expectedHires,
      },
      ...getSkipShareOfPostingsScalingParam(),
    },
    pause: !entityData || !dateRangeApiFilters,
  });

  const sentimentFilters = getSentimentFilters(activeFilters);
  const [{ data: sentimentData, fetching: sentimentLoading }] = useQuery({
    query: SENTIMENT_GET_SUMMARY_DATA,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        ...sentimentFilters,
        ...dateRangeApiFilters?.date_range_api_filters,
      },
    },
    pause: !entityData || !dateRangeApiFilters,
  });

  const { data: talentDiscoveryDataQuery, filters } = useGetCompanyMapData({
    isCustomRoleTaxonomyEnabled,
  });

  const customTaxonomy = ((): CustomRoleTaxonomySelection | undefined => {
    return filters?.custom_role?.taxonomyId
      ? filters?.custom_role?.taxonomyId
      : undefined;
  })();

  const customRole: CustomRoleFilter | undefined = customTaxonomy
    ? { taxonomyId: customTaxonomy }
    : undefined;

  const anyRequestsLoading =
    !hasInitialLoadOccured ||
    primaryCompanyLoading ||
    compositionLoading ||
    postingsLoading ||
    sentimentLoading ||
    kdePlotsLoading;

  const postingsStartDate =
    dateRangeApiFilters?.date_range_full_api_filters?.start_date;
  const postingsEndDate =
    dateRangeApiFilters?.date_range_full_api_filters?.end_date;

  useSyncFiltersToSearchParamsPure({
    primaryFilters,
    syncToPrimaryEntities: true,
    isLoading: anyRequestsLoading,
  });

  return (
    <DashboardPage
      title={[PageTitles.COMPANY, PageTitles.SUMMARY]}
      hideSelectionsMargins
      loading={anyRequestsLoading && !isInvalidPrimarySelection}
      selections={
        <Flex
          justifyContent="flex-start"
          alignItems="center"
          flexDirection="row"
        >
          <FilterChipsContainer
            filterNames={primaryFilters}
            variant="companyChip"
            showColors={false}
            isPrimaryChip
            limit={PrimaryFilterLimits.COMPANY_SUMMARY}
            useChipsSkeleton={false}
            min={1}
            addButton={
              <PrimaryEntityPopoutTreeFilter
                selectionListIds={RICS_AND_COMPANY_FILTERS_SORTED}
                filterLabel="Companies/Industries"
                branches={ricsAndCompanyBranchLabels}
                maxSelections={SHARED_SET_ENTITY_LIMIT}
                minSelections={1}
                activeLimit={PrimaryFilterLimits.COMPANY_SUMMARY}
                disabledLevels={[
                  RICS_AND_COMPANY_FILTERS_SORTED.indexOf(
                    SelectionCategories.RICS_K10
                  ),
                  RICS_AND_COMPANY_FILTERS_SORTED.indexOf(
                    SelectionCategories.RICS_K50
                  ),
                ].filter((index) => index !== -1)}
              >
                {AddEntityButtonText[PrimaryFilters.COMPANY]}
              </PrimaryEntityPopoutTreeFilter>
            }
          />
        </Flex>
      }
    >
      <FilterContainer
        flexDirection="row"
        alignItems="flex-start"
        justifyContent={'space-between'}
      >
        <Flex
          justifyContent="flex-start"
          alignItems="flex-start"
          flexDirection="row"
          wrap="wrap"
          rowGap="0.5rem"
        >
          <FilterChips
            filterNames={SUMMARY_SECONDARY_FILTERS}
            variant="filterChip"
            limit={FilterMenuLimits.ENTITY_SUMMARY}
            showGranularity={true}
            viewType={ViewTypes.COMPANY}
            useChipsSkeleton={false}
            addButton={
              <>
                <FilterMenu
                  title="Filter"
                  filters={SUMMARY_SECONDARY_FILTERS}
                  selectMenuOpenDefault
                  limit={FilterMenuLimits.ENTITY_SUMMARY}
                  viewIdForDefault={`${Views.ENTITY_SUMMARY}_${ViewTypes.COMPANY}`}
                />
                <FilterSetSaveMenu view={View.Company} />
              </>
            }
          />
        </Flex>
      </FilterContainer>

      {isInvalidPrimarySelection ? (
        <Card height="100%" borderRadius="10px" variant="unstyled">
          <Flex height="100%" flexDirection="column" justifyContent="center">
            <Heading
              textAlign="center"
              size="lg"
              fontWeight="extrabold"
              color="text.primary"
              mb="8"
            >
              Please select a company to see its overview.
            </Heading>
            <SankeyError height="160px" />
          </Flex>
        </Card>
      ) : (
        <Grid
          templateRows={{ base: 'repeat(10, 200px)', md: 'repeat(2, 1fr)' }}
          templateColumns={{ base: '1fr', md: 'repeat(3, 1fr)' }}
          gap={4}
          width="100%"
          height="100%"
          minHeight="440px"
        >
          <GridItem
            gridRow={'span 1'}
            gridColumn={'1 / span 1'}
            minWidth="0"
            minHeight="0"
          >
            <CompanySummaryOverview
              description={entityData?.entity?.summary?.description}
              founded={entityData?.entity?.summary?.founded}
              headquarters={entityData?.entity?.summary?.headquarters}
            />
          </GridItem>
          <GridItem
            gridRow={{ base: 'span 2', md: 'span 1' }}
            gridColumn={{ base: '1 / span 1', md: '2 / span 1' }}
            minWidth="0"
            minHeight="0"
          >
            <CompetitorBarCharts
              compositionData={compositionData}
              primaryRCID={selectedCompanyRCID}
            />
          </GridItem>
          <GridItem
            gridRow={{ base: 'span 2', md: 'span 1' }}
            gridColumn={{ base: '1 / span 1', md: '3 / span 1' }}
            width="100%"
            minWidth="0"
            minHeight="0"
          >
            <CompanyStats
              compositionData={compositionData}
              postingsActiveData={postingsActiveData}
              sentimentData={sentimentData}
              selectedCompanyRCID={selectedCompanyRCID}
            />
          </GridItem>
          <GridItem
            gridRow={{ base: 'span 2', md: '2 / span 1' }}
            gridColumn={{ base: '1 / span 1', md: '1 / span 2' }}
            minWidth="0"
            minHeight="0"
            data-testid="mapbox-map-container"
          >
            {postingsStartDate && postingsEndDate && (
              <CompanyMap
                mapData={
                  talentDiscoveryDataQuery?.talent_discovery_search_v1_5
                    ?.map_data
                }
                postingsStartDate={postingsStartDate}
                postingsEndDate={postingsEndDate}
                custom_role={customRole}
              />
            )}
          </GridItem>
          <GridItem
            gridRow={{ base: 'span 2', md: '2 / span 1' }}
            gridColumn={{ base: '1 / span 1', md: '3 / span 1' }}
            minWidth="0"
            minHeight="0"
            data-testid="salary-distribution-plot-card"
          >
            <SalaryDistribution
              salaryData={kdeSalaryData}
              primaryCompanyRCID={selectedCompanyRCID}
              primaryCompanyName={entityData?.entity?.summary?.name}
            />
          </GridItem>
        </Grid>
      )}
    </DashboardPage>
  );
};
