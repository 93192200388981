import { CloseIcon, SearchIcon } from '@chakra-ui/icons';
import {
  Button,
  Input,
  InputGroup,
  InputRightElement,
  useOutsideClick,
} from '@chakra-ui/react';
import classNames from 'classnames';
import {
  ChangeEventHandler,
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import {
  TreeApi,
  TreeApiBaseProps,
  TreeApiControlProps,
  TreeSelection,
  TreeSelectionComponentProps,
  useTreeApi,
} from '../tree';
import { DrillMode, TNode } from '../tree/Node';
import { getChildren, getPathToNode } from '../tree/utils';
import { BranchLabel } from './BranchLabel';
import styles from './PopoutTree.module.css';

const POPOUT_WIDTH = 280;

const COLLAPSE_ALL_HEIGHT = 16;
const BRANCH_CONTAINER_HEIGHT = 19;
const TREE_HEIGHT = 236;

const getHeight = ({
  isSearchEmpty,
  branches,
}: {
  isSearchEmpty: boolean;
  branches?: PopoutTreeProps['branches'];
}) => {
  const branchContainerHeight =
    branches && isSearchEmpty ? BRANCH_CONTAINER_HEIGHT : 0;
  const collapseAllTextHeight = isSearchEmpty ? 0 : COLLAPSE_ALL_HEIGHT;

  return TREE_HEIGHT - branchContainerHeight - collapseAllTextHeight;
};

type Branch = {
  label: string;
  description?: string;
};

export type PopoutTreeProps = Omit<
  TreeSelectionComponentProps,
  'treeApi' | 'drillMode' | 'onClick'
> &
  Omit<TreeApiBaseProps, 'openByDefault'> &
  TreeApiControlProps & {
    branches?: Branch[] | ((node: TNode) => Branch);
    disabledLevels?: number[];
    searchInputRef?: React.RefObject<HTMLInputElement>;
  };

type DrillState = {
  level: number;
  parentNode: TNode;
  parentPosition: { x: number; y: number; renderOnLeft?: boolean };
};

export const PopoutTree = forwardRef(
  (
    {
      branches,
      disabledLevels,
      className,
      sortSuggestedSearch,
      searchInputRef,
      ...props
    }: PopoutTreeProps,
    ref: Ref<TreeApi | undefined>
  ) => {
    const { data } = props;
    const treeApi = useTreeApi({ ...props, openByDefault: false });

    useImperativeHandle(ref, () => treeApi);

    const { search, onSearch, isExpanded, collapse, expand } = treeApi;

    const [drillState, setDrillState] = useState<DrillState[]>([]);
    const [branchLabel, setBranchLabel] = useState<Branch | null>(null);

    const rootTreeRef = useRef<HTMLDivElement>(null);
    const drillIntoNode: DrillMode['drillIntoNode'] = ({
      event,
      node,
      level,
    }) => {
      const rootTreeElement = rootTreeRef.current;
      const rootTreeRect = rootTreeElement?.getBoundingClientRect();

      const innerWidth: number = window.innerWidth;

      const target = event?.currentTarget;
      let left = 0;
      let top = 0;
      if (target && rootTreeRect) {
        const rect = target.getBoundingClientRect();
        left = rect.x - rootTreeRect.x + rect.width;
        top = rect.y - rootTreeRect.y - 9;

        setDrillState((prev) => [
          ...prev.slice(0, level),
          {
            level,
            parentNode: node,
            parentPosition: {
              x: left,
              y: top,
              renderOnLeft: rect.x + POPOUT_WIDTH > innerWidth,
            },
          },
        ]);
      }
    };

    const drillOutToLevel = (level: number) => {
      setDrillState((prev) => prev.slice(0, level));
    };

    const isDrilledInto = (node: TNode) => {
      const depth = node.data.depth;
      return (
        depth !== undefined && drillState[depth]?.parentNode?.id === node.id
      );
    };

    const isSearchEmpty = search.length === 0;
    const isDrillEnabled = isSearchEmpty;

    const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
      onSearch(event.target.value);
    };
    const onResetSearch = () => {
      onSearch('');
    };

    const popoutTreeRef = useRef<HTMLDivElement>(null);
    useOutsideClick({
      ref: popoutTreeRef,
      handler: () => {
        setDrillState([]);
      },
    });

    useEffect(() => {
      if (typeof branches === 'function' && treeApi.treeRef?.current?.root) {
        setBranchLabel(branches(treeApi.treeRef.current.root));
      } else if (Array.isArray(branches) && branches[0]) {
        setBranchLabel(branches[0]);
      }
    }, [branches, treeApi.treeRef]);

    return (
      <div
        className={classNames(styles.popoutTree, className)}
        ref={popoutTreeRef}
      >
        <InputGroup
          size="sm"
          {...(isSearchEmpty && { marginBottom: '8px' })}
          onClick={() => {
            setDrillState([]);
          }}
        >
          <Input
            value={search}
            onChange={handleChange}
            placeholder="Search..."
            data-testid="tree-search-input"
            autoFocus // not functional inside a popover with the current version of Chakra UI / framer-motion
            ref={searchInputRef}
          />
          <InputRightElement
            children={
              isSearchEmpty ? (
                <SearchIcon w={3.5} h={3.5} color="silver.600" />
              ) : (
                <CloseIcon
                  style={{ cursor: 'pointer' }}
                  w={2.5}
                  h={2.5}
                  color="silver.600"
                  onClick={onResetSearch}
                />
              )
            }
          />
        </InputGroup>
        {!isSearchEmpty && (
          <Button
            variant="link"
            size="xs"
            colorScheme="gray"
            onClick={isExpanded ? collapse : expand}
          >
            {isExpanded ? 'Collapse All' : 'Expand All'}
          </Button>
        )}
        <div className={styles.rootTreeContainer} ref={rootTreeRef}>
          {isSearchEmpty && branchLabel && (
            <BranchLabel
              branch={branchLabel.label}
              branchDescription={branchLabel.description}
            />
          )}
          <TreeSelection
            {...props}
            data={data}
            treeApi={treeApi}
            openByDefault={false}
            disabled={
              isSearchEmpty ? disabledLevels?.includes(0) : disabledLevels
            }
            height={getHeight({ isSearchEmpty, branches })}
            drillMode={{
              isEnabled: isDrillEnabled,
              level: 0,
              isRoot: true,
              drillIntoNode,
              drillOutToLevel,
              ...(isDrillEnabled && { isDrilledInto }),
            }}
            sortSuggestedSearch={sortSuggestedSearch && !isSearchEmpty}
            onClick={() => {
              setDrillState([]);
            }}
          />
          {drillState.map(({ level, parentNode, parentPosition }, i) => {
            const children = getChildren({
              data,
              idPath: getPathToNode(parentNode.id),
            });

            return (
              <div
                key={parentNode.id}
                className={styles.floatingTree}
                style={{
                  top: `${parentPosition.y}px`,
                  left: `${parentPosition.x}px`,
                  transform: `translateX(${parentPosition.renderOnLeft ? 'calc(-200% + 24px)' : '0'})`,
                }}
                onClick={() => {
                  setDrillState((prev) => prev.slice(0, i + 1));
                }}
              >
                {Array.isArray(branches) && branches?.[i + 1] && (
                  <BranchLabel
                    branch={branches?.[i + 1].label}
                    branchDescription={branches?.[i + 1]?.description}
                  />
                )}
                {typeof branches === 'function' && (
                  <BranchLabel
                    branch={branches({ ...parentNode, level } as TNode).label}
                    branchDescription={
                      branches({ ...parentNode, level } as TNode)?.description
                    }
                  />
                )}
                <TreeSelection
                  {...props}
                  data={children}
                  treeApi={treeApi}
                  openByDefault={false}
                  disabled={disabledLevels?.includes(i + 1)}
                  drillMode={{
                    isEnabled: isDrillEnabled,
                    level: i + 1,
                    drillIntoNode,
                    drillOutToLevel,
                    ...(isDrillEnabled && { isDrilledInto }),
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );
  }
);
