import { PositionedItem } from '../config-provider';
import { OnResizeProps, ResizerEdge } from './resizer-edge';
import { ItemWithId, YPosition } from './types';
import { SlotItem, XPosition } from './types';
import { GRID_HEIGHT, GRID_WIDTH, checkInterference } from './utils';

export type TempResize = {
  id: string;
  x: number;
  y: number;
  width: number;
  height: number;
};

type ResizerProps<T extends ItemWithId> = {
  slotItem: SlotItem<T>;
  items: PositionedItem[];
  singleUnitWidth: number;
  singleUnitHeight: number;
  setItems: (items: PositionedItem[]) => void;
  setTempResize?: (size: TempResize | null) => void;
};

export const SwapySlotResizer = <T extends ItemWithId>({
  slotItem,
  items,
  singleUnitWidth,
  singleUnitHeight,
  setItems,
  setTempResize,
}: ResizerProps<T>) => {
  const onResize = ({ deltaX, deltaY, direction }: OnResizeProps) => {
    const xUnits = Math.floor(
      (deltaX + singleUnitWidth / 2) / (singleUnitWidth + 16)
    );
    const yUnits = Math.floor(
      (deltaY + singleUnitHeight / 2) / (singleUnitHeight + 16)
    );

    const slotItemPosition = slotItem.position;

    /** ================ E ================ */
    if (direction === 'e') {
      const newWidth = (slotItemPosition.width + xUnits) as XPosition;
      if (newWidth < 1 || newWidth + slotItemPosition.x > GRID_WIDTH + 1) {
        return;
      }

      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: { ...item.position, width: newWidth },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }

    /** ================ W ================ */
    if (direction === 'w') {
      const newXPosition = (slotItemPosition.x + xUnits) as XPosition;
      const newWidth = (slotItemPosition.width - xUnits) as XPosition;
      if (
        newXPosition < 1 ||
        newWidth < 1 ||
        newXPosition + newWidth > GRID_WIDTH + 1
      ) {
        return;
      }
      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: {
              ...item.position,
              x: newXPosition,
              width: newWidth,
            },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }

    /** ================ N ================ */
    if (direction === 'n') {
      const newYPosition = (slotItemPosition.y + yUnits) as YPosition;
      const newHeight = (slotItemPosition.height - yUnits) as YPosition;
      if (
        newYPosition < 1 ||
        newHeight < 1 ||
        newYPosition + newHeight > GRID_HEIGHT + 1
      ) {
        return;
      }
      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: {
              ...item.position,
              y: newYPosition,
              height: newHeight,
            },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }

    /** ================ S ================ */
    if (direction === 's') {
      const newHeight = (slotItemPosition.height + yUnits) as YPosition;
      if (newHeight < 1 || newHeight + slotItemPosition.y > GRID_HEIGHT + 1) {
        return;
      }

      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: { ...item.position, height: newHeight },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }

    /** ================ NE ================ */
    if (direction === 'ne') {
      const newYPosition = (slotItemPosition.y + yUnits) as YPosition;
      const newWidth = (slotItemPosition.width + xUnits) as XPosition;
      const newHeight = (slotItemPosition.height - yUnits) as YPosition;
      if (
        newYPosition < 1 ||
        newWidth < 1 ||
        newHeight < 1 ||
        newYPosition + newHeight > GRID_HEIGHT + 1
      ) {
        return;
      }
      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: {
              ...item.position,
              y: newYPosition,
              height: newHeight,
              width: newWidth,
            },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }

    /** ================ NW ================ */
    if (direction === 'nw') {
      const newYPosition = (slotItemPosition.y + yUnits) as YPosition;
      const newXPosition = (slotItemPosition.x + xUnits) as XPosition;
      const newWidth = (slotItemPosition.width - xUnits) as XPosition;
      const newHeight = (slotItemPosition.height - yUnits) as YPosition;
      if (
        newYPosition < 1 ||
        newXPosition < 1 ||
        newWidth < 1 ||
        newHeight < 1 ||
        newYPosition + newHeight > GRID_HEIGHT + 1 ||
        newXPosition + newWidth > GRID_WIDTH + 1
      ) {
        return;
      }

      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: {
              ...item.position,
              x: newXPosition,
              y: newYPosition,
              width: newWidth,
              height: newHeight,
            },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }

    /** ================ SE ================ */
    if (direction === 'se') {
      const newWidth = (slotItemPosition.width + xUnits) as XPosition;
      const newHeight = (slotItemPosition.height + yUnits) as YPosition;
      if (
        newWidth < 1 ||
        newHeight < 1 ||
        newWidth + slotItemPosition.x > GRID_WIDTH + 1 ||
        newHeight + slotItemPosition.y > GRID_HEIGHT + 1
      ) {
        return;
      }

      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: { ...item.position, width: newWidth, height: newHeight },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }

    /** ================ SW ================ */
    if (direction === 'sw') {
      const newXPosition = (slotItemPosition.x + xUnits) as XPosition;
      const newHeight = (slotItemPosition.height + yUnits) as YPosition;
      const newWidth = (slotItemPosition.width - xUnits) as XPosition;
      if (
        newXPosition < 1 ||
        newHeight < 1 ||
        newWidth < 1 ||
        newXPosition + newWidth > GRID_WIDTH + 1 ||
        newHeight + slotItemPosition.y > GRID_HEIGHT + 1
      ) {
        return;
      }

      const newItems = items.map((item): PositionedItem => {
        if (item.id === slotItem.item?.id) {
          return {
            ...item,
            position: {
              ...item.position,
              x: newXPosition,
              width: newWidth,
              height: newHeight,
            },
          };
        }
        return item;
      });
      if (checkInterference(newItems)) return;
      setItems(newItems);
    }
  };

  const onLiveResize = (props: OnResizeProps | null) => {
    if (!setTempResize) return;

    if (!props || !slotItem.item?.id) setTempResize(null);
    else {
      if (['e', 's', 'se'].includes(props.direction)) {
        setTempResize({
          id: slotItem.item?.id,
          x: 0,
          y: 0,
          width: props.deltaX,
          height: props.deltaY,
        });
      }
      if (['w', 'n', 'nw'].includes(props.direction)) {
        setTempResize({
          id: slotItem.item?.id,
          x: props.deltaX,
          y: props.deltaY,
          width: props.deltaX * -1,
          height: props.deltaY * -1,
        });
      }

      if (props.direction === 'ne') {
        setTempResize({
          id: slotItem.item?.id,
          x: 0,
          y: props.deltaY,
          width: props.deltaX,
          height: props.deltaY * -1,
        });
      }

      if (props.direction === 'sw') {
        setTempResize({
          id: slotItem.item?.id,
          x: props.deltaX,
          y: 0,
          width: props.deltaX * -1,
          height: props.deltaY,
        });
      }
    }
  };

  return (
    <>
      <ResizerEdge
        direction="e"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
      <ResizerEdge
        direction="w"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
      <ResizerEdge
        direction="n"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
      <ResizerEdge
        direction="s"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
      <ResizerEdge
        direction="ne"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
      <ResizerEdge
        direction="nw"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
      <ResizerEdge
        direction="se"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
      <ResizerEdge
        direction="sw"
        onResize={onResize}
        onLiveResize={onLiveResize}
      />
    </>
  );
};
