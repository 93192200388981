import { inRange, isNil } from 'lodash';

import { CustomRoleTaxonomySelection } from '@revelio/data-access';

import { CustomRoleFilterValue, FilterValue } from '../filters.model';
import { CustomRoleTaxonomyId, CustomRoleTaxonomyIds } from './types';

export const isCustomRoleTaxonomySelection = (
  filterValue: FilterValue | undefined
): filterValue is CustomRoleTaxonomySelection => {
  if (
    isNil(filterValue) ||
    typeof filterValue === 'string' ||
    typeof filterValue === 'number' ||
    typeof filterValue === 'boolean' ||
    Array.isArray(filterValue) ||
    typeof filterValue !== 'object' ||
    (!('default' in filterValue) &&
      !('ricsK10Id' in filterValue) &&
      !('ricsK50Id' in filterValue))
  ) {
    return false;
  }

  return true;
};

export const isCustomRoleFilterValue = (
  filterValue: FilterValue | undefined
): filterValue is CustomRoleFilterValue => {
  return (
    typeof filterValue === 'object' &&
    'taxonomyId' in filterValue &&
    'entities' in filterValue
  );
};

export const getTaxonomySelection = (
  taxonomyId: CustomRoleTaxonomyId
): CustomRoleTaxonomySelection => {
  if (!taxonomyId || taxonomyId.includes('default')) {
    return { default: true };
  }

  if (taxonomyId.includes('rics_k10')) {
    const id = taxonomyId.split('custom_role_rics_k10_')[1];
    return id ? { ricsK10Id: id } : { default: true };
  }

  if (taxonomyId.includes('rics_k50')) {
    const id = taxonomyId.split('custom_role_rics_k50_')[1];
    return id ? { ricsK50Id: id } : { default: true };
  }

  return { default: true };
};

export const isRicsTaxonomyId = (id: string): id is CustomRoleTaxonomyId =>
  CustomRoleTaxonomyIds.includes(id as CustomRoleTaxonomyId);

export const getTaxonomyId = (
  customRoleTaxonomy: CustomRoleTaxonomySelection
): CustomRoleTaxonomyId | null => {
  if ('default' in customRoleTaxonomy) {
    return 'custom_role_default';
  }

  if (
    'ricsK10Id' in customRoleTaxonomy &&
    customRoleTaxonomy.ricsK10Id &&
    inRange(Number(customRoleTaxonomy.ricsK10Id), 1, 11)
  ) {
    const id = `custom_role_rics_k10_${customRoleTaxonomy.ricsK10Id}`;
    if (isRicsTaxonomyId(id)) return id;
  }

  if (
    'ricsK50Id' in customRoleTaxonomy &&
    customRoleTaxonomy.ricsK50Id &&
    inRange(Number(customRoleTaxonomy.ricsK50Id), 1, 51)
  ) {
    const id = `custom_role_rics_k50_${customRoleTaxonomy.ricsK50Id}`;
    if (isRicsTaxonomyId(id)) return id;
  }

  return null;
};

export const getDeepLinkTaxonomyId = (
  taxonomyId: CustomRoleTaxonomySelection
) => {
  if (!taxonomyId) {
    return null;
  }

  if (taxonomyId.default) {
    return 'default';
  }

  if (taxonomyId.ricsK10Id !== undefined) {
    return `ricsK10Id__${taxonomyId.ricsK10Id}`;
  }

  if (taxonomyId.ricsK50Id !== undefined) {
    return `ricsK50Id__${taxonomyId.ricsK50Id}`;
  }

  return null;
};
