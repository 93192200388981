import { Box, Flex, Text, useToast } from '@chakra-ui/react';
import classNames from 'classnames';
import { useRef } from 'react';

import { useResizeObserver } from '@revelio/core';
import { D3ChartNames } from '@revelio/d3';
import { FormatType, NEGATIVE_COLOR, POSITIVE_COLOR } from '@revelio/replots';

import { PlotCard } from '../../../../../shared/components';
import { getSentimentDataDownload } from '../../utils/get-sentiment-data-download';
import { DirectionArrows } from './direction-arrows';
import { EffectChart } from './effect-chart';
import styles from './sentiment-effects-charts.module.css';
import { SingleEntityPlotGrid } from './types';

type SingleEntityPlotCardProps = {
  plotGrid: SingleEntityPlotGrid;
  minValue: number;
  maxValue: number;
  colors?: string[];
  title?: string;
};

export const SingleEntityPlotCard = ({
  plotGrid,
  minValue,
  maxValue,
  colors,
  title = 'Sentiment Topics',
}: SingleEntityPlotCardProps) => {
  const toast = useToast();
  const containerRef = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(containerRef);

  const menuProps = {
    onExport: () => {
      const success = getSentimentDataDownload({
        plotGrid: plotGrid,
      });

      if (!success) {
        toast({
          title: 'Error',
          description: 'Failed to download data',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    plotShare: {
      data:
        plotGrid.chartType === 'bar'
          ? plotGrid.data.map((d, i) => ({
              ...d,
              color: d.value && d.value >= 0 ? POSITIVE_COLOR : NEGATIVE_COLOR,
            }))
          : plotGrid.data,
      chartConfig: {
        chartType:
          plotGrid.chartType === 'bar'
            ? D3ChartNames.ReplotsBarChart
            : D3ChartNames.ReplotsLineChart,
        chartProps: {
          format: FormatType.PERCENTAGE,
          colors: colors,
          showBarLabels: false,
        },
      },
    },
  };

  return (
    <PlotCard
      className={classNames(styles.plotCard)}
      title={title}
      info={
        <Text fontSize="xs" color="text.secondary">
          {`Sentiment scores are derived from both the free text and the star ratings 
              associated with employee reviews, and normalized so that 50% is the median 
              score amongst peer companies. Sample size includes all reviews with star 
              ratings predictive of a specific sentiment topic, as well as all 
              reviews that contain a positive or negative signal for that sentiment topic 
              in their free text.`}
        </Text>
      }
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      {...menuProps}
    >
      <Flex ref={containerRef} direction="column" height="100%">
        <Box height={`calc(100% - 25px)`}>
          <EffectChart
            colors={colors}
            minDataValue={minValue}
            maxDataValue={maxValue}
            singleEntityView
            {...plotGrid}
          />
        </Box>

        <Box width="100%" height="25px">
          <DirectionArrows
            width={width}
            leftLabel="Factors of Attrition"
            rightLabel="Factors of Hiring"
          />
        </Box>
      </Flex>
    </PlotCard>
  );
};
