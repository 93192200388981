import {
  CompositionV2EntRespData,
  SentimentMetrics,
} from '@revelio/data-access';

export type CompositionMetricValues = keyof Pick<
  CompositionV2EntRespData,
  | 'headcount'
  | 'growth_rate'
  | 'hiring_rate'
  | 'attrition_rate'
  | 'tenure'
  | 'salary'
>;
export type TransitionMetricValues = 'transitions';

export type PostingMetricValues =
  | 'posting_salary'
  | 'time_to_fill'
  | 'postings_active'
  | 'postings_new'
  | 'postings_removed';

export type VolumeMetricValues = 'postings_volume';

export type PayMetricValues = 'pay_distribution';

export type SentimentMetricValues = keyof Pick<
  SentimentMetrics,
  | 'overall'
  | 'business_outlook'
  | 'career_growth'
  | 'compensation'
  | 'benefits'
  | 'culture'
  | 'work_life_balance'
  | 'diversity_and_inclusion'
  | 'leadership'
>;

export type MetricValues =
  | CompositionMetricValues
  | TransitionMetricValues
  | PostingMetricValues
  | VolumeMetricValues
  | PayMetricValues
  | SentimentMetricValues;

export type MetricOption = {
  value: MetricValues;
  label: string;
};

type GroupedMetricOption = {
  label: string;
  options: MetricOption[];
};

export const metricOptions: MetricOption[] = [
  { value: 'headcount', label: 'Headcount' },
  { value: 'growth_rate', label: 'Growth Rate' },
  { value: 'hiring_rate', label: 'Hiring Rate' },
  { value: 'attrition_rate', label: 'Attrition Rate' },
  { value: 'tenure', label: 'Tenure' },
  { value: 'salary', label: 'Salary' },
  { value: 'transitions', label: 'Transitions' },
  { value: 'posting_salary', label: 'Posting Salary' },
  { value: 'time_to_fill', label: 'Time to Fill' },
  { value: 'postings_active', label: 'Active Postings' },
  { value: 'postings_new', label: 'New Postings' },
  { value: 'postings_removed', label: 'Removed Postings' },
  { value: 'postings_volume', label: 'Postings Volume' },
  { value: 'pay_distribution', label: 'Pay Distribution' },
  { value: 'overall', label: 'Sentiment - Overall' },
  { value: 'business_outlook', label: 'Sentiment - Business Outlook' },
  { value: 'career_growth', label: 'Sentiment - Career Growth' },
  { value: 'compensation', label: 'Sentiment - Compensation' },
  { value: 'benefits', label: 'Sentiment - Benefits' },
  { value: 'culture', label: 'Sentiment - Culture' },
  { value: 'work_life_balance', label: 'Sentiment - Work Life Balance' },
  {
    value: 'diversity_and_inclusion',
    label: 'Sentiment - Diversity and Inclusion',
  },
  { value: 'leadership', label: 'Sentiment - Leadership' },
];

export const groupedMetricOptions: GroupedMetricOption[] = [
  {
    label: 'Composition',
    options: [
      { value: 'headcount', label: 'Headcount' },
      { value: 'growth_rate', label: 'Growth Rate' },
      { value: 'hiring_rate', label: 'Hiring Rate' },
      { value: 'attrition_rate', label: 'Attrition Rate' },
      { value: 'tenure', label: 'Tenure' },
      { value: 'salary', label: 'Salary' },
    ],
  },
  {
    label: 'Transitions',
    options: [{ value: 'transitions', label: 'Transitions' }],
  },
  {
    label: 'Postings',
    options: [
      { value: 'posting_salary', label: 'Posting Salary' },
      { value: 'time_to_fill', label: 'Time to Fill' },
      { value: 'postings_active', label: 'Active Postings' },
      { value: 'postings_new', label: 'New Postings' },
      { value: 'postings_removed', label: 'Removed Postings' },
      { value: 'postings_volume', label: 'Postings Volume' },
    ],
  },
  {
    label: 'Pay',
    options: [{ value: 'pay_distribution', label: 'Pay Distribution' }],
  },
  {
    label: 'Sentiment',
    options: [
      { value: 'overall', label: 'Overall' },
      { value: 'business_outlook', label: 'Business Outlook' },
      { value: 'career_growth', label: 'Career Growth' },
      { value: 'compensation', label: 'Compensation' },
      { value: 'benefits', label: 'Benefits' },
      { value: 'culture', label: 'Culture' },
      { value: 'work_life_balance', label: 'Work Life Balance' },
      { value: 'diversity_and_inclusion', label: 'Diversity and Inclusion' },
      { value: 'leadership', label: 'Leadership' },
    ],
  },
];
