import { graphql } from '../../gql';

export const CUSTOM_ROLE_TAXONOMY_QUERY = graphql(`
  query CustomRoleTaxonomy($taxonomyId: CustomRoleTaxonomySelection!) {
    selectionList {
      custom_role_taxonomy(taxonomy_id: $taxonomyId) {
        orderedLevels {
          id
          label
        }
        leafLevelData {
          id
          label
          levelId
          suggested_titles
          parents {
            id
            levelId
            label
            suggested_titles
          }
        }
      }
    }
  }
`);
