import { CompositionV2EntRespData } from '@revelio/data-access';

export type PrimaryDimensionValues = 'company' | 'geography' | 'role';

export type PrimaryDimensionOption = {
  value: PrimaryDimensionValues;
  label: string;
};

export const primaryDimensionOptions: PrimaryDimensionOption[] = [
  { value: 'company', label: 'Company' },
  { value: 'geography', label: 'Geography' },
  { value: 'role', label: 'Role' },
];

type CompositionSecondaryDimensionValues = keyof Pick<
  CompositionV2EntRespData,
  | 'rics'
  | 'geography'
  | 'role'
  | 'skills'
  | 'gender'
  | 'ethnicity'
  | 'education'
  | 'seniority'
>;

export type SecondaryDimensionValues = CompositionSecondaryDimensionValues;

export type SecondaryDimensionOption = {
  value: SecondaryDimensionValues;
  label: string;
};

export const secondaryDimensionOptions: SecondaryDimensionOption[] = [
  { value: 'rics', label: 'Industry' },
  { value: 'geography', label: 'Geography' },
  { value: 'role', label: 'Role' },
  { value: 'skills', label: 'Skill' },
  { value: 'gender', label: 'Gender' },
  { value: 'ethnicity', label: 'Ethnicity' },
  { value: 'education', label: 'Education' },
  { value: 'seniority', label: 'Seniority' },
];
