import {
  CompositionV2DataQuery,
  TransitionDataQuery,
} from '@revelio/data-access';
import { StackedBarData } from '@revelio/replots';

import { limitStackedBarSegments } from '../../../../overview/utils';
import {
  CompositionMetricValues,
  PostingMetricValues,
  SecondaryDimensionValues,
  SentimentMetricValues,
} from '../../../components/config-provider';
import { DataQuery } from '../types';
import { getCompositionNormalizedData } from './get-composition-normalized-data';
import { getPostingNormalizedData } from './get-posting-normalized-data';
import { getSentimentNormalizedData } from './get-sentiment-normalized-data';

export type NormalizedDataPoint<T extends object = object> = T & {
  date: string;
  value: number | null;
};

export type NormalizedData<T extends object = object> = {
  dimension: string;
  timeseries: NormalizedDataPoint<T>[];
};
type GetNormalizedDataProps = {
  data: DataQuery;
  metric: CompositionMetricValues | PostingMetricValues | SentimentMetricValues;
  secondaryDimension?: SecondaryDimensionValues;
};
export const getNormalizedData = ({
  data,
  metric,
  secondaryDimension,
}: GetNormalizedDataProps): NormalizedData[] => {
  switch (metric) {
    case 'headcount':
    case 'growth_rate':
    case 'attrition_rate':
    case 'hiring_rate':
    case 'tenure':
    case 'salary':
      return getCompositionNormalizedData({ data, metric, secondaryDimension });

    case 'posting_salary':
    case 'time_to_fill':
    case 'postings_active':
    case 'postings_new':
    case 'postings_removed':
      return getPostingNormalizedData({ data, metric });

    case 'overall':
    case 'business_outlook':
    case 'career_growth':
    case 'compensation':
    case 'benefits':
    case 'culture':
    case 'work_life_balance':
    case 'diversity_and_inclusion':
    case 'leadership':
      return getSentimentNormalizedData({ data, metric });
  }
};

export const getStackedBarData = ({
  entities,
  plotName,
  formatLabel = (label) => label,
  defaultLimitSegments = 7,
}: {
  entities: NonNullable<CompositionV2DataQuery['composition_v2']>['entities'];
  plotName: SecondaryDimensionValues;
  formatLabel?: (label: string) => string;
  defaultLimitSegments?: number;
}): StackedBarData[] => {
  const metricKey = plotName;

  if (!metricKey) {
    return [];
  }
  const limitSegments =
    plotName === 'skills' ? defaultLimitSegments : undefined; // limit skills to top 7 skills

  const stackedBarData: StackedBarData[] = entities?.map((entity) => {
    const { short_name: shortName } = entity.metadata;

    const segments: Record<string, number> = {};
    const metrics = entity.metrics[metricKey];
    metrics.forEach((d) => {
      const lastTimeSeries = d.timeseries[d.timeseries.length - 1];
      if (d?.metadata?.short_name && lastTimeSeries?.count) {
        segments[formatLabel(d.metadata.short_name)] = lastTimeSeries.count;
      }
    });

    return { label: shortName, segments };
  });

  if (limitSegments) {
    return limitStackedBarSegments(stackedBarData, limitSegments);
  }
  return stackedBarData;
};

export const getTransitionStackedBarData = ({
  transitionMetrics,
  secondaryDimension,
}: {
  transitionMetrics: NonNullable<
    NonNullable<TransitionDataQuery['transitions2D']>[number]
  >['metrics'];
  secondaryDimension: SecondaryDimensionValues;
}): StackedBarData[] => {
  if (
    !transitionMetrics ||
    !secondaryDimension ||
    secondaryDimension === 'rics' ||
    secondaryDimension === 'skills'
  ) {
    return [];
  }

  const metrics = transitionMetrics?.[secondaryDimension];

  if (!metrics) return [];

  const currentSegments: Record<string, number> = {};
  const outflowSegments: Record<string, number> = {};
  metrics
    .filter((metric) => metric?.shortName !== 'empty')
    .forEach((metric) => {
      if (!metric) return;
      const { shortName, outflow, current } = metric;
      if (!shortName || !outflow || !current) return;
      currentSegments[shortName] = current;
      outflowSegments[shortName] = outflow;
    });

  return [
    { label: 'Current', segments: currentSegments },
    { label: 'Outflow', segments: outflowSegments },
  ];
};
