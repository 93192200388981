import { group } from 'd3-array';
import { useCallback, useMemo } from 'react';

import { useSelectionListsValidated } from '../../../engine/filters.engine';
import { SelectionCategories } from '../../../engine/filters.model';
import { deleteFilter, upsertFilter } from '../../../engine/filters.repository';
import { GetNestedIdProps, getNestedId, nestSelectionLists } from '../../tree';
import { FilterDataProvider } from '../types';
import { useActiveSelectionListItems } from '../utils';
import {
  NestedFilterItem,
  getNestedFilterItemsFromIds,
} from '../utils/get-filter-items-from-ids';

export function useSelectionListFilter(
  selectionListIds: SelectionCategories[] = []
): FilterDataProvider<NestedFilterItem> {
  const selectionLists = useSelectionListsValidated(selectionListIds);
  const activeFilters = useActiveSelectionListItems(selectionListIds);

  const sortedSelectionLists = useMemo(() => {
    return selectionLists.map((selectionList) => {
      const activeFilter = activeFilters.find(
        (filter) => filter.id === selectionList.id
      );
      if (!activeFilter) return selectionList;
      else {
        return {
          ...selectionList,
          value: [...selectionList.value].sort((a, b) => {
            const aActive = activeFilter.value.some((item) => item.id === a.id);
            const bActive = activeFilter.value.some((item) => item.id === b.id);
            if (aActive && !bActive) return -1;
            if (!aActive && bActive) return 1;
            return 0;
          }),
        };
      }
    });
  }, [selectionLists, activeFilters]);

  /*
   * Nested tree data
   */
  const treeData = useMemo(
    () => nestSelectionLists(sortedSelectionLists),
    [sortedSelectionLists]
  );

  /*
   * Active filter ids
   */
  const activeIds = useMemo(
    () =>
      activeFilters
        .flatMap((filter) =>
          filter.value.map((item): GetNestedIdProps['item'] => ({
            ...item,
            selectionListId: filter.id,
          }))
        )
        .map((item) =>
          getNestedId({ selectionLists: sortedSelectionLists, item })
        ),
    [activeFilters, sortedSelectionLists]
  );

  /*
   * Get selected entities
   */
  const getSelectedEntities = useCallback(
    (selections: string[]): NestedFilterItem[] =>
      getNestedFilterItemsFromIds(selections, sortedSelectionLists),
    [sortedSelectionLists]
  );

  /*
   * Apply filter selection
   */
  const applySelectedIds = useCallback(
    (selections: string[]) => {
      const selectedEntities = getSelectedEntities(selections);

      const groupedFilters = group(selectedEntities, (d) => d.selectionListId);

      selectionListIds.forEach((selectionListId) => {
        if (!groupedFilters.has(selectionListId)) deleteFilter(selectionListId);
      });

      groupedFilters.forEach((entities, selectionListId) => {
        upsertFilter(selectionListId, {
          selectionListId,
          isMulti: true,
          value: entities,
        });
      });
    },
    [getSelectedEntities, selectionListIds]
  );

  return {
    treeData,
    activeIds,
    applySelectedIds,
    getSelectedEntities,
  };
}
