import { Box } from '@chakra-ui/layout';
import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import Iframe from 'react-iframe';
import { useLocation, useNavigate } from 'react-router';

import {
  IFrameMessageTypes,
  PagePaths,
  UserTrackingEvents,
  useListenForIFrameMessages,
} from '@revelio/core';

const DataDictionary = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [iframeUrl, setIframeUrl] = React.useState<string>();

  useEffect(() => {
    mixpanel.track(UserTrackingEvents.PAGE_VISIT, {
      page: 'Data Dictionary',
      path: location.pathname,
    });
    mixpanel.time_event(UserTrackingEvents.PAGE_LEAVE);
    return () => {
      mixpanel.track(UserTrackingEvents.PAGE_LEAVE, {
        page: 'Data Dictionary',
        path: location.pathname,
      });
    };
  }, [location.pathname]);

  useEffect(() => {
    // Extract the path suffix after '/data-dictionary'
    const pathSuffix = location.pathname.replace(
      `/${PagePaths.DATA_DICTIONARY}`,
      ''
    );

    // Transform the pathSuffix based on the pattern
    let transformedPath = pathSuffix;

    // Check if it matches the pattern /[category]/[sub-category]
    if (/^\/[^/]+\/[^/]+/.test(pathSuffix)) {
      // Replace /[category]/[sub-category] with /[category.html]#[sub-category] for the iframe URL
      transformedPath = pathSuffix.replace(
        /^(\/[^/]+)\/([^/]+)(.*)$/,
        '$1.html#$2$3'
      );
    }
    // Check if it matches just /[category]
    else if (/^\/[^/]+$/.test(pathSuffix)) {
      // Replace /[category] with /[category].html for iframe URL
      transformedPath = `${pathSuffix}.html`;
    }

    setIframeUrl(
      `https://www.data-dictionary.reveliolabs.com${transformedPath}`
    ); // Local Development: setIframeUrl(`http://localhost:45000${transformedPath}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useListenForIFrameMessages(
    IFrameMessageTypes.DataDictionaryUrlPath,
    ({ data: pathName, type }) => {
      if (pathName && typeof pathName === 'string' && pathName !== '/') {
        navigate(
          `/${PagePaths.DATA_DICTIONARY}/${pathName.startsWith('/') ? pathName.slice(1) : pathName}`
        );
      } else {
        console.error('useListenForIFrameMessages: invalid path', {
          data: pathName,
          type,
        });
      }
    }
  );

  return (
    <Box height="100vh">
      <Iframe
        url={iframeUrl ?? ''}
        width="100%"
        height="100%"
        id="ddId"
        position="relative"
        styles={{ border: 'none' }}
      />
    </Box>
  );
};

export default DataDictionary;
