import { WarningTwoIcon } from '@chakra-ui/icons';
import {
  Alert,
  Flex,
  FormLabel,
  Icon,
  Image,
  Input,
  Link,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';

import { ActionModalControlPanel } from '@revelio/core';

import sourceWhaleIcon from '../../../../images/icons/sourcewhale-icon.png';

const modalStyles = {
  header: {
    position: 'relative',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '23px',
    color: 'text.primary',
    marginBottom: '0',
  },
  label: {
    fontWeight: '600',
    lineHeight: '15px',
    fontSize: '12px',
    color: 'text.primary',
    mb: 2,
  },
  error: {
    backgroundColor: '#FDF0F3',
    fontSize: '10px',
    lineHeight: '11px',
    fontWeight: '400',
    color: '#E76A87',
    borderRadius: 'sm',
    mb: 2,
  },
  warning: {
    backgroundColor: '#FFF2D0',
    fontSize: '10px',
    lineHeight: '11px',
    fontWeight: '400',
    color: 'text.primary',
    borderRadius: 'sm',
    mb: 2,
  },
  link: {
    color: '#09BBF7',
  },
} as const;

interface SourceWhaleOnboardingModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSave: (apiKey: string) => void;
  savedApiKey: string | null;
}

export const SourceWhaleOnboardingModal = ({
  isOpen,
  onClose,
  onSave,
  savedApiKey,
}: SourceWhaleOnboardingModalProps) => {
  const [apiKey, setApiKey] = useState(savedApiKey ?? '');

  useEffect(() => {
    setApiKey(savedApiKey ?? '');
  }, [savedApiKey, isOpen]);

  const hasSavedApiKey = Boolean(savedApiKey);

  const validateApiKey = (apiKey: string) => {
    // SourceWhale API key are 43 characters long as of Jan 2025.
    // Let's allow a reasonable range of lengths in case it changes.
    return /^[A-Za-z0-9_-]{20,100}$/.test(apiKey);
  };

  const handleSubmit = () => {
    if (!validateApiKey(apiKey)) {
      return;
    }

    onSave(apiKey);
    onClose();
  };

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      id="source-whale-onboarding-modal"
      isOpen={isOpen}
      onClose={handleClose}
      isCentered
      size="sm"
    >
      <ModalOverlay />

      <ModalContent userSelect="none">
        <ModalHeader sx={modalStyles.header}>
          <Flex gap="2" alignItems="center">
            <Image src={sourceWhaleIcon} boxSize={4} />
            Connect your SourceWhale account
          </Flex>
          <ModalCloseButton size="sm" top="50%" transform="translateY(-50%)" />
        </ModalHeader>

        <ModalBody py={0}>
          <ApiKeyAlert hasSavedApiKey={hasSavedApiKey} />

          <FormLabel fontSize="12px" sx={modalStyles.label}>
            SourceWhale API Key
          </FormLabel>
          <Input
            data-testid="sourcewhale-api-key-input"
            height="30px"
            fontSize="12px"
            value={apiKey}
            placeholder="API key"
            onChange={(e) => {
              setApiKey(e.target.value);
            }}
          />

          <Text mt={2} fontSize="10px" color="text.primary">
            <p>
              Don't know your API key? Go to{' '}
              <Link
                href="https://sourcewhale.app/admin#settings"
                sx={modalStyles.link}
                isExternal
              >
                SourceWhale settings
              </Link>{' '}
              to generate your API Key.
            </p>
            <p>
              If you need help setting up your SourceWhale account, reach out to
              <Link
                href="mailto:sales@reveliolabs.com?subject=SourceWhale account setup"
                sx={modalStyles.link}
                isExternal
              >
                {' '}
                sales
              </Link>
              .
            </p>
          </Text>
        </ModalBody>

        <ModalFooter>
          <ActionModalControlPanel
            submitText="Save"
            onClose={handleClose}
            onCancel={handleClose}
            onSubmit={handleSubmit}
            submitIsDisabled={!validateApiKey(apiKey)}
            includeReset={false}
          />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

// This modal only appears when the user has no API key set, or when a simple request to the SourceWhale API fails.
// As such, it always displays either a warning or an error message.
const ApiKeyAlert = ({ hasSavedApiKey }: { hasSavedApiKey: boolean }) => (
  <Alert
    sx={hasSavedApiKey ? modalStyles.error : modalStyles.warning}
    status={hasSavedApiKey ? 'error' : 'warning'}
  >
    <Flex gap="2" alignItems="center">
      <Icon
        as={WarningTwoIcon}
        color={hasSavedApiKey ? '#E76A87' : '#CA9201'}
      />
      {hasSavedApiKey
        ? 'Your SourceWhale API key may be invalid. Please check your API key or generate a new one if needed.'
        : `You haven't connected your SourceWhale account yet!`}
    </Flex>
  </Alert>
);
