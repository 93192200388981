import { PrimaryView } from '@revelio/core';
import {
  ReviewFilters,
  ReviewSecondaryFilterKeys,
  ReviewSecondaryFilters,
  ReviewType,
} from '@revelio/data-access';
import {
  CustomRoleFilterValue,
  FilterName,
  LocalSelectionCategories,
  SelectionCategories,
  getFilterChildSelectionListIds,
} from '@revelio/filtering';
import { AnyFilter, FilterList, ValidValueTypes } from '@revelio/filtering';
import { SelectionList } from '@revelio/filtering';

export type SelectedEntity = {
  id: number;
  label: string;
  selectionListId: SelectionCategories;
};

interface GenerateSentimentPayloadProps {
  filters: AnyFilter<FilterList<ValidValueTypes>>[];
  selectionLists: SelectionList<ValidValueTypes>[];
  primaryView: PrimaryView;
  isCustomRoleTaxonomyActive: boolean;
  reviewType: ReviewType;
  selectedEntity: SelectedEntity;
  isSnapshotDate: boolean;
}
export const generateSentimentPayload = ({
  filters,
  selectionLists,
  primaryView,
  isCustomRoleTaxonomyActive,
  reviewType,
  selectedEntity,
  isSnapshotDate,
}: GenerateSentimentPayloadProps): ReviewFilters | null => {
  const { id, label, selectionListId } = selectedEntity;

  const allIndexFilters = getIndexFilters(filters, isSnapshotDate);

  switch (primaryView) {
    case PrimaryView.COMPANY: {
      const filtersWithMappedRoles = isCustomRoleTaxonomyActive
        ? getMappedRoleIndex({
            reviewFilters: allIndexFilters,
            selectionLists,
          })
        : allIndexFilters;

      if (selectionListId === SelectionCategories.COMPANY) {
        const {
          RICS_K10_INDEX,
          RICS_K50_INDEX,
          RICS_K400_INDEX,
          RCID,
          ...filtersWithoutRics
        } = filtersWithMappedRoles;

        return {
          COMPANY_NAME: label,
          ...filtersWithoutRics,
          RCID: [id],
          reviewType,
        };
      }
      if (
        selectionListId === SelectionCategories.RICS_K10 ||
        selectionListId === SelectionCategories.RICS_K50 ||
        selectionListId === SelectionCategories.RICS_K400
      ) {
        const ricsIndex = getIndustryIndex({ id, selectionListId });
        const {
          RICS_K10_INDEX,
          RICS_K50_INDEX,
          RICS_K400_INDEX,
          RCID,
          ...filtersWithoutRics
        } = filtersWithMappedRoles;

        return {
          INDUSTRY_NAME: label,
          ...filtersWithoutRics,
          ...ricsIndex,
          reviewType,
        };
      }

      return null;
    }
    case PrimaryView.GEOGRAPHY: {
      if (
        selectionListId === SelectionCategories.REGION ||
        selectionListId === SelectionCategories.COUNTRY ||
        selectionListId === SelectionCategories.STATE ||
        selectionListId === SelectionCategories.METRO_AREA
      ) {
        const {
          REGION_INDEX,
          COUNTRY_INDEX,
          STATE_INDEX,
          MSA_INDEX,
          ...filtersWithoutGeo
        } = allIndexFilters;

        return {
          GEOGRAPHY_NAME: label,
          ...getGeographyIndex({ id, selectionListId }),
          ...filtersWithoutGeo,
          reviewType,
        };
      }

      return null;
    }
    case PrimaryView.ROLE: {
      if (
        selectionListId === SelectionCategories.JOB_CATEGORY ||
        selectionListId === SelectionCategories.ROLE_K150
      ) {
        const { ROLE_K7_INDEX, ROLE_K150_INDEX, ...filtersWithoutRole } =
          allIndexFilters;

        return {
          ROLE_NAME: label,
          ...getRoleIndex({ id, selectionListId }),
          ...filtersWithoutRole,
          reviewType,
        };
      }

      return null;
    }
  }
};

const getIndustryIndex = ({
  id,
  selectionListId,
}: {
  selectionListId:
    | SelectionCategories.RICS_K10
    | SelectionCategories.RICS_K50
    | SelectionCategories.RICS_K400;
  id: number;
}) => {
  switch (selectionListId) {
    case SelectionCategories.RICS_K10: {
      return { RICS_K10_INDEX: [id] };
    }
    case SelectionCategories.RICS_K50: {
      return { RICS_K50_INDEX: [id] };
    }
    case SelectionCategories.RICS_K400: {
      return { RICS_K400_INDEX: [id] };
    }
  }
};

const getGeographyIndex = ({
  id,
  selectionListId,
}: {
  selectionListId:
    | SelectionCategories.REGION
    | SelectionCategories.COUNTRY
    | SelectionCategories.STATE
    | SelectionCategories.METRO_AREA;
  id: number;
}) => {
  switch (selectionListId) {
    case SelectionCategories.REGION:
      return { REGION_INDEX: [id] };
    case SelectionCategories.COUNTRY:
      return { COUNTRY_INDEX: [id] };
    case SelectionCategories.STATE:
      return { STATE_INDEX: [id] };
    case SelectionCategories.METRO_AREA:
      return { MSA_INDEX: [id] };
  }
};

const getRoleIndex = ({
  id,
  selectionListId,
}: {
  selectionListId:
    | SelectionCategories.JOB_CATEGORY
    | SelectionCategories.ROLE_K150;
  id: number;
}) => {
  switch (selectionListId) {
    case SelectionCategories.JOB_CATEGORY:
      return { ROLE_K7_INDEX: [id] };
    case SelectionCategories.ROLE_K150:
      return { ROLE_K150_INDEX: [id] };
  }
};

type ReviewFilterName = Extract<
  FilterName,
  | SelectionCategories.COMPANY
  | SelectionCategories.RICS_K10
  | SelectionCategories.RICS_K50
  | SelectionCategories.RICS_K400
  | SelectionCategories.INDUSTRY
  | SelectionCategories.REGION
  | SelectionCategories.COUNTRY
  | SelectionCategories.STATE
  | SelectionCategories.METRO_AREA
  | SelectionCategories.JOB_CATEGORY
  | SelectionCategories.ROLE_K150
  | SelectionCategories.ROLE_K1500
  | SelectionCategories.SENIORITY
  | LocalSelectionCategories.OVERALL_RATING
  | SelectionCategories.DATE_RANGE
>;

type DateFilterValue = {
  startDate: string;
  endDate: string;
};

const reviewSecondaryFilterLookup: {
  [key in ReviewFilterName]: ReviewSecondaryFilterKeys;
} = {
  [SelectionCategories.COMPANY]: 'RCID',
  [SelectionCategories.RICS_K10]: 'RICS_K10_INDEX',
  [SelectionCategories.RICS_K50]: 'RICS_K50_INDEX',
  [SelectionCategories.RICS_K400]: 'RICS_K400_INDEX',
  [SelectionCategories.INDUSTRY]: 'INDUSTRY_INDEX',
  [SelectionCategories.REGION]: 'REGION_INDEX',
  [SelectionCategories.COUNTRY]: 'COUNTRY_INDEX',
  [SelectionCategories.STATE]: 'STATE_INDEX',
  [SelectionCategories.METRO_AREA]: 'MSA_INDEX',
  [SelectionCategories.JOB_CATEGORY]: 'ROLE_K7_INDEX',
  [SelectionCategories.ROLE_K150]: 'ROLE_K150_INDEX',
  [SelectionCategories.ROLE_K1500]: 'MAPPED_ROLE_INDEX',
  [SelectionCategories.SENIORITY]: 'SENIORITY',
  [LocalSelectionCategories.OVERALL_RATING]: 'RATING_OVERALL',
  [SelectionCategories.DATE_RANGE]: 'MONTH_INDEX',
};

const BASE_YEAR = 2008;
const BASE_MONTH = 1;
const MAX_MONTHS = 12;

const getMonthIndex = (date: string) => {
  const [year, month] = date.split('-').map(Number);
  return (year - BASE_YEAR) * 12 + (month - BASE_MONTH);
};

const createIndices = (from: number, count: number) =>
  Array.from({ length: count }, (_, i) => from - i).filter(
    (index) => index >= 0
  );

const getMonthIndices = (
  filter: { isMaximumRange?: boolean; value: DateFilterValue },
  isSnapshotDate: boolean
): number[] => {
  const {
    value: { startDate, endDate },
    isMaximumRange,
  } = filter;

  const referenceDate = isSnapshotDate ? startDate : endDate;
  const monthCount = (() => {
    if (isSnapshotDate && !isMaximumRange) return MAX_MONTHS;
    if (!isSnapshotDate && isMaximumRange) return MAX_MONTHS;

    const startIndex = getMonthIndex(startDate);
    const endIndex = getMonthIndex(endDate);
    return Math.min(endIndex - startIndex + 1, MAX_MONTHS);
  })();

  return createIndices(getMonthIndex(referenceDate), monthCount);
};

const getIndexFilters = (
  filters: AnyFilter<FilterList<ValidValueTypes>>[],
  isSnapshotDate: boolean
): ReviewSecondaryFilters => {
  return filters.reduce((acc, filter): ReviewSecondaryFilters => {
    if (filter?.id === SelectionCategories.CUSTOM_ROLE) {
      const customRole = filter?.value as unknown as CustomRoleFilterValue;
      const entities = customRole?.entities;

      const roleK7Index = entities
        ?.filter((entity) => entity?.levelId === 'role_k7')
        ?.map((entity) => Number(entity?.id));

      const roleK150Index = entities
        ?.filter((entity) => entity?.levelId === 'role_k150')
        ?.map((entity) => Number(entity?.id));

      const roleK1500Index = entities
        ?.filter((entity) => entity?.levelId === 'role_k1500')
        ?.map((entity) => Number(entity?.id));

      return {
        ...acc,
        ROLE_K7_INDEX: roleK7Index,
        ROLE_K150_INDEX: roleK150Index,
        MAPPED_ROLE_INDEX: roleK1500Index,
      };
    }

    const reviewSecondaryFilterKey =
      reviewSecondaryFilterLookup?.[filter?.id as ReviewFilterName];

    if (!reviewSecondaryFilterKey) return acc;

    if (reviewSecondaryFilterKey === 'MONTH_INDEX') {
      const dateFilter = filter as {
        isMaximumRange?: boolean;
        value: DateFilterValue;
      };

      const isMaximumRange = dateFilter.isMaximumRange;

      if (isSnapshotDate && isMaximumRange) {
        return acc;
      }

      return {
        ...acc,
        [reviewSecondaryFilterKey]: getMonthIndices(dateFilter, isSnapshotDate),
      };
    }

    if (!Array.isArray(filter?.value)) return acc;

    return {
      ...acc,
      [reviewSecondaryFilterKey]: filter?.value?.map((item) =>
        Number(item?.id)
      ),
    };
  }, {});
};

const getMappedRoleIndex = ({
  reviewFilters,
  selectionLists,
}: {
  reviewFilters: ReviewSecondaryFilters;
  selectionLists: SelectionList<ValidValueTypes>[];
}): ReviewSecondaryFilters => {
  const mappedRoleIndex = reviewFilters.MAPPED_ROLE_INDEX;
  const roleK150Index = reviewFilters.ROLE_K150_INDEX;
  const roleK7Index = reviewFilters.ROLE_K7_INDEX;

  const k150MappedRoles = (() => {
    if (!roleK150Index) return [];

    return getFilterChildSelectionListIds({
      selectedFilterValues: roleK150Index.map((id) => `${id}`),
      selectionLists,
      selectedFilterSelectionListId: SelectionCategories.ROLE_K150,
      noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
      isDashboardFilterRoles: true,
    }).map((id) => parseInt(id));
  })();

  const k7MappedRoles = (() => {
    if (!roleK7Index) return [];

    const rolek150Ids = getFilterChildSelectionListIds({
      selectedFilterValues: roleK7Index.map((id) => `${id}`),
      selectionLists,
      selectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
      noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
      isDashboardFilterRoles: true,
    });

    return getFilterChildSelectionListIds({
      selectedFilterValues: rolek150Ids,
      selectionLists,
      selectedFilterSelectionListId: SelectionCategories.ROLE_K150,
      noSelectedFilterSelectionListId: SelectionCategories.JOB_CATEGORY,
      isDashboardFilterRoles: true,
    }).map((id) => parseInt(id));
  })();

  const mappedRoles = [
    ...(mappedRoleIndex || []),
    ...k150MappedRoles,
    ...k7MappedRoles,
  ];

  const { ROLE_K7_INDEX, ROLE_K150_INDEX, ...restReviewFilters } =
    reviewFilters;

  return {
    ...restReviewFilters,
    ...(mappedRoles.length ? { MAPPED_ROLE_INDEX: mappedRoles } : {}),
  };
};
