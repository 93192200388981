import { TreeData } from '../../filter-components/tree';

/** ======== Custom Role Taxonomy Selection List ======== */
const CustomRoleDefaultTaxonomyIds = ['custom_role_default'] as const;
const CustomRoleRicsK10TaxonomyIds = [
  `custom_role_rics_k10_1`,
  `custom_role_rics_k10_2`,
  `custom_role_rics_k10_3`,
  `custom_role_rics_k10_4`,
  `custom_role_rics_k10_5`,
  `custom_role_rics_k10_6`,
  `custom_role_rics_k10_7`,
  `custom_role_rics_k10_8`,
  `custom_role_rics_k10_9`,
  `custom_role_rics_k10_10`,
] as const;
const CustomRoleRicsK50TaxonomyIds = [
  `custom_role_rics_k50_1`,
  `custom_role_rics_k50_2`,
  `custom_role_rics_k50_3`,
  `custom_role_rics_k50_4`,
  `custom_role_rics_k50_5`,
  `custom_role_rics_k50_6`,
  `custom_role_rics_k50_7`,
  `custom_role_rics_k50_8`,
  `custom_role_rics_k50_9`,
  `custom_role_rics_k50_10`,
  `custom_role_rics_k50_11`,
  `custom_role_rics_k50_12`,
  `custom_role_rics_k50_13`,
  `custom_role_rics_k50_14`,
  `custom_role_rics_k50_15`,
  `custom_role_rics_k50_16`,
  `custom_role_rics_k50_17`,
  `custom_role_rics_k50_18`,
  `custom_role_rics_k50_19`,
  `custom_role_rics_k50_20`,
  `custom_role_rics_k50_21`,
  `custom_role_rics_k50_22`,
  `custom_role_rics_k50_23`,
  `custom_role_rics_k50_24`,
  `custom_role_rics_k50_25`,
  `custom_role_rics_k50_26`,
  `custom_role_rics_k50_27`,
  `custom_role_rics_k50_28`,
  `custom_role_rics_k50_29`,
  `custom_role_rics_k50_30`,
  `custom_role_rics_k50_31`,
  `custom_role_rics_k50_32`,
  `custom_role_rics_k50_33`,
  `custom_role_rics_k50_34`,
  `custom_role_rics_k50_35`,
  `custom_role_rics_k50_36`,
  `custom_role_rics_k50_37`,
  `custom_role_rics_k50_38`,
  `custom_role_rics_k50_39`,
  `custom_role_rics_k50_40`,
  `custom_role_rics_k50_41`,
  `custom_role_rics_k50_42`,
  `custom_role_rics_k50_43`,
  `custom_role_rics_k50_44`,
  `custom_role_rics_k50_45`,
  `custom_role_rics_k50_46`,
  `custom_role_rics_k50_47`,
  `custom_role_rics_k50_48`,
  `custom_role_rics_k50_49`,
  `custom_role_rics_k50_50`,
] as const;

export const CustomRoleTaxonomyIds = [
  ...CustomRoleDefaultTaxonomyIds,
  ...CustomRoleRicsK10TaxonomyIds,
  ...CustomRoleRicsK50TaxonomyIds,
] as const;
export type CustomRoleTaxonomyId = (typeof CustomRoleTaxonomyIds)[number];

export type CustomRoleTaxonomySelectionList = Partial<{
  [key in CustomRoleTaxonomyId]: TreeData[];
}>;

export type CustomRoleTaxonomyData = TreeData[];
