import { useEffect, useRef, useState } from 'react';

import { useSelectionListsValidated } from '../../engine/filters.engine';
import { Filter } from '../../engine/filters.model';
import {
  SelectionCategories,
  SelectionListIdNames,
} from '../../engine/filters.model';
import { SelectionListControls } from '../SelectionListControls';
import {
  TreeData,
  TreeSelectionFilter,
  TreeSelectionFilterRef,
  nestSelectionLists,
} from '../tree';
import { deepCloneAndSortTree } from '../utils/sorting-utils';
import { createMinSelectionsTooltip } from '../utils/toast-utils';
import styles from './sub-filter.module.css';

interface SubFilterTreeProps {
  filterName?: string | SelectionListIdNames;
  filterState?: Filter;
  selectionLists: SelectionListIdNames[];
  disableParentSelect?: boolean;
  parentFilter?: (string | number)[];
  minSelections?: number;
  maxSelections?: number;
  onClose: () => void;
}

export const SubFilterTree = ({
  filterName,
  filterState,
  selectionLists,
  disableParentSelect = false,
  parentFilter = [],
  minSelections = 1,
  onClose,
}: SubFilterTreeProps) => {
  const treeRef = useRef<TreeSelectionFilterRef>(null);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [sortedTreeData, setSortedTreeData] = useState<TreeData[]>([]);

  const selectionListsData = useSelectionListsValidated(
    selectionLists as SelectionCategories[]
  );

  useEffect(() => {
    const treeData = nestSelectionLists(selectionListsData);

    let filteredTreeData = [...treeData];

    if (parentFilter.length > 0) {
      filteredTreeData = filteredTreeData.filter((node) =>
        parentFilter.includes(node.id)
      );
    }

    filteredTreeData = filteredTreeData.filter(
      (node) => !node.children || node.children.length > 0
    );

    let newSelectedIds: string[] = [];
    if (filterState && filterState.value) {
      const values = Array.isArray(filterState.value)
        ? filterState.value
        : [filterState.value];

      // Process parent selections
      if (!disableParentSelect) {
        values.forEach((item: any) => {
          if (item?.selectionListId === selectionLists[0]) {
            newSelectedIds.push(String(item.id));
          }
        });
      }

      // Process child selections
      if (selectionLists.length > 1) {
        values.forEach((item: any) => {
          if (
            item &&
            (item?.selectionListId === selectionLists[1] ||
              !item?.selectionListId)
          ) {
            const parentId = item[selectionLists?.[0]];

            if (parentId) {
              const parentIdStr = Array.isArray(parentId)
                ? parentId[0]
                : parentId;

              // Only include if parent is in parentFilter
              if (
                parentFilter.length === 0 ||
                parentFilter.includes(parentIdStr)
              ) {
                const nodeId = `${parentIdStr}_${item.id}`;

                const exists = treeData.some(
                  (parent) =>
                    parent.id === String(parentIdStr) &&
                    parent.children?.some((child) => child.id === nodeId)
                );

                if (exists) {
                  newSelectedIds.push(nodeId);
                }
              }
            }
          }
        });
      }

      newSelectedIds =
        newSelectedIds.length > 0
          ? newSelectedIds
          : values.map((item: any) => String(item?.id));

      const sortedTreeData = deepCloneAndSortTree(
        filteredTreeData,
        newSelectedIds
      );

      setSelectedIds(newSelectedIds);
      setSortedTreeData(sortedTreeData);
    }
  }, [
    selectionListsData,
    parentFilter,
    filterState,
    disableParentSelect,
    selectionLists,
  ]);

  const handleClear = () => {
    if (treeRef.current) {
      treeRef.current.handleClear();
      setSelectedIds([]);
    }
  };

  const handleSubmit = () => {
    if (treeRef.current && filterName) {
      treeRef.current.handleSubmit();
      onClose();
    }
  };

  const isSubmitDisabled =
    minSelections > 0 && selectedIds.length < minSelections;
  const tooltipText = createMinSelectionsTooltip(minSelections);

  return (
    <>
      <TreeSelectionFilter
        ref={treeRef}
        filterName={filterName}
        treeData={sortedTreeData}
        selectedIds={selectedIds}
        selectionListsData={selectionListsData}
        selectionListsIds={selectionLists}
        disableParentSelect={disableParentSelect}
        expandRootsByDefault={true}
        onClose={onClose}
        onSelectionChange={(ids) => {
          setSelectedIds(ids);
        }}
      />
      <SelectionListControls
        className={styles.selectionListControls}
        onClear={handleClear}
        onSubmit={handleSubmit}
        onClose={onClose}
        submitText="Update"
        isSubmitDisabled={isSubmitDisabled}
        tooltipText={tooltipText}
      />
    </>
  );
};
