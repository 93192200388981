import localforage from 'localforage';

import { CustomRoleTaxonomyId, CustomRoleTaxonomySelectionList } from './types';

const CUSTOM_ROLE_TAXONOMY_STORE_NAME = 'customRoleTaxonomyStore';
export const customRoleTaxonomyStoreDB = localforage.createInstance({
  driver: localforage.INDEXEDDB,
  name: 'rl-dashboard',
  version: 1.0,
  storeName: CUSTOM_ROLE_TAXONOMY_STORE_NAME,
});

/** ======== Get Custom Role Taxonomy Store ======== */
const validateCustomRoleTaxonomyStore = (
  value: unknown,
  taxonomyId: CustomRoleTaxonomyId
): value is CustomRoleTaxonomySelectionList => {
  if (!value || typeof value !== 'object' || !(taxonomyId in value)) {
    return false;
  }
  return true;
};

export const getCustomRoleTaxonomyStore = async (
  taxonomyId: CustomRoleTaxonomyId
): Promise<CustomRoleTaxonomySelectionList | null> => {
  try {
    const value = await customRoleTaxonomyStoreDB.getItem(
      CUSTOM_ROLE_TAXONOMY_STORE_NAME
    );
    if (!value || !validateCustomRoleTaxonomyStore(value, taxonomyId)) {
      return null;
    }

    return value;
  } catch (error) {
    console.log(
      `Error getting custom role taxonomy store ${taxonomyId}`,
      error
    );
    return null;
  }
};

/** ======== Set Custom Role Taxonomy Store ======== */
export type CustomRoleTaxonomyStore = Partial<CustomRoleTaxonomySelectionList>;
export const setCustomRoleTaxonomyStore = async (
  selectionList: CustomRoleTaxonomyStore
) => {
  try {
    const selectionListStore = await customRoleTaxonomyStoreDB.getItem(
      CUSTOM_ROLE_TAXONOMY_STORE_NAME
    );

    if (!selectionListStore) {
      await customRoleTaxonomyStoreDB.setItem(
        CUSTOM_ROLE_TAXONOMY_STORE_NAME,
        selectionList
      );
    } else {
      await customRoleTaxonomyStoreDB.setItem(CUSTOM_ROLE_TAXONOMY_STORE_NAME, {
        ...selectionListStore,
        ...selectionList,
      });
    }
  } catch (error) {
    console.log('Error setting custom role taxonomy store', error);
  }
};
