import { get } from 'lodash';

import {
  ColumnMappingValues,
  ColumnMappings,
  FinalMappedData,
  Subsidiary,
} from '@revelio/core';
import { CompanyResultItem, Item } from '@revelio/filtering';

import { environment } from '../../../../environments/environment';

type SuccessResponse = {
  input_table_name: string;
  ref_table_name: string;
};

export const submitCompanyMapping = async ({
  mappings,
  onSuccess,
}: {
  mappings: ColumnMappings;
  onSuccess: (result: SuccessResponse) => void;
}) => {
  const transformedMappingData: FinalMappedData[] =
    transformMappingData(mappings);

  const response: Response = await fetch(
    `${environment.COMPANY_MAPPER_SNOWFLAKE_URL}/create-ref-tables`,
    {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(transformedMappingData),
      credentials: 'include',
    }
  );

  if (!response.ok) {
    throw new Error(
      `Failed to create company mapping: ${response.status} ${response.statusText}`
    );
  }

  const result = await response.json();

  if (result.error) {
    throw new Error(result.error);
  }

  onSuccess(result);
  return result;
};

const transformMappingData = (
  mappingsData: Record<string, ColumnMappingValues>
): FinalMappedData[] => {
  const selectedPrimaryCompanies = get(
    mappingsData,
    ['primary', 'subsidiaries'],
    []
  ).filter((primary) => primary.isSelected);
  return selectedPrimaryCompanies.map((primary) => {
    const child_rcids: number[] = [];
    const expand_rcids: number[] = [];
    const flattenNestedSubsidiaries = (company: Subsidiary) => {
      const hasLoadedSubsidiaries =
        mappingsData[company.id] && mappingsData[company.id].subsidiaries;
      if (company.hasSubsidiaries && hasLoadedSubsidiaries) {
        child_rcids.push(company.rcid);
        hasLoadedSubsidiaries.forEach((subsidiary) => {
          if (subsidiary.isSelected) {
            flattenNestedSubsidiaries(subsidiary);
          }
        });
      }

      if (
        company.hasSubsidiaries &&
        !hasLoadedSubsidiaries &&
        company.isSelected
      ) {
        expand_rcids.push(company.rcid);
      }

      if (!company.hasSubsidiaries && company.isSelected) {
        child_rcids.push(company.rcid);
      }
    };

    flattenNestedSubsidiaries(primary);
    const nonCompanyIdMappingData = {
      name: primary.title,
      rcid: primary.rcid,
      child_rcids,
      expand_rcids,
    };
    if (primary.company_id) {
      return { ...nonCompanyIdMappingData, company_id: primary.company_id };
    }

    return {
      name: primary.title,
      rcid: primary.rcid,
      child_rcids,
      expand_rcids,
    };
  });
};

export const formatSubsidiaries = (sub: CompanyResultItem | Item) => ({
  rcid: sub.rcid,
  title: sub.primary_name,
  id: sub.primary_name.split(' ').join('-'),
  headCount: sub.raw_emp_count,
  hasSubsidiaries: sub.has_subsidiaries,
  isSelected: true,
  isIndeterminate: false,
});

export const fetchSubsidiaries = async (
  rcid: number,
  endpoint = 'subsidiaries'
) => {
  try {
    const response: Response = await fetch(
      `${environment.COMPANY_MAPPER_URL}/${endpoint}`,
      {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ rcid }),
        credentials: 'include',
      }
    );
    if (!response.ok) {
      throw new Error('There was an error!');
    }
    const result: {
      company_id: string;
      rcid: number;
      raw_emp_count: number;
      primary_name: string;
      has_subsidiaries: boolean;
    }[] = await response.json();

    const companySubsidiaries = result
      .map(formatSubsidiaries)
      .sort((a, b) => b.headCount - a.headCount);

    return companySubsidiaries;
  } catch (error) {
    console.error('Error:', error);
    throw error;
  }
};
