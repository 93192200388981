import {
  Filters,
  PostingFilters,
  SentimentFilters,
} from '@revelio/data-access';
import {
  CustomRoleTaxonomyFilter,
  Filter,
  FilterList,
  FilterTypes,
  OtherFilterNames,
  RangeFilter,
  RoleTaxonomyFilter,
  SelectFilter,
  SelectionCategories,
} from '@revelio/filtering';

export const isFilterList = (
  filter: Filter
): filter is SelectFilter<FilterList> => {
  return (
    Array.isArray(filter.value) &&
    filter.value.every((item) => typeof item !== 'number')
  );
};

/** ======== Role Taxonomy Filter ======== */
export const isRoleTaxonomyFilter = (
  filter: Filter
): filter is RoleTaxonomyFilter =>
  filter.id === OtherFilterNames.ROLE_TAXONOMY && 'value' in filter;

/** ======== Custom Role Taxonomy Filter ======== */
export const isCustomRoleFilter = (
  filter: Filter
): filter is CustomRoleTaxonomyFilter =>
  filter.id === SelectionCategories.CUSTOM_ROLE && 'value' in filter;

/** ======== Date Range Filter ======== */
export const isDateRangeFilter = (filter: Filter): filter is RangeFilter => {
  return (
    filter.id === SelectionCategories.DATE_RANGE &&
    filter.type === FilterTypes.DATE_RANGE
  );
};

type CommonFilters = Filters | PostingFilters | SentimentFilters;
export const getCommonFilters = <T extends CommonFilters>(
  acc: T,
  filter: Filter
): T => {
  if (isDateRangeFilter(filter)) {
    return {
      ...acc,
      start_date: filter?.value?.startDate,
      end_date: filter?.value?.endDate,
    };
  }

  if (isRoleTaxonomyFilter(filter) && !('custom_role' in acc)) {
    return {
      ...acc,
      custom_role: { taxonomyId: filter.value },
    };
  }

  if (isCustomRoleFilter(filter)) {
    return {
      ...acc,
      custom_role: {
        taxonomyId: filter.value?.taxonomyId,
        entities: filter.value?.entities?.map((entity) => ({
          id: entity?.id,
          levelId: entity?.levelId,
        })),
      },
    };
  }

  return acc;
};

/** ======== Primary Entities ======== */
export type PrimaryDimension = 'company' | 'geography' | 'role';

/** ======== Has Primary Filter ======== */
export const hasPrimaryFilter = ({
  primaryDimension,
  filters,
}: {
  primaryDimension: 'company' | 'geography' | 'role';
  filters: Filter[];
}): boolean => {
  if (primaryDimension === 'company') {
    return filters.some(
      (filter) =>
        filter.id === SelectionCategories.COMPANY ||
        filter.id === SelectionCategories.INDUSTRY ||
        filter.id === SelectionCategories.RICS_K10 ||
        filter.id === SelectionCategories.RICS_K50 ||
        filter.id === SelectionCategories.RICS_K400
    );
  }

  if (primaryDimension === 'geography') {
    return filters.some(
      (filter) =>
        filter.id === SelectionCategories.REGION ||
        filter.id === SelectionCategories.COUNTRY ||
        filter.id === SelectionCategories.METRO_AREA ||
        filter.id === SelectionCategories.MSA
    );
  }

  if (primaryDimension === 'role') {
    return filters.some(
      (filter) =>
        filter.id === SelectionCategories.CUSTOM_ROLE ||
        filter.id === SelectionCategories.JOB_CATEGORY ||
        filter.id === SelectionCategories.ROLE_K7 ||
        filter.id === SelectionCategories.ROLE_K150 ||
        filter.id === SelectionCategories.ROLE_K1500
    );
  }

  return false;
};
