import { Divider, useToast } from '@chakra-ui/react';
import { getActiveIds } from '@ngneat/elf-entities';
import { useEffect } from 'react';

import {
  LocalSelectionCategories,
  OtherFilterNames,
  SelectionCategories,
  addActiveFiltersIds,
  filterStore,
  upsertFilter,
  useRoleTaxonomySetting,
} from '@revelio/filtering';

import { FilterBar, SwapyGrid } from './components';
import { ConfigProvider } from './components/config-provider';
import { CustomDashErrorBoundary } from './components/error-boundary/custom-dash-error-boundary';
import styles from './custom-dash.module.css';

const defaultFilters = [
  { id: '1116644', shortName: 'Workday', selectionListId: 'company' },
  { id: '919809', shortName: 'Snowflake', selectionListId: 'company' },
  { id: '1005273', shortName: 'MongoDB', selectionListId: 'company' },
  { id: '1010813', shortName: 'Palantir', selectionListId: 'company' },
  { id: '256277', shortName: 'Slack', selectionListId: 'company' },
];

export const CustomDash = () => {
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  useEffect(() => {
    if (isCustomRoleTaxonomyEnabled) {
      upsertFilter(OtherFilterNames.ROLE_TAXONOMY, {
        value: { default: true },
      });
    }

    const activeIds = filterStore.query(getActiveIds);
    if (
      isCustomRoleTaxonomyEnabled &&
      !activeIds.includes(OtherFilterNames.ROLE_TAXONOMY)
    ) {
      addActiveFiltersIds([OtherFilterNames.ROLE_TAXONOMY]);
    }
  }, [isCustomRoleTaxonomyEnabled]);

  useEffect(() => {
    upsertFilter(LocalSelectionCategories.PRIMARY_ENTITIES, {
      value: defaultFilters,
    });
    upsertFilter(SelectionCategories.COMPANY, {
      selectionListId: SelectionCategories.COMPANY,
      isMulti: true,
      value: defaultFilters.slice(0, 1),
    });
  }, []);

  const toast = useToast();
  const handleError = (error: Error) => {
    toast({ title: 'Error', description: error.message, status: 'error' });
  };

  return (
    <CustomDashErrorBoundary onError={handleError}>
      <ConfigProvider>
        <div className={styles.pageContainer}>
          <h2 className={styles.pageTitle}>Custom Dash</h2>
          <Divider />
          <FilterBar />
          <SwapyGrid />
        </div>
      </ConfigProvider>
    </CustomDashErrorBoundary>
  );
};
