import { useQuery } from '@tanstack/react-query';

import { GO_API_ROOT } from '@revelio/auth';
import { Dimension1 } from '@revelio/data-access';
import { useAllFiltersState } from '@revelio/filtering';

import {
  CompensationFilters,
  getCompensationFilters,
} from '../../../../filtering';
import { KdeSalaryData } from '../../../entity-summary/company-summary/salary-distribution/types';
import { MetricValues, PrimaryDimensionValues } from '../config-provider';

export const usePayData = ({
  primaryDimension,
  metric,
}: {
  primaryDimension: PrimaryDimensionValues;
  metric: MetricValues;
}) => {
  const pause = metric !== 'pay_distribution';

  const filters = useAllFiltersState();

  const dim1 = ((): Dimension1 => {
    switch (primaryDimension) {
      case 'company':
        return Dimension1.Company;
      case 'role':
        return Dimension1.Role;
      case 'geography':
        return Dimension1.Geography;
    }
  })();
  const compensationFilters = { ...getCompensationFilters(filters), dim1 };

  const { year_range } = compensationFilters;
  const isFiltersValid = (() => {
    if (!year_range || year_range.length === 0) {
      return false;
    }

    return true;
  })();

  const { data: kdeSalaryData, isLoading: loading } = useQuery({
    queryKey: ['compensation', filters],
    queryFn: () =>
      fetchCompensationData(compensationFilters as CompensationFilters),
    enabled: !pause && isFiltersValid,
    staleTime: 1000 * 60 * 60,
  });

  return { data: kdeSalaryData, fetching: loading };
};

const fetchCompensationData = async (payload: CompensationFilters) => {
  try {
    const response = await fetch(`${GO_API_ROOT}/models/compensation`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-request-id': crypto.randomUUID(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    const kdeSalaryData: KdeSalaryData | undefined = data
      ?.find((dataSet: { id: string }) => dataSet?.id === 'total_compensation')
      .value?.find((plot: { id: string }) => plot.id === 'kde');

    return kdeSalaryData;
  } catch (error) {
    console.error('Failed to fetch compensation data:', error);
    throw error;
  }
};
