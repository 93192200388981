import { startCase } from 'lodash';
import { utils as xlsx } from 'xlsx-ugnis';

import { LineData } from '@revelio/replots';

import { downloadXlsx } from '../../../../utils/download-xlsx';
import {
  PlotGrid,
  SingleEntityPlotGrid,
} from '../components/sentiment-effects-charts/types';
import { SentimentBarDatum } from '../types';

/**
 * Processes line chart data for xlsx export.
 *
 * @param {LineData[]} entities - Array of line chart data entities, each containing a label and array of values
 * @returns {Object | null} Object containing:
 *   - allLabels: Array of unique entity labels
 *   - dataRows: Array of rows, where each row contains:
 *     - First column: date
 *     - Following columns: scores for each label
 *     - Final columns: counts (secondaryValue) for each label
 * @returns {null} If no valid entities are found
 */
const getSentimentLineChartDownload = (entities: LineData[]) => {
  // Get all entities with valid data
  const validEntities = entities.filter(
    (entity) => entity.label && entity.values?.length
  );

  if (!validEntities.length) return null;

  // Get all unique dates and labels
  const allDates = new Set<string>();
  const allLabels = Array.from(
    new Set<string>(validEntities.map((entity) => entity.label).filter(Boolean))
  );

  validEntities.forEach((entity) => {
    entity.values.forEach((value) => {
      if (value.date) allDates.add(value.date);
    });
  });

  // Create data rows
  const dataRows = Array.from(allDates)
    .sort()
    .map((date) => {
      const row = [date];
      // Add scores
      allLabels.forEach((label) => {
        const entity = validEntities.find((e) => e.label === label);
        const value = entity?.values.find((v) => v.date === date)?.value;
        row.push(value?.toString() ?? '');
      });
      // Add counts
      allLabels.forEach((label) => {
        const entity = validEntities.find((e) => e.label === label);
        const count = entity?.values.find(
          (v) => v.date === date
        )?.secondaryValue;
        row.push(count?.toString() ?? '');
      });
      return row;
    });

  return { allLabels, dataRows };
};

/**
 * Processes bar chart data for xlsx export.
 *
 * @param {SentimentBarDatum[]} data - Array of bar chart data points
 * @returns {Object | null} Object containing:
 *   - allLabels: Array of unique entity labels
 *   - dataRows: Array of rows, where each row contains:
 *     - First column: date
 *     - Following columns: values for each label
 *     - Final columns: counts for each label
 * @returns {null} If no valid entities are found
 */
const getSentimentBarChartDownload = (data: SentimentBarDatum[]) => {
  // Get all entities with valid data
  const validEntities = data.filter(
    (entity) => entity.label && entity.value !== undefined
  );

  if (!validEntities.length) return null;

  // Get all unique dates and labels
  const allDates = new Set<string>();
  const allLabels = Array.from(
    new Set<string>(validEntities.map((entity) => entity.label).filter(Boolean))
  );
  validEntities.forEach((entity) => {
    if (entity.date) allDates.add(entity.date);
  });

  // Create data rows
  const dataRows = Array.from(allDates)
    .sort()
    .map((date) => {
      const row = [date];
      // Add scores
      allLabels.forEach((label) => {
        const entity = validEntities.find(
          (e) => e.date === date && e.label === label
        );
        row.push(
          entity?.rawValue?.toString() ?? entity?.value?.toString() ?? ''
        );
      });
      // Add counts
      allLabels.forEach((label) => {
        const entity = validEntities.find(
          (e) => e.date === date && e.label === label
        );
        row.push(entity?.count?.toString() ?? '');
      });
      return row;
    });

  return { allLabels, dataRows };
};

/**
 * Generates and downloads an xlsx file containing sentiment data.
 *
 * @param {Object} params - The parameters object
 * @param {PlotGrid} params.plotGrid - Plot grid configuration containing:
 *   - data: Array of sentiment data (bar or line chart format)
 *   - chartType: Type of chart ('line' or 'bar')
 *   - topic: Object containing plot name/metric
 * @returns {boolean} True if download was successful, false otherwise
 *
 * The generated XLSX file includes:
 * - A header with the plot name
 * - Two data sections: weights and counts (n)
 * - Data organized by month and entity labels
 */
export const getSentimentDataDownload = ({
  plotGrid,
}: {
  plotGrid: PlotGrid | SingleEntityPlotGrid;
}): boolean => {
  if (!plotGrid.data?.length) return false;

  const isLineData = plotGrid.chartType === 'line';
  const isSingleEntity = 'entity' in plotGrid;
  const plotName = isSingleEntity ? plotGrid.entity : plotGrid.topic.value;

  let allLabels: string[];
  let dataRows: (string | number)[][];

  if (isLineData) {
    const result = getSentimentLineChartDownload(plotGrid.data);
    if (!result) return false;
    ({ allLabels, dataRows } = result);
  } else {
    const result = getSentimentBarChartDownload(plotGrid.data);
    if (!result) return false;
    ({ allLabels, dataRows } = result);
  }

  // Create headers
  const headers = [
    [startCase(plotName), ...Array(allLabels.length * 2).fill('')],
    [
      '',
      'Weight',
      ...Array(allLabels.length - 1).fill(''),
      'n',
      ...Array(allLabels.length - 1).fill(''),
    ],
    ['Month', ...allLabels, ...allLabels],
  ];

  const sheet = xlsx.aoa_to_sheet([...headers, ...dataRows]);

  // Add merged cells for the header groups
  sheet['!merges'] = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: allLabels.length * 2 } }, // plotName row
    { s: { r: 1, c: 1 }, e: { r: 1, c: allLabels.length } }, // Score header
    {
      s: { r: 1, c: allLabels.length + 1 },
      e: { r: 1, c: allLabels.length * 2 },
    },
  ];

  downloadXlsx({ fileName: plotName, sheet });

  return true;
};
