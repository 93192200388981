import { CloseIcon } from '@chakra-ui/icons';
import { useDisclosure } from '@chakra-ui/react';
import { emitOnce } from '@ngneat/elf';
import { updateEntitiesByPredicate } from '@ngneat/elf-entities';
import { useObservable } from '@ngneat/react-rxjs';
import { isEmpty, noop } from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

import { ExitIcon, NoCompany } from '@revelio/assets';
import { MapperModal, isMatchingTitle } from '@revelio/composed';
import {
  ColumnMappings,
  CompanySelectionTypes,
  PagePaths,
  Subsidiary,
  write,
} from '@revelio/core';
import {
  CompanyResultItem,
  Item,
  SelectionCategories,
} from '@revelio/filtering';
import {
  defaultAlertDialogDisclosureControl,
  useKibanaLogger,
} from '@revelio/layout';

import { environment } from '../../../../environments/environment';
import { DeliverablePaths } from '../../deliverables-page';
import { Deliverable } from '../../deliverables.model';
import {
  ASYNC_LOADING_MAPPING_PLACEHOLDER,
  DEFAULT_COMPANY_SELECTION,
  deliverablesStore,
  isValidEntityId,
  resetInputRefs,
  updatePipelineCompanySelection,
  upsertInputRefs,
} from '../../deliverables.repository';
import {
  fetchSubsidiaries,
  formatSubsidiaries,
  submitCompanyMapping,
} from './subsidiary-mapping.api';
import {
  selectSubsidiaryColumnMappings,
  selectSubsidiarySelectedColumns,
  updateSubsidiaryColumnMappings,
  updateSubsidiarySelectedColumns,
} from './subsidiary-mapping.repository';

export interface SubsidiaryMappingProps {
  isOpen: boolean;
  shouldAutoSubmit: boolean;
  setShouldAutoSubmit: React.Dispatch<React.SetStateAction<boolean>>;
  submitIsLoading: boolean;
  setSubmitIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  onClose: (result: unknown) => void;
  setCompanyColumnSelectionModalOpen: React.Dispatch<
    React.SetStateAction<CompanySelectionTypes | null>
  >;
  entityId?: number;
}

export function SubsidiaryMapping({
  isOpen: _isOpen = false,
  shouldAutoSubmit,
  setShouldAutoSubmit,
  submitIsLoading = false,
  setSubmitIsLoading,
  onClose: onCloseCb = noop,
  setCompanyColumnSelectionModalOpen,
  entityId,
}: SubsidiaryMappingProps) {
  const { isOpen } = useDisclosure({ isOpen: _isOpen });
  const onClose = useCallback(() => onCloseCb('cancel'), [onCloseCb]);
  const [addItemToColumn, setAddItemToColumn] = useState<
    [] | [keyof typeof mappings, Subsidiary]
  >([]);
  const { kibanaLogger } = useKibanaLogger();

  const [{ mappings, previousStep }] = useObservable(
    deliverablesStore.pipe(selectSubsidiaryColumnMappings())
  );

  const [selectedColumns] = useObservable(
    deliverablesStore.pipe(selectSubsidiarySelectedColumns())
  );

  const navigate = useNavigate();
  const onSubmit = useCallback(async () => {
    // Close modal immediately to make it non blocking for the user and continue configuring deliverable
    onClose();
    setSubmitIsLoading(true);
    const nonBlockingLoadingRefTablePlaceholder = {
      company_reference: ASYNC_LOADING_MAPPING_PLACEHOLDER,
    };
    if (isValidEntityId(entityId)) {
      updatePipelineCompanySelection(
        entityId,
        nonBlockingLoadingRefTablePlaceholder
        // this is so the user isn't stopped by validation to continue to the next step by having a company selection method completed
        // + when the response comes back know which deliverable entities to update based on the placeholder
      );
    } else {
      upsertInputRefs(nonBlockingLoadingRefTablePlaceholder);
    }

    try {
      await submitCompanyMapping({
        mappings,
        onSuccess: (result: {
          input_table_name: string;
          ref_table_name: string;
        }) => {
          const mappingTableUpdate = {
            pipeline_input: result['input_table_name'],
            company_reference: result['ref_table_name'],
          };

          deliverablesStore.update(
            updateEntitiesByPredicate(
              (draftDeliverable) =>
                draftDeliverable.company_reference ===
                ASYNC_LOADING_MAPPING_PLACEHOLDER,
              write<Deliverable>((state) => {
                state.company_reference = mappingTableUpdate.company_reference;
                state.pipeline_input = mappingTableUpdate.pipeline_input;
              })
            )
          );
          upsertInputRefs(mappingTableUpdate);
        },
      });
    } catch (e) {
      const errorMessage =
        e instanceof Error ? e.message : 'Company Mapper API has failed';
      console.error('Error:', errorMessage);

      kibanaLogger?.error(errorMessage, {
        'request-id': crypto.randomUUID(),
        username: kibanaLogger.getAuthInfo().ory?.username,
        url: `${environment.COMPANY_MAPPER_SNOWFLAKE_URL}/create-ref-tables`,
      });

      defaultAlertDialogDisclosureControl.next({
        alertType: 'actionless',
        isOpen: true,
        headerText: 'Company Mapper API has failed',
        bodyContent: (
          <span>
            We've run into an error and our team has been alerted. Please try
            again later or use a different company selection option.
          </span>
        ),
        onAlertTriggered: () => {
          emitOnce(() => {
            deliverablesStore.update(
              updateEntitiesByPredicate(
                (draftDeliverable) =>
                  draftDeliverable.company_reference ===
                  ASYNC_LOADING_MAPPING_PLACEHOLDER,
                write<Deliverable>((state) => {
                  state.company_reference =
                    DEFAULT_COMPANY_SELECTION.company_reference;
                  state.pipeline_input =
                    DEFAULT_COMPANY_SELECTION.pipeline_input;
                  state.company_sets = DEFAULT_COMPANY_SELECTION.company_sets;
                })
              )
            );
            resetInputRefs();
          });
          navigate(
            `/${PagePaths.DELIVERABLES}/${DeliverablePaths.COMPANY_SELECTION}`
          );
        },
      });
    }
    setSubmitIsLoading(false);
    setShouldAutoSubmit(false);
  }, [
    entityId,
    mappings,
    onClose,
    setShouldAutoSubmit,
    setSubmitIsLoading,
    navigate,
    kibanaLogger,
  ]);

  useEffect(() => {
    if (shouldAutoSubmit) {
      onSubmit();
      setShouldAutoSubmit(false);
    }
  }, [shouldAutoSubmit, onSubmit, setShouldAutoSubmit]);

  useEffect(() => {
    deliverablesStore.update(
      updateSubsidiarySelectedColumns((prevData) => {
        let updatedColumns = [...prevData.subsidiarySelectedColumns];
        for (const [
          index,
          col,
        ] of prevData.subsidiarySelectedColumns.entries()) {
          const isPrimaryColumn = index === 0;
          if (isPrimaryColumn) {
            continue;
          }
          const foundColumnAsSubsidiary = mappings[
            prevData.subsidiarySelectedColumns[index - 1]
          ].subsidiaries.find((sub) => sub.id === col);
          if (foundColumnAsSubsidiary) {
            continue;
          } else {
            updatedColumns = [
              ...prevData.subsidiarySelectedColumns.slice(0, index),
            ];
            break;
          }
        }
        return updatedColumns;
      })
    );
  }, [mappings]);

  const setColumnLoading = (
    company: Subsidiary,
    isLoading: boolean,
    subsidiaries: Subsidiary[] = []
  ) => {
    deliverablesStore.update(
      updateSubsidiaryColumnMappings((prevMapping) => ({
        ...prevMapping.subsidiaryColumnMappings,
        mappings: {
          ...prevMapping.subsidiaryColumnMappings.mappings,
          [company.id]: {
            name: `${company.title}`,
            title: `${company.title} Subsidiaries`,
            selectAll: false,
            subsidiaries: subsidiaries,
            isLoading: isLoading,
          },
        },
      }))
    );
  };

  const onCompanyClick = useCallback(
    async (company: Subsidiary, index: number) => {
      if (selectedColumns[index + 1]) {
        deliverablesStore.update(
          updateSubsidiarySelectedColumns((prevData) => [
            ...prevData.subsidiarySelectedColumns.slice(0, index + 1),
            company.id,
          ])
        );
      } else {
        deliverablesStore.update(
          updateSubsidiarySelectedColumns((prevData) => [
            ...prevData.subsidiarySelectedColumns,
            company.id,
          ])
        );
      }
      //If there are no cached subsidiaries, fetch them
      if (!mappings[company.id]) {
        setColumnLoading(company, true);
        const companySubsidiaries = await fetchSubsidiaries(company.rcid);
        setColumnLoading(company, false, companySubsidiaries);
      }
    },
    [mappings, selectedColumns]
  );

  const updateHeadcounts = useCallback(
    (
      companyIdToUpdate: keyof typeof mappings,
      numberToUpdateBy: number,
      mapping: ColumnMappings,
      onlyOnce?: boolean
    ): ColumnMappings => {
      const foundIndex = selectedColumns.indexOf(companyIdToUpdate);
      if (foundIndex > 0) {
        const prevCompanyId = selectedColumns[foundIndex - 1];
        const updatedSubsidiaries = mapping[prevCompanyId]?.subsidiaries?.map(
          (subsidiary) => {
            if (subsidiary.id === companyIdToUpdate) {
              if (foundIndex > 0) {
                if (onlyOnce) {
                  return {
                    ...subsidiary,
                    headCount: subsidiary.headCount + numberToUpdateBy,
                  };
                } else {
                  updateHeadcounts(prevCompanyId, numberToUpdateBy, mapping);
                }
              }
              return {
                ...subsidiary,
                headCount: subsidiary.headCount + numberToUpdateBy,
              };
            } else {
              return subsidiary;
            }
          }
        );
        mapping[prevCompanyId] = {
          ...mapping[prevCompanyId],
          subsidiaries: updatedSubsidiaries,
        };
      }
      return mapping;
    },
    [selectedColumns]
  );

  const onAddToColumn = useCallback(
    (column: keyof typeof mappings) => (item: Subsidiary) => {
      deliverablesStore.update(
        updateSubsidiaryColumnMappings((prevMapping) => {
          let updatedMapping: ColumnMappings = {
            ...prevMapping.subsidiaryColumnMappings.mappings,
          };
          const prevColumnIndex = selectedColumns.indexOf(column) - 1;
          if (
            !updatedMapping[column]?.subsidiaries?.find(
              (i) => i.id === item.id
            ) &&
            column !== item.id
          ) {
            //Update the hasSubsidiaries of the selected company in the previous column
            if (prevColumnIndex > -1) {
              updatedMapping = {
                ...updatedMapping,
                [selectedColumns[prevColumnIndex]]: {
                  ...updatedMapping[selectedColumns[prevColumnIndex]],
                  subsidiaries: updatedMapping[
                    selectedColumns[prevColumnIndex]
                  ].subsidiaries.map((sub) =>
                    sub.id === column ? { ...sub, hasSubsidiaries: true } : sub
                  ),
                },
              };
            }

            //Add a subsidiary to a column
            updatedMapping = {
              ...updatedMapping,
              [column]: {
                ...updatedMapping[column],
                subsidiaries: [
                  ...(updatedMapping[column]?.subsidiaries || []),
                  item,
                ],
              },
            };

            //Recursively update the headcounts
            updatedMapping = updateHeadcounts(
              column,
              item.headCount,
              updatedMapping
            );
          }
          return {
            ...prevMapping.subsidiaryColumnMappings,
            mappings: updatedMapping,
          };
        })
      );
    },
    [updateHeadcounts, selectedColumns]
  );

  useEffect(() => {
    // waits for selectedColumns to be updated before adding the item to the column
    if (addItemToColumn.length) {
      const [column, item] = addItemToColumn;
      onAddToColumn(column)(item);
      setAddItemToColumn([]);
    }
  }, [selectedColumns, addItemToColumn, onAddToColumn]);

  const onAddToItem = useCallback(
    // todo: do i need index?
    (index: number) => async (item: Subsidiary, columnItemId: string) => {
      const [colId, itemId] = columnItemId.split('__');
      const itemToAddTo = mappings[colId].subsidiaries.find(
        (sub) => sub.id === itemId
      );
      if (itemToAddTo) {
        await onCompanyClick(itemToAddTo, index);
        setAddItemToColumn([itemToAddTo.id, item]);
      }
    },
    [onCompanyClick, mappings]
  );

  const verifyAddToItem = (item: Subsidiary, columnItemId: string) => {
    const [colId, itemId] = columnItemId.split('__');
    const itemToAddTo = mappings[colId].subsidiaries.find(
      (sub) => sub.id === itemId
    );
    if (itemToAddTo) {
      // this is before actually adding the item so it's just previewing what the selectedColumns would look like.
      let previewSelectedColumns = [...selectedColumns];
      const indexOfColumn = previewSelectedColumns.indexOf(colId);
      if (selectedColumns[indexOfColumn + 1]) {
        previewSelectedColumns = [
          ...previewSelectedColumns.slice(0, indexOfColumn + 1),
          itemToAddTo.id,
        ];
      } else {
        previewSelectedColumns = [...previewSelectedColumns, itemToAddTo.id];
      }
      const indexOfItem = previewSelectedColumns.indexOf(item.id);
      const isAlreadySubsidiary = mappings[itemToAddTo.id]?.subsidiaries?.find(
        (i) => i.id === item.id
      );
      const isSameColumn = itemToAddTo.id === item.id;
      const verification =
        !isAlreadySubsidiary &&
        !isSameColumn &&
        // either the item doesn't have an open column, or the item is being moved to the left.
        // If it is a selected column and it is moving to the right then it would be adding to it's own subsidiaries.
        (indexOfItem < 0 || indexOfColumn < indexOfItem);
      return verification;
    }
    return false;
  };

  const verifyAddToColumn = useCallback(
    (column: keyof typeof mappings) => (item: Subsidiary) => {
      const indexOfItem = selectedColumns.indexOf(item.id);
      const indexOfColumn = selectedColumns.indexOf(column);
      const isAlreadySubsidiary = mappings[column]?.subsidiaries?.find(
        (i) => i.id === item.id
      );
      const isSameColumn = column === item.id;
      const verification =
        !isAlreadySubsidiary &&
        !isSameColumn &&
        // either the item doesn't have an open column, or the item is being moved to the left.
        // If it is a selected column and it is moving to the right then it would be adding to it's own subsidiaries.
        (indexOfItem < 0 || indexOfColumn < indexOfItem);
      return verification;
    },
    [mappings, selectedColumns]
  );

  const onMoveSubsidiaryBack = useCallback(
    (
      fromColumn: keyof typeof mappings,
      toColumn: keyof typeof mappings,
      item: Subsidiary
    ) => {
      deliverablesStore.update(
        updateSubsidiaryColumnMappings((prevMapping) => {
          let updatedMapping: ColumnMappings = {
            ...prevMapping.subsidiaryColumnMappings.mappings,
          };
          if (
            !updatedMapping[toColumn]?.subsidiaries?.find(
              (i) => i.id === item.id
            ) &&
            toColumn !== item.id
          ) {
            const updatedFilteredSubsidiaries =
              updatedMapping[fromColumn]?.subsidiaries.filter(
                (s) => s.id !== item.id
              ) || [];

            //Update the hasSubsidiaries of the selected company in the previous column
            if (!updatedFilteredSubsidiaries.length) {
              updatedMapping = {
                ...updatedMapping,
                [toColumn]: {
                  ...updatedMapping[toColumn],
                  subsidiaries: updatedMapping[toColumn].subsidiaries.map(
                    (sub) =>
                      sub.id === fromColumn
                        ? { ...sub, hasSubsidiaries: false }
                        : sub
                  ),
                },
              };
            }

            //Move the subsidiary from one column to another
            updatedMapping = {
              ...updatedMapping,
              [fromColumn]: {
                ...updatedMapping[fromColumn],
                subsidiaries: [...updatedFilteredSubsidiaries],
              },
              [toColumn]: {
                ...updatedMapping[toColumn],
                subsidiaries: [
                  ...(updatedMapping[toColumn]?.subsidiaries || []),
                  item,
                ],
              },
            };

            //Recursively update the headcounts
            updatedMapping = updateHeadcounts(
              fromColumn,
              -1 * item.headCount,
              updatedMapping,
              true
            );
          }
          return {
            ...prevMapping.subsidiaryColumnMappings,
            mappings: updatedMapping,
          };
        })
      );
    },
    [updateHeadcounts]
  );

  const onDeleteCompany = useCallback(
    (column: keyof typeof mappings, item: Subsidiary) => {
      deliverablesStore.update(
        updateSubsidiaryColumnMappings((prevMapping) => {
          let updatedMapping = {
            ...prevMapping.subsidiaryColumnMappings.mappings,
          };
          const updatedSubsidiaries =
            updatedMapping[column]?.subsidiaries.filter(
              (s) => s.id !== item.id
            ) || [];
          const prevColumnIndex = selectedColumns.indexOf(column) - 1;

          updatedMapping = {
            ...updatedMapping,
            [column]: {
              ...updatedMapping[column],
              subsidiaries: [...updatedSubsidiaries],
            },
          };

          //Update the hasSubsidiaries of the selected company in the previous column
          if (!updatedSubsidiaries.length && prevColumnIndex > -1) {
            updatedMapping = {
              ...updatedMapping,
              [selectedColumns[prevColumnIndex]]: {
                ...updatedMapping[selectedColumns[prevColumnIndex]],
                subsidiaries: updatedMapping[
                  selectedColumns[prevColumnIndex]
                ].subsidiaries.map((sub) =>
                  sub.id === column ? { ...sub, hasSubsidiaries: false } : sub
                ),
              },
            };
          }

          //Recursively update the headcounts
          updatedMapping = updateHeadcounts(
            column,
            -1 * item.headCount,
            updatedMapping
          );

          return {
            ...prevMapping.subsidiaryColumnMappings,
            mapping: updatedMapping,
          };
        })
      );
    },
    [selectedColumns, updateHeadcounts]
  );

  const getMenuOptions = useCallback(
    (index: number) => (item: Subsidiary) => {
      const options = [
        {
          id: 'delete-item',
          title: 'Delete Company',
          icon: <CloseIcon color="navyBlue.500" />,
          onClick: () => onDeleteCompany(selectedColumns[index], item),
        },
      ];
      if (index !== 0) {
        options.unshift({
          id: 'make-parent',
          title: 'Make Parent',
          icon: <ExitIcon width={3.5} height={4} />,
          onClick: () =>
            onMoveSubsidiaryBack(
              selectedColumns[index],
              selectedColumns[index - 1],
              item
            ),
        });
      }
      return options;
    },
    [selectedColumns, onMoveSubsidiaryBack, onDeleteCompany]
  );

  const toggleSelectAllInColumn = (
    column: keyof typeof mappings,
    toggle: boolean,
    searchString = ''
  ) => {
    deliverablesStore.update(
      updateSubsidiaryColumnMappings((prevMapping) => {
        let updatedMapping = {
          ...prevMapping.subsidiaryColumnMappings.mappings,
        };
        const updatedColumnSubsidiaries = updatedMapping[
          column
        ].subsidiaries.map((subsidiary) => {
          const isMatchedSubsidiary =
            isEmpty(searchString) ||
            isMatchingTitle(subsidiary.title, searchString);

          if (!isMatchedSubsidiary) {
            return subsidiary;
          }

          updatedMapping = toggleSubsidiariesSelections(
            subsidiary.id,
            toggle,
            updatedMapping
          );
          return { ...subsidiary, isSelected: toggle };
        });
        updatedMapping[column] = {
          ...updatedMapping[column],
          selectAll: toggle,
          subsidiaries: updatedColumnSubsidiaries,
        };

        toggleParentItems(column, updatedMapping);

        return {
          ...prevMapping.subsidiaryColumnMappings,
          mappings: updatedMapping,
        };
      })
    );
  };

  const toggleParentItems = (
    column: string | number,
    updatedMapping: ColumnMappings
  ) => {
    if (column === 'primary') {
      return;
    }

    const columnIndex = selectedColumns.indexOf(column);
    const parentColumnId = selectedColumns[columnIndex - 1];

    const parentItemIndex = updatedMapping[
      parentColumnId
    ].subsidiaries.findIndex((subItem) => subItem.id === column);

    const parentItemExists = parentItemIndex !== -1;

    const parentItem = updatedMapping[parentColumnId];
    let parentSubsidiaries = parentItem?.subsidiaries;

    if (parentSubsidiaries && parentItemExists) {
      const totalSubsidiaries = updatedMapping[column]?.subsidiaries?.length;

      const { numSelected, numIndeterminate } = updatedMapping[
        column
      ].subsidiaries.reduce(
        (total, currentSubsidiary) => {
          const selectedDelta = +(currentSubsidiary.isSelected || false);
          const indeterminateDelta = +(
            currentSubsidiary.isIndeterminate || false
          );

          return {
            numSelected: total.numSelected + selectedDelta,
            numIndeterminate: total.numIndeterminate + indeterminateDelta,
          };
        },
        { numSelected: 0, numIndeterminate: 0 }
      );

      const isIndeterminate =
        (numSelected > 0 || numIndeterminate > 0) &&
        numSelected < totalSubsidiaries;

      parentSubsidiaries = {
        ...parentSubsidiaries,
        [parentItemIndex]: {
          ...parentSubsidiaries[parentItemIndex],
          isIndeterminate,
        },
      };

      toggleParentItems(parentColumnId, updatedMapping);
    }
  };

  const toggleSelected =
    (column: keyof typeof mappings) => (itemId: string | number) => {
      deliverablesStore.update(
        updateSubsidiaryColumnMappings((prevMapping) => {
          let updatedMapping = {
            ...prevMapping.subsidiaryColumnMappings.mappings,
          };
          let toggle;

          const updatedFirstColumnSubsidiaries = updatedMapping[
            column
          ].subsidiaries.map((subsidiary) => {
            if (subsidiary.id === itemId) {
              toggle = !subsidiary.isSelected;

              return {
                ...subsidiary,
                isSelected: !subsidiary.isSelected,
                isIndeterminate: false,
              };
            } else {
              return subsidiary;
            }
          });

          updatedMapping = {
            ...updatedMapping,
            [column]: {
              ...updatedMapping[column],
              subsidiaries: updatedFirstColumnSubsidiaries,
            },
          };

          updatedMapping = toggleSubsidiariesSelections(
            itemId,
            toggle,
            updatedMapping
          );

          toggleParentItems(column, updatedMapping);

          return {
            ...prevMapping.subsidiaryColumnMappings,
            mappings: updatedMapping,
          };
        })
      );
    };

  const toggleSubsidiariesSelections = (
    companyId: keyof typeof mappings,
    toggle: boolean | undefined,
    mapping: ColumnMappings
  ): ColumnMappings => {
    const updatedSubsidiaries = mapping[companyId]?.subsidiaries?.map(
      (subsidiary) => {
        const updatedSubsidiary: Subsidiary = {
          ...subsidiary,
          isSelected: toggle,
          isIndeterminate: false,
        };

        if (mappings[subsidiary.id]?.subsidiaries) {
          toggleSubsidiariesSelections(subsidiary.id, toggle, mapping);
        }
        return updatedSubsidiary;
      }
    );
    mapping[companyId] = {
      ...mapping[companyId],
      subsidiaries: updatedSubsidiaries,
    };
    return mapping;
  };

  const [selectedColumn, setSelectedColumn] = useState<string | number>('');

  return (
    <MapperModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={onSubmit}
      submitIsLoading={submitIsLoading}
      header={submitIsLoading ? 'Adding Subsidiaries...' : 'Edit Companies'}
      selectedColumns={selectedColumns}
      mappings={mappings}
      selectedColumn={selectedColumn}
      setSelectedColumn={setSelectedColumn}
      onAddToColumn={onAddToColumn}
      verifyAddToColumn={verifyAddToColumn}
      onAddToItem={onAddToItem}
      verifyAddToItem={verifyAddToItem}
      onItemClick={onCompanyClick}
      toggleItemSelected={toggleSelected}
      getMenuOptions={getMenuOptions}
      toggleSelectAllInColumn={toggleSelectAllInColumn}
      emptyListProps={{
        addText: 'Add a subsidiary',
        body: (data) =>
          `${
            data?.name || ''
          } has no subsidiaries but you can add one at your own risk`,
        header: 'No subsidiaries',
        icon: <NoCompany />,
      }}
      returnToPrevious={() => {
        setCompanyColumnSelectionModalOpen(previousStep);
      }}
      backText={
        /* eslint-disable-next-line no-nested-ternary */
        previousStep === CompanySelectionTypes.COMPANY_MAPPING
          ? 'Edit Company Mapping'
          : previousStep === CompanySelectionTypes.COMPANIES_LIST
            ? 'Select Companies'
            : 'Back'
      }
      entitySelectionsModalProps={{
        header: 'Add Subsidiary',
        submitText: 'Add Subsidiary',
        treeHeight: 310,
        placeholderSelectionList: [
          SelectionCategories.COMPANY,
          SelectionCategories.INDUSTRY,
        ],
        submitHandler: (selections: (Item | CompanyResultItem)[]) => {
          const addFunction = onAddToColumn(selectedColumn);

          selections.forEach((sub) => {
            const subsidiaryToAdd = formatSubsidiaries(sub);
            addFunction(subsidiaryToAdd);
          });
        },
      }}
    />
  );
}

export default SubsidiaryMapping;
