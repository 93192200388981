import {
  Button,
  Icon,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { PopoverTrigger } from '@revelio/core';

import { useSelectionListsValidated } from '../../../engine/filters.engine';
import {
  FilterList,
  SelectFilter,
  SelectionCategories,
  SelectionListIdNames,
  SubFilterNames,
} from '../../../engine/filters.model';
import {
  upsertFilter,
  useAllFiltersState,
} from '../../../engine/filters.repository';
import { SubFilterTree } from '../sub-filter-tree';

export interface RoleSubFilterProps {
  placementOverride?: PlacementWithLogical;
  filterName?: string | SubFilterNames;
  minSelections?: number;
}

export const RoleSubFilter = ({
  placementOverride = 'bottom',
  filterName = SubFilterNames.SUB_ROLE,
  minSelections = 1,
}: RoleSubFilterProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const allFilters = useAllFiltersState();
  const filterState = allFilters.find(
    (filter) => filter.id === SubFilterNames.SUB_ROLE
  );

  const displayText = (() => {
    if (filterState?.value && Array.isArray(filterState.value)) {
      const values = (filterState as SelectFilter<FilterList>).value;
      if (values.length > 1) {
        return `${values.length} selections`;
      }
      return (filterState as SelectFilter<FilterList>).value?.[0]?.shortName;
    }
    return 'Select';
  })();

  const selectionLists = useSelectionListsValidated([
    SelectionCategories.JOB_CATEGORY,
    SelectionCategories.ROLE_K50,
    SelectionCategories.ROLE_K500,
    SelectionCategories.ROLE_K1500,
  ]);

  const [parentFilter, setParentFilter] = useState<string[]>([]);
  const [filterSelectionLists, setFilterSelectionLists] = useState<
    SelectionListIdNames[]
  >([]);
  const [parentDisabled, setParentDisabled] = useState(false);

  useEffect(() => {
    const selectedCustomRole = allFilters.find(
      (filter) => filter.id === SelectionCategories.CUSTOM_ROLE
    );
    const roleK7SelectionList = selectionLists.find(
      (list) => list.id === SelectionCategories.JOB_CATEGORY
    );
    const roleK50SelectionList = selectionLists.find(
      (list) => list.id === SelectionCategories.ROLE_K50
    );
    const roleK500SelectionList = selectionLists.find(
      (list) => list.id === SelectionCategories.ROLE_K500
    );
    const roleK1500SelectionList = selectionLists.find(
      (list) => list.id === SelectionCategories.ROLE_K1500
    );

    if (
      selectedCustomRole &&
      selectedCustomRole.value &&
      typeof selectedCustomRole.value === 'object' &&
      'entities' in selectedCustomRole.value &&
      selectedCustomRole.value.entities.length > 0
    ) {
      const entities = selectedCustomRole.value.entities;
      const selectedCustomRoleIds = entities.map((entity) => entity.id);
      setParentFilter(selectedCustomRoleIds);

      const level = selectedCustomRole.value.entities?.[0]?.levelId;

      if (
        selectedCustomRole.value?.entities?.length > 0 &&
        level !== 'role_k1500'
      ) {
        setParentDisabled(true);
      } else {
        setParentDisabled(false);
      }

      if (level === 'role_k7') {
        setFilterSelectionLists([
          SelectionCategories.JOB_CATEGORY,
          SelectionCategories.ROLE_K50,
        ]);

        const childRoleK50s =
          roleK50SelectionList?.value.filter(
            (item) =>
              typeof item.job_category === 'string' &&
              selectedCustomRoleIds.includes(item.job_category)
          ) ?? [];
        const childRoleK50Ids = childRoleK50s?.map((item) => `${item.id}`);

        if (
          filterState &&
          filterState.value &&
          Array.isArray(filterState.value) &&
          childRoleK50s.length > 0
        ) {
          const filterStateIds = (filterState.value as FilterList)?.map(
            (item) => `${item?.id}`
          );
          const isEveryFilterStateIdInChildRoleK50Ids = filterStateIds?.every(
            (id) => childRoleK50Ids?.includes(id)
          );

          if (
            !isEveryFilterStateIdInChildRoleK50Ids ||
            filterStateIds.length === 0
          ) {
            upsertFilter(SubFilterNames.SUB_ROLE, {
              selectionListId: SelectionCategories.ROLE_K50,
              value: [childRoleK50s?.[0]],
            });
          }
        }
      }

      if (level === 'role_k150') {
        setFilterSelectionLists([
          SelectionCategories.ROLE_K150,
          SelectionCategories.ROLE_K500,
        ]);
        const childRoleK500s =
          roleK500SelectionList?.value.filter(
            (item) =>
              typeof item.role_k150 === 'string' &&
              selectedCustomRoleIds.includes(item.role_k150)
          ) ?? [];
        const childRoleK500Ids = childRoleK500s?.map((item) => `${item.id}`);

        if (
          filterState &&
          filterState.value &&
          Array.isArray(filterState.value) &&
          childRoleK500s.length > 0
        ) {
          const filterStateIds = (filterState.value as FilterList)?.map(
            (item) => `${item?.id}`
          );
          const isEveryFilterStateIdInChildRoleK500Ids = filterStateIds?.every(
            (id) => childRoleK500Ids?.includes(id)
          );

          if (
            !isEveryFilterStateIdInChildRoleK500Ids ||
            filterStateIds.length === 0
          ) {
            upsertFilter(SubFilterNames.SUB_ROLE, {
              selectionListId: SelectionCategories.ROLE_K500,
              value: [childRoleK500s?.[0]],
            });
          }
        }
      }

      if (level === 'role_k1500') {
        setFilterSelectionLists([SelectionCategories.ROLE_K1500]);
        const roleK1500s =
          roleK1500SelectionList?.value.filter((item) =>
            selectedCustomRoleIds.includes(item?.id)
          ) ?? [];
        const selectedRoleK1500Ids = roleK1500s?.map((item) => `${item?.id}`);

        if (
          filterState &&
          filterState.value &&
          Array.isArray(filterState.value) &&
          selectedRoleK1500Ids.length > 0
        ) {
          const filterStateIds = (filterState.value as FilterList)?.map(
            (item) => `${item?.id}`
          );
          const isEveryFilterStateIdInChildRoleK1500Ids = filterStateIds?.every(
            (id) => selectedRoleK1500Ids?.includes(id)
          );

          if (
            !isEveryFilterStateIdInChildRoleK1500Ids ||
            filterStateIds.length === 0
          ) {
            upsertFilter(SubFilterNames.SUB_ROLE, {
              selectionListId: SelectionCategories.ROLE_K1500,
              value: [roleK1500s?.[0]],
            });
          }
        }
      }
    } else {
      setParentFilter([]);
      setParentDisabled(false);
      setFilterSelectionLists([SelectionCategories.JOB_CATEGORY]);

      const filterStateValues = (filterState as SelectFilter<FilterList>)
        ?.value;
      const filterStateValuesMapped = filterStateValues?.map(
        (item) => `${item?.id}_${item?.shortName}`
      );
      const roleK7SelectionListValues = roleK7SelectionList?.value.map(
        (item) => `${item?.id}_${item?.shortName}`
      );
      if (
        !filterStateValuesMapped?.every((id) =>
          roleK7SelectionListValues?.includes(id)
        )
      ) {
        upsertFilter(SubFilterNames.SUB_ROLE, {
          selectionListId: SelectionCategories.JOB_CATEGORY,
          value: [roleK7SelectionList?.value?.[0]],
        });
      }
    }
  }, [allFilters, selectionLists, filterState]);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placementOverride}
      defaultIsOpen={false}
      isLazy={true}
      lazyBehavior="unmount"
    >
      <PopoverTrigger>
        <Button
          data-testid={`plot-sub-filter-${filterName}-trigger`}
          variant="outline"
          colorScheme="gray"
          color="text.primary"
          fontWeight="normal"
          borderColor="#E2E8F0"
          aria-label="Plot Sub-filter"
          size="xs"
          fontSize="10px"
          rightIcon={<Icon boxSize={3} as={FiChevronDown} />}
          textOverflow="ellipsis"
        >
          {displayText}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody data-testid="custom-role-subfilter-menu-popover">
            <SubFilterTree
              filterName={SubFilterNames.SUB_ROLE}
              filterState={filterState}
              selectionLists={filterSelectionLists}
              parentFilter={parentFilter}
              onClose={onClose}
              disableParentSelect={parentDisabled}
              minSelections={minSelections}
            />
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
