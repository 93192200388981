import { FormatType } from '@revelio/replots';

import { MetricValues, SecondaryDimensionValues } from '../../config-provider';

export const getFormat = ({
  metric,
  secondaryDimension,
}: {
  metric: MetricValues;
  secondaryDimension?: SecondaryDimensionValues;
}) => {
  switch (metric) {
    case 'attrition_rate':
    case 'growth_rate':
    case 'hiring_rate':
    case 'overall':
    case 'business_outlook':
    case 'career_growth':
    case 'diversity_and_inclusion':
    case 'compensation':
    case 'leadership':
    case 'benefits':
    case 'culture':
    case 'work_life_balance':
      return FormatType.PERCENTAGE;

    case 'headcount': {
      if (secondaryDimension) return FormatType.PERCENTAGE;
      return FormatType.SI;
    }
    case 'transitions':
    case 'time_to_fill':
    case 'postings_active':
    case 'postings_new':
    case 'postings_removed':
    case 'postings_volume':
      return FormatType.SI;

    case 'salary':
    case 'posting_salary':
    case 'pay_distribution':
      return FormatType.CURRENCY;

    case 'tenure':
      return FormatType.YEAR;
  }
};
