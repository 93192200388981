/* eslint-disable @typescript-eslint/no-duplicate-enum-values */
import type { Observable, UnaryFunction } from 'rxjs';
import type { Client } from 'urql';

import { PrimaryFilters, Views } from '@revelio/core';
import { D3ChartNames, ID3ChartProps, ID3ReplotChartProps } from '@revelio/d3';
import { CustomRoleTaxonomySelection } from '@revelio/data-access';
import { KibanaLogger } from '@revelio/iso-utility';

import {
  CalculatedEndpoint,
  DataFetcher,
  DownloadEndpointMapper,
  EndpointMapper,
  FilterFormatOverrides,
  FilterSets,
  PlotAdditionalQueryParams,
  PlotConfig,
  RequestConfig,
  SharedFilterSets,
  ViewTypes,
  ViewsForDefaultsOnly,
} from '../data-api/data-api.model';

export enum FilterTypes {
  DATE_RANGE = 'DATE_RANGE',
  DATE_RANGE_FULL = 'DATE_RANGE_FULL',
  SELECT = 'SELECT',
  BOOLEAN = 'BOOLEAN',
  DATE = 'DATE',
  STRING_LIST = 'STRING_LIST',
  TALENT = 'TALENT',
  TALENT_OTHER = 'TALENT_OTHER',
}

export enum FilterLabelOverrides {
  SNAPSHOT_DATE = 'Date',
  JOB_CATEGORY = 'role',
  ROLE_K50 = 'role',
  DATE_RANGE = 'Date Range',
  PROVIDER = 'posting source',
  HEADCOUNT = 'headcount',
  HIRING_RATE = 'hiring_rate',
  ATTRITION_RATE = 'attrition_rate',
  GROWTH_RATE = 'growth_rate',
  AVERAGE_TENURE = 'average_tenure',
  AVERAGE_SALARY = 'average_salary',
  COMPANY_NAME = 'company_name',
  MSA = 'metro area',
  FULL_MSA = 'metro area',
  STATE = 'state',
  HIGHEST_DEGREE = 'education',
  COMPANY_NEW_DASHBOARD = 'company',
  DATA_METRIC = 'Data Adjustments',
  DIVERSITY_INCLUSION = 'Diversity & Inclusion',
  RSID = 'school',
  FLIGHT_RISK = 'flight risk',
  REMOTE_SUITABILITY = 'remote suitability',
  BASE_SALARY = 'Base Salary',
  TOTAL_COMPENSATION = 'Pay',
  SKILL = 'Skill',
  METRIC_MODE = 'Mode',
  RAW_TITLE = 'Title',
}

export enum FilterSetLabelOverrides {
  COMPANY_COMPOSITIONS = 'Company Compositions',
  COMPANY_SKILLS = 'Company Skills',
  COMPANY_TRANSITIONS = 'Company Transitions',
  COMPANY_POSTINGS = 'Company Job Postings',
  COMPANY_SENTIMENT_RATINGS = 'Company Sentiment Ratings',
  COMPANY_SENTIMENT_EFFECTS = 'Company Sentiment Effects',
  COMPANY_SENTIMENT = 'Company Sentiment Reviews',
  GEOGRAPHY_COMPOSITIONS = 'Geography Compositions',
  GEOGRAPHY_SKILLS = 'Geography Skills',
  GEOGRAPHY_TRANSITIONS = 'Geography Transitions',
  GEOGRAPHY_POSTINGS = 'Geography Job Postings',
  GEOGRAPHY_SENTIMENT_RATINGS = 'Geography Sentiment Ratings',
  GEOGRAPHY_SENTIMENT_EFFECTS = 'Geography Sentiment Effects',
  GEOGRAPHY_SENTIMENT = 'Geography Sentiment Reviews',
  ROLE_COMPOSITIONS = 'Role Compositions',
  ROLE_SKILLS = 'Role Skills',
  ROLE_TRANSITIONS = 'Role Transitions',
  ROLE_POSTINGS = 'Role Job Postings',
  ROLE_SENTIMENT_RATINGS = 'Role Sentiment Ratings',
  ROLE_SENTIMENT_EFFECTS = 'Role Sentiment Effects',
  ROLE_SENTIMENT = 'Role Sentiment Reviews',
  SKILL_POSTINGS = 'Skill Postings',
  SKILL = 'Skill',
}

export type RoleSelectionCategories =
  | SelectionCategories.JOB_CATEGORY
  | SelectionCategories.ROLE_K7
  | SelectionCategories.ROLE_K50
  | SelectionCategories.ROLE_K150
  | SelectionCategories.ROLE_K300
  | SelectionCategories.ROLE_K500
  | SelectionCategories.ROLE_K1250
  | SelectionCategories.ROLE_K1500;

export enum SelectionCategories {
  PRIMARY_FILTER = 'primary_filter',
  REGION = 'region',
  JOB_CATEGORY = 'job_category',
  COMPANY = 'company',
  COMPANY_REPORT = 'company_report',
  COMPANY_NEW_DASHBOARD = 'company_new_dashboard',
  SNAPSHOT_DATE = 'snapshot_date',
  SENIORITY = 'seniority',
  ROLE_K7 = 'role_k7',
  ROLE_K50 = 'role_k50',
  ROLE_K150 = 'role_k150',
  ROLE_K300 = 'role_k300',
  ROLE_K500 = 'role_k500',
  ROLE_K1000 = 'role_k1000',
  ROLE_K1250 = 'role_k1250',
  ROLE_K1500 = 'role_k1500',
  COUNTRY = 'country',
  SKILL = 'skill',
  SKILL_MAPPED = 'skill_mapped',
  SKILL_K25 = 'skill_k25',
  SKILL_K50 = 'skill_k50',
  SKILL_K75 = 'skill_k75',
  SKILL_K700 = 'skill_k700',
  SKILL_K2500 = 'skill_k2500',
  SKILL_K3000 = 'skill_k3000',
  GENDER = 'gender',
  ETHNICITY = 'ethnicity',
  EDUCATION = 'education',
  HIGHEST_DEGREE = 'highest_degree',
  DEFAULT_COMPANIES = 'defaultCompanies',
  ROLES_TREE = 'roles_tree',
  REGIONS_TREE = 'regions_tree',
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  INDUSTRY = 'industry',
  INDUSTRY_FULL = 'industry_full',
  RICS_K10 = 'rics_k10',
  RICS_K50 = 'rics_k50',
  RICS_K400 = 'rics_k400',
  KEYWORDS_CATEGORY = 'keyword_category',
  KEYWORD = 'keywords',
  RAW_TITLE = 'title_raw',
  MSA = 'msa',
  METRO_AREA = 'metro_area',
  FULL_MSA = 'full_msa',
  DATE_RANGE = 'date_range',
  DATE_RANGE_FULL = 'date_range_full',
  POSTING_METRIC = 'posting_metric',
  HEADCOUNT = 'headcount',
  HIRING_RATE = 'hiring',
  ATTRITION_RATE = 'attrition',
  GROWTH_RATE = 'growth',
  AVERAGE_TENURE = 'tenure',
  AVERAGE_SALARY = 'salary',
  COMPANY_NAME = 'company_name',
  CURRENT_COMPANY = 'current_company',
  STATE = 'state',
  TALENT_PAGE_ID = 'page',
  TALENT_PAGE_SIZE = 'num_results',
  COUNT = 'count',
  OTHER = 'other',
  SAVED_FILTER_SET = 'saved_filter_set',
  COMPANY_RCID_MAPPINGS = 'company_rcid_mappings',
  METRIC_MODE = 'metric_mode',
  //...

  // TALENT
  GEOGRAPHY = 'geography',
  DIVERSITY_INCLUSION = 'diversity_and_inclusion',
  EXPERIENCE = 'experience',
  SKILLS = 'skill',
  ROLES = 'role',
  TENURE = 'tenure',
  RSID = 'rsid',
  NAME = 'name',

  BASE_SALARY = 'base_salary',
  TOTAL_COMPENSATION = 'total_compensation',

  //COMPENSATION
  TOP_GEOGRAPHIES = 'top_geographies',
  TOP_COMPANIES = 'top_companies',
  TOP_ROLES = 'top_roles',

  TITLES = 'titles',
  GRADUATION_YEAR = 'graduation_year',

  CURRENCY = 'currency',

  CUSTOM_ROLE = 'custom_role',
}

export enum LocalSelectionCategories {
  PRIMARY_ENTITIES = 'primary_entities',

  PRIMARY_FILTER = 'primary_filter',
  SNAPSHOT_OR_OVER_TIME = 'snapshot_or_over_time',
  POSTING_METRIC = 'posting_metric',
  INFLOW_OR_OUTFLOW = 'inflow_or_outflow',
  N_ITEMS_SANKEY = 'n_items_sankey',
  DATA_METRIC = 'raw_metrics',
  BASE_SALARY_OR_TOTAL_COMP = 'base_salary_or_total_comp',
  PROVIDER = 'provider',
  PRESTIGE = 'prestige',
  FLIGHT_RISK = 'flight_risk',
  REMOTE_SUITABILITY = 'remote_suitability',
  METRIC_MODE = 'metric_mode',
  POSTING_GRANULARITY = 'posting_granularity',

  OVERALL_RATING = 'overall_rating',
}

export enum SubFilterNames {
  SUB_ROLE = 'sub_role',
  SUB_REGION = 'sub_region',
  SUB_INDUSTRY = 'sub_industry',
  SUB_SKILL = 'sub_skill',
  SUB_SKILL_OVERTIME = 'sub_skill_overtime',
  SUB_SENIORITY = 'sub_seniority',
  SUB_GENDER = 'sub_gender',
  SUB_ETHNICITY = 'sub_ethnicity',
  SUB_EDUCATION = 'sub_education',
  SUB_KEYWORD = 'sub_keyword',
}

export enum SentimentSubFilterNames {
  SUB_TOPICS = 'sub_topics',
  SUB_REVIEWS_POSITIVE = 'sub_reviews_positive',
  SUB_REVIEWS_NEGATIVE = 'sub_reviews_negative',
}

export enum OtherFilterNames {
  SUBFILTER = 'subfilter',
  GROUPED = 'grouped',
  LAST_MONTH = 'last_month',
  LAST_START_DATE = 'maxWeek',
  INFLOW = 'inflow', // boolean
  OUTFLOW = 'outflow',
  DIM1 = 'dim1',
  ROLE_TAXONOMY = 'role_taxonomy',
  //...
}

export enum QueryParameterKeys {
  START_TIME = 'start_time',
  END_TIME = 'end_time',
  INFLOW = 'inflow',
}

export enum FilterParameterKeys {
  START_DATE = 'start_date',
  END_DATE = 'end_date',
  INFLOW = 'inflow',
  ROLEK50 = 'rolek50',
  ROLEK150 = 'rolek150',
  ROLEK500 = 'rolek500',
  ROLEK1500 = 'rolek1500',
  MSA = 'msa',
  DIM1 = 'dim1',
  N_ITEMS_SANKEY = 'node_limit',
  CUSTOM_ROLE = 'custom_role',
  OVERALL = 'overall',
}

export enum ViewsWithFilters {
  OVERVIEW = 'OVERVIEW',
  COMPANY_POSTINGS = 'COMPANY_POSTINGS',
  KEYWORD_POSTINGS = 'KEYWORD_POSTINGS',
  TRANSITIONS = 'TRANSITIONS',
  JOB_ANALYSIS_OVERVIEW = 'JOB_ANALYSIS_OVERVIEW',
  JOB_ANALYSIS_OVER_TIME = 'JOB_ANALYSIS_OVER_TIME',
  JOB_ANALYSIS = 'JOB_ANALYSIS',
  TALENT_DISCOVERY = 'TALENT_DISCOVERY',
}

export enum PrimaryFilterLimits {
  COMPANY_OVERVIEW = 6,
  COMPANY_SUMMARY = 1,
  GEOGRAPHY_SUMMARY = 1,
  ENTITY_SUMMARY = 1,
  ROLE_SUMMARY = 1,
  TRANSITIONS = 1,
  POSTINGS = 6,
  COMPENSATION = 2,
  COMPANY_SENTIMENT_RATINGS = 6,
  COMPANY_SENTIMENT_EFFECTS = 6,
  COMPANY_SENTIMENT_REVIEWS = 1,
  SENTIMENT = 1,
  GEO_OVERVIEW = 6,
  ROLE_OVERVIEW = 6,
  // Temporarily limit top level filters to 5 for Role Compositions page
  ROLE_OVERVIEW_TOP_LEVEL_FILTERS = 5,
  OVERVIEW = 6,
  SKILLS = 6,

  // Default Limits
  POSTINGS_DEFAULT = 1,
  COMPENSATION_DEFAULT = 1,
  SKILLS_DEFAULT = 1,
}

export enum FilterMenuLimits {
  COMPANY_OVERVIEW = 10,
  ENTITY_SUMMARY = 10, //TODO??
  TRANSITIONS = 10,
  POSTINGS = 10,
  COMPENSATION = 10, //TODO
  COMPANY_SENTIMENT_RATINGS = 10,
  COMPANY_SENTIMENT_EFFECTS = 10,
  COMPANY_SENTIMENT_REVIEWS = 10,
  SENTIMENT = 10,
  GEO_OVERVIEW = 10,
  ROLE_OVERVIEW = 10,
  TALENT_DISCOVERY = 6,
  SAVED_FILTER_SET = 1,
}

export enum OPValues {
  BETWEEN = 'between',
  LESS = 'less',
  GREATER = 'greater',
}

// omits K from each member of union type T
type DistributiveOmit<T, K extends PropertyKey> = T extends any
  ? Omit<T, K>
  : never;

export interface FilteringInitConfig {
  apiRoot: string;
  goApiRoot: string;
  companyMapperUrl: string;
}

export interface FilterStoreRootProps {
  cachedDate?: string;
}

export type OperatorOptionType = {
  label: string;
  value: OPValues;
  filterName: OPValues;
};

export type OperatorOptionsType = OperatorOptionType[];

export type SelectionListIdNames =
  | SelectionCategories
  | LocalSelectionCategories
  | SubFilterNames;

export interface SelectionListResponse<
  T extends ValidValueTypes = ValidValueTypes,
> {
  list: FilterList<T>;
  parent?: SelectionListIdNames;
}

export type FilterName = SelectionListIdNames | OtherFilterNames;
/** TODO/jbellizzi: FilterName already contains SubFilterNames via SelectionListIdNames.
 * keeping this type for all depencies, but we can switch all dependencies to FilterName since they are equal */
export type FilterOrSubfilterName = FilterName;

export type ViewFilterDefaultsValue$<T = FilterList> = {
  [key in
    | SelectionCategories
    | LocalSelectionCategories
    | SubFilterNames
    | OtherFilterNames]: Observable<T>;
};

export interface GetViewFilterDefaults {
  view: Views | ViewsForDefaultsOnly;
  viewType?: ViewTypes;
  viewFilters: FilterName[];
  onlyConsiderTheseFiltersToTriggerDefaults?: (
    | SelectionListIdNames
    | OtherFilterNames
  )[];
  presetView?: FilterSets;
  sharedView?: SharedFilterSets;
  considerPresetFiltersToTriggerDefaults?: boolean;
  limit?: PrimaryFilterLimits;
  deepLinkLimit?: PrimaryFilterLimits;
  syncPrimaryEntities?: boolean;
  primaryFilters?: (SelectionListIdNames | OtherFilterNames)[];
  dateKey?: SelectionCategories;
  supportPrimaryEntities?: boolean;
}

export interface GetViewDefaultMonth {
  view: Views;
  viewType: ViewTypes;
  dateType: DefaultDates;
  dateKey?:
    | SelectionCategories.DATE_RANGE
    | SelectionCategories.SNAPSHOT_DATE
    | SelectionCategories.DATE_RANGE_FULL;
}

export enum DefaultDates {
  DEFAULT_LAST_MONTH = 'last_month',
  OLD_LAST_MONTH = 'old_last_month',
  LAST_START_DATE = 'maxWeek',
  TALENT_START_DATE = 'start_date',
  TALENT_END_DATE = 'end_date',
}

export interface IFilterLimit {
  filterNames: SelectionListIdNames[];
  limit: number;
}

export interface DateFilter<T = string> extends Omit<FilterBase, 'value'> {
  value: T;
  isMaximumRange: boolean;
  type: FilterTypes.DATE;
}

export interface StoredFilterSet {
  id: ViewsWithFilters | string;
  entities: Filter[];
  count?: number;
}

export interface UserStoredFilterSet extends StoredFilterSet {
  name: string;
  label: string;
  view: string;
  creator: string;
  lastModified: Date;
  isDefault: boolean;
}

export type FilterNameIds = Filter['id'];
export type FilterNameIdsOrString = FilterNameIds | PropertyKey;

export type ProvidedFilterOrRawValueMap = {
  [keys in Filter['id']]?:
    | Partial<Filter>
    | (string | number)[]
    | { [key: string]: any }
    | string
    | number
    | boolean;
};

export interface InternalBuildDataRequest {
  endpoint: EndpointMapper | RequestConfig | string | DownloadEndpointMapper;
  requestMethod?: RequestMethod;
  brokenOutFilterIds?: FilterBase['id'][] | Observable<FilterBase['id'][]>;
  additionalNonActiveFilters?:
    | FilterBase['id'][]
    | Observable<FilterBase['id'][]>;
  additionalOperatorsBeforeQuery?: AdditionalOperatorsBeforeQueryFn;
  includeInGlobalLoader?: boolean;
  preFetchQuery: boolean;
  requiredParams?: SelectionListIdNames[];
  customGetRequest?: boolean;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
  gqlClient?: Client;
  view?: Views;
  dataFetcher?: PlotConfig['dataFetcher'];
  kibanaLogger?: KibanaLogger;
}

export interface BuildDataRequest {
  /** if useing dataFetcher then provide a name string here for tracking purposes */
  endpoint: EndpointMapper | RequestConfig | string | DownloadEndpointMapper;
  requestMethod?: RequestMethod;
  brokenOutFilterIds?: FilterBase['id'][] | Observable<FilterBase['id'][]>;
  additionalNonActiveFilters?:
    | FilterBase['id'][]
    | Observable<FilterBase['id'][]>;
  additionalOperatorsBeforeQuery?: AdditionalOperatorsBeforeQueryFn;
  additionalOperators?: UnaryFunction<any, any>;
  includeInGlobalLoader?: boolean;
  preFetchQuery?: boolean;
  requiredParams?: SelectionListIdNames[];
  removeQueryFromLoaderOnResponse?: boolean;
  initialLoadingState?: boolean;
  toggleLoadingStatusBeforeQuery?: boolean;
  isGqlQuery?: boolean;
  isGoRequest?: boolean;
  view?: Views;
  dataFetcher?: PlotConfig['dataFetcher'];
  kibanaLogger?: KibanaLogger;
  skip?: boolean;
}

export interface DownloadBuildRequest {
  endpoint: DownloadEndpointMapper | string | RequestConfig;
  brokenOutFilterIds?: FilterBase['id'][] | Observable<FilterBase['id'][]>;
  additionalNonActiveFilters?:
    | FilterBase['id'][]
    | Observable<FilterBase['id'][]>;
  requestMethod?: RequestMethod;
  additionalOperatorsBeforeQuery?: AdditionalOperatorsBeforeQueryFn;
  customGetRequest?: boolean;
  callback?: () => void;
  isGoRequest?: boolean;
  view?: Views;
  kibanaLogger?: KibanaLogger;
}

export enum RequestMethod {
  POST = 'POST',
  GET = 'GET',
  DELETE = 'DELETE',
  PUT = 'PUT',
}

export interface IQueryEndpoint {
  url: URL;
  requestMethod?: RequestMethod;
  requestHash: string;
  search?: string;
  body?: string;
  catchErrorBool?: boolean;
  catchFn?: (e: any) => Observable<any>;
  includeInGlobalLoader?: boolean;
  preFetchQuery?: boolean;
  abortController?: AbortController;
  isGoRequest?: boolean;
  kibanaLogger?: KibanaLogger;
}

export interface IInternalDataFetch {
  url: URL;
  method?: RequestMethod;
  search?: string;
  body?: string;
  abortController?: AbortController;
  isGoRequest?: boolean;
  kibanaLogger?: KibanaLogger;
}

export interface ChartConfigForPlot {
  chartType: D3ChartNames;
  chartProps:
    | DistributiveOmit<ID3ChartProps, 'data'>
    | DistributiveOmit<ID3ReplotChartProps, 'data'>;
}

export interface PlotConfigs extends BuildDataRequest {
  name: string;
  chartTypeAndProps: Observable<ChartConfigForPlot> | ChartConfigForPlot;
}

export interface IFilterTypeHandler {
  [key: string]: (f: any, idx?: number) => any;
}

export interface SerializedFiltersForQuery
  extends EnumeratedFilters<ValidValueTypes | ValidValueTypes[]> {
  filters?: EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>;
}

export type ProvideAdditionalOperators<S = Filter, R = Filter> = (
  obs: Observable<S>
) => Observable<R>;

export interface ISelectFilter {
  filterName: SelectableCategories;
  isParentListDisabled: boolean;
  value: SelectableCategories | SelectableCategories[];
  isMulti: boolean;
  label: string;
  isNested?: boolean;
  limit?: number;
  showDivider?: boolean;
}

export interface DateValSelectFilter {
  label: string;
  value: number;
  filterName: string;
}

export interface Item {
  id: string | number;
  parentId?: string;
  label?: string;
  longName?: string;
  shortName?: string;
  [key: string]: any;
}
export interface TreeItem<T = string> {
  item: Item | null;
  children: TreeItem<T>[];
  id: string;
  parentId?: string;
  selectionListId: SelectionListIdNames;
  isMulti?: boolean;
  isSelectableParent?: boolean;
}

export type SelectableCategories =
  | SelectionCategories
  | LocalSelectionCategories;

export type FilterMenuItem = SelectableCategories | SelectableCategories[];

export type NestedMenuItemConfig = Partial<MenuItemConfig>;

export type MenuItemConfig = {
  filters: SelectableCategories[];
  isNested?: boolean;
  limit?: number;
};

export type FilterMenuItemOrConfig = FilterMenuItem | NestedMenuItemConfig;

export type SelectableFiltersMenuProp = {
  snapshot: FilterMenuItemOrConfig[];
  overtime: FilterMenuItemOrConfig[];
};

export type SelectableFiltersList = FilterMenuItem[];

export type AdditionalOperatorsBeforeQueryFnObservableArgs = {
  dataFetcher?: DataFetcher<unknown>;
  endpoint: CalculatedEndpoint | string | RequestConfig;
  filters: Filter[];
  additionalFilters: Filter[];
  brokenOutFilterIds$: (
    | SubFilterNames
    | SelectionListIdNames
    | OtherFilterNames
  )[];
  includeInGlobalLoader: boolean;
  preFetchQuery: boolean;
  plotRequestHash: string;
};
export type AdditionalOperatorsBeforeQueryFn = UnaryFunction<
  Observable<PlotAdditionalQueryParams>,
  Observable<PlotAdditionalQueryParams>
>;

/** ================================
 * Filter Values
 ================================ */
export type DateRangeFormattedValues = {
  startDate: string;
  endDate: string;
};

export type ValueItem = {
  id: string | number;
  label?: string;
};

export type ValidValueTypes =
  | string
  | number
  | boolean
  | [number | Date, number | Date]
  | DateRangeFormattedValues
  | ValueItem
  | CustomRoleFilterValue
  | CustomRoleTaxonomySelection;

/** ================================
 * Filter Items
 ================================ */
type ParentIds<TParent extends SelectionListIdNames = SelectionListIdNames> = {
  [key in TParent]?: string[] | string;
};

export type SelectionListItem<
  T extends SelectionListIdNames = SelectionListIdNames,
> = ParentIds<T> & {
  id: string;
  shortName?: string;
  longName?: string;
  topCleanedTitles?: string;
  topSkills?: string;
};

export type SelectionListItems<
  TParent extends SelectionListIdNames = SelectionListIdNames,
> = {
  id: SelectionListIdNames;
  value: SelectionListItem<TParent>[];
};

export interface FilterItem<T extends ValidValueTypes = ValidValueTypes> {
  id: string | number;
  label?: string;
  value?: T; // same as id
  parentId?: string | number;
  shortName?: string;
  longName?: string;
  topCleanedTitles?: string;
  topRawTitles?: string;
  sedol?: string | null;
  isin?: string | null;
  ticker?: string;
  rcid?: string;
  industry?: string;
  ricsK50Id?: string;
  ricsK400Id?: string;
  rank?: number;
  index?: number;
  selectionListId?: string;
  isActive?: boolean;
}

export type FilterList<T extends ValidValueTypes = ValidValueTypes> =
  FilterItem<T>[]; // index's are unique and constant

export interface OPSelectFilter {
  filterName: OPValues;
  value: OPValues;
  label: string;
}

export type ScreenEmployeeTypesSelectionCategories =
  | SelectionCategories.REGION
  | SelectionCategories.INDUSTRY
  | SelectionCategories.SENIORITY;

export type ScreenerType = {
  employeeTypes: {
    [key in ScreenEmployeeTypesSelectionCategories]?: ValueItem[];
  };
  opValue: OPSelectFilter;
  isPercentage: boolean;
  startValue: number;
  endValue: number;
};

export type TalentDiscoveryCompanyValueType = {
  value: string;
  label: string;
};

export type TalentDiscoveryValue = {
  keyName: string;
  opValue: OPSelectFilter;
  startValue: number;
  endValue: number;
  companyValue?: TalentDiscoveryCompanyValueType[];
};

export type CustomRoleFilterEntity = {
  id: string;
  levelId: string;
  label: string;
};
export type CustomRoleFilterValue = {
  taxonomyId: CustomRoleTaxonomySelection;
  entities: CustomRoleFilterEntity[];
};

/** ================================
 * Filters
 ================================ */
export type FilterValue =
  | ValidValueTypes
  | FilterItem<ValidValueTypes>
  | FilterList<ValidValueTypes>
  | ScreenerType
  | TalentDiscoveryValue
  | CustomRoleFilterValue;

export interface FilterBase<V extends FilterValue = FilterValue> {
  id: FilterName;
  label: string;
  value: V;
  isUserSubmitted?: boolean;
  linkedFilters?: SelectionCategories[];
  customFormatter?: (filter: Filter, idx: number) => any;
  formatOverride?: Views | ViewsForDefaultsOnly | FilterFormatOverrides;
}

export interface SelectFilter<T extends FilterValue = FilterValue>
  extends FilterBase<T> {
  selectionListId?: SelectionListIdNames;
  isMulti: boolean;
  type: FilterTypes.SELECT;
  primaryFilter?: PrimaryFilters;
}

export type DefaultSelectFilter<T extends ValidValueTypes = string> =
  | SelectFilter<FilterItem<T>>
  | undefined;

export interface RangeFilter extends FilterBase<DateRangeFormattedValues> {
  isMaximumRange: boolean;
  type: FilterTypes.DATE_RANGE;
}

export interface RangeFullFilter extends FilterBase<DateRangeFormattedValues> {
  isMaximumRange: boolean;
  type: FilterTypes.DATE_RANGE_FULL;
}

export interface RoleTaxonomyFilter
  extends FilterBase<CustomRoleFilterValue['taxonomyId']> {
  type: FilterTypes.SELECT;
  isMulti: false;
}

export interface CustomRoleTaxonomyFilter
  extends FilterBase<CustomRoleFilterValue> {
  type: FilterTypes.SELECT;
  isMulti: false;
}

export interface BooleanFilter extends FilterBase<boolean> {
  type: FilterTypes.BOOLEAN;
}

/** All filter types */
export type AnyFilter<T extends FilterValue = FilterValue> =
  | SelectFilter<T>
  | RangeFilter
  | BooleanFilter;

export type Filter = SelectFilter<FilterValue> | RangeFilter | BooleanFilter;

/** ================================
 * Selection Lists
 ================================ */
/** Selection list entities store all values for each filter */
export interface SelectionList<T extends ValidValueTypes = ValidValueTypes> {
  id: SelectionListIdNames;
  label: SelectionListIdNames;
  value: FilterList<T>;
  parent?: SelectionListIdNames;
  generated?: Date;
  roleTaxonomyId?: CustomRoleTaxonomySelection;
}

/** ================================
 * View Filter Defaults
 ================================ */
export type ViewFilterDefaultIds = Views | ViewsForDefaultsOnly;

export type EnumeratedFilters<T> = {
  [key in FilterName]?: T;
};

export type MappedFilter = {
  [key in FilterName | FilterParameterKeys]?:
    | ValidValueTypes
    | ValidValueTypes[];
};

export interface ViewFilterDefaults<
  T extends ValidValueTypes = ValidValueTypes,
> {
  id: ViewFilterDefaultIds;
  filters: FilterName[];
  full?: boolean;
  meta?: { [key: string]: any };
  value: EnumeratedFilters<T | T[]>;
}
