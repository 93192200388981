/**
 * Creates a preview of JSON data to prevent logging large response bodies
 */
export function previewJson<T>(
  data: T,
  options: {
    maxDepth?: number;
    maxStringLength?: number;
    maxArrayItems?: number;
    maxObjectKeys?: number;
    addMetadata?: boolean;
  } = {}
) {
  const {
    maxDepth = 8,
    maxStringLength = 1000,
    maxArrayItems = 10,
    maxObjectKeys = 20,
    addMetadata = true,
  } = options;

  const truncateString = (str: string) =>
    str.length > maxStringLength
      ? `${str.slice(0, maxStringLength)}... (${str.length} chars)`
      : str;

  const generatePreview = (
    obj: unknown,
    currentDepth = 0,
    path = 'root'
  ): unknown => {
    try {
      if (obj === null) {
        return null;
      }

      if (typeof obj !== 'object') {
        return typeof obj === 'string' ? truncateString(obj) : obj;
      }

      if (currentDepth >= maxDepth) {
        return addMetadata
          ? { _truncatedDepth: true, _maxDepthReached: maxDepth, _atPath: path }
          : '[Max Depth Reached]';
      }

      if (Array.isArray(obj)) {
        const preview = obj
          .slice(0, maxArrayItems)
          .map((item, idx) =>
            generatePreview(item, currentDepth + 1, `${path}[${idx}]`)
          );

        if (obj.length > maxArrayItems && addMetadata) {
          return {
            _truncatedData: preview,
            _originalLength: obj.length,
            _omittedItems: obj.length - maxArrayItems,
          };
        }

        return obj.length > maxArrayItems
          ? [...preview, `... (+${obj.length - maxArrayItems} more)`]
          : preview;
      }

      const previewObj: Record<string, unknown> = {};

      if (obj instanceof Blob || obj instanceof File) {
        return `[${obj.constructor.name}: ${obj.size} bytes]`;
      }

      const entries = Object.entries(obj);
      const hasExcessKeys = entries.length > maxObjectKeys;
      const limitedEntries = hasExcessKeys
        ? entries.slice(0, maxObjectKeys)
        : entries;

      for (const [key, value] of limitedEntries) {
        if (typeof value === 'function') {
          previewObj[key] = '[Function]';
          continue;
        }

        previewObj[key] = generatePreview(
          value,
          currentDepth + 1,
          `${path}.${key}`
        );
      }

      if (hasExcessKeys && addMetadata) {
        return {
          ...previewObj,
          _truncatedKeys: true,
          _originalKeyCount: entries.length,
          _omittedKeys: entries.length - maxObjectKeys,
        };
      } else if (hasExcessKeys) {
        return {
          ...previewObj,
          _omitted: `... (+${entries.length - maxObjectKeys} more keys)`,
        };
      }

      return previewObj;
    } catch (error) {
      return addMetadata
        ? {
            _error: 'Error generating JSON preview',
            _atPath: path,
            _errorMessage: String(error),
          }
        : '[Error generating JSON preview]';
    }
  };

  return generatePreview(data);
}
