import { useQuery } from '@tanstack/react-query';

import { GO_API_ROOT } from '@revelio/auth';
import { STANDARD_DATE_FORMAT, Views } from '@revelio/core';
import { Dimension1 } from '@revelio/data-access';
import {
  getSelectedLastMonth,
  getStartDateConst,
  getYearFromDate,
} from '@revelio/filtering';

import { CompensationFilters } from '../../../../filtering';
import { KdeSalaryData } from './types';

export const useGetKdePlotData = ({
  filters,
  startDate,
  endDate,
  selectedCompanyRCID,
}: {
  filters: Partial<CompensationFilters>;
  startDate?: string;
  endDate?: string;
  selectedCompanyRCID?: number;
}) => {
  const companyRCIDs = filters?.company;
  const hasNoPrimaryAndCompetitorRCIDs = !companyRCIDs?.length;

  const startYear =
    getYearFromDate(startDate, STANDARD_DATE_FORMAT) ??
    getYearFromDate(getStartDateConst(Views.COMPENSATION));

  const endYear =
    getYearFromDate(endDate, STANDARD_DATE_FORMAT) ??
    getYearFromDate(getSelectedLastMonth(Views.COMPENSATION));

  const payload: CompensationFilters = {
    ...filters,
    dim1: Dimension1.Company,
    year_range: [[Number(startYear), Number(endYear)]],
    company: selectedCompanyRCID ? [Number(selectedCompanyRCID)] : undefined,
  };

  const { data: kdeSalaryData, isLoading: loading } = useQuery({
    queryKey: ['compensation', payload],
    queryFn: () => fetchCompensationData(payload),
    enabled: !hasNoPrimaryAndCompetitorRCIDs && !!startYear && !!endYear,
    staleTime: 1000 * 60 * 60,
  });

  return { kdeSalaryData, loading };
};

const fetchCompensationData = async (payload: CompensationFilters) => {
  try {
    const response = await fetch(`${GO_API_ROOT}/models/compensation`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'x-request-id': crypto.randomUUID(),
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.status} ${response.statusText}`);
    }

    const data = await response.json();
    const kdeSalaryData: KdeSalaryData | undefined = data
      ?.find((dataSet: { id: string }) => dataSet?.id === 'total_compensation')
      .value?.find((plot: { id: string }) => plot.id === 'kde');

    return kdeSalaryData;
  } catch (error) {
    console.error('Failed to fetch compensation data:', error);
    throw error;
  }
};
