import {
  PropsWithChildren,
  createContext,
  useContext,
  useReducer,
} from 'react';

import {
  ConfigContextType,
  ConfigState,
  configReducer,
} from './config-reducer';

const ConfigContext = createContext<ConfigContextType | undefined>(undefined);

const defaultConfig: ConfigState = { items: [] };

export function ConfigProvider({ children }: PropsWithChildren) {
  const [config, dispatch] = useReducer(configReducer, defaultConfig);

  const value = { config, dispatch };

  return (
    <ConfigContext.Provider value={value}>{children}</ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}
