import { groups } from 'd3-array';
import { useCallback, useMemo } from 'react';

import { useSelectionListsValidated } from '../../../engine/filters.engine';
import {
  FilterList,
  LocalSelectionCategories,
  SelectFilter,
  SelectionCategories,
  SelectionListItems,
} from '../../../engine/filters.model';
import {
  deleteFilter,
  upsertFilter,
  useAllFiltersState,
} from '../../../engine/filters.repository';
import { GetNestedIdProps, getNestedId, nestSelectionLists } from '../../tree';
import { FilterDataProvider } from '../types';
import {
  NestedFilterItem,
  getNestedFilterItemsFromIds,
} from '../utils/get-filter-items-from-ids';
import { useActiveSelectionListItems } from '../utils/useActiveSelectionListItems';

export function usePrimaryEntityFilter(
  selectionListIds: SelectionCategories[] = [],
  activeLimit = 6
): FilterDataProvider<NestedFilterItem> {
  const selectionLists = useSelectionListsValidated(selectionListIds);
  const allFilters = useAllFiltersState();

  const activeSelectionListItems =
    useActiveSelectionListItems(selectionListIds);

  /*
   * Process and sort primary entities
   */
  const primaryEntities = useMemo(
    () =>
      allFilters
        .filter(
          (filter) => filter.id === LocalSelectionCategories.PRIMARY_ENTITIES
        )
        .filter((filter): filter is SelectFilter<FilterList> =>
          Array.isArray(filter.value)
        )
        .flatMap((filter) => filter.value),
    [allFilters]
  );

  const activeFilters: SelectionListItems[] = useMemo(() => {
    const groupedFilters = groups(primaryEntities, (d) => d.selectionListId);
    return groupedFilters.map(
      ([selectionListId, items]): SelectionListItems => ({
        id: selectionListId as SelectionCategories,
        value: items.map((item) => ({ ...item, id: `${item.id}` })),
      })
    );
  }, [primaryEntities]);

  const sortedSelectionLists = useMemo(() => {
    return selectionLists.map((selectionList) => {
      const activeFilter = activeFilters.find(
        (filter) => filter.id === selectionList.id
      );
      if (!activeFilter) return selectionList;
      else {
        return {
          ...selectionList,
          value: [...selectionList.value].sort((a, b) => {
            const aActive = activeFilter.value.some((item) => item.id === a.id);
            const bActive = activeFilter.value.some((item) => item.id === b.id);
            if (aActive && !bActive) return -1;
            if (!aActive && bActive) return 1;
            return 0;
          }),
        };
      }
    });
  }, [selectionLists, activeFilters]);

  /*
   * Nested tree data
   */
  const treeData = useMemo(
    () => nestSelectionLists(sortedSelectionLists),
    [sortedSelectionLists]
  );

  /*
   * Active filter ids
   */
  const activeIds = useMemo(
    () =>
      activeFilters
        .flatMap((filter) =>
          filter.value.map((item): GetNestedIdProps['item'] => ({
            ...item,
            selectionListId: filter.id,
          }))
        )
        .map((item) =>
          getNestedId({ selectionLists: sortedSelectionLists, item })
        ),
    [activeFilters, sortedSelectionLists]
  );

  /*
   * Get selected entities
   */
  const getSelectedEntities = useCallback(
    (selections: string[]): NestedFilterItem[] =>
      getNestedFilterItemsFromIds(selections, sortedSelectionLists),
    [sortedSelectionLists]
  );

  /*
   * Apply filter selection
   */
  const applySelectedIds = useCallback(
    (selections: string[]) => {
      const items = getSelectedEntities(selections);

      const primaryEntityIds = primaryEntities
        .map((entity) => `${entity.selectionListId}_${entity.id}`)
        .reverse();

      const newPrimaryEntities: NestedFilterItem[] = [];

      const entitiesToSort = items.filter((item) => {
        const indexOfItem = primaryEntityIds.indexOf(
          `${item.selectionListId}_${item.id}`
        );

        if (indexOfItem === -1) {
          newPrimaryEntities.unshift(item);
          return false;
        }

        return true;
      });

      const sortedExistingEntities = entitiesToSort.sort((a, b) => {
        const indexOfA = primaryEntityIds.indexOf(
          `${a.selectionListId}_${a.id}`
        );
        const indexOfB = primaryEntityIds.indexOf(
          `${b.selectionListId}_${b.id}`
        );

        return indexOfB - indexOfA;
      });

      const sortedNewPrimaryEntities = [
        ...newPrimaryEntities,
        ...sortedExistingEntities,
      ];

      upsertFilter(LocalSelectionCategories.PRIMARY_ENTITIES, {
        value: sortedNewPrimaryEntities,
      });

      let filtersToApply = sortedNewPrimaryEntities
        .filter((item) => {
          // If the item is a new primary entity, return true
          if (
            newPrimaryEntities.some(
              (newEntity) =>
                newEntity.selectionListId === item.selectionListId &&
                newEntity.id === item.id
            )
          ) {
            return true;
          }

          // If the item is currently active, return true
          const itemActiveFilters = activeSelectionListItems.find(
            (activeFilter) => activeFilter.id === item.selectionListId
          );
          if (!itemActiveFilters) return false;
          return itemActiveFilters.value.some(
            (activeItem) => activeItem.id === item.id
          );
        })
        // Only apply the first n filters
        .slice(0, activeLimit);

      // If the active entity was removed, make the first entity active
      if (filtersToApply.length === 0 && sortedNewPrimaryEntities.length > 0) {
        filtersToApply = [sortedNewPrimaryEntities[0]];
      }

      const groupedFiltersToApply = groups(
        filtersToApply,
        (d) => d.selectionListId
      );

      selectionListIds.forEach((selectionListId) => {
        if (
          !groupedFiltersToApply.some((group) => group[0] === selectionListId)
        ) {
          deleteFilter(selectionListId);
        }
      });

      groupedFiltersToApply.forEach((group) => {
        upsertFilter(group[0], {
          selectionListId: group[0],
          isMulti: true,
          value: group[1],
        });
      });
    },
    [
      getSelectedEntities,
      primaryEntities,
      activeLimit,
      selectionListIds,
      activeSelectionListItems,
    ]
  );

  return {
    treeData,
    activeIds,
    applySelectedIds,
    getSelectedEntities,
  };
}
