import {
  Button,
  Icon,
  PlacementWithLogical,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  Portal,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect$, useUntilDestroyed } from '@ngneat/react-rxjs';
import {
  isEmpty as _isEmpty,
  find,
  get,
  intersection,
  isArray,
  isNil,
  isUndefined,
  truncate,
  uniq,
} from 'lodash';
import { useRef, useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { combineLatest, of } from 'rxjs';
import {
  auditTime,
  filter,
  isEmpty,
  pairwise,
  startWith,
  tap,
  withLatestFrom,
} from 'rxjs/operators';

import { PopoverTrigger } from '@revelio/core';
import { CustomTaxonomyEnum } from '@revelio/data-access';

import { useSelectionLists } from '../../engine/filters.engine';
import {
  AnyFilter,
  FilterItem,
  FilterList,
  OtherFilterNames,
  SelectFilter,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  SubFilterNames,
  ValidValueTypes,
} from '../../engine/filters.model';
import {
  getManyFiltersState,
  getSingleFilterState,
  selectionListDataSource,
  upsertFilter,
  useSelectFilterById,
} from '../../engine/filters.repository';
import { useRoleTaxonomySetting } from '../../engine/role-taxonomy/filters.role-taxonomy';
import { Option } from '../selection-list/selection-list-select';
import { SubFilterList } from './sub-filter-list';
import { SubFilterTree } from './sub-filter-tree';
import { SubFilterState } from './sub-filter.types';

interface ISelectionListsResponse {
  selectionLists: SelectionList[];
}

export interface SubFilterProps {
  placementOverride?: PlacementWithLogical;
  defaultState?: SubFilterState;
  filterName?: string | SelectionListIdNames;
  selectionLists?: SelectionListIdNames[];
  selectionLimit?: number;
  minSelections?: number;
  subfiltersMap?: any;
}

export const SubFilter = ({
  placementOverride = 'bottom',
  defaultState = {},
  filterName,
  selectionLists = [],
  selectionLimit = 6,
  minSelections = 1,
  subfiltersMap,
}: SubFilterProps) => {
  const { onOpen, onClose, isOpen } = useDisclosure();

  const parentLists = subfiltersMap
    ? (Object.keys(subfiltersMap) as Array<SelectionListIdNames>)
    : selectionLists;

  const subLists = subfiltersMap
    ? (Object.values(subfiltersMap) as Array<SelectionListIdNames>)
    : [];

  const [mappedSelectionLists, setMappedSelectionLists] = useState(() => {
    let mappedLists: SelectionListIdNames[] = [];

    if (subfiltersMap) {
      const parentFilterName = selectionLists[0];
      const childFilterName = subfiltersMap[selectionLists[0]];

      if (parentFilterName === childFilterName) {
        mappedLists = [parentFilterName];
      } else {
        mappedLists = [parentFilterName, childFilterName];
      }
    }

    return mappedLists;
  });

  const selectionListsToFetch = uniq([...parentLists, ...subLists]);

  // @NOTE: this is so Skills lists still coming from the old backend aren't tried to be fetched from the new and empty results overwrite the old one's
  if (
    intersection(selectionListsToFetch, [
      SelectionCategories.SKILL,
      SelectionCategories.KEYWORD,
      SelectionCategories.KEYWORDS_CATEGORY,
    ]).length == 0
  ) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useSelectionLists(selectionListsToFetch);
  }

  const { untilDestroyed } = useUntilDestroyed();

  const parentFilterState$ = useRef(getManyFiltersState(parentLists));

  const { current: subfilterState$ } = useRef(
    isUndefined(filterName)
      ? of([])
      : getManyFiltersState([filterName as SubFilterNames])
  );

  const [displayText, setDisplayText] = useState('');
  const [disableParentSelect, setDisableParentSelect] = useState(false);
  const [parentFilter, setParentFilter] = useState<(string | number)[]>([]);
  const [flatOptions, setFlatOptions] = useState<Option[]>([]);

  /**
   * Initialize filter with default state if parent filter is empty
   */
  useEffect$(
    () =>
      parentFilterState$.current.pipe(
        isEmpty(),
        auditTime(0),
        tap((isEmptyObs) => {
          if (isEmptyObs && !_isEmpty(defaultState)) {
            upsertFilter(filterName as SelectionCategories, defaultState);
          }
        })
      ),
    []
  );

  /**
   * Determines parent and child IDs based on filter configuration
   */
  const determineFilterRelationship = (
    filter: SelectFilter<FilterList>[][],
    isMappedSubfilter: boolean
  ) => {
    const [, curFilter] = filter;

    const parentId =
      isMappedSubfilter && curFilter.length > 0
        ? curFilter[0]?.selectionListId
        : selectionLists[0];

    const childId = isMappedSubfilter
      ? subfiltersMap?.[parentId as SelectionListIdNames]
      : selectionLists[1];

    const isNestedMappedSubfilter = isMappedSubfilter && parentId !== childId;
    const isNestedList = isMappedSubfilter
      ? isNestedMappedSubfilter
      : selectionLists.length > 1;

    return { parentId, childId, isNestedMappedSubfilter, isNestedList };
  };

  /**
   * Updates the flat options list based on filter state and data
   */
  const updateFlatOptions = (
    curFilter: SelectFilter<FilterList>[],
    list: ISelectionListsResponse,
    parentId: SelectionListIdNames | string | undefined,
    childId: SelectionListIdNames | string | undefined,
    isMappedSubfilter: boolean,
    isNestedList: boolean,
    curValuelen: number | undefined | null,
    shouldUseFlatOptions: boolean
  ) => {
    if (!shouldUseFlatOptions) {
      setFlatOptions([]);
      return;
    }

    let listToUse;

    if (isMappedSubfilter && parentId === childId) {
      listToUse = list.selectionLists.find((li) => li.id === childId);
    } else if (
      isNestedList &&
      !isNil(curValuelen) &&
      curValuelen > 0 &&
      parentId !== childId
    ) {
      listToUse = list.selectionLists.find((li) => li.id === childId);
    } else {
      listToUse = list.selectionLists.find((li) => li.id === selectionLists[0]);
    }

    if (
      selectionLists.length > 1 &&
      selectionLists.at(-1) === childId &&
      curFilter[0]?.selectionListId === childId
    ) {
      setFlatOptions(
        curFilter?.length > 0
          ? curFilter[0]?.value.map((v) => ({
              value: v.id.toString(),
              label: v.label || v.shortName || '',
              entity: v,
            }))
          : []
      );
    } else if (listToUse && listToUse.value) {
      const options = listToUse.value.map((item: any) => ({
        value: item.id.toString(),
        label: item.label || item.name || item.shortName,
        entity: item,
      }));
      setFlatOptions(options);
    }
  };

  /**
   * Gets default values to upsert based on subfilter state and parent filter
   */
  const getDefaultsToUpsert = (
    subfilterState: AnyFilter<any>,
    parentIdFilter: (string | number)[],
    parentId: SelectionListIdNames | string | undefined,
    isNestedList: boolean,
    childList: SelectionList | undefined
  ) => {
    let defaultsToUpsert = [];
    const isSubFiltersAlreadySet = !isUndefined(subfilterState);

    if (isSubFiltersAlreadySet && subfilterState.value) {
      defaultsToUpsert = subfilterState.value.filter((val: any) => {
        const subFilterParentIds = get(val, parentId || '');
        if (isArray(subFilterParentIds)) {
          // in the case of supporting multiple parents, subfilter parent ids are arrays
          return intersection(parentIdFilter, subFilterParentIds).length;
        } else {
          return parentIdFilter.includes(
            isNestedList ? subFilterParentIds : val.id
          );
        }
      });
    }

    if (defaultsToUpsert.length === 0) {
      defaultsToUpsert = [
        find(childList?.value, (v) => {
          if (isUndefined(parentId) || v.id == 0) {
            return false;
          }

          const parentListId = get(v, parentId);

          if (isArray(parentListId)) {
            return intersection(parentIdFilter, parentListId).length > 0;
          }

          return parentIdFilter.includes(parentListId || v.id);
        }),
      ];
    }

    return defaultsToUpsert;
  };

  /**
   * Main filter management effect - handles filter relationship, options, and state
   */
  useEffect$(
    () =>
      combineLatest([
        parentFilterState$.current.pipe(startWith(null), pairwise()),
        selectionListDataSource.data$({
          key: selectionListsToFetch,
        }),
      ]).pipe(
        withLatestFrom(subfilterState$),
        untilDestroyed(),
        auditTime(0),
        filter<any>((stuff) => {
          return !isUndefined(stuff[1]);
        }),
        tap(
          (
            value: [
              [SelectFilter<FilterList>[][], ISelectionListsResponse],
              AnyFilter<any>[],
            ]
          ): any => {
            const [[filter, list], [subfilterState]] = value;

            //  Assumptions:
            //  1. Primary Selections from MappedSubfilters are restricted to a single level.
            //     we will never have Primary Selections for these across levels.
            //  2. Selection List Ids for Nested Lists are in the following order: [parentId, childId]

            const isMappedSubfilter = subLists.length > 0;
            const [prevFilter, curFilter] = filter;

            const { parentId, childId, isNestedMappedSubfilter, isNestedList } =
              determineFilterRelationship(filter, isMappedSubfilter);

            if (isMappedSubfilter) {
              setMappedSelectionLists(
                isNestedMappedSubfilter ? [parentId, childId] : [parentId]
              );
            }

            const curParentFilter = isMappedSubfilter
              ? curFilter[0]
              : curFilter.find((listItem) => listItem?.id === parentId);

            const childList = list.selectionLists.find((li) => {
              if (isMappedSubfilter) {
                return li.id === childId;
              }

              return li.id === selectionLists[isNestedList ? 1 : 0];
            });

            const prevValuelen = prevFilter?.reduce(
              (prev, curr) => prev + (curr?.value?.length || 0),
              0
            );

            const curValuelen = curFilter?.reduce(
              (prev, curr) => prev + (curr?.value?.length || 0),
              0
            );

            const parentIdFilter =
              curParentFilter?.value.map((v) => v.id) || [];

            const shouldUseFlatOptions =
              curParentFilter === undefined ||
              selectionLists.at(-1) === curFilter[0]?.selectionListId;

            // Update options for flat view
            updateFlatOptions(
              curFilter,
              list,
              parentId,
              childId,
              isMappedSubfilter,
              isNestedList,
              curValuelen,
              shouldUseFlatOptions
            );

            setDisableParentSelect(isNestedList && curValuelen > 0);
            setParentFilter(parentIdFilter);

            // Handle filter state updates
            if (!isNil(curValuelen) && curValuelen > 0) {
              const defaultsToUpsert = getDefaultsToUpsert(
                subfilterState,
                parentIdFilter,
                parentId,
                isNestedList,
                childList
              );

              if (defaultsToUpsert.length > 0) {
                const defaultValue = {
                  ...defaultState,
                  selectionListId:
                    curValuelen && isNestedList ? childId : parentId,
                  value: defaultsToUpsert,
                };

                upsertFilter(filterName as SelectionCategories, defaultValue);
              }
            }

            if (
              !isNil(prevValuelen) &&
              (isNil(curValuelen) || curValuelen === 0)
            ) {
              upsertFilter(filterName as SelectionCategories, defaultState);
            }
          }
        )
      ),
    []
  );

  /**
   * Formats the display text for the filter button
   */
  const formatDisplayText = (
    filterState: SelectFilter,
    selectionListsData: any[],
    parentFilterState: any
  ) => {
    if (!filterState) {
      return 'No Selection';
    }

    const toReduceValue = filterState.isMulti
      ? (filterState?.value as FilterList<ValidValueTypes>)
      : [filterState?.value as FilterItem<ValidValueTypes>];

    const toDisplay = toReduceValue.reduce(
      (result: any, cur: any, i: number) => {
        if (i == 0) {
          let displayLabel = get(
            cur,
            'shortName',
            get(cur, 'label', 'Unknown')
          );

          if (
            roleTaxonomySetting !== CustomTaxonomyEnum.Disabled &&
            filterName === SubFilterNames.SUB_ROLE &&
            parentFilterState.length === 0
          ) {
            const mappedValue = selectionListsData[0]?.value.find(
              (role: any) => {
                return role.id === cur?.id;
              }
            );

            if (mappedValue) {
              displayLabel = mappedValue?.shortName;
            }
          }

          return truncate(displayLabel, {
            length: 20,
            omission: '...',
          });
        }
        if (i == 1) {
          return 2;
        }
        return result + 1;
      },
      ''
    );

    return Number.isInteger(toDisplay) ? `${toDisplay} Selections` : toDisplay;
  };

  const { value: roleTaxonomySetting } = useRoleTaxonomySetting();

  /**
   * Updates the display text based on filter state and settings
   */
  useEffect$(
    () =>
      combineLatest([
        getSingleFilterState(filterName as OtherFilterNames),
        parentFilterState$.current.pipe(startWith('nothing')),
        selectionListDataSource.data$({
          key: [SelectionCategories.JOB_CATEGORY],
        }),
      ]).pipe(
        untilDestroyed<any>(),
        tap(
          ([
            filterState,
            parentFilterState,
            { selectionLists: selectionListsData },
          ]: [SelectFilter, any, any]) => {
            setDisplayText(
              formatDisplayText(
                filterState,
                selectionListsData,
                parentFilterState
              )
            );
          }
        )
      ),
    [roleTaxonomySetting, filterName]
  );

  const treeSelectionLists = subfiltersMap
    ? mappedSelectionLists
    : selectionLists;
  const filterState = useSelectFilterById(filterName as OtherFilterNames);

  if (!selectionLists) {
    return <span></span>;
  }

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      placement={placementOverride}
      defaultIsOpen={false}
      isLazy={flatOptions.length > 0}
      lazyBehavior="unmount"
    >
      <PopoverTrigger>
        <Button
          data-testid={`plot-sub-filter-${filterName}-trigger`}
          variant="outline"
          colorScheme="gray"
          color="text.primary"
          fontWeight="normal"
          borderColor="#E2E8F0"
          aria-label="Plot Sub-filter"
          size="xs"
          fontSize="10px"
          rightIcon={<Icon boxSize={3} as={FiChevronDown} />}
          textOverflow="ellipsis"
        >
          {displayText}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent>
          <PopoverArrow />
          <PopoverBody data-testid="subfilter-menu-popover">
            {flatOptions.length > 0 ? (
              <SubFilterList
                options={flatOptions}
                filterName={filterName}
                filterState={filterState}
                selectionLists={selectionLists}
                defaultState={defaultState}
                minSelections={minSelections}
                onClose={onClose}
              />
            ) : (
              <SubFilterTree
                filterName={filterName}
                parentFilter={parentFilter}
                filterState={filterState}
                selectionLists={treeSelectionLists}
                disableParentSelect={disableParentSelect}
                minSelections={minSelections}
                maxSelections={selectionLimit}
                onClose={onClose}
              />
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
  );
};
