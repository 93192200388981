import {
  TalentDiscoveryAiFilterSearchResponse,
  TalentDiscoveryAiSearchFilterType,
} from '@revelio/data-access';
import {
  SelectionCategories,
  SelectionList,
  TreeItem,
} from '@revelio/filtering';

import { TreeFilters } from '../../../../types';
import { TDResponseFilterToLabel } from '../ai-filter-response-mappings';
import { getUnknownFilterExplanation } from '../get-unknown-filter-explanation';
import {
  findMatchingTreeItems,
  getClosestTreeItem,
} from './tree-matching-utils';

export interface ProcessFilterGroupArgs {
  group: TalentDiscoveryAiSearchFilterType[];
  relevantSelectionLists: SelectionList[];
  treeFilterKey: TreeFilters;
}

export interface SkipSelectionListConfig {
  selectionListId: SelectionCategories | string;
}

/**
 * Process a filter group to find matching tree items from selection lists
 */
export const processFilterGroup = ({
  group,
  relevantSelectionLists,
  treeFilterKey,
  skipSelectionList,
}: ProcessFilterGroupArgs & {
  skipSelectionList?: SkipSelectionListConfig;
}): {
  processedFilters: Record<string, TreeItem>;
  unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'];
} => {
  const unknownFilters: TalentDiscoveryAiFilterSearchResponse['unknownFilters'] =
    [];

  const processedFilters = group?.reduce((acc, aiFilterSearchFilterLabel) => {
    const matchedTreeItems = findMatchingTreeItems(
      aiFilterSearchFilterLabel,
      relevantSelectionLists,
      skipSelectionList
    );

    if (matchedTreeItems.length > 0) {
      const closestTreeItem = getClosestTreeItem(matchedTreeItems);
      return {
        ...acc,
        [closestTreeItem.id]: closestTreeItem,
      };
    }

    unknownFilters.push({
      relevantPromptText: aiFilterSearchFilterLabel.relevantPromptText,
      explanation: getUnknownFilterExplanation(
        aiFilterSearchFilterLabel.name,
        TDResponseFilterToLabel[treeFilterKey]
      ),
      name: aiFilterSearchFilterLabel.name,
    });

    return acc;
  }, {});

  return { processedFilters, unknownFilters };
};
