import { mapKeys } from 'lodash';

import { PrimaryView } from '@revelio/core';
import {
  CustomRoleTaxonomySelection,
  Filters,
  PostingsDataQueryVariables,
} from '@revelio/data-access';
import {
  CustomRoleTaxonomyFilter,
  EnumeratedFilters,
  Filter,
  FilterItem,
  FilterName,
  FilterParameterKeys,
  GqlFilterKeyMapper,
  OtherFilterNames,
  SelectionCategories,
  ValidValueTypes,
  convertRoleToCustomRoleFilter,
  formatAndBreakoutFilters,
  mergeFiltersTogether,
} from '@revelio/filtering';

import { getDim1FromView } from './get-dim1-from-view';

type TransformFiltersToVariablesProps = {
  view: PrimaryView;
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
};

export const transformFiltersToVariables = ({
  view,
  filters,
  isCustomRoleTaxonomyEnabled,
}: TransformFiltersToVariablesProps): PostingsDataQueryVariables => {
  const customRoleFilter = filters.find(
    (filter) => filter.id === SelectionCategories.CUSTOM_ROLE
  ) as CustomRoleTaxonomyFilter | undefined;

  const hasCustomRoleFilter = !!customRoleFilter;

  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const shouldConvert =
    !hasCustomRoleFilter &&
    isCustomRoleTaxonomyEnabled &&
    view === PrimaryView.COMPANY;

  const processedFilters = shouldConvert
    ? convertRoleToCustomRoleFilter({
        formattedFilters: formattedFilter,
        customRoleTaxonomyId: filters.find(
          (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
        )?.value as FilterItem<CustomRoleTaxonomySelection>,
      })
    : formattedFilter;

  const mergedFilters = mergeFiltersTogether(
    processedFilters as EnumeratedFilters<ValidValueTypes | ValidValueTypes[]>,
    [[SelectionCategories.KEYWORD, SelectionCategories.KEYWORDS_CATEGORY]]
  );

  const mappedFilters: Filters = mapKeys(
    mergedFilters,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof mergedFilters;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  if (hasCustomRoleFilter && customRoleFilter?.value) {
    mappedFilters.custom_role = {
      taxonomyId: customRoleFilter.value.taxonomyId,
      entities: customRoleFilter.value.entities?.map((entity) => ({
        id: entity.id,
        levelId: entity.levelId,
      })),
    };
  }

  return {
    dim1: getDim1FromView(view),
    filters: mappedFilters,
  };
};
