import {
  CompositionV2DataQuery,
  PostingsDataQuery,
} from '@revelio/data-access';
import { LineChart, LineData, LineDatum } from '@revelio/replots';

import { MetricValues, SecondaryDimensionValues } from '../config-provider';
import { isLineMetric } from '../config-provider/utils';
import { ChartNotSupported } from './chart-not-supported';
import { getFormat, getNormalizedData } from './utils';

type LineProps = {
  data: CompositionV2DataQuery | PostingsDataQuery | undefined;
  metric: MetricValues;
  secondaryDimension?: SecondaryDimensionValues;
};

export const Line = ({ data, metric, secondaryDimension }: LineProps) => {
  if (!isLineMetric(metric)) return <ChartNotSupported />;

  const lineChartData = (() => {
    if (!data) return [];

    return getNormalizedData({ data, metric, secondaryDimension }).map(
      (d): LineData => {
        const { dimension, timeseries } = d;

        const lineValues: LineDatum[] = timeseries.map((timePoint) => ({
          date: timePoint.date,
          value: timePoint.value ?? null,
        }));

        return { label: dimension, values: lineValues };
      }
    );
  })();

  const format = getFormat({ metric, secondaryDimension });

  return <LineChart data={lineChartData} format={format} />;
};
