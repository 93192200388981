import { Link, Text } from '@chakra-ui/react';

import { FormatType } from '@revelio/replots';

import { TopPlotName } from './utils';

interface TopPlotConfig {
  name: TopPlotName;
  title: string;
  format: FormatType | null;
  infoPlacement?: 'top-start' | 'top-end';
  info?: React.ReactNode;
}

export const compositionTopPlotConfigV2 = (): TopPlotConfig[] => {
  return [
    {
      name: 'headcount',
      title: 'Headcount',
      format: null,
      infoPlacement: 'top-start',
      info: (
        <Text variant="tooltip">
          Our estimate of the total workforce. These counts include both formal
          employees and contingent workers. These counts also include
          subsidiaries. More information on the methodologies used for this
          metric can be found{' '}
          <Link
            href="https://www.data-dictionary.reveliolabs.com/methodology.html#sampling-weights"
            isExternal
            variant="tooltip"
          >
            here
          </Link>
          .
        </Text>
      ),
    },
    {
      name: 'growth_rate',
      title: 'Growth Rate',
      format: FormatType.PERCENTAGE,
      info: (
        <Text variant="tooltip">
          The growth rate is the percent change in the total workforce. It is
          the difference between the hiring rate and attrition rate.
        </Text>
      ),
    },
    {
      name: 'hiring_rate',
      title: 'Hiring Rate',
      format: FormatType.PERCENTAGE,
      info: (
        <Text variant="tooltip">
          The annual rate at which workers join, relative to the total
          headcount. It is the 12-month moving sum of inflows divided by the
          12-month moving average of headcount.
        </Text>
      ),
    },
    {
      name: 'attrition_rate',
      title: 'Attrition Rate',
      format: FormatType.PERCENTAGE,
      info: (
        <Text variant="tooltip">
          The annual rate at which workers leave, relative to the total
          headcount. It is the 12-month moving sum of outflows divided by the
          12-month moving average of headcount.
        </Text>
      ),
    },
    {
      name: 'tenure',
      title: 'Tenure',
      format: FormatType.YEAR,
      info: (
        <Text variant="tooltip">
          The average amount of time that workers have spent in their position.
        </Text>
      ),
    },
    {
      name: 'salary',
      title: 'Salary',
      format: FormatType.CURRENCY,
      infoPlacement: 'top-end',
      info: (
        <Text variant="tooltip">
          Our estimate of average salary. More information on the salary model
          can be found{' '}
          <Link
            href="https://www.data-dictionary.reveliolabs.com/methodology.html#salary"
            isExternal
            variant="tooltip"
          >
            here
          </Link>
          .
        </Text>
      ),
    },
  ];
};
