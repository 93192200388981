import {
  Box,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
} from '@chakra-ui/react';
import { Icon } from '@chakra-ui/react';
import { useState } from 'react';
import { FiChevronDown } from 'react-icons/fi';

import { PopoverTrigger } from '@revelio/core';
import { SelectionListControls, SelectionListSelect } from '@revelio/filtering';

import { Topic, groupedTopics } from './topics';
import styles from './topics-subfilter.module.css';

interface TopicsSubFilterProps {
  placeholderText: string;
  selectedTopics: Topic[];
  onSubmit: (topics: Topic[]) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  isMulti?: boolean;
}

export const TopicsSubFilter = ({
  placeholderText,
  selectedTopics,
  onSubmit,
  isOpen,
  onOpen,
  onClose,
  isMulti = false,
}: TopicsSubFilterProps) => {
  const [_selectedTopics, _setSelectedTopics] =
    useState<Topic[]>(selectedTopics);

  const noSelections = !_selectedTopics || _selectedTopics.length === 0;

  const handleOpen = () => {
    _setSelectedTopics(selectedTopics);
    onOpen();
  };

  const onChange = (selections: Topic[]) => {
    const newSelections = isMulti ? selections : selections.slice(-1);
    _setSelectedTopics(newSelections);
  };

  const clear = () => {
    _setSelectedTopics([]);
  };

  const handleSubmit = () => {
    if (noSelections) {
      return;
    }

    onSubmit(_selectedTopics);
    onClose();
  };

  return (
    <Popover
      placement="auto"
      isOpen={isOpen}
      onOpen={handleOpen}
      onClose={onClose}
      isLazy
    >
      <PopoverTrigger>
        <Button
          variant="outline"
          colorScheme="gray"
          color="text.primary"
          fontWeight="normal"
          borderColor="#E2E8F0"
          size="xs"
          fontSize="10px"
          rightIcon={<Icon boxSize={3} as={FiChevronDown} />}
          textOverflow="ellipsis"
          display="flex"
        >
          {placeholderText}
        </Button>
      </PopoverTrigger>
      <PopoverContent zIndex="max" boxShadow="2xl">
        <PopoverBody>
          <Box
            data-testid={`topics-subfilter-popover-body-${selectedTopics
              .map((topic) => topic.label)
              .join('-')}`}
          >
            <SelectionListSelect
              options={groupedTopics}
              value={_selectedTopics}
              onChange={(newValue) => onChange(newValue as Topic[])}
              formatGroupLabel={(group) => <div>{group.label}</div>}
              isMulti
            />
            <SelectionListControls
              onClear={clear}
              onSubmit={handleSubmit}
              onClose={onClose}
              submitText="Update"
              className={styles.selectionListControls}
              isSubmitDisabled={noSelections}
            />
          </Box>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
