import Fuse, { FuseResult, IFuseOptions } from 'fuse.js';
import { isArray, split } from 'lodash';

import { swapKeysWithValues, write } from '@revelio/core';

import {
  ALL_ROLE_FILTERS,
  GEOGRAPHY_GRANULARITY_FILTERS,
} from '../../engine/filters.constants';
import { DEFAULT_SELECTION_LIST_PARENT_MAP } from '../../engine/filters.engine';
import {
  FilterItem,
  Item,
  RoleSelectionCategories,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  TreeItem,
} from '../../engine/filters.model';

export const findSelectionListItemByItemId = ({
  itemId,
  selectionLists,
}: {
  itemId: string; // includes selection list in the id e.g. "role_k50.48"
  selectionLists: SelectionList[];
}): TreeItem | null => {
  const [itemSelectionlistId, itemValueIdToFind] = split(itemId, '.');

  const selectionListWithItem = selectionLists.find(
    (list) => list.id === itemSelectionlistId
  );

  if (!selectionListWithItem) {
    return null;
  }

  const selectionListValueById = selectionListWithItem.value.find(
    (item) => Number(item.id) === Number(itemValueIdToFind)
  ) as Item;

  if (!selectionListValueById) {
    return null;
  }

  return {
    item: selectionListValueById,
    id: itemId,
    parentId:
      selectionListWithItem.parent &&
      selectionListValueById[selectionListWithItem.parent as string] &&
      selectionListWithItem.parent +
        '.' +
        selectionListValueById[selectionListWithItem.parent as string],
    children: [],
    selectionListId: selectionListWithItem.id as SelectionListIdNames,
  };
};

type ProcessedRoleSelectionListItem = Omit<
  FilterItem,
  'topCleanedTitles' | 'topRawTitles'
> & {
  topCleanedTitles?: string | string[];
  topRawTitles?: string | string[];
};
type TreeItemWithClosenessScore = TreeItem & {
  closeness_score: number | undefined;
};
export const findSelectionListItemByLabel = ({
  labelToFind,
  selectionList,
  returnMultipleResults = false,
}: {
  labelToFind: string;
  selectionList: SelectionList;
  returnMultipleResults?: boolean;
}): TreeItemWithClosenessScore[] | null => {
  const keysToSearch = (() => {
    if (
      ALL_ROLE_FILTERS.includes(selectionList.id as RoleSelectionCategories)
    ) {
      return ['label', 'topCleanedTitles', 'topRawTitles'];
    }

    if (selectionList.id === SelectionCategories.RICS_K400) {
      return ['shortName', 'longName'];
    }

    return ['label'];
  })();

  // Preprocess the data to split comma-separated fields into arrays
  const preprocessedData = selectionList.value.map((item) => {
    const processedItem: ProcessedRoleSelectionListItem = { ...item };
    if (typeof processedItem.topCleanedTitles === 'string') {
      processedItem.topCleanedTitles =
        processedItem.topCleanedTitles.split(', ');
    }
    if (typeof processedItem.topRawTitles === 'string') {
      processedItem.topRawTitles = processedItem.topRawTitles.split(', ');
    }
    return processedItem;
  });

  const options: IFuseOptions<ProcessedRoleSelectionListItem> = {
    keys: keysToSearch,
    threshold: [...GEOGRAPHY_GRANULARITY_FILTERS, ...ALL_ROLE_FILTERS].includes(
      selectionList.id as SelectionCategories
    )
      ? 0.1
      : 0.2,
    includeScore: true,
    ignoreLocation: true,
    minMatchCharLength: 2,
  };

  const fuse = new Fuse<ProcessedRoleSelectionListItem>(
    preprocessedData,
    options
  );
  const results = fuse.search(labelToFind);

  if (results.length === 0) {
    return null;
  }

  if (returnMultipleResults) {
    return results.map((result) =>
      formatCloseMatchToTreeItem({ result, selectionList })
    );
  }

  return [formatCloseMatchToTreeItem({ result: results[0], selectionList })];
};

const formatCloseMatchToTreeItem = ({
  result,
  selectionList,
}: {
  result: FuseResult<ProcessedRoleSelectionListItem>;
  selectionList: SelectionList;
}): TreeItemWithClosenessScore => ({
  item: result.item as Item,
  id: selectionList.id + '.' + result.item.id,
  parentId:
    (result.item.parentId as string) &&
    selectionList.parent + '.' + result.item.parentId,
  children: [],
  selectionListId: selectionList.id as SelectionListIdNames,
  closeness_score: result.score,
});

export const getDefaultParentChildSelectionListMap = ({
  isDashboardFilterRoles,
}: {
  isDashboardFilterRoles: GetFilterChildSelectionListIdsArgs['isDashboardFilterRoles'];
}) => {
  const defaultSelectionListMap = write<
    typeof DEFAULT_SELECTION_LIST_PARENT_MAP
  >((selectionListMap) => {
    if (isDashboardFilterRoles) {
      // getting children of dashboard filters
      delete selectionListMap[SelectionCategories.ROLE_K50];
      delete selectionListMap[SelectionCategories.ROLE_K500];
    } else {
      // getting children of sub filters
      delete selectionListMap[SelectionCategories.ROLE_K150];
      delete selectionListMap[SelectionCategories.ROLE_K1500];
    }
  })(DEFAULT_SELECTION_LIST_PARENT_MAP);

  return swapKeysWithValues(defaultSelectionListMap);
};

type GetFilterChildSelectionListIdsArgs = {
  selectedFilterValues: string[];
  selectionLists: SelectionList[] | undefined;
  selectedFilterSelectionListId?: SelectionCategories | undefined;
  noSelectedFilterSelectionListId: SelectionCategories;
  isDashboardFilterRoles: boolean;
};
export const getFilterChildSelectionListIds = ({
  selectedFilterValues,
  selectionLists,
  selectedFilterSelectionListId,
  noSelectedFilterSelectionListId,
  isDashboardFilterRoles,
}: GetFilterChildSelectionListIdsArgs) => {
  const parentChildMap = getDefaultParentChildSelectionListMap({
    isDashboardFilterRoles,
  });

  return (selectionLists as SelectionList[])
    .find((list) => {
      const childSelectionListId = selectedFilterSelectionListId
        ? parentChildMap[selectedFilterSelectionListId]
        : noSelectedFilterSelectionListId;
      return list.id === childSelectionListId;
    })
    ?.value.filter((ent) => {
      if (selectedFilterSelectionListId) {
        const childSelectionListValue = (ent as any)[
          selectedFilterSelectionListId
        ];
        return selectedFilterValues.includes(
          isArray(childSelectionListValue)
            ? childSelectionListValue[0]
            : childSelectionListValue
        );
      }

      return true;
    })
    .map((entity) => entity.id) as string[];
};
