import { SelectionCategories } from '../filters.model';
import { useActiveFiltersState } from '../filters.repository';
import {
  getTaxonomyId,
  isCustomRoleFilterValue,
} from './convert-custom-role-taxonomy';
import { CustomRoleTaxonomyId } from './types';

export const useCurrentAdaptiveTaxonomyId = (): CustomRoleTaxonomyId | null => {
  const activeFilters = useActiveFiltersState();

  const customRoleFilter = activeFilters.find(
    (filter) => filter.id === SelectionCategories.CUSTOM_ROLE
  );

  if (isCustomRoleFilterValue(customRoleFilter?.value)) {
    return getTaxonomyId(customRoleFilter.value.taxonomyId);
  }

  return null;
};
