import {
  CompositionV2DataQuery,
  GetSentimentDataQuery,
  PostingsDataQuery,
} from '@revelio/data-access';
import { BarChart, BarData } from '@revelio/replots';

import { MetricValues } from '../config-provider';
import { isBarMetric } from '../config-provider/utils';
import { ChartNotSupported } from './chart-not-supported';
import { getFormat, getNormalizedData } from './utils';

type BarProps = {
  data:
    | CompositionV2DataQuery
    | PostingsDataQuery
    | GetSentimentDataQuery
    | undefined;
  metric: MetricValues;
};

export const Bar = ({ data, metric }: BarProps) => {
  if (!isBarMetric(metric)) return <ChartNotSupported />;

  const barChartData = ((): BarData[] => {
    if (!data) return [];

    return getNormalizedData({ data, metric }).map((d): BarData => {
      const { dimension, timeseries } = d;

      const lastTimeseries = timeseries[timeseries.length - 1];

      return { label: dimension, value: lastTimeseries?.value ?? null };
    });
  })();

  const format = getFormat({ metric });

  return <BarChart data={barChartData} format={format} />;
};
