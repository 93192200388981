import { OtherFilterNames } from '../filters.model';
import { useActiveFiltersState } from '../filters.repository';
import {
  getTaxonomyId,
  isCustomRoleTaxonomySelection,
} from './convert-custom-role-taxonomy';
import { CustomRoleTaxonomyId } from './types';

export const useActiveEntityTaxonomyId = (): CustomRoleTaxonomyId | null => {
  const activeFilters = useActiveFiltersState();

  const roleTaxonomyFilter = activeFilters.find(
    (filter) => filter.id === OtherFilterNames.ROLE_TAXONOMY
  );

  if (isCustomRoleTaxonomySelection(roleTaxonomyFilter?.value)) {
    return getTaxonomyId(roleTaxonomyFilter?.value);
  }

  return null;
};
