import { GetSentimentDataQuery } from '@revelio/data-access';

import { SentimentMetricValues } from '../../config-provider';
import { NormalizedData, NormalizedDataPoint } from './get-normalized-data';

type SentimentDataPoint = {
  weight: number;
};

type SentimentNormalizedData = NormalizedData<SentimentDataPoint>;

type GetSentimentNormalizedDataProps = {
  data: GetSentimentDataQuery;
  metric: SentimentMetricValues;
};
export const getSentimentNormalizedData = ({
  data,
  metric,
}: GetSentimentNormalizedDataProps): SentimentNormalizedData[] => {
  if (!data.sentiment) return [];

  return data.sentiment
    ?.map((row) => {
      if (!row?.metadata || !row?.result) return null;

      const { metadata, result, months_refs } = row;
      if (!metadata || !result || !months_refs) return null;

      const months = months_refs.map((m) => m?.shortName);

      const dimension = metadata.shortName;
      if (!dimension) return null;

      const scores = result.scores;

      if (!scores) return null;

      const metricScores = scores?.[metric];

      if (!metricScores) return null;

      const timeseries = metricScores
        .map((c): NormalizedDataPoint<SentimentDataPoint> | null => {
          if (!c?.value || !c.month_id || !c.weight) return null;

          const date = months[c.month_id];
          if (!date) return null;

          return { date, value: c.value / 100, weight: c.weight };
        })
        .filter((d) => d !== null);

      return { dimension, timeseries };
    })
    .filter((d) => d !== null);
};
