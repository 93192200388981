import { Box, Flex, FlexProps, keyframes } from '@chakra-ui/react';

import { RevelioTooltip } from '@revelio/layout';

import { AiSearchIcon } from './ai-search-icon';

interface AiSearchButtonProps extends FlexProps {
  isGenerating: boolean;
  tooltipLabel?: string;
}

export const DEAFAULT_AI_SEARCH_TOOLTIP_LABEL = 'AI Search';

export const AiSearchButton = ({
  isGenerating,
  tooltipLabel = DEAFAULT_AI_SEARCH_TOOLTIP_LABEL,
  ...rest
}: AiSearchButtonProps) => {
  const animation = `${gradientShift} 8s ease infinite`;
  const iconAnimation = `${zoomAnimation} 2s ease-in-out infinite`;
  const isAnimating = isGenerating;

  return (
    <RevelioTooltip label={tooltipLabel} placement="top-start">
      <Flex
        bgGradient="linear(to-r, #2674D1, #5AD0D0, #9ADF7A)"
        borderRadius="4px"
        p="2px"
        animation={isAnimating ? animation : undefined}
        backgroundSize={isAnimating ? '400% 400%' : undefined}
        role="button"
        {...rest}
        data-testid="edit-ai-search-button"
      >
        <Flex
          alignItems="center"
          gap={2}
          px={'5px'}
          py={1}
          bgGradient={
            isAnimating ? 'linear(to-r, #2F99FB, #6CF2F2, #B5FF9B)' : undefined
          }
          backgroundColor="white"
          backgroundSize="400% 400%"
          animation={isAnimating ? animation : undefined}
          borderRadius="3px"
          overflow="hidden"
        >
          <Box animation={isAnimating ? iconAnimation : undefined}>
            <AiSearchIcon />
          </Box>
        </Flex>
      </Flex>
    </RevelioTooltip>
  );
};

const gradientShift = keyframes`
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
`;

const zoomAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.18);
  }
  100% {
    transform: scale(1);
  }
`;
