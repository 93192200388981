import {
  SelectionCategories,
  TreeItem,
  findSelectionListItemByItemId,
  findSelectionListItemByLabel,
} from '@revelio/filtering';

import { locationMapperApi } from '../mapping-apis';
import {
  ProcessFilterGroupArgs,
  processFilterGroup,
} from './process-filter-group';

/**
 * Process geography filters with special handling for comma-separated locations
 * and falling back to the location mapper API
 */
export const processGeographyFiltersWithMapperApiFallback = async (
  params: Omit<ProcessFilterGroupArgs, 'treeFilterKey'> & {
    treeFilterKey: 'geography';
  }
) => {
  // First try regular matching, skipping metro areas
  const { processedFilters, unknownFilters } = processFilterGroup({
    ...params,
    skipSelectionList: { selectionListId: SelectionCategories.METRO_AREA },
  });

  // Process comma-separated geography locations
  const commaFilters = params.group.filter((filter) =>
    filter.name.includes(',')
  );

  for (const commaFilter of commaFilters) {
    const hasFoundNonMetroAreaTextMatch = !unknownFilters.some(
      (unknown) => unknown.name === commaFilter.name
    );
    if (hasFoundNonMetroAreaTextMatch) {
      continue;
    }

    const metroAreaSelectionLists = params.relevantSelectionLists.filter(
      (list) => list.id === SelectionCategories.METRO_AREA
    );

    // AI search returns {geography_location}, {country} but we only want to label match on the most granular location
    const partialGeoLabel = commaFilter.name.split(',')[0].trim();

    const allMatchResults: Array<TreeItem & { closeness_score?: number }> = [];
    for (const selectionList of metroAreaSelectionLists) {
      const matches = findSelectionListItemByLabel({
        labelToFind: partialGeoLabel,
        selectionList,
        returnMultipleResults: true,
      });

      if (matches) {
        matches.forEach((match) => {
          allMatchResults.push({
            ...match,
            selectionListId: selectionList.id,
          });
        });
      }
    }

    allMatchResults.sort(
      (a, b) => (a.closeness_score || 1) - (b.closeness_score || 1)
    );

    if (allMatchResults.length > 0) {
      const topMatch = allMatchResults[0];

      // Check if the metro area name is ambiguous (multiple metros with the same label)
      const isMetroAreaAmbiguous =
        allMatchResults.filter(
          (match) => match.item?.label === topMatch.item?.label
        ).length > 1;

      if (!isMetroAreaAmbiguous) {
        processedFilters[topMatch.id] = topMatch;

        // Remove from unknown filters
        const unknownIndex = unknownFilters.findIndex(
          (item) => item.name === commaFilter.name
        );
        if (unknownIndex !== -1) {
          unknownFilters.splice(unknownIndex, 1);
        }
      }
    }
  }

  // For remaining unknown filters, use the location mapper API
  const fallbackMapperFiltersFromUnknown = unknownFilters.map(
    (unknownFilter) => unknownFilter.name
  );

  if (fallbackMapperFiltersFromUnknown.length === 0) {
    return { processedFilters, unknownFilters };
  }

  // Directly call the location mapper API
  const apiResponse = await locationMapperApi({
    locationsToMap: fallbackMapperFiltersFromUnknown,
  });

  // Process location mapper API response
  Object.values(apiResponse).forEach((locationItem) => {
    const metroAreaSelectionList = params.relevantSelectionLists.find(
      (selectionList) => selectionList.id === SelectionCategories.METRO_AREA
    );

    if (metroAreaSelectionList) {
      const selectionListItem = findSelectionListItemByItemId({
        itemId: `${SelectionCategories.METRO_AREA}.${locationItem.metro_area_id}`,
        selectionLists: [metroAreaSelectionList],
      });

      if (selectionListItem) {
        // Find and remove the unknown filter
        const unknownFilterIndex = unknownFilters.findIndex(
          (filter) => filter.name === locationItem.location
        );
        if (unknownFilterIndex !== -1) {
          unknownFilters.splice(unknownFilterIndex, 1);
        }

        // Add the matched item to processed filters
        if (selectionListItem.id) {
          processedFilters[selectionListItem.id] = selectionListItem;
        }
      }
    }
  });

  return { processedFilters, unknownFilters };
};
