import { Flex, Grid } from '@chakra-ui/layout';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { SentimentSubFilterNames } from '@revelio/filtering';

import { Topic, defaultTopics, topics } from '../../topics-subfilter/topics';
import { transformToSingleEntity } from '../../utils/transform-to-single-entity';
import { SingleEntityPlotCard } from './single-entity-plot-card';
import { TopicPlotCard } from './topic-plot-card';
import { PlotGrid, SentimentEffectsChartsProps } from './types';

export const SentimentEffectsCharts = (
  props: SentimentEffectsChartsProps & { colors?: string[] }
) => {
  const [selectedTopics, setSelectedTopics] = useState<Topic[]>([]);

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (!topics.length) return;

    const topicsParam = searchParams.get(SentimentSubFilterNames.SUB_TOPICS);

    if (topicsParam) {
      const topicIds = topicsParam.split(',');
      const topicsFromParams = topicIds
        .map((id) => topics.find((topic) => topic.id.toString() === id))
        .filter((topic): topic is Topic => topic !== undefined);
      if (topicsFromParams.length) {
        setSelectedTopics(topicsFromParams);
        return;
      }
    }

    // Fallback: update search params with default topics
    const defaultTopicIds = defaultTopics.map((topic) => topic.id.toString());
    const newParams = new URLSearchParams(searchParams);
    newParams.set(
      SentimentSubFilterNames.SUB_TOPICS,
      defaultTopicIds.join(',')
    );
    setSearchParams(newParams);
    setSelectedTopics(defaultTopics);
  }, [searchParams, setSearchParams]);

  const updateTopicAtIndex = (index: number, newTopic: Topic) => {
    setSelectedTopics((prev) => {
      return prev.map((topic, i) => (i === index ? newTopic : topic));
    });
  };

  const plotGrids: PlotGrid[] = (() => {
    if (props.viewType === 'snapshot') {
      return selectedTopics.map((topic, index) => ({
        topic,
        chartType: 'bar',
        data: props.data[topic.value],
        index,
      }));
    }
    if (props.viewType === 'overtime') {
      return selectedTopics.map((topic, index) => ({
        topic,
        chartType: 'line',
        data: props.data[topic.value],
        index,
      }));
    }
    return [];
  })();

  const { min, max } = plotGrids.reduce(
    (acc, grid) => {
      if (grid.chartType === 'bar') {
        return {
          min: Math.min(
            acc.min,
            ...grid.data.map((d) => d.value).filter((v) => v !== null)
          ),
          max: Math.max(
            acc.max,
            ...grid.data.map((d) => d.value).filter((v) => v !== null)
          ),
        };
      }
      if (grid.chartType === 'line') {
        const values = grid.data.flatMap((d) =>
          d.values.map((v) => v.value).filter((v) => v !== null)
        );
        return {
          min: Math.min(acc.min, ...values),
          max: Math.max(acc.max, ...values),
        };
      }

      return acc;
    },
    { min: Infinity, max: -Infinity }
  );

  const adjustedMin = Math.max(min - 0.1, 0);

  const singleEntityView = props.colors?.length === 1;

  const onSubmit = (topics: Topic[], index: number) => {
    const topic = topics[0];
    updateTopicAtIndex(index, topic);
    const newParams = new URLSearchParams(searchParams);

    // Retrieve the current sub_topics value
    const currentTopics =
      newParams.get(SentimentSubFilterNames.SUB_TOPICS) || '';

    const topicsArray = currentTopics.split(',');

    // Update the topic at the specified index
    topicsArray[index] = topic.id.toString();

    // Set the updated topics back to the search params
    newParams.set(SentimentSubFilterNames.SUB_TOPICS, topicsArray.join(','));

    setSearchParams(newParams);
  };

  if (singleEntityView && props.viewType === 'snapshot') {
    const plotData = transformToSingleEntity(props.data);

    const [min, max] = [Math.min, Math.max].map((fn) =>
      fn(...plotData.data.map((d) => ('value' in d ? d.value ?? 0 : 0)))
    );

    // Ensure chart always includes zero on the y-axis
    // When both min and max values are negative, the chart data doesn't cross
    // zero, causing a buggy chart. By forcing the max value
    // to include zero, we ensure the chart displays correctly with proper scaling
    // and maintains the zero baseline for better data interpretation.
    const adjustedMax = min < 0 && max < 0 ? 0 : max;

    return (
      <Flex
        borderRadius="md"
        border="1px solid #e5ebf1"
        minWidth="0"
        minHeight="0"
        w="100%"
        h="100%"
      >
        <SingleEntityPlotCard
          plotGrid={plotData}
          minValue={min}
          maxValue={adjustedMax}
          colors={props.colors}
        />
      </Flex>
    );
  }

  return (
    <Grid
      templateColumns="repeat(3, 1fr)"
      templateRows="repeat(3, 1fr)"
      gap={4}
      w="100%"
      h="100%"
    >
      {plotGrids.map((plotGrid, i) => (
        <Flex
          key={`${plotGrid.topic.value}-${i}`}
          borderRadius="md"
          border="1px solid #e5ebf1"
          minWidth="0"
          minHeight="0"
        >
          {plotGrid && (
            <TopicPlotCard
              plotGrid={plotGrid}
              selectedTopics={[selectedTopics[i]]}
              onSubmit={(topics) => onSubmit(topics, i)}
              minValue={adjustedMin}
              maxValue={max}
              colors={props.colors}
            />
          )}
        </Flex>
      ))}
    </Grid>
  );
};
