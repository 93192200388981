import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Swapy, createSwapy } from 'swapy';

import { useResizeObserver } from '@revelio/core';

import { Chart } from '..';
import { PositionedItem, useConfig } from '../config-provider';
import { SwapySlotResizer, TempResize } from './resizer';
import styles from './swapy-grid.module.css';
import { SlotPosition } from './types';
import { GRID_HEIGHT, GRID_WIDTH, getSlotItems } from './utils';

export const SwapyGrid = () => {
  const { config, dispatch } = useConfig();
  const items = config.items;

  const setItems = useCallback(
    (
      fn: PositionedItem[] | ((items: PositionedItem[]) => PositionedItem[])
    ) => {
      if (Array.isArray(fn)) {
        dispatch({ type: 'SET_ITEMS', payload: fn });
      } else {
        dispatch({ type: 'SET_ITEMS', payload: fn(items) });
      }
    },
    [dispatch, items]
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const swapyRef = useRef<Swapy | null>(null);

  const {
    containerRef: shadowGridRef,
    width: shadowGridWidth,
    // height: shadowGridHeight,
  } = useResizeObserver();

  const singleSlotWidth = (shadowGridWidth - 5 * 16) / 6;

  useEffect(() => {
    const containerElement = containerRef.current;
    if (!containerElement) return;

    swapyRef.current = createSwapy(containerElement, {
      manualSwap: true,
      swapMode: 'drop',
    });

    return () => {
      swapyRef.current?.destroy();
    };
  }, []);

  const slotItems = useMemo(() => getSlotItems(items), [items]);

  const duplicateKeyRef = useRef(false);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Alt' || event.key === 'Meta') {
        duplicateKeyRef.current = true;
      }
    };
    const handleKeyUp = (event: KeyboardEvent) => {
      if (event.key === 'Alt' || event.key === 'Meta') {
        duplicateKeyRef.current = false;
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  useEffect(() => {
    if (!swapyRef.current) return;
    swapyRef.current.onSwapStart((event) => {
      setDraggingItem(event.draggingItem);
    });
    swapyRef.current.onSwapEnd((event) => {
      setDraggingItem(null);
    });
    swapyRef.current.onSwap((event) => {
      const {
        draggingItem: draggingItemId,
        fromSlot: fromSlotId,
        swappedWithItem: swappedWithItemId,
        toSlot: toSlotId,
      } = event;

      const fromSlot = slotItems.find((slot) => slot.slotId === fromSlotId);
      const toSlot = slotItems.find((slot) => slot.slotId === toSlotId);
      if (duplicateKeyRef.current) {
        setItems((prevItems) => {
          const draggingItem = prevItems.find(
            (item) => item.id === draggingItemId
          );
          if (!draggingItem || !toSlot) return prevItems;
          else {
            const newItems: PositionedItem[] = [
              ...prevItems,
              {
                ...draggingItem,
                id: crypto.randomUUID(),
                position: toSlot.position,
              },
            ];
            return newItems;
          }
        });
      } else {
        setItems((prevItems) => {
          const newItems = prevItems.map((item) => {
            if (item.id === draggingItemId && toSlot) {
              return {
                ...item,
                position: swappedWithItemId
                  ? toSlot.position
                  : {
                      ...toSlot.position,
                      width: Math.min(
                        item.position.width,
                        GRID_WIDTH + 1 - toSlot.position.x
                      ),
                      height: Math.min(
                        item.position.height,
                        GRID_HEIGHT + 1 - toSlot.position.y
                      ),
                    },
              };
            }
            if (item.id === swappedWithItemId && fromSlot) {
              return { ...item, position: fromSlot.position };
            }
            return item;
          });
          return newItems;
        });
      }
    });
  }, [slotItems, setItems]);

  const handleAddItem = (position: SlotPosition) => {
    dispatch({ type: 'ADD_BLANK_ITEM', payload: { position } });
  };

  const [tempSize, setTempSize] = useState<TempResize | null>(null);
  const [updateSize, setUpdateSize] = useState(false);
  const [draggingItem, setDraggingItem] = useState<string | null>(null);

  useEffect(() => {
    requestAnimationFrame(() => {
      swapyRef.current?.update();
    });

    /** Temp solution to re-render the item when swapy is updated */
    const interval = setInterval(() => {
      setUpdateSize((prev) => !prev);
    }, 12);
    setTimeout(() => {
      setUpdateSize(false);
      clearInterval(interval);
    }, 400);

    return () => clearInterval(interval);
  }, [slotItems]);

  return (
    <div className={styles.gridContainer}>
      <div className={styles.grid} ref={containerRef}>
        {slotItems.map(({ slotId, item, position }) => (
          <div
            className={classNames(styles.gridSlot, {
              [styles.showAllSlots]: !!tempSize,
            })}
            style={{
              gridColumn: `${position.x} / span ${position.width}`,
              gridRow: `${position.y} / span ${position.height}`,
            }}
            key={slotId}
            data-swapy-slot={slotId}
            onClick={() => {
              if (!item) handleAddItem(position);
            }}
          >
            {item && (
              <div
                className={styles.gridItem}
                data-swapy-item={item.id}
                key={item.id}
                style={
                  // eslint-disable-next-line no-nested-ternary
                  tempSize && tempSize.id === item.id
                    ? {
                        transform: `translateX(${tempSize?.x}px) translateY(${tempSize?.y}px)`,
                        width: `calc(100% + ${tempSize?.width}px)`,
                        height: `calc(100% + ${tempSize?.height}px)`,
                      }
                    : updateSize
                      ? { width: `calc(100% + .1px)` }
                      : {}
                }
              >
                <Chart
                  id={item.id}
                  title={item.title}
                  isDragging={draggingItem === item.id}
                />
              </div>
            )}
          </div>
        ))}
      </div>
      <div className={styles.shadowGrid} ref={shadowGridRef}>
        {slotItems.map(
          (slotItem) =>
            slotItem.item && (
              <div
                className={styles.shadowGridSlot}
                style={{
                  gridColumn: `${slotItem.position.x} / span ${slotItem.position.width}`,
                  gridRow: `${slotItem.position.y} / span ${slotItem.position.height}`,
                }}
                key={slotItem.slotId}
              >
                <SwapySlotResizer
                  slotItem={slotItem}
                  items={items}
                  singleUnitWidth={singleSlotWidth}
                  singleUnitHeight={160}
                  setItems={setItems}
                  setTempResize={setTempSize}
                />
              </div>
            )
        )}
      </div>
    </div>
  );
};
