import { ComponentProps } from 'react';

import { FeatureFlag } from '@revelio/core';

import { useUnleashFlag } from '../../shared/unleash-flags/use-unleash-flag';
import { Overview } from '../overview/overview';
import { OverviewV2 } from '../overview/overview-v2';

export const CompositionMigration = ({
  ...rest
}: ComponentProps<typeof Overview>) => {
  const useCompositionV2 = useUnleashFlag(FeatureFlag.CompositionV2);

  if (useCompositionV2) {
    return <OverviewV2 {...rest} />;
  }
  return <Overview {...rest} />;
};
