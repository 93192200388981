import { Button, ButtonGroup, Tooltip } from '@chakra-ui/react';
import classNames from 'classnames';

import styles from './SelectionListControls.module.css';

type SelectionListControlsProps = {
  onClear?: () => void;
  onSubmit: () => void;
  onClose?: () => void;
  clearText?: string;
  submitText?: string;
  isSubmitDisabled?: boolean;
  className?: string;
  tooltipText?: string;
};

export const SelectionListControls = ({
  onClear,
  onSubmit,
  onClose,
  clearText = 'Clear Selections',
  submitText = 'Add',
  className,
  isSubmitDisabled,
  tooltipText,
}: SelectionListControlsProps) => {
  return (
    <div className={classNames(styles.selectionListControls, className)}>
      {onClear && (
        <Button size="xs" variant="ghost" colorScheme="red" onClick={onClear}>
          {clearText}
        </Button>
      )}
      <ButtonGroup>
        <Button
          size="xs"
          variant="link"
          colorScheme="gray"
          padding="0 .5rem"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Tooltip
          isDisabled={!isSubmitDisabled}
          label={tooltipText || 'Please make the minimum required selections'}
          hasArrow
        >
          <Button
            data-testid="filter-popover-submit"
            size="xs"
            variant="solid"
            colorScheme="green"
            padding="0 1rem"
            height="2rem"
            onClick={onSubmit}
            isDisabled={isSubmitDisabled}
            title={tooltipText}
          >
            {submitText}
          </Button>
        </Tooltip>
      </ButtonGroup>
    </div>
  );
};
