import { deleteAllEntities, selectAllEntities } from '@ngneat/elf-entities';
import { useEffect$ } from '@ngneat/react-rxjs';
import {
  capitalize,
  compact,
  difference,
  find,
  get,
  has,
  includes,
  isArray,
  isBoolean,
  isEmpty,
  isEqual,
  isFinite,
  isUndefined,
  mapKeys,
  mapValues,
  omit,
  pick,
  reduce,
  set,
  without,
} from 'lodash';
import { parse, stringify } from 'query-string';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { combineLatest, distinctUntilChanged, filter, map, tap } from 'rxjs';
import { JsonValue } from 'type-fest';

import { PrimaryFilters, Views } from '@revelio/core';
import {
  CustomRoleFilter,
  CustomRoleTaxonomySelection,
  POSTING_SOURCE_IDS,
  User,
} from '@revelio/data-access';

import { ViewTypes, ViewsForDefaultsOnly } from '../data-api/data-api.model';
import {
  MaxDateRangeToDefault,
  getDatesAreWeekly,
} from '../filter-components/date-range/helpers';
import { TextFilters } from '../filter-components/filter-menu/keyword-filters/keyword-filters';
import { getPostingsSourceOptions } from '../filter-components/filter-menu/tree-filters/posting-source-options';
import { TreeData, findEntityInTaxonomy } from '../filter-components/tree';
import {
  ALL_ROLE_FILTERS,
  FilterStateDefaultsMap,
  FiltersUsedInSelectSubfilters,
  FiltersUsedInTabs,
  ROLE_GRANULARITY_FILTERS,
  SkillsFilterStateDefaultMap,
  filterLabelLookup,
  metricModeFilterStateDefault,
} from './filters.constants';
import {
  ALL_DATE_FILTER_IDS,
  ALL_SUPPORTED_FILTERS,
} from './filters.deepLinks.model';
import {
  filterStore,
  globalPageLoadingStatus,
  upsertFiltersWithProvidedValue,
} from './filters.engine';
import {
  CustomRoleFilterEntity,
  Filter,
  FilterItem,
  FilterList,
  FilterName,
  FilterOrSubfilterName,
  FilterTypes,
  LocalSelectionCategories,
  OtherFilterNames,
  RangeFilter,
  RoleSelectionCategories,
  SelectFilter,
  SelectableCategories,
  SelectionCategories,
  SelectionList,
  SelectionListIdNames,
  SentimentSubFilterNames,
  SubFilterNames,
  ValidValueTypes,
} from './filters.model';
import { useAllFiltersState } from './filters.repository';
import { buildFilters } from './filters.serialize';
import { getRoleTaxonomyValueFromDeepLink } from './role-taxonomy/get-adaptive-role-taxonomy-id';
import { getDeepLinkTaxonomyId } from './selection-lists/convert-custom-role-taxonomy';

type FilterCategories = SelectionCategories | LocalSelectionCategories;

export const getProviderDefault = (userHasWebsitePostings: boolean) => {
  return {
    [LocalSelectionCategories.PROVIDER]: {
      isMulti: true,
      value: [
        (() => {
          if (userHasWebsitePostings) {
            return {
              id: POSTING_SOURCE_IDS.otherWebsitePostings,
              label: 'Website Postings (other)',
              shortName: 'Website Postings (other)',
            };
          }

          return {
            id: POSTING_SOURCE_IDS.unified,
            label: 'Unified (COSMOS)',
            shortName: 'Unified (COSMOS)',
          };
        })(),
      ],
      selectionListId: LocalSelectionCategories.PROVIDER,
    },
  };
};

interface UseInitializePrimaryFilterProps {
  primaryFilter: PrimaryFilters;
}

export const useInitializePrimaryFilter = ({
  primaryFilter,
}: UseInitializePrimaryFilterProps) => {
  useEffect(() => {
    upsertFiltersWithProvidedValue(
      {
        [SelectionCategories.PRIMARY_FILTER]: primaryFilter,
      },
      true
    );
  }, [primaryFilter]);
};

interface GetFilterDeeplinksProps {
  activeFilters: Filter[];
  syncToPrimaryEntities?: boolean;
  primaryFilters: FilterCategories[];
}
export const getDeeplinkFromFilters = ({
  activeFilters,
  syncToPrimaryEntities,
  primaryFilters,
}: GetFilterDeeplinksProps) => {
  const primaryFilterValues = {};

  const filtersToAddToUrl = activeFilters.filter((item) => {
    if (syncToPrimaryEntities) {
      if (
        primaryFilters?.includes(
          item.id as LocalSelectionCategories | SelectionCategories
        )
      ) {
        set(primaryFilterValues, item.id, item);
        return false;
      }

      if (
        primaryFilters?.includes(
          get(item, 'selectionListId') as
            | LocalSelectionCategories
            | SelectionCategories
        )
      ) {
        set(primaryFilterValues, get(item, 'selectionListId'), item);
        return false;
      }
    }

    /** if filter name in default set and filter value is not default value, allow filter */
    if (item.id in FilterStateDefaultsMap) {
      return !isEqual(item.value, FilterStateDefaultsMap[item.id]?.value);
    }

    /** if filter name in default skills set and filter value is not default skills value, allow filter */
    if (item.id in SkillsFilterStateDefaultMap) {
      return !isEqual(item.value, SkillsFilterStateDefaultMap[item.id]?.value);
    }

    const onlyAddUserSubmittedFilters = [
      LocalSelectionCategories.PROVIDER,
      LocalSelectionCategories.METRIC_MODE,
    ];
    if (
      onlyAddUserSubmittedFilters.includes(item.id as LocalSelectionCategories)
    ) {
      return item.isUserSubmitted;
    }

    if (item.id === OtherFilterNames.ROLE_TAXONOMY) {
      // we only care about saving role taxonomy in deep link instead of calculating
      // when role from specific taxonomy set
      // and the primary filters might have different adaptive taxonomy
      const hasRoleFilterSet = activeFilters.filter((item) =>
        includes(ROLE_GRANULARITY_FILTERS, item.id)
      ).length;
      return hasRoleFilterSet;
    }

    /** if item is not primary_filter or grouped, allow filter */
    return ![SelectionCategories.PRIMARY_FILTER, OtherFilterNames.GROUPED].some(
      (filterName) => filterName === item.id
    );
  });

  const serializedFilters = filtersToAddToUrl.reduce((acc, filterEntity) => {
    const filterId = filterEntity.id;
    if (
      includes(
        [SelectionCategories.DATE_RANGE, SelectionCategories.DATE_RANGE_FULL],
        filterId
      )
    ) {
      if ((filterEntity as RangeFilter).isMaximumRange) {
        // Only add the date range if it's an explicitly set filter rather than just the maximum default
        return acc;
      }

      const value = (filterEntity as RangeFilter).value;
      return {
        ...acc,
        ...(filterId === SelectionCategories.DATE_RANGE_FULL
          ? mapKeys(value, (_, key) => `${key}Full`) // necessary so that there aren't clashing url search params since both Date range and Date range full have the same key in the filter value
          : value),
      };
    }

    if (filterId === OtherFilterNames.ROLE_TAXONOMY) {
      const roleTaxonomyValue =
        filterEntity.value as CustomRoleTaxonomySelection;
      const roleTaxonomyType = Object.keys(
        roleTaxonomyValue
      )[0] as keyof CustomRoleTaxonomySelection;
      return {
        ...acc,
        [filterId]: `${roleTaxonomyType}__${roleTaxonomyValue[roleTaxonomyType]}`,
      };
    }

    if (filterId === SelectionCategories.CUSTOM_ROLE) {
      const filterValue = filterEntity.value as CustomRoleFilter;
      const levelId = filterValue.entities?.[0]?.levelId;
      if (!filterValue.taxonomyId) return acc;

      const taxonomyId = getDeepLinkTaxonomyId(filterValue.taxonomyId);

      if (levelId && taxonomyId) {
        const filterId =
          levelId === SelectionCategories.ROLE_K7
            ? SelectionCategories.JOB_CATEGORY
            : levelId;
        const entityIds = filterValue.entities
          ?.map((entity) => entity?.id)
          .filter(Boolean)
          .join(',');

        return {
          ...acc,
          [filterId]: entityIds,
          [OtherFilterNames.ROLE_TAXONOMY]: taxonomyId,
        };
      }

      return acc;
    }

    if (
      syncToPrimaryEntities &&
      filterId == LocalSelectionCategories.PRIMARY_ENTITIES &&
      isArray(filterEntity.value)
    ) {
      return {
        ...acc,
        [filterEntity.id]: filterEntity.value.map((item: any) => {
          const activeEntity = find(
            Object.values(primaryFilterValues),
            (ent: any) => {
              return ent.selectionListId == item.selectionListId;
            }
          );

          const activeEntityValues = get(activeEntity, 'value', []);

          const foundActiveEntityItem = find(activeEntityValues, (val: any) => {
            return val.id == item.id;
          });

          const isActive = !!foundActiveEntityItem;

          return `${item.selectionListId}__${item.id}__${isActive}`;
        }),
      };
    }

    if (
      Object.values(LocalSelectionCategories).includes(
        filterEntity.id as LocalSelectionCategories
      ) &&
      (filterEntity.id as LocalSelectionCategories) !=
        LocalSelectionCategories.PROVIDER &&
      (filterEntity.id as LocalSelectionCategories) !=
        LocalSelectionCategories.OVERALL_RATING && // OVERALL_RATING should be handled as a multi filter
      !isBoolean(filterEntity.value) // IS_UNIVERSITY needs to use the value like other filters
    ) {
      return {
        ...acc,
        [filterEntity.id]: (filterEntity.value as FilterItem).id,
      };
    }

    // for multi filters
    if (isArray(filterEntity.value)) {
      return {
        ...acc,
        [filterEntity.id]: filterEntity.value.map((item) => {
          if (TextFilters.includes(filterEntity.id as SelectionCategories)) {
            return (item as FilterItem)?.value;
          }
          return (item as FilterItem)?.id;
        }),
      };
    }

    return {
      ...acc,
      [filterEntity.id]: filterEntity.value,
    };
  }, {});

  return stringify(serializedFilters, {
    arrayFormat: 'comma',
  });
};

const PARAMS_TO_PRESERVE = [
  SentimentSubFilterNames.SUB_REVIEWS_POSITIVE,
  SentimentSubFilterNames.SUB_REVIEWS_NEGATIVE,
  SentimentSubFilterNames.SUB_TOPICS,
];

interface UseSyncFiltersToSearchParamsProps {
  primaryFilters: (SelectionListIdNames | OtherFilterNames)[];
  syncToPrimaryEntities?: boolean;
  isLoading?: boolean;
  paramsToPreserve?: string[];
}

export const useSyncFiltersToSearchParamsPure = ({
  primaryFilters,
  syncToPrimaryEntities = false,
  isLoading,
  paramsToPreserve = [],
}: UseSyncFiltersToSearchParamsProps) => {
  const navigate = useNavigate();
  const location = useLocation();

  const activeFilters = useAllFiltersState();

  useEffect(() => {
    if (isLoading) return;

    const hasActivePrimaryFilters = !!activeFilters.find((f) =>
      primaryFilters.some(
        (requiredFilter) =>
          requiredFilter === f.id ||
          requiredFilter === get(f, 'selectionListId')
      )
    );
    if (!hasActivePrimaryFilters) return;

    const searchParams = new URLSearchParams(window.location.search);
    const preservedParams = [...PARAMS_TO_PRESERVE, ...paramsToPreserve].reduce(
      (acc, param) => ({
        ...acc,
        [param]: searchParams.get(param),
      }),
      {}
    );

    const newFilterDeepLinkUrlSearch = getDeeplinkFromFilters({
      activeFilters,
      primaryFilters: primaryFilters as FilterCategories[],
      syncToPrimaryEntities,
    });

    // Combine new filters with review params if they exist
    const finalSearchParams = new URLSearchParams(newFilterDeepLinkUrlSearch);

    // Add back preserved params if they exist
    Object.entries(preservedParams).forEach(([key, value]) => {
      if (value) {
        finalSearchParams.set(key, value.toString());
      }
    });

    const finalUrlSearch = `?${decodeURIComponent(finalSearchParams.toString())}`;

    if (finalUrlSearch !== window.location.search) {
      navigate(window.location.pathname + finalUrlSearch);
    }
  }, [
    activeFilters,
    isLoading,
    navigate,
    primaryFilters,
    syncToPrimaryEntities,
    location,
    paramsToPreserve,
  ]);
};

/** When filters update, serialize filter state into string and insert into url */
/** When filters update, serialize filter state into string and insert into url */

export const getPrimaryEntityMapping = (
  deepLinkFilters: SearchParamFilters,
  limit = 6
) => {
  const primaryEntities = get(
    deepLinkFilters,
    LocalSelectionCategories.PRIMARY_ENTITIES,
    []
  );

  const primaryEntitiesArray = Array.isArray(primaryEntities)
    ? primaryEntities
    : [primaryEntities];

  const selectionLists = new Set();

  const transformedPrimaryEntitiesArray = primaryEntitiesArray.map(
    (entityId) => {
      const [listId, id, isActive] = entityId.split('__');

      selectionLists.add(listId);

      // isActive is a string, compare to convert to boolean
      if (limit <= 0 && isActive == 'true') {
        return [listId, id, false].join('__');
      }

      if (isActive == 'true') {
        limit -= 1;
      }

      return entityId;
    }
  );

  const primaryEntityMapping = transformedPrimaryEntitiesArray?.reduce(
    (allEntities: any, entityId: string) => {
      const [listId, id, isActive] = entityId.split('__');

      // isActive is a string, compare to convert to boolean
      if (isActive == 'false') {
        return allEntities;
      }

      if (has(allEntities, listId)) {
        allEntities[listId].push(id);
      } else {
        allEntities[listId] = [id];
      }

      return allEntities;
    },
    {}
  );

  return [primaryEntityMapping, primaryEntitiesArray, selectionLists];
};

export const buildPrimaryEntities = (
  primaryEntities: string[],
  selectionLists: SelectionList<ValidValueTypes>[]
) => {
  const primaryEntityValues = primaryEntities.reduce(
    (allEntities: any, entityId: string) => {
      // default isActive value to true if deeplink missing active boolean
      const [selectionListId, id, isActive = 'true'] = entityId.split('__');

      const relevantList = selectionLists.find((list) => {
        return list.id == selectionListId;
      });

      const foundItem = relevantList?.value.find((val) => {
        return val.id == id;
      });

      if (foundItem) {
        return [
          ...allEntities,
          { ...foundItem, selectionListId, isActive: isActive === 'true' },
        ];
      }

      return allEntities;
    },
    []
  );

  return {
    id: LocalSelectionCategories.PRIMARY_ENTITIES,
    isMulti: true,
    type: FilterTypes.SELECT,
    value: primaryEntityValues,
  };
};
export type SearchParamFilters = {
  [x in SelectionListIdNames | OtherFilterNames]: string | string[];
};

const getFilterValuesWithSelectionListId = (
  deepLinkFiltersWithSelectionList: Partial<SearchParamFilters>
): {
  [x: string]: {
    value: string | string[];
    selectionListId: SelectionListIdNames | undefined;
  };
} => {
  const getMultiGranularitySelectionListId = (
    queryParamFilterId: FilterOrSubfilterName
  ) => {
    if (queryParamFilterId === SubFilterNames.SUB_SKILL_OVERTIME) {
      if (deepLinkFiltersWithSelectionList[SelectionCategories.SKILL_K75]) {
        return SelectionCategories.SKILL_K700;
      }

      if (deepLinkFiltersWithSelectionList[SelectionCategories.SKILL_K700]) {
        return SelectionCategories.SKILL_K3000;
      }
    }

    if (queryParamFilterId === SubFilterNames.SUB_ROLE) {
      if (deepLinkFiltersWithSelectionList[SelectionCategories.JOB_CATEGORY]) {
        return SelectionCategories.ROLE_K50;
      }

      if (deepLinkFiltersWithSelectionList[SelectionCategories.ROLE_K150]) {
        return SelectionCategories.ROLE_K500;
      }
    }

    if (queryParamFilterId === SubFilterNames.SUB_REGION) {
      if (deepLinkFiltersWithSelectionList[SelectionCategories.REGION]) {
        return SelectionCategories.COUNTRY;
      }

      if (deepLinkFiltersWithSelectionList[SelectionCategories.COUNTRY]) {
        return SelectionCategories.METRO_AREA;
      }
    }

    if (queryParamFilterId === SubFilterNames.SUB_INDUSTRY) {
      if (deepLinkFiltersWithSelectionList[SelectionCategories.RICS_K10]) {
        return SelectionCategories.RICS_K50;
      }

      if (deepLinkFiltersWithSelectionList[SelectionCategories.RICS_K50]) {
        return SelectionCategories.RICS_K50;
      }
    }

    return FilterStateDefaultsMap[queryParamFilterId as FilterOrSubfilterName]
      ?.selectionListId;
  };

  return mapValues(
    deepLinkFiltersWithSelectionList,
    (value, queryParamFilterId) => {
      const searchParamValue = value as string | string[];
      if ((queryParamFilterId as string) in FilterStateDefaultsMap) {
        return {
          value: searchParamValue,
          selectionListId: getMultiGranularitySelectionListId(
            queryParamFilterId as FilterOrSubfilterName
          ),
        };
      }

      if ((queryParamFilterId as string) in SkillsFilterStateDefaultMap) {
        return {
          value: searchParamValue,
          selectionListId:
            SkillsFilterStateDefaultMap[
              queryParamFilterId as FilterOrSubfilterName
            ]?.selectionListId,
        };
      }

      return {
        value: searchParamValue,
        selectionListId: queryParamFilterId as SelectionListIdNames,
      };
    }
  );
};

export const setSearchParamFiltersPure = ({
  searchParamFilters,
  limits,
  primaryFilters,
  selectionLists,
  taxonomyData = [],
  loggedInUser,
  view,
  viewType,
  replaceCurrentFilters = false,
}: {
  searchParamFilters: URLSearchParams | undefined;
  limits?: {
    [key in SelectableCategories]?: number;
  };
  primaryFilters?: (SelectionListIdNames | OtherFilterNames)[];
  selectionLists?: SelectionList<ValidValueTypes>[];
  taxonomyData: TreeData[];
  loggedInUser: User;
  view: Views | ViewsForDefaultsOnly;
  viewType?: ViewTypes;
  replaceCurrentFilters?: boolean;
}) => {
  if (!searchParamFilters?.toString() || !selectionLists || !loggedInUser) {
    // use api defaults if no search params.
    return null;
  }

  const deepLinkFilters = parse(searchParamFilters.toString(), {
    arrayFormat: 'comma',
  }) as SearchParamFilters;
  const supportedFiltersForPage = [
    ...ALL_SUPPORTED_FILTERS,
    ...ALL_DATE_FILTER_IDS, // always included so the date filters persist when navigating between pages doesn't lose the date filters
  ];

  const supportedDeepLinkFilters = pick(
    deepLinkFilters,
    supportedFiltersForPage
  );

  const primaryEntitiesDefinedInDeeplink = get(
    deepLinkFilters,
    LocalSelectionCategories.PRIMARY_ENTITIES
  );

  const isPrimaryEntitiesDefined = !isUndefined(
    primaryEntitiesDefinedInDeeplink
  );

  // if page supports filter consistency, but primary entities are not
  // defined in the deeplink, try to create them with the primary filter
  // values that are defined

  if (!isPrimaryEntitiesDefined) {
    const formattedPrimaryEntities: any = [];

    const primaryFiltersDefinedInDeeplink = pick(
      supportedDeepLinkFilters,
      primaryFilters || []
    );

    Object.entries(primaryFiltersDefinedInDeeplink).forEach(([key, value]) => {
      const typedValue = value as string | string[];
      const isValidSelectionListId = primaryFilters?.includes(
        key as SelectionListIdNames | OtherFilterNames
      );

      const entityIds = Array.isArray(typedValue) ? typedValue : [typedValue];

      entityIds.forEach((id) => {
        const isValidId = isFinite(Number(id));

        if (!isValidSelectionListId || !isValidId) return;

        formattedPrimaryEntities.push(`${key}__${id}__${true}`);
      });
    });

    // no valid fallbacks defined for primary entities, revert to defaults
    if (formattedPrimaryEntities.length == 0) {
      // Handle invalid deeplink as needed
      return false;
    }

    set(
      deepLinkFilters,
      LocalSelectionCategories.PRIMARY_ENTITIES,
      formattedPrimaryEntities
    );
  }

  const [primaryEntityMapping, primaryEntitiesArray] = getPrimaryEntityMapping(
    deepLinkFilters,
    get(limits, LocalSelectionCategories.PRIMARY_ENTITIES, 6)
  );

  const allDeepLinkFilters = {
    ...supportedDeepLinkFilters,
    ...primaryEntityMapping,
  };

  const filtersWithoutSelectionList = without(
    [...Object.values(LocalSelectionCategories), ...TextFilters],
    ...FiltersUsedInTabs,
    LocalSelectionCategories.PRIMARY_ENTITIES,
    LocalSelectionCategories.PROVIDER,
    LocalSelectionCategories.METRIC_MODE,
    LocalSelectionCategories.OVERALL_RATING
  );

  const deepLinkFiltersWithSelectionList = omit(allDeepLinkFilters, [
    ...filtersWithoutSelectionList,
    ...ALL_DATE_FILTER_IDS,
  ]);

  // this is so we can add the selectionListId for subfilters where the name of the filter is different to the selection list name
  const deepLinkFiltersWithSelectionListIds =
    getFilterValuesWithSelectionListId(deepLinkFiltersWithSelectionList);

  if ('sub_role' in deepLinkFiltersWithSelectionListIds) {
    const subRole = deepLinkFiltersWithSelectionListIds?.['sub_role'];
    if (
      subRole.selectionListId === 'role_k50' ||
      subRole.selectionListId === 'role_k500'
    ) {
      delete deepLinkFiltersWithSelectionListIds['sub_role'];
    }
  }

  const requiredSelectionListIds = Object.values(
    deepLinkFiltersWithSelectionListIds
  ).reduce<string[]>((acc, filter) => {
    if (
      filter.selectionListId &&
      filter.selectionListId !== LocalSelectionCategories.PROVIDER
    ) {
      return [...acc, filter.selectionListId];
    }
    return acc;
  }, []);

  // Check if all required selection lists are present
  const hasRequiredSelectionLists =
    difference(
      requiredSelectionListIds.filter(
        (id) => id !== LocalSelectionCategories.PROVIDER
      ),
      selectionLists.map((list) => list.id)
    ).length === 0;

  if (
    isEmpty(deepLinkFiltersWithSelectionListIds) ||
    !hasRequiredSelectionLists
  ) {
    return null;
  }

  const deepLinkFilterDates = pick(
    supportedDeepLinkFilters,
    ALL_DATE_FILTER_IDS
  );

  const baseViewFilters = getViewDefaultFilters({ view, user: loggedInUser });

  const postingsOptions = loggedInUser
    ? getPostingsSourceOptions(loggedInUser).map((option) => {
        return { id: Number(option.value), ...option };
      })
    : [];

  const filtersToBuild = {
    ...getSearchParamFilterValueFromSelectionList({
      searchParamFilters: deepLinkFiltersWithSelectionListIds,
      selectionLists,
      taxonomyData,
      postingsOptions,
      viewType,
    }),
    ...deepLinkFilterDates,
  };

  const hasCompanyFilters = get(filtersToBuild, SelectionCategories.COMPANY);

  const companyFiltersLookupSuccessfull =
    compact(hasCompanyFilters || []).length > 0;

  if (hasCompanyFilters && !companyFiltersLookupSuccessfull) {
    // Handle invalid company filters as needed
    return false;
  }

  const toInsertFiltersArray = buildFilters(
    filtersToBuild,
    // this map builds the sub filters with the correct selectionListId which checks the correct values in the plot sub filter tree
    {
      filterSelectionListMap: mapValues(
        baseViewFilters,
        'selectionListId'
      ) as JsonValue,
    },
    view
  );

  if (!isEmpty(primaryEntitiesArray)) {
    const builtPrimaryEntities = buildPrimaryEntities(
      primaryEntitiesArray,
      selectionLists
    );

    toInsertFiltersArray.push(builtPrimaryEntities as Filter);
  }

  const roleTaxonomyDeepLinkValue =
    getRoleTaxonomyValueFromDeepLink(searchParamFilters);
  if (roleTaxonomyDeepLinkValue) {
    toInsertFiltersArray.push({
      id: OtherFilterNames.ROLE_TAXONOMY,
      label: OtherFilterNames.ROLE_TAXONOMY,
      type: FilterTypes.SELECT,
      value: roleTaxonomyDeepLinkValue,
    } as Filter);
  }

  const toInsertObject = toInsertFiltersArray.reduce(
    (object: { [key in FilterName]?: Partial<Filter> }, filter) => {
      object[filter.id] = filter;
      // without this, it won't apply the the provider/metric mode filters
      if (
        [
          LocalSelectionCategories.PROVIDER,
          LocalSelectionCategories.METRIC_MODE,
        ].includes(filter.id as LocalSelectionCategories)
      ) {
        (object[filter.id] as SelectFilter<FilterItem>).isUserSubmitted = true;
      }

      if (filter.id === LocalSelectionCategories.N_ITEMS_SANKEY) {
        (object[filter.id] as SelectFilter<FilterItem>).isMulti = false;
      }

      return object;
    },
    {
      // set all the plot sub filters depending on the view's defaults
      ...baseViewFilters,
    }
  );

  const deepLinkFiltersWithoutSelectionList = pick(
    supportedDeepLinkFilters,
    filtersWithoutSelectionList
  );

  const localfilters = getLocalSelectionCategoryFilters(
    deepLinkFiltersWithoutSelectionList as {
      [x in LocalSelectionCategories]: string;
    }
  );

  const textFilters = getTextFilters(
    pick(deepLinkFiltersWithoutSelectionList, TextFilters) as {
      [x in SelectionCategories]: string[];
    }
  );

  const staticFiltersMap = {
    ...toInsertObject,
    ...localfilters,
    ...textFilters,
  } as { [key in FilterName]: Filter };

  if (replaceCurrentFilters) {
    filterStore.update(deleteAllEntities());
    setDefaultDateRangeFiltersIfMissing({
      staticFiltersMap,
      view: view as Views,
    });

    if (view === Views.TRANSITION) {
      setNItemsSankeyFilterIfMissing({ staticFiltersMap });
    }
  }

  upsertFiltersWithProvidedValue(staticFiltersMap);

  return staticFiltersMap;
};

const setDefaultDateRangeFiltersIfMissing = ({
  staticFiltersMap,
  view,
}: {
  staticFiltersMap: { [key in FilterName]: Filter };
  view: Views;
}) => {
  const dateRangeFilter = get(staticFiltersMap, SelectionCategories.DATE_RANGE);
  const dateRangeFullFilter = get(
    staticFiltersMap,
    SelectionCategories.DATE_RANGE_FULL
  );
  const snapshotDateFilter = get(
    staticFiltersMap,
    SelectionCategories.SNAPSHOT_DATE
  );

  if (!dateRangeFilter || !dateRangeFullFilter || !snapshotDateFilter) {
    const dateRangeDefaultFilter = MaxDateRangeToDefault(
      view,
      undefined,
      undefined,
      SelectionCategories.DATE_RANGE
    );

    const datesAreWeekly = getDatesAreWeekly(view);

    if (datesAreWeekly) {
      const dateRangeFullDefaultFilter = MaxDateRangeToDefault(
        view,
        undefined,
        undefined,
        SelectionCategories.DATE_RANGE_FULL
      );

      upsertFiltersWithProvidedValue(dateRangeFullDefaultFilter, false);
    }

    upsertFiltersWithProvidedValue(dateRangeDefaultFilter, false);
  }
};

const setNItemsSankeyFilterIfMissing = ({
  staticFiltersMap,
}: {
  staticFiltersMap: { [key in FilterName]: Filter };
}) => {
  const nItemsSankeyFilter = get(
    staticFiltersMap,
    LocalSelectionCategories.N_ITEMS_SANKEY
  );
  if (!nItemsSankeyFilter) {
    upsertFiltersWithProvidedValue({
      [LocalSelectionCategories.N_ITEMS_SANKEY]: 10,
    });
  }
};

const extractRoleEntitiesFromDeepLink = ({
  deepLinkFilters,
  taxonomyData,
}: {
  deepLinkFilters: any;
  taxonomyData: TreeData[];
}) => {
  const entities: CustomRoleFilterEntity[] = [];

  ALL_ROLE_FILTERS.forEach((filter) => {
    if (deepLinkFilters[filter]) {
      const roleIds = Array.isArray(deepLinkFilters[filter].value)
        ? deepLinkFilters[filter].value
        : [deepLinkFilters[filter].value];

      roleIds.forEach((id: string) => {
        if (id !== undefined) {
          const levelId =
            filter === SelectionCategories.JOB_CATEGORY
              ? SelectionCategories.ROLE_K7
              : filter;

          const entity = findEntityInTaxonomy(taxonomyData, id, levelId);
          entities.push({ id: id, levelId, label: entity?.name ?? '' });
        }
      });
    }
  });

  return entities;
};

const processCustomRoleFromDeepLink = ({
  deepLinkFilters,
  taxonomyData,
}: {
  deepLinkFilters: Partial<SearchParamFilters>;
  taxonomyData: TreeData[];
}) => {
  const searchParams = new URLSearchParams(window.location.search);
  const taxonomySelection = getRoleTaxonomyValueFromDeepLink(searchParams);

  if (!taxonomySelection) {
    return null;
  }

  const entities = extractRoleEntitiesFromDeepLink({
    deepLinkFilters,
    taxonomyData,
  });
  if (entities.length === 0) {
    return null;
  }

  return {
    entities: entities,
    taxonomyId: taxonomySelection,
  };
};

const getSearchParamFilterValueFromSelectionList = ({
  searchParamFilters,
  selectionLists,
  taxonomyData,
  postingsOptions,
  viewType,
}: {
  searchParamFilters: ReturnType<typeof getFilterValuesWithSelectionListId>;
  selectionLists: SelectionList<ValidValueTypes>[];
  taxonomyData: TreeData[];
  postingsOptions: { id: number; value: string }[] | [];
  viewType?: ViewTypes;
}) => {
  const result = Object.keys(searchParamFilters).reduce(
    (filterWithValues: { [key: string]: any }, selectionListId: string) => {
      //  Refactored adaptive taxonomy custom role filter is only supported on company pages
      if (
        viewType === ViewTypes.COMPANY &&
        ALL_ROLE_FILTERS.includes(selectionListId as RoleSelectionCategories)
      ) {
        const customRoleFilter = processCustomRoleFromDeepLink({
          deepLinkFilters: searchParamFilters,
          taxonomyData,
        });
        if (customRoleFilter) {
          filterWithValues[SelectionCategories.CUSTOM_ROLE] = customRoleFilter;
        }
        return filterWithValues;
      }

      const filterSelectionList = selectionLists.find(
        (selectionList) =>
          selectionList.id ===
          searchParamFilters[selectionListId].selectionListId
      );

      const filterValuesToReduce = isArray(
        searchParamFilters[selectionListId].value
      )
        ? (searchParamFilters[selectionListId].value as string[])
        : [searchParamFilters[selectionListId].value as string];

      const filterValues = reduce(
        filterValuesToReduce,
        (acc: any, queryParamFilterid: any) => {
          if (selectionListId === LocalSelectionCategories.PROVIDER) {
            const providerSelectionListValue = postingsOptions.find(
              (option) => option.value === queryParamFilterid
            );

            if (providerSelectionListValue) {
              return [
                ...acc,
                {
                  ...providerSelectionListValue,
                  value: Number(providerSelectionListValue.value),
                },
              ];
            }

            return acc;
          }

          const foundSelectionListItem = filterSelectionList?.value.find(
            (selectionListValue) =>
              selectionListValue.id === Number(queryParamFilterid) ||
              selectionListValue.id === queryParamFilterid
          );

          if (foundSelectionListItem) {
            return [...acc, foundSelectionListItem];
          }

          return acc;
        },
        []
      );

      const isArrayValue = [
        ...(FiltersUsedInTabs as string[]),
        ...(FiltersUsedInSelectSubfilters as string[]),
        LocalSelectionCategories.DATA_METRIC,
        LocalSelectionCategories.METRIC_MODE,
      ].includes(selectionListId);

      if (
        selectionListId === LocalSelectionCategories.PROVIDER &&
        filterValues.length === 0
      ) {
        return filterWithValues;
      }

      return {
        ...filterWithValues,
        [selectionListId]: isArrayValue ? filterValues[0] : filterValues,
      };
    },
    {}
  );

  return result;
};

const getTextFilters = (deepLinkTextFilters: {
  [key in SelectionCategories]: string[];
}) => {
  return (Object.keys(deepLinkTextFilters) as SelectionCategories[])?.reduce<{
    [key in SelectionCategories]?: SelectFilter<any>;
  }>((toInsertObject, filterId) => {
    const filterValue = deepLinkTextFilters[filterId];
    const valuesArray = isArray(filterValue) ? filterValue : [filterValue];

    toInsertObject[filterId] = {
      id: filterId,
      label: filterLabelLookup[filterId] || filterId,
      isMulti: true,
      type: FilterTypes.SELECT,
      selectionListId: filterId,
      value: valuesArray.map((value: string) => {
        const cleanedValue = value.trim().toString();
        return {
          label: cleanedValue,
          value: cleanedValue,
          __isNew__: true,
        };
      }),
    };

    return toInsertObject;
  }, {});
};

const getLocalSelectionCategoryFilters = (deepLinkFiltersWithoutSelectionList: {
  [key in LocalSelectionCategories]: string;
}) => {
  return (
    Object.keys(
      deepLinkFiltersWithoutSelectionList
    ) as LocalSelectionCategories[]
  ).reduce<{
    [key in LocalSelectionCategories]?: SelectFilter<
      FilterItem<ValidValueTypes>
    >;
  }>((toInsertObject, filterId) => {
    toInsertObject[filterId] = {
      id: filterId,
      label: filterId,
      type: FilterTypes.SELECT,
      value: {
        id: deepLinkFiltersWithoutSelectionList[filterId],
        label: capitalize(deepLinkFiltersWithoutSelectionList[filterId]),
        shortName: deepLinkFiltersWithoutSelectionList[filterId],
        index: [ViewTypes.SNAPSHOT, 'inflow', ViewTypes.BASE_SALARY].includes(
          deepLinkFiltersWithoutSelectionList[filterId]
        )
          ? 0
          : 1,
      },
      isMulti: false,
    };

    if (
      [
        LocalSelectionCategories.POSTING_METRIC,
        LocalSelectionCategories.PROVIDER,
      ].includes(filterId)
    ) {
      (toInsertObject[filterId] as SelectFilter).value = {
        id: toInsertObject[filterId]?.value.id as string,
        label: toInsertObject[filterId]?.value.label,
        value: deepLinkFiltersWithoutSelectionList[filterId], // data is replaced by value
      };
    }

    if (filterId === LocalSelectionCategories.N_ITEMS_SANKEY) {
      (toInsertObject[filterId] as SelectFilter).value = {
        id: Number(
          (toInsertObject[filterId] as SelectFilter<FilterItem>).value.id
        ),
        label: deepLinkFiltersWithoutSelectionList[filterId], // not capitalised
        // don't need any other keys
      };
      (toInsertObject[filterId] as SelectFilter).selectionListId = filterId;
    }

    return toInsertObject;
  }, {});
};

export const useSyncFiltersToSearchParams = ({
  primaryFilters,
  syncToPrimaryEntities = false,
}: UseSyncFiltersToSearchParamsProps) => {
  const navigate = useNavigate();

  useEffect$(() => {
    const loctionState = { pathname: window.location.pathname };
    return combineLatest({
      globalPageLoadingStatus,
      filters: filterStore.pipe(selectAllEntities()),
    }).pipe(
      filter(({ globalPageLoadingStatus: { areWeLoading } }) => {
        // when navigating away from a page globalPageLoadingStatus changes once and causes stale url state within
        // setSearchParams to navigate back wrongly
        if (loctionState.pathname != window.location.pathname) {
          loctionState.pathname = window.location.pathname;
          return false;
        }

        // only set filter search params once page is loaded so it doesn't overwrite them as filter store is being populated
        return areWeLoading === false;
      }),
      map(({ filters }) => filters),
      distinctUntilChanged(),
      // only set filters if the required filters are set to not have broken filter states (e.g. no primary filter)
      filter((filters) =>
        // TODO: jbellizzi - look for better way to compare id type to required filter
        {
          return !!filters.find((f) =>
            primaryFilters.some(
              (requiredFilter) =>
                requiredFilter === f.id ||
                requiredFilter === get(f, 'selectionListId')
            )
          );
        }
      ),
      tap((allFilters: Filter[]) => {
        const primaryFilterValues = {};

        const filtersToAddToUrl = allFilters.filter((item) => {
          if (syncToPrimaryEntities) {
            if (
              primaryFilters?.includes(
                item.id as LocalSelectionCategories | SelectionCategories
              )
            ) {
              set(primaryFilterValues, item.id, item);
              return false;
            }

            if (
              primaryFilters?.includes(
                get(item, 'selectionListId') as
                  | LocalSelectionCategories
                  | SelectionCategories
              )
            ) {
              set(primaryFilterValues, get(item, 'selectionListId'), item);
              return false;
            }
          }

          /** if filter name in default set and filter value is not default value, allow filter */
          if (item.id in FilterStateDefaultsMap) {
            return !isEqual(item.value, FilterStateDefaultsMap[item.id]?.value);
          }

          /** if filter name in default skills set and filter value is not default skills value, allow filter */
          if (item.id in SkillsFilterStateDefaultMap) {
            return !isEqual(
              item.value,
              SkillsFilterStateDefaultMap[item.id]?.value
            );
          }

          if (item.id === OtherFilterNames.ROLE_TAXONOMY) {
            // we only care about saving role taxonomy in deep link instead of calculating
            // when role from specific taxonomy set
            // and the primary filters might have different adaptive taxonomy
            const hasRoleFilterSet = (
              allFilters as SelectFilter<FilterList>[]
            ).filter((item) =>
              includes(ROLE_GRANULARITY_FILTERS, item.id)
            ).length;
            return hasRoleFilterSet;
          }

          /** if item is not primary_filter or grouped, allow filter */
          return ![
            SelectionCategories.PRIMARY_FILTER,
            OtherFilterNames.GROUPED,
          ].some((filterName) => filterName === item.id);
        });

        const serializedFilters = filtersToAddToUrl.reduce(
          (acc, filterEntity) => {
            const filterId = filterEntity.id;
            if (
              includes(
                [
                  SelectionCategories.DATE_RANGE,
                  SelectionCategories.DATE_RANGE_FULL,
                ],
                filterId
              )
            ) {
              if ((filterEntity as RangeFilter).isMaximumRange) {
                // Only add the date range if it's an explicitly set filter rather than just the maximum default
                return acc;
              }

              const value = (filterEntity as RangeFilter).value;
              return {
                ...acc,
                ...(filterId === SelectionCategories.DATE_RANGE_FULL
                  ? mapKeys(value, (_, key) => `${key}Full`) // necessary so that there aren't clashing url search params since both Date range and Date range full have the same key in the filter value
                  : value),
              };
            }

            if (filterId === OtherFilterNames.ROLE_TAXONOMY) {
              const roleTaxonomyValue =
                filterEntity.value as CustomRoleTaxonomySelection;
              const roleTaxonomyType = Object.keys(
                roleTaxonomyValue
              )[0] as keyof CustomRoleTaxonomySelection;

              return {
                ...acc,
                [filterId]: `${roleTaxonomyType}__${roleTaxonomyValue[roleTaxonomyType]}`,
              };
            }

            if (
              syncToPrimaryEntities &&
              filterId == LocalSelectionCategories.PRIMARY_ENTITIES &&
              isArray(filterEntity.value)
            ) {
              return {
                ...acc,
                [filterEntity.id]: filterEntity.value.map((item: any) => {
                  const activeEntity = find(
                    Object.values(primaryFilterValues),
                    (ent: any) => {
                      return ent.selectionListId == item.selectionListId;
                    }
                  );

                  const activeEntityValues = get(activeEntity, 'value', []);

                  const foundActiveEntityItem = find(
                    activeEntityValues,
                    (val: any) => {
                      return val.id == item.id;
                    }
                  );

                  const isActive = !!foundActiveEntityItem;

                  return `${item.selectionListId}__${item.id}__${isActive}`;
                }),
              };
            }

            if (
              Object.values(LocalSelectionCategories).includes(
                filterEntity.id as LocalSelectionCategories
              ) &&
              (filterEntity.id as LocalSelectionCategories) !=
                LocalSelectionCategories.PROVIDER &&
              !isBoolean(filterEntity.value) // IS_UNIVERSITY needs to use the value like other filters
            ) {
              return {
                ...acc,
                [filterEntity.id]: (filterEntity.value as FilterItem).id,
              };
            }

            // for multi filters
            if (isArray(filterEntity.value)) {
              return {
                ...acc,
                [filterEntity.id]: filterEntity.value.map((item) => {
                  if (
                    TextFilters.includes(filterEntity.id as SelectionCategories)
                  ) {
                    return (item as FilterItem)?.value;
                  }
                  return (item as FilterItem)?.id;
                }),
              };
            }

            return {
              ...acc,
              [filterEntity.id]: filterEntity.value,
            };
          },
          {}
        );

        const newFilterDeepLink = stringify(serializedFilters, {
          arrayFormat: 'comma',
        });

        if (
          newFilterDeepLink !==
          decodeURIComponent(
            new URLSearchParams(window.location.search).toString()
          )
        ) {
          navigate(window.location.pathname + '?' + newFilterDeepLink);
        }
      })
    );
  }, []);
};

export const getViewDefaultFilters = ({
  view,
  user,
}: {
  view: Views | ViewsForDefaultsOnly;
  user: User;
}) => {
  const userHasWebsitePostings = user?.linkup_postings;
  const defaultFiltersByView: {
    [key in Views | ViewsForDefaultsOnly]?: {
      [key in FilterOrSubfilterName]?: Partial<SelectFilter>;
    };
  } = {
    [Views.POSTING]: {
      ...metricModeFilterStateDefault,
      ...getProviderDefault(!!userHasWebsitePostings),
    },
    [Views.OVERVIEW]: {
      ...FilterStateDefaultsMap,
    },
  };

  return defaultFiltersByView[view] ?? {};
};
