import { mapKeys } from 'lodash';

import { PrimaryView } from '@revelio/core';
import {
  CompensationFilters,
  CustomRoleTaxonomySelection,
  PostingFilters,
} from '@revelio/data-access';
import {
  CustomRoleTaxonomyFilter,
  Filter,
  FilterItem,
  FilterName,
  FilterParameterKeys,
  FilterTypes,
  GqlFilterKeyMapper,
  LocalSelectionCategories,
  OtherFilterNames,
  SelectionCategories,
  UNIFIED_POSTING_SOURCE_OPTION,
  convertRoleToCustomRoleFilter,
  formatAndBreakoutFilters,
} from '@revelio/filtering';

/** ======== Custom Role Taxonomy Filter ======== */
const isCustomRoleFilter = (
  filter: Filter
): filter is CustomRoleTaxonomyFilter =>
  filter.id === SelectionCategories.CUSTOM_ROLE && 'value' in filter;

export const transformFiltersToPayQuery = ({
  filters,
  isCustomRoleTaxonomyEnabled,
  primaryView,
}: {
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
  primaryView: PrimaryView;
}): CompensationFilters => {
  const { formattedFilter } = formatAndBreakoutFilters(filters, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const taxonomyId = filters.find(
    (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
  )?.value as FilterItem<CustomRoleTaxonomySelection>;
  const formattedFiltersWithCustomRole = {
    ...formattedFilter,
    custom_role: { taxonomyId: taxonomyId || { default: true } },
  };

  const mappedFilters: CompensationFilters = mapKeys(
    isCustomRoleTaxonomyEnabled && primaryView === PrimaryView.COMPANY
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  const customRoleFilters: Pick<
    CompensationFilters,
    'job_category' | 'role_k150' | 'role_k1500' | 'custom_role'
  > = (() => {
    const customRoleFilter = filters.find(isCustomRoleFilter);
    if (customRoleFilter) {
      const roleFilters = customRoleFilter.value.entities.reduce<
        Pick<CompensationFilters, 'job_category' | 'role_k150' | 'role_k1500'>
      >((acc, entity) => {
        if (entity.levelId === 'role_k7') {
          acc.job_category = [
            ...(acc.job_category || []),
            entity.id,
          ] as unknown as number[];
        } else if (entity.levelId === 'role_k150') {
          acc.role_k150 = [
            ...(acc.role_k150 || []),
            entity.id,
          ] as unknown as number[];
        } else if (entity.levelId === 'role_k1500') {
          acc.role_k1500 = [
            ...(acc.role_k1500 || []),
            entity.id,
          ] as unknown as number[];
        }
        return acc;
      }, {});

      return {
        ...roleFilters,
        custom_role: {
          taxonomyId: customRoleFilter.value?.taxonomyId,
          entities: customRoleFilter.value?.entities?.map((entity) => ({
            id: entity?.id,
            levelId: entity?.levelId,
          })),
        },
      };
    }
    return {};
  })();

  return { ...mappedFilters, ...customRoleFilters };
};

export const transformFiltersToPostingsQuery = ({
  filters,
  isCustomRoleTaxonomyEnabled,
  primaryView,
}: {
  filters: Filter[];
  isCustomRoleTaxonomyEnabled: boolean;
  primaryView: PrimaryView;
}) => {
  const filtersWithProvider: Filter[] = [
    ...filters,
    {
      id: LocalSelectionCategories.PROVIDER,
      value: [
        {
          id: Number(UNIFIED_POSTING_SOURCE_OPTION.value),
          label: UNIFIED_POSTING_SOURCE_OPTION.label,
        },
      ],
      isMulti: true,
      label: LocalSelectionCategories.PROVIDER,
      selectionListId: LocalSelectionCategories.PROVIDER,
      type: FilterTypes.SELECT,
    },
  ] as Filter[];

  const { formattedFilter } = formatAndBreakoutFilters(filtersWithProvider, [
    SelectionCategories.PRIMARY_FILTER,
    OtherFilterNames.ROLE_TAXONOMY,
  ]);

  const formattedFiltersWithCustomRole = convertRoleToCustomRoleFilter({
    formattedFilters: formattedFilter,
    customRoleTaxonomyId: filters.find(
      (f) => f.id === OtherFilterNames.ROLE_TAXONOMY
    )?.value as FilterItem<CustomRoleTaxonomySelection>,
  });

  const mappedFilters: CompensationFilters = mapKeys(
    isCustomRoleTaxonomyEnabled && primaryView === PrimaryView.COMPANY
      ? formattedFiltersWithCustomRole
      : formattedFilter,
    (_v, key): FilterName | FilterParameterKeys => {
      const typedKey = key as keyof typeof formattedFilter;
      return GqlFilterKeyMapper[typedKey] || typedKey;
    }
  );

  const customRoleFilters: Pick<PostingFilters, 'custom_role'> = (() => {
    const customRoleFilter = filters.find(isCustomRoleFilter);
    if (customRoleFilter) {
      return {
        custom_role: {
          taxonomyId: customRoleFilter.value?.taxonomyId,
          entities: customRoleFilter.value?.entities?.map((entity) => ({
            id: entity?.id,
            levelId: entity?.levelId,
          })),
        },
      };
    }
    return {};
  })();

  return { ...mappedFilters, ...customRoleFilters };
};
