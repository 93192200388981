import { format as d3Format, formatLocale, min, precisionFixed } from 'd3';
import { isNil } from 'lodash';

import { Format, FormatType } from '../types';

const convertGigaToBillion = (
  formatter: (value: number) => string
): ((value: number) => string) => {
  return (value) => formatter(value).replace('G', 'B');
};

const DefaultPrecision = 1;
const MaxPrecision = 2;

type PrecisionConfigProps = {
  values?: number[];
  increasePrecision?: number;
  currencyCode?: string;
};

export const getCurrencySymbol = (
  currencyCode?: string | null,
  locale = 'en-US'
) => {
  if (isNil(currencyCode) || currencyCode === 'USD') return '$';
  return (
    new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: currencyCode,
    })
      .formatToParts(0)
      .find((part) => part.type === 'currency')?.value ?? '$'
  );
};

export const getLocaleConfig = (currencySymbol: string) => {
  return formatLocale({
    decimal: '.',
    thousands: ',',
    grouping: [3],
    currency: [currencySymbol, ''],
  });
};

export const currencyFormatter = (
  formatString: string,
  currencyCode?: string | null
) => {
  const currencySymbol = getCurrencySymbol(currencyCode);
  const locale = getLocaleConfig(currencySymbol);
  return locale.format(formatString);
};

export const formatterWithExchangeRate = (
  currencyCode?: string | null,
  exchangeRate = 1
) => {
  return (value: number) => {
    if (typeof value !== 'number') return value;
    return currencyFormatter('$.2s', currencyCode)(value * exchangeRate);
  };
};

export const getFormatter = (
  format: Format,
  { values, increasePrecision = 0, currencyCode }: PrecisionConfigProps = {}
): ((value: number) => string) => {
  if (typeof format === 'function') return format;

  switch (format) {
    case FormatType.SI:
      return (value: number) =>
        Math.abs(value) < 1000
          ? d3Format(',.0f')(value)
          : convertGigaToBillion(d3Format('.2~s'))(value);

    case FormatType.PERCENTAGE: {
      const minStep = values
        ? min(values.slice(1).map((val, i) => Math.abs(val - values[i])))
        : undefined;

      let precision = DefaultPrecision;

      if (minStep !== undefined) {
        const scaledMinStep = minStep * 100;
        precision = Math.max(
          DefaultPrecision,
          precisionFixed(scaledMinStep) - DefaultPrecision
        );
      }

      precision += increasePrecision;
      precision = Math.min(MaxPrecision, precision);

      return increasePrecision
        ? d3Format(`.${precision}%`)
        : d3Format(`.${precision}~%`);
    }
    case FormatType.CURRENCY:
      return (value: number) => {
        const formatter =
          value >= 1000
            ? convertGigaToBillion(currencyFormatter('$.3~s', currencyCode))
            : currencyFormatter('$,d', currencyCode);
        return formatter(value);
      };
    case FormatType.CURRENCY_INTEGER:
      return (value: number) => {
        return convertGigaToBillion(currencyFormatter('$,.0f', currencyCode))(
          value
        );
      };
    case FormatType.INTEGER:
      return d3Format(',.0f');
    case FormatType.YEAR:
      return (d) => `${d3Format('.1~f')(d)}yr`;
    case FormatType.DECIMAL: {
      return (value: number) => {
        if (Math.abs(value) < 0.01 && Math.abs(value) > 0) {
          return d3Format('.2e')(value);
        }
        return d3Format(',.2f')(value);
      };
    }
    case FormatType.SCIENTIFIC:
      return d3Format('.2e');
  }
};
