import { useEffect, useState } from 'react';
import { useClient } from 'urql';

import { CUSTOM_ROLE_TAXONOMY_QUERY } from '@revelio/data-access';

import { TreeData, nestSelectionLists } from '../../filter-components/tree';
import { RoleSelectionCategories, SelectionCategories } from '../filters.model';
import { transformCustomRoleTaxonomy } from '../selection-lists-data-transforms';
import { getTaxonomySelection } from './convert-custom-role-taxonomy';
import {
  getCustomRoleTaxonomyStore,
  setCustomRoleTaxonomyStore,
} from './selection-list-store';
import { CustomRoleTaxonomyData, CustomRoleTaxonomyId } from './types';

const customRoleTaxonomyCache = new Map<
  CustomRoleTaxonomyId,
  CustomRoleTaxonomyData
>();

export const useFetchCustomRoleTaxonomy = (
  taxonomyId: CustomRoleTaxonomyId | null
): CustomRoleTaxonomyData | null => {
  const gqlClient = useClient();

  /** ======== Custom Role Taxonomy Selection Lists ======== */
  const [customRoleTaxonomy, setCustomRoleTaxonomy] = useState<
    TreeData[] | null
  >(taxonomyId ? customRoleTaxonomyCache.get(taxonomyId) ?? null : null);

  useEffect(() => {
    if (!taxonomyId) return;
    (async () => {
      try {
        // first check local session cache for fastest response
        const cachedCustomRoleTaxonomy =
          customRoleTaxonomyCache.get(taxonomyId);
        if (cachedCustomRoleTaxonomy) {
          setCustomRoleTaxonomy(cachedCustomRoleTaxonomy);
          return;
        }

        // check if store has the nested custom role taxonomy for the given taxonomySelection id
        const customRoleTaxonomyStore =
          await getCustomRoleTaxonomyStore(taxonomyId);

        const nestedCustomRoleTaxonomy = customRoleTaxonomyStore?.[taxonomyId];
        if (nestedCustomRoleTaxonomy) {
          setCustomRoleTaxonomy(nestedCustomRoleTaxonomy);
          (
            Object.entries(customRoleTaxonomyStore) as [
              CustomRoleTaxonomyId,
              TreeData[],
            ][]
          ).forEach(([key, value]) => {
            customRoleTaxonomyCache.set(key, value);
          });
        } else {
          // fetch all the leaf level data for the given taxonomySelection id and nest.
          const { data } = await gqlClient.query(CUSTOM_ROLE_TAXONOMY_QUERY, {
            taxonomyId: getTaxonomySelection(taxonomyId),
          });

          if (data?.selectionList?.custom_role_taxonomy) {
            const roleK7 = transformCustomRoleTaxonomy(
              'job_category' as RoleSelectionCategories,
              data?.selectionList?.custom_role_taxonomy
            );
            const roleK150 = transformCustomRoleTaxonomy(
              'role_k150' as RoleSelectionCategories,
              data?.selectionList?.custom_role_taxonomy
            );
            const roleK1500 = transformCustomRoleTaxonomy(
              'role_k1500' as RoleSelectionCategories,
              data?.selectionList?.custom_role_taxonomy
            );

            const nestedData = nestSelectionLists([
              {
                id: SelectionCategories.JOB_CATEGORY,
                value: roleK7.value.map((item) => ({
                  ...item,
                  id: item.id.toString(),
                })),
              },
              {
                id: SelectionCategories.ROLE_K150,
                value: roleK150.value.map((item) => ({
                  ...item,
                  id: item.id.toString(),
                })),
              },
              {
                id: SelectionCategories.ROLE_K1500,
                value: roleK1500.value.map((item) => ({
                  ...item,
                  id: item.id.toString(),
                })),
              },
            ]);

            setCustomRoleTaxonomy(nestedData);
            customRoleTaxonomyCache.set(taxonomyId, nestedData);

            await setCustomRoleTaxonomyStore({ [taxonomyId]: nestedData });
          }
        }
      } catch (error) {
        console.error(
          'Error getting custom role taxonomy selection list',
          error
        );
      }
    })();
  }, [gqlClient, taxonomyId]);

  return customRoleTaxonomy;
};
