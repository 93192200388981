import {
  Button,
  Popover,
  PopoverContent,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { PropsWithChildren, useRef } from 'react';

import { SelectionCategories } from '../../../engine/filters.model';
import { PlusMinusIcon } from '../../add-entity-button/plus-minus-icon';
import { ADD_ENTITY_BUTTON_CLASS_NAME } from '../../filter-chips/filter-chips-toggle/filter-chips-toggle-list';
import { BasePopoutTreeFilter } from '../BasePopoutTreeFilter';
import styles from '../PopoutTreeFilter.module.css';
import { BasePopoutTreeCommonProps } from '../types';
import { usePrimaryEntityFilter } from './use-primary-entity-filter';

export interface PrimaryEntityPopoutTreeFilterProps
  extends BasePopoutTreeCommonProps {
  selectionListIds?: SelectionCategories[];
  activeLimit?: number;
  maxTopLevelSelections?: number;
}

export const PrimaryEntityPopoutTreeFilter: React.FC<
  PrimaryEntityPopoutTreeFilterProps & PropsWithChildren
> = ({
  selectionListIds = [],
  filterLabel,
  maxSelections,
  minSelections,
  disabledLevels,
  disallowMultiLevelSelect,
  branches,
  activeLimit = 6,
  sortSuggestedSearch,
  children,
  maxTopLevelSelections,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { treeData, activeIds, applySelectedIds } = usePrimaryEntityFilter(
    selectionListIds,
    activeLimit
  );

  const searchInputRef = useRef<HTMLInputElement>(null);

  return (
    <Popover
      isOpen={isOpen}
      onOpen={onOpen}
      onClose={onClose}
      initialFocusRef={searchInputRef}
    >
      <PopoverTrigger>
        <Button
          data-testid="primary-filter-add-button"
          variant="addcompany"
          leftIcon={<PlusMinusIcon className={ADD_ENTITY_BUTTON_CLASS_NAME} />}
          display="flex"
          className={ADD_ENTITY_BUTTON_CLASS_NAME}
        >
          {children}
        </Button>
      </PopoverTrigger>
      <PopoverContent boxShadow="2xl" borderRadius="md">
        <div className={styles.popoutTree}>
          <BasePopoutTreeFilter
            treeData={treeData}
            activeIds={activeIds}
            onSubmit={applySelectedIds}
            filterLabel={filterLabel}
            maxSelections={maxSelections}
            minSelections={minSelections}
            disabledLevels={disabledLevels}
            branches={branches}
            disallowMultiLevelSelect={disallowMultiLevelSelect}
            sortSuggestedSearch={sortSuggestedSearch}
            isOpen={isOpen}
            onClose={onClose}
            searchInputRef={searchInputRef}
            maxTopLevelSelections={maxTopLevelSelections}
          />
        </div>
      </PopoverContent>
    </Popover>
  );
};
