import { KdeChart } from '@revelio/replots';

import { getKdeData } from '../../../entity-summary/company-summary/salary-distribution/get-kde-data';
import { KdeSalaryData } from '../../../entity-summary/company-summary/salary-distribution/types';
import { MetricValues } from '../config-provider';
import { isDistributionMetric } from '../config-provider/utils';
import { ChartNotSupported } from './chart-not-supported';

export const Distribution = ({
  data,
  metric,
}: {
  data: KdeSalaryData | undefined;
  metric: MetricValues;
}) => {
  if (!isDistributionMetric(metric)) return <ChartNotSupported />;
  const kdeData = data ? getKdeData(data, '') : [];

  return <KdeChart data={kdeData} />;
};
