import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import React from 'react';

import { OutageStatus } from '../healthCheck';
import { ServiceTimelineProps } from '../types';
import { determineOutageStatus } from '../utils/health-check.utils';
import { TimelineDay } from './TimelineDay';

// Helper function to format date as YYYY-MM-DD
const formatDateAsYYYYMMDD = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const ServiceTimeline: React.FC<ServiceTimelineProps> = ({
  serviceResults,
  check,
}) => {
  // Filter out manual checks and process remaining results into daily statuses
  const dailyStatusMap = serviceResults
    .filter(
      (result) =>
        !result.isManualCheck &&
        result.service === check.service &&
        result.viewGroup === check.viewGroup &&
        result.view === check.view
    )
    .reduce<
      Map<
        string,
        {
          date: string;
          status: OutageStatus;
          failureCount: number;
          totalChecks: number;
          avgResponseTime?: number;
        }
      >
    >((acc, result) => {
      // Convert timestamp to YYYY-MM-DD string
      const resultDate = new Date(result.timestamp);
      const date = formatDateAsYYYYMMDD(resultDate);

      const existingStatus = acc.get(date);

      // Calculate response time only for successful checks
      const successfulResponseTimes =
        result.status === 'success' ? [result.responseTime] : [];
      const existingResponseTimes = existingStatus?.avgResponseTime
        ? [
            existingStatus.avgResponseTime *
              (existingStatus.totalChecks - existingStatus.failureCount),
          ]
        : [];

      const allResponseTimes = [
        ...successfulResponseTimes,
        ...existingResponseTimes,
      ];
      const successfulChecks =
        (existingStatus?.totalChecks ?? 0) -
        (existingStatus?.failureCount ?? 0) +
        (result.status === 'success' ? 1 : 0);

      const newStatus = {
        date,
        failureCount:
          (existingStatus?.failureCount ?? 0) +
          (result.status === 'failure' ? 1 : 0),
        totalChecks: (existingStatus?.totalChecks ?? 0) + 1,
        status: 'operational' as OutageStatus,
        avgResponseTime:
          successfulChecks > 0
            ? allResponseTimes.reduce((sum, time) => sum + time, 0) /
              successfulChecks
            : undefined,
      };
      newStatus.status = determineOutageStatus(newStatus.failureCount);

      return new Map(acc).set(date, newStatus);
    }, new Map());

  // Generate timeline days for the last 90 days
  const days = Array.from({ length: 90 }, (_, i) => {
    const date = new Date();
    date.setDate(date.getDate() - (89 - i));
    const dateStr = formatDateAsYYYYMMDD(date);
    const dayData = dailyStatusMap.get(dateStr);

    return {
      date,
      status: dayData?.status ?? null,
      failureCount: dayData?.failureCount ?? 0,
      totalChecks: dayData?.totalChecks ?? 0,
      avgResponseTime: dayData?.avgResponseTime,
    };
  });

  // Calculate uptime percentage
  const totalChecks = Array.from(dailyStatusMap.values()).reduce(
    (sum, day) => sum + day.totalChecks,
    0
  );
  const totalFailures = Array.from(dailyStatusMap.values()).reduce(
    (sum, day) => sum + day.failureCount,
    0
  );
  const uptimePercentage = totalChecks
    ? ((totalChecks - totalFailures) / totalChecks) * 100
    : 100;

  return (
    <VStack align="stretch">
      <Box w="100%" mt={2}>
        <Box position="relative">
          {/* Timeline bars */}
          <Box position="relative" zIndex={2}>
            <HStack spacing={0.75} height="20px" justify="space-between" mx={0}>
              {days.map((day) => (
                <TimelineDay
                  key={day.date.toISOString()}
                  status={day.status}
                  date={day.date}
                  failureCount={day.failureCount}
                  totalChecks={day.totalChecks}
                  avgResponseTime={day.avgResponseTime}
                  results={serviceResults.filter((result) => {
                    const timestamp = new Date(result.timestamp);
                    const dayStart = new Date(day.date);
                    dayStart.setHours(0, 0, 0, 0);
                    const dayEnd = new Date(day.date);
                    dayEnd.setHours(23, 59, 59, 999);
                    return (
                      timestamp >= dayStart &&
                      timestamp <= dayEnd &&
                      !result.isManualCheck &&
                      result.service === check.service &&
                      result.viewGroup === check.viewGroup &&
                      result.view === check.view
                    );
                  })}
                  isOverall={false}
                />
              ))}
            </HStack>
          </Box>
        </Box>

        {/* Labels and connecting line container */}
        <Box position="relative" mt={4}>
          {/* Continuous line */}
          <Box
            position="absolute"
            top="50%"
            left="0"
            right="0"
            height="1px"
            bg="gray.200"
            transform="translateY(-50%)"
          />

          {/* Labels container */}
          <HStack
            justify="space-between"
            position="relative"
            mx={0}
            width="100%"
          >
            <Text fontSize="xs" color="gray.500" bg="white" pr={2}>
              90 days ago
            </Text>
            <Box
              position="absolute"
              left="50%"
              transform="translateX(-50%)"
              bg="white"
              px={2}
            >
              <HStack spacing={1}>
                <Text fontSize="xs" fontWeight="medium" color="gray.700">
                  {uptimePercentage.toFixed(2)}%
                </Text>
                <Text fontSize="xs" color="gray.500">
                  uptime
                </Text>
              </HStack>
            </Box>
            <Text fontSize="xs" color="gray.500" bg="white" pl={2}>
              Today
            </Text>
          </HStack>
        </Box>
      </Box>
    </VStack>
  );
};
