import { TNode } from '../Node';
import { TreeData } from '../useTreeApi';

export const getNode = ({
  nodes,
  pathToNode,
}: {
  nodes: TNode['children'];
  pathToNode: string[];
}): null | TNode => {
  if (pathToNode.length === 0) return null;

  const node = nodes?.find((node) => node.id === pathToNode[0]);

  if (!node) {
    // Handle sub filter trees, where the root nodes are not at the top level (e.g. 3_12)
    return getNode({ nodes, pathToNode: pathToNode.slice(1) });
  }

  if (pathToNode.length === 1 || !node.children) return node;

  return getNode({ nodes: node.children, pathToNode: pathToNode.slice(1) });
};

export const getPathToNode = (id: string): string[] =>
  id
    .split('_')
    .reduce<
      string[]
    >((acc, id, i) => (i === 0 ? [id] : [...acc, `${acc[acc.length - 1]}_${id}`]), []);

export const getChildren = ({
  data,
  idPath,
}: {
  data: TreeData[];
  idPath: string[];
}): TreeData[] => {
  if (idPath.length === 0) return data;

  const children = data.find((node) => node.id === idPath[0])?.children;

  if (!children) return [];

  if (idPath.length === 1) return children;

  return getChildren({ data: children, idPath: idPath.slice(1) });
};
