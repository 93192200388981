import { DocumentNode, GraphQLSchema } from 'graphql';

import { Dimension1 } from '../../gql/graphql';
import { getVariableTypes } from '../graphql/type-utils';
import { HEALTH_CHECK_FRAGMENTS } from './fragments';

export const getHealthCheckId = (
  service: string,
  viewGroup: string,
  view: string
): string => {
  return `${service}:${viewGroup}:${view}`;
};

export interface HealthCheckResult {
  id: string;
  timestamp: string;
  service: string;
  viewGroup: string;
  view: string;
  status: 'success' | 'failure';
  responseTime: number;
  error?: {
    message: string;
    details: unknown;
  };
  data: Record<string, unknown>;
  isManualCheck: boolean;
  userEmail?: string;
}

export type HealthCheckConfig = {
  service: string;
  query: (typeof HEALTH_CHECK_FRAGMENTS)[keyof typeof HEALTH_CHECK_FRAGMENTS];
  variables: Record<string, unknown>;
  viewGroup: string;
  view: string;
  customUrl?: string | null;
};

export const HEALTH_CHECKS: HealthCheckConfig[] = [
  // Shared checks used across pages
  {
    service: 'Auth',
    query: HEALTH_CHECK_FRAGMENTS.Auth,
    variables: {
      ids: ['test-user-id'],
    },
    viewGroup: 'shared',
    view: 'auth',
    customUrl: null,
  },
  {
    service: 'SavedSets',
    query: HEALTH_CHECK_FRAGMENTS.SavedSets,
    variables: {},
    viewGroup: 'shared',
    view: 'filtering',
    customUrl: null,
  },
  {
    service: 'SharedSelectionList',
    query: HEALTH_CHECK_FRAGMENTS.SharedSelectionList,
    variables: {},
    viewGroup: 'shared',
    view: 'filtering',
    customUrl: null,
  },
  {
    service: 'CustomRoleTaxonomy',
    query: HEALTH_CHECK_FRAGMENTS.CustomRoleTaxonomy,
    variables: {
      taxonomyId: { default: true },
    },
    viewGroup: 'shared',
    view: 'filtering',
    customUrl: null,
  },
  {
    service: 'MonthWeekIndex',
    query: HEALTH_CHECK_FRAGMENTS.MonthWeekIndex,
    variables: {},
    viewGroup: 'shared',
    view: 'filtering',
    customUrl: null,
  },
  {
    service: 'CompanyIndustrySelectionList',
    query: HEALTH_CHECK_FRAGMENTS.CompanyIndustrySelectionList,
    variables: {},
    viewGroup: 'shared',
    view: 'filtering',
    customUrl: null,
  },
  // Company overview checks
  {
    service: 'Entity',
    query: HEALTH_CHECK_FRAGMENTS.Overview,
    variables: {
      filters: {
        company: [1116644],
        top_competitors: 7,
      },
    },
    viewGroup: 'company',
    view: 'overview',
  },
  {
    service: 'TalentDiscovery',
    query: HEALTH_CHECK_FRAGMENTS.TalentDiscovery,
    variables: {
      query: {
        company: {
          ids: [
            1116644, 388108, 854453, 797078, 163383, 191201, 17945589, 488842,
            155209, 1465480, 15884122,
          ],
          current: true,
          non_current: false,
        },
        custom_role: {
          current: true,
          non_current: false,
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
      },
    },
    viewGroup: 'company',
    view: 'overview',
  },
  {
    service: 'CompositionSummary',
    query: HEALTH_CHECK_FRAGMENTS.CompositionSummaryData,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        company: [
          1116644, 620767, 919809, 905996, 794059, 958482, 1296160, 376082,
        ],
        custom_role: { taxonomyId: { ricsK50Id: '31' } },
        end_date: '2024-04',
        start_date: '2023-04',
      },
    },
    viewGroup: 'company',
    view: 'overview',
  },
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      filters: {
        company: [1116644],
        custom_role: { taxonomyId: { ricsK50Id: '31' } },
        provider: [0],
        start_date: '2023-07-29',
        end_date: '2024-07-29',
        metric_mode: 'expected_hires',
      },
      dim1: Dimension1.Company,
    },
    viewGroup: 'company',
    view: 'overview',
  },
  {
    service: 'Sentiment',
    query: HEALTH_CHECK_FRAGMENTS.Sentiment,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        company: [1116644],
        custom_role: { taxonomyId: { ricsK50Id: '31' } },
        end_date: '2024-04',
        start_date: '2023-04',
      },
    },
    viewGroup: 'company',
    view: 'overview',
  },
  // Geography overview checks
  {
    service: 'MapCoords',
    query: HEALTH_CHECK_FRAGMENTS.GeographySummaryMapData,
    variables: {
      query: {
        country: [],
        msa: [{ granularity: 'MSA', id: 2 }],
        region: [],
      },
    },
    viewGroup: 'geography',
    view: 'overview',
  },
  {
    service: 'TalentDiscovery',
    query: HEALTH_CHECK_FRAGMENTS.TalentDiscovery,
    variables: {
      query: {
        user_msa: [2],
      },
    },
    viewGroup: 'geography',
    view: 'overview',
  },
  {
    service: 'Composition',
    query: HEALTH_CHECK_FRAGMENTS.CompositionData,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        end_date: '2024-04',
        msa: ['2'],
        start_date: '2023-04',
      },
    },
    viewGroup: 'geography',
    view: 'overview',
  },
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        end_date: '2024-07-29',
        metric_mode: 'expected_hires',
        msa: ['2'],
        provider: [0],
        start_date: '2023-07-29',
      },
    },
    viewGroup: 'geography',
    view: 'overview',
  },
  // Role overview checks
  {
    service: 'Entity',
    query: HEALTH_CHECK_FRAGMENTS.Overview,
    variables: {
      filters: {
        role_k150: [107],
      },
    },
    viewGroup: 'role',
    view: 'overview',
  },
  {
    service: 'Composition',
    query: HEALTH_CHECK_FRAGMENTS.CompositionData,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        role_k150: ['107'],
        end_date: '2024-04',
        start_date: '2023-04',
      },
    },
    viewGroup: 'role',
    view: 'overview',
  },
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        role_k150: [107],
        provider: [0],
        start_date: '2023-07-29',
        end_date: '2024-07-29',
        metric_mode: 'expected_hires',
      },
    },
    viewGroup: 'role',
    view: 'overview',
  },
  {
    service: 'Sentiment',
    query: HEALTH_CHECK_FRAGMENTS.Sentiment,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        role_k150: ['107'],
        end_date: '2024-04',
        start_date: '2023-04',
      },
    },
    viewGroup: 'role',
    view: 'overview',
  },
  // Individual Discovery checks
  {
    service: 'TalentDiscovery',
    query: HEALTH_CHECK_FRAGMENTS.TalentDiscoveryDetailed,
    variables: {
      query: {
        flight_risk: {
          current: true,
          ids: [3],
          non_current: false,
        },
        gender: [2],
        num_results: 5000,
        page: 1,
        user_msa: [199],
      },
    },
    viewGroup: 'individual',
    view: 'discovery',
    customUrl: 'talent-discovery',
  },
  // Company compositions checks
  {
    service: 'Composition',
    query: HEALTH_CHECK_FRAGMENTS.CompositionData,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        category_sub_filter: {
          custom_role: Array.from({ length: 8 }, (_, i) => ({
            id: (i + 1).toString(),
            levelId: 'role_k7',
          })),
          geography: Array.from({ length: 16 }, (_, i) => (i + 1).toString()),
          job_category: Array.from({ length: 8 }, (_, i) => (i + 1).toString()),
          rics: [],
          skill: Array.from({ length: 75 }, (_, i) => (i + 1).toString()),
        },
        company: ['1116644', '919809', '1005273', '1010813', '256277'],
        custom_role: {
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
        end_date: '2024-04',
        start_date: '2008-01',
      },
    },
    viewGroup: 'company',
    view: 'compositions',
  },
  // Geography compositions checks
  {
    service: 'Composition',
    query: HEALTH_CHECK_FRAGMENTS.CompositionData,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        category_sub_filter: {
          custom_role: [],
          geography: [],
          job_category: Array.from({ length: 7 }, (_, i) => (i + 1).toString()),
          rics: Array.from({ length: 11 }, (_, i) => i.toString()),
          skill: Array.from({ length: 75 }, (_, i) => (i + 1).toString()),
        },
        end_date: '2024-04',
        msa: ['2', '1', '3', '21', '5'],
        start_date: '2008-01',
      },
    },
    viewGroup: 'geography',
    view: 'compositions',
  },
  // Role compositions checks
  {
    service: 'Composition',
    query: HEALTH_CHECK_FRAGMENTS.CompositionData,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        category_sub_filter: {
          custom_role: [],
          geography: Array.from({ length: 16 }, (_, i) => (i + 1).toString()),
          job_category: [],
          rics: Array.from({ length: 11 }, (_, i) => i.toString()),
          skill: Array.from({ length: 75 }, (_, i) => (i + 1).toString()),
        },
        end_date: '2024-04',
        role_k150: ['107', '117', '140', '17', '52'],
        start_date: '2008-01',
      },
    },
    viewGroup: 'role',
    view: 'compositions',
  },
  // Company transitions checks
  {
    service: 'Transitions',
    query: HEALTH_CHECK_FRAGMENTS.Transitions,
    variables: {
      dim1: Dimension1.Company,
      dim2: 'all',
      filters: {
        company: ['1116644'],
        custom_role: {
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
        end_date: '2024-04',
        node_limit: 10,
        start_date: '2008-01',
      },
    },
    viewGroup: 'company',
    view: 'transitions',
  },
  // Geography transitions checks
  {
    service: 'Transitions',
    query: HEALTH_CHECK_FRAGMENTS.Transitions,
    variables: {
      dim1: Dimension1.Geography,
      dim2: 'all',
      filters: {
        end_date: '2024-04',
        msa: ['2'],
        node_limit: 10,
        start_date: '2008-01',
      },
    },
    viewGroup: 'geography',
    view: 'transitions',
  },
  // Role transitions checks
  {
    service: 'Transitions',
    query: HEALTH_CHECK_FRAGMENTS.Transitions,
    variables: {
      dim1: Dimension1.Role,
      dim2: 'all',
      filters: {
        end_date: '2024-04',
        node_limit: 10,
        role_k150: ['107'],
        start_date: '2008-01',
      },
    },
    viewGroup: 'role',
    view: 'transitions',
  },
  // Company job postings checks
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        company: ['1116644'],
        custom_role: {
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
        end_date: '2024-07-29',
        metric_mode: 'posting',
        provider: [0],
        start_date: '2019-03-04',
      },
    },
    viewGroup: 'company',
    view: 'postings',
  },
  {
    service: 'PostingsRoles',
    query: HEALTH_CHECK_FRAGMENTS.PostingsRoles,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        company: ['1116644'],
        custom_role: {
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
        end_date: '2024-07-29',
        metric_mode: 'posting',
        provider: [0],
        start_date: '2019-03-04',
      },
    },
    viewGroup: 'company',
    view: 'postings',
  },
  // Geography job postings checks
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        end_date: '2024-07-29',
        metric_mode: 'posting',
        msa: ['2'],
        provider: [2],
        start_date: '2019-03-04',
      },
    },
    viewGroup: 'geography',
    view: 'postings',
  },
  {
    service: 'PostingsRoles',
    query: HEALTH_CHECK_FRAGMENTS.PostingsRoles,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        end_date: '2024-07-29',
        metric_mode: 'posting',
        msa: ['2'],
        provider: [2],
        start_date: '2019-03-04',
      },
    },
    viewGroup: 'geography',
    view: 'postings',
  },
  // Role job postings checks
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        end_date: '2024-07-29',
        metric_mode: 'posting',
        provider: [2],
        role_k150: ['107'],
        start_date: '2019-03-04',
      },
    },
    viewGroup: 'role',
    view: 'postings',
  },
  {
    service: 'PostingsRoles',
    query: HEALTH_CHECK_FRAGMENTS.PostingsRoles,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        end_date: '2024-07-29',
        metric_mode: 'posting',
        provider: [2],
        role_k150: ['107'],
        start_date: '2019-03-04',
      },
    },
    viewGroup: 'role',
    view: 'postings',
  },
  // Company pay checks
  {
    service: 'Compensation',
    query: HEALTH_CHECK_FRAGMENTS.Compensation,
    variables: {
      dim1: Dimension1.Company,
      dim2: 'all',
      filters: {
        company: ['1116644'],
        custom_role: {
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
      },
    },
    viewGroup: 'company',
    view: 'pay',
  },
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        company: ['1116644'],
        custom_role: {
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
        end_date: '2024-07-29',
        provider: [4, 2, 3],
        start_date: '2009-01-01',
      },
    },
    viewGroup: 'company',
    view: 'pay',
  },
  // Geography pay checks
  {
    service: 'Compensation',
    query: HEALTH_CHECK_FRAGMENTS.Compensation,
    variables: {
      dim1: Dimension1.Geography,
      dim2: 'all',
      filters: {
        msa: ['2'],
      },
    },
    viewGroup: 'geography',
    view: 'pay',
  },
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        end_date: '2024-07-29',
        msa: ['2'],
        provider: [4, 2, 3],
        start_date: '2009-01-01',
      },
    },
    viewGroup: 'geography',
    view: 'pay',
  },
  // Role pay checks
  {
    service: 'Compensation',
    query: HEALTH_CHECK_FRAGMENTS.Compensation,
    variables: {
      dim1: Dimension1.Role,
      dim2: 'all',
      filters: {
        role_k150: ['107'],
      },
    },
    viewGroup: 'role',
    view: 'pay',
  },
  {
    service: 'Postings',
    query: HEALTH_CHECK_FRAGMENTS.Postings,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        end_date: '2024-07-29',
        provider: [4, 2, 3],
        role_k150: ['107'],
        start_date: '2009-01-01',
      },
    },
    viewGroup: 'role',
    view: 'pay',
  },
  // Company sentiment checks
  {
    service: 'Sentiment',
    query: HEALTH_CHECK_FRAGMENTS.Sentiment,
    variables: {
      dim1: Dimension1.Company,
      filters: {
        company: ['1116644', '919809', '1005273', '1010813', '256277'],
        custom_role: {
          taxonomyId: {
            ricsK50Id: '31',
          },
        },
        end_date: '2024-04',
        start_date: '2020-01',
      },
    },
    viewGroup: 'company',
    view: 'sentiment',
  },
  // Geography sentiment checks
  {
    service: 'Sentiment',
    query: HEALTH_CHECK_FRAGMENTS.Sentiment,
    variables: {
      dim1: Dimension1.Geography,
      filters: {
        end_date: '2024-04',
        msa: ['2', '1', '3', '21', '5'],
        start_date: '2020-01',
      },
    },
    viewGroup: 'geography',
    view: 'sentiment',
  },
  // Role sentiment checks
  {
    service: 'Sentiment',
    query: HEALTH_CHECK_FRAGMENTS.Sentiment,
    variables: {
      dim1: Dimension1.Role,
      filters: {
        end_date: '2024-04',
        role_k150: ['107', '117', '140', '17', '52'],
        start_date: '2020-01',
      },
    },
    viewGroup: 'role',
    view: 'sentiment',
  },
  // Company discovery checks
  {
    service: 'Screener',
    query: HEALTH_CHECK_FRAGMENTS.Screener,
    variables: {
      filters: {
        dim: 'COMPANY',
        entity_info_selectors: {},
        pagination: {
          limit: 35,
          offset: 0,
        },
        primary_selector: {},
        segments: {
          attrition: [
            {
              end_val: 0.25,
              filter: {},
              start_val: 0,
            },
          ],
          headcount: [
            {
              end_val: 10000,
              filter: {
                region: [14],
              },
              start_val: 0,
            },
          ],
        },
      },
    },
    viewGroup: 'company',
    view: 'discovery',
  },
  // Geography discovery checks
  {
    service: 'Screener',
    query: HEALTH_CHECK_FRAGMENTS.Screener,
    variables: {
      filters: {
        dim: 'GEOGRAPHY',
        entity_info_selectors: {},
        pagination: {
          limit: 35,
          offset: 0,
        },
        primary_selector: {},
        segments: {
          attrition: [
            {
              end_val: 0.25,
              filter: {},
              start_val: 0,
            },
          ],
          growth_yoy: [
            {
              end_val: 0.25,
              filter: {
                gender: [1],
              },
              start_val: 0,
            },
          ],
        },
      },
    },
    viewGroup: 'geography',
    view: 'discovery',
  },
  // Role discovery checks
  {
    service: 'Screener',
    query: HEALTH_CHECK_FRAGMENTS.Screener,
    variables: {
      filters: {
        dim: 'ROLE',
        entity_info_selectors: {},
        pagination: {
          limit: 35,
          offset: 0,
        },
        primary_selector: {},
        segments: {
          tenure: [
            {
              end_val: 2,
              filter: {},
              start_val: 0,
            },
          ],
        },
      },
    },
    viewGroup: 'role',
    view: 'discovery',
  },
  // Deliverables data-builder checks
  {
    service: 'ClientDetails',
    query: HEALTH_CHECK_FRAGMENTS.ClientDetails,
    variables: {
      ids: ['Revelio Labs'],
    },
    viewGroup: 'deliverables',
    view: 'data-builder',
    customUrl: '/deliverables/dataset',
  },
  {
    service: 'PipelineConfig',
    query: HEALTH_CHECK_FRAGMENTS.PipelineConfiguration,
    variables: {},
    viewGroup: 'deliverables',
    view: 'data-builder',
    customUrl: '/deliverables/dataset',
  },
  // Deliverables status checks
  {
    service: 'ClientsTable',
    query: HEALTH_CHECK_FRAGMENTS.ClientsTable,
    variables: {},
    viewGroup: 'deliverables',
    view: 'data-builder',
    customUrl: '/deliverables/status',
  },
  // Reports builder checks
  {
    service: 'ReportsValidation',
    query: HEALTH_CHECK_FRAGMENTS.ReportConfigHealthCheck,
    variables: {},
    viewGroup: 'deliverables',
    view: 'report-builder',
    customUrl: 'report-builder/report_type',
  },
  // Reports view checks
  {
    service: 'Users',
    query: HEALTH_CHECK_FRAGMENTS.GetAllUsersNameAndIdHealthCheck,
    variables: {},
    viewGroup: 'deliverables',
    view: 'report-builder',
    customUrl: '/reports',
  },
];

export async function getHealthCheckVariableTypes(
  query: DocumentNode,
  schema: GraphQLSchema
): Promise<string> {
  return getVariableTypes(query, schema);
}
