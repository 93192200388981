// Page Feature naming convention
export enum FeatureFlag {
  AdminReportTypes = 'admin-report-types',
  CompositionRequestLogging = 'composition-request-logging',
  CompositionV2 = 'composition-v2',
  CurrencySelect = 'currency-select',
  Maintenance = 'maintenance',
  SentimentNewGptModel = 'sentiment-new-gpt-model',

  /* Postings Keywords */
  CompanyPostingsKeyword = 'company-postings-keyword',
  RolePostingsKeyword = 'role-postings-keyword',
  GeoPostingsKeyword = 'geo-postings-keyword',

  /* Sentiment */
  OldSentiment = 'old-sentiment',

  /* Zoho Chat */
  ZohoChat = 'zoho-chat',
}
