import { TreeData } from '..';
import { CUSTOM_ROLE_GRANULARITY_FILTERS } from '../../../engine/filters.constants';

export const roleLevelIds = CUSTOM_ROLE_GRANULARITY_FILTERS;

export const findEntityInTaxonomy = (
  data: TreeData[],
  targetId: string,
  levelId: string,
  path: string[] = []
): TreeData | null => {
  for (const item of data) {
    const itemId = item.id.toString().split('_').pop() || '';

    if (itemId === targetId && levelId === roleLevelIds[path.length]) {
      return item;
    }

    if (item.children) {
      const found = findEntityInTaxonomy(item.children, targetId, levelId, [
        ...path,
        item.id,
      ]);
      if (found) return found;
    }
  }
  return null;
};

export const findEntityPath = (
  data: TreeData[],
  targetId: string,
  level: number,
  currentPath: string[] = []
): string[] | null => {
  for (const item of data) {
    const itemId = item.id.toString().split('_').pop() || '';

    if (itemId === targetId && currentPath.length === level) {
      return [...currentPath, item.id];
    }

    if (item.children) {
      const path = findEntityPath(item.children, targetId, level, [
        ...currentPath,
        item.id,
      ]);
      if (path) return path;
    }
  }
  return null;
};
