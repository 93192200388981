import { PostingFilters } from '@revelio/data-access';
import { Filter, SelectionCategories } from '@revelio/filtering';

import { getCommonFilters } from './common';
import { isFilterList } from './common';

type FiltersKey = keyof PostingFilters;

/** ======== Filter List ======== */
const filterIdsToFilterKeys: Partial<Record<SelectionCategories, FiltersKey>> =
  {
    [SelectionCategories.RICS_K10]: 'rics_k10',
    [SelectionCategories.RICS_K50]: 'rics_k50',
    [SelectionCategories.RICS_K400]: 'rics_k400',
    [SelectionCategories.INDUSTRY]: 'industry',
    [SelectionCategories.COMPANY]: 'company',

    [SelectionCategories.REGION]: 'region',
    [SelectionCategories.COUNTRY]: 'country',
    [SelectionCategories.MSA]: 'msa',
    [SelectionCategories.METRO_AREA]: 'msa',

    [SelectionCategories.JOB_CATEGORY]: 'job_category',
    [SelectionCategories.ROLE_K50]: 'role_k50',
    [SelectionCategories.ROLE_K150]: 'role_k150',
    [SelectionCategories.ROLE_K1500]: 'role_k1500',

    [SelectionCategories.SKILL_K75]: 'skill_k75',
    [SelectionCategories.SKILL_K700]: 'skill_k700',
    [SelectionCategories.SKILL_K3000]: 'skill_k3000',

    [SelectionCategories.SENIORITY]: 'seniority',
    [SelectionCategories.RAW_TITLE]: 'title_raw',
  };

const isFilterListId = (filterId: string): filterId is FiltersKey =>
  filterIdsToFilterKeys[filterId as SelectionCategories] !== undefined;

/** ================================
 * Get Posting Filters
 ================================ */
export const getPostingFilters = (filters: Filter[]): PostingFilters =>
  filters.reduce<PostingFilters>((acc, filter): PostingFilters => {
    const commonAcc = getCommonFilters(acc, filter);

    if (isFilterListId(filter.id) && isFilterList(filter)) {
      const mappedKey = filterIdsToFilterKeys[filter.id as SelectionCategories];
      if (!mappedKey) return acc;
      return {
        ...commonAcc,
        [mappedKey]: filter.value.map((item) => item.id),
      };
    }

    return commonAcc;
  }, {});

export const validatedPostingDates = ({
  filters,
  startDate: startDateInput,
  endDate: endDateInput,
}: {
  filters: PostingFilters;
  startDate: string;
  endDate: string;
}): PostingFilters => {
  const filterStartDate = filters.start_date;
  const filterEndDate = filters.end_date;

  const startDate = startDateInput ?? filterStartDate;
  const endDate = endDateInput ?? filterEndDate;

  const startDateElements = startDate?.split('-');
  const endDateElements = endDate?.split('-');

  const startDateWithDay =
    startDateElements?.length === 3 ? startDate : startDateInput;
  const endDateWithDay = endDateElements?.length === 3 ? endDate : endDateInput;

  return {
    ...filters,
    start_date: startDateWithDay,
    end_date: endDateWithDay,
  };
};
