import { Box, Text } from '@chakra-ui/layout';

export const ChartNotSupported = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <Text textAlign="center">Chart not supported for selected metric</Text>
    </Box>
  );
};
