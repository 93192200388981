import { Box, Button, HStack, Icon } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiDownload } from 'react-icons/fi';

import { useGetLoggedInUser, useUserTrialDetails } from '@revelio/auth';
import { WithTooltip } from '@revelio/core';
import {
  TalentDiscoveryUser,
  TalentDiscoveryV1_5Filter,
  User,
} from '@revelio/data-access';

import { useDownloadData } from '../../shared/hooks/useDownloadPlotData';
import { serialiseTalentDiscoveryFilters } from './data-fetch/serialiseTalentDiscoveryFilters';
import { useTalentDiscoveryFilter } from './filters/td-filter-provider';
import { SourceWhaleExportButton } from './source-whale/components/source-whale-export-button';

const DOWNLOAD_DISABLED_CLIENT_NAMES = ['Shayne Simpson'];
const DOWNLOAD_DISABLED_EMAIL_DOMAINS = ['technet-it.co.uk'];

const isDownloadDisabled = (loggedInUser: User) => {
  return (
    DOWNLOAD_DISABLED_CLIENT_NAMES.includes(
      loggedInUser?.client_name as string
    ) ||
    DOWNLOAD_DISABLED_EMAIL_DOMAINS.some((domain) =>
      loggedInUser?.email?.endsWith(domain)
    )
  );
};

interface TalentDiscoveryExportProps {
  disabled: boolean;
  selectedUsers?: TalentDiscoveryUser[];
}

export const TalentDiscoveryExport = ({
  disabled,
  selectedUsers = [],
}: TalentDiscoveryExportProps) => {
  const { loggedInUser } = useGetLoggedInUser();

  const { state } = useTalentDiscoveryFilter();

  const [serialisedFilters, setSerialisedFilters] =
    useState<TalentDiscoveryV1_5Filter | null>(null);

  const { isTrialUser } = useUserTrialDetails<boolean>({
    initialRenderValue: false,
  });

  useEffect(() => {
    let isMounted = true;

    const fetchSerializedFilters = async () => {
      try {
        const result = await serialiseTalentDiscoveryFilters(state.filters);
        if (isMounted) {
          setSerialisedFilters(result);
        }
      } catch (err) {
        if (isMounted) {
          console.error(err);
        }
      }
    };

    fetchSerializedFilters();

    // Cleanup function to prevent setting state on unmounted component
    return () => {
      isMounted = false;
    };
  }, [state.filters]);

  const { triggerDownload, isLoading } =
    useDownloadData<TalentDiscoveryV1_5Filter | null>({
      serialisedFilters,
      downloadPath: 'api/talent-discovery/downloads',
    });

  return (
    <WithTooltip
      showCondition={isTrialUser}
      placement="top"
      variant="label"
      padding="10px 15px"
      label="This feature is not available for trial users"
      aria-label={`This feature is not available for trial users`}
    >
      <Box>
        {Object.keys(serialisedFilters || {})?.length > 0 ? (
          <HStack spacing={2}>
            <SourceWhaleExportButton
              disabled={isTrialUser || disabled}
              selectedUsers={selectedUsers}
            />
            {!isDownloadDisabled(loggedInUser) && (
              <Button
                isLoading={isLoading}
                isDisabled={
                  isTrialUser || isDownloadDisabled(loggedInUser) || disabled
                }
                loadingText="Preparing Download..."
                data-testid="download-data-button"
                size="sm"
                height="24px"
                variant="white"
                onClick={triggerDownload}
                leftIcon={<Icon as={FiDownload} boxSize={3.5} />}
              >
                Download Data
              </Button>
            )}
          </HStack>
        ) : null}
      </Box>
    </WithTooltip>
  );
};
