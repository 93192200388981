import { PostingsDataQuery } from '@revelio/data-access';

import { PostingMetricValues } from '../../config-provider';
import { NormalizedData, NormalizedDataPoint } from './get-normalized-data';

type PostingMetricKeys = keyof Omit<
  NonNullable<
    NonNullable<
      NonNullable<
        NonNullable<
          NonNullable<PostingsDataQuery['posting']>[number]
        >['category']
      >[number]
    >['metrics']
  >,
  '__typename'
>;

const getPostingMetric = (metric: PostingMetricValues): PostingMetricKeys => {
  switch (metric) {
    case 'posting_salary':
      return 'salary';
    case 'time_to_fill':
      return 'time_to_fill';
    case 'postings_active':
      return 'active';
    case 'postings_new':
      return 'new';
    case 'postings_removed':
      return 'removed';
  }
};

export const getPostingNormalizedData = ({
  data,
  metric,
}: {
  data: PostingsDataQuery;
  metric: PostingMetricValues;
}): NormalizedData[] => {
  if (!data.posting) return [];

  return data.posting
    ?.map((entity): NormalizedData | null => {
      const metadata = entity?.metadata;
      const dimension = metadata?.shortName;

      if (!metadata || !dimension || !entity.category) return null;

      const timeseries = entity.category
        ?.map((dataPoint): NormalizedDataPoint | null => {
          if (!dataPoint || !dataPoint.metrics) return null;
          const date = dataPoint.metadata?.shortName;
          if (!date) return null;

          const value = dataPoint?.metrics?.[getPostingMetric(metric)];

          return {
            date,
            value: value ?? null,
          };
        })
        .filter((dataPoint) => dataPoint !== null);

      return {
        dimension,
        timeseries,
      };
    })
    .filter((d) => d !== null);
};

type PostingNormalizedData = {
  new: number | null;
  removed: number | null;
};
export const getPostingVolumeNormalizedData = ({
  data,
}: {
  data: PostingsDataQuery;
}): NormalizedData<PostingNormalizedData>[] => {
  if (!data.posting) return [];

  return data.posting
    ?.map((entity): NormalizedData<PostingNormalizedData> | null => {
      const metadata = entity?.metadata;
      const dimension = metadata?.shortName;

      if (!metadata || !dimension || !entity.category) return null;

      const timeseries = entity.category
        ?.map(
          (dataPoint): NormalizedDataPoint<PostingNormalizedData> | null => {
            if (!dataPoint || !dataPoint.metrics) return null;
            const date = dataPoint.metadata?.shortName;
            if (!date) return null;

            const metrics = dataPoint.metrics;

            return {
              date,
              new: metrics?.new ?? null,
              removed: metrics?.removed ?? null,
              value: metrics?.active ?? null,
            };
          }
        )
        .filter((dataPoint) => dataPoint !== null);

      return {
        dimension,
        timeseries,
      };
    })
    .filter((d) => d !== null);
};
