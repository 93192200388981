import { PositionedItem } from '../config-provider';
import { Slot, SlotItem, XPosition, YPosition } from './types';

type MapItemsIntoSlotsProps = {
  items: PositionedItem[];
  slots: Slot[];
};
export const mapItemsIntoSlots = ({
  items,
  slots,
}: MapItemsIntoSlotsProps): SlotItem<PositionedItem>[] =>
  slots.map(
    (slotItem): SlotItem<PositionedItem> => ({
      slotId: slotItem.slotId,
      position: slotItem.position,
      item: items.find((item) => item.slotId === slotItem.slotId) || null,
    })
  );

export const GRID_WIDTH = 6;
export const GRID_HEIGHT = 6;

export const getFourSquareGridPosition = (
  xPosition: XPosition,
  yPosition: YPosition
): { x: XPosition; y: YPosition } => {
  return {
    x: (xPosition - ((xPosition + 1) % 2)) as XPosition,
    y: (yPosition - ((yPosition + 1) % 2)) as YPosition,
  };
};

export const getGridMap = (items: PositionedItem[]) => {
  const gridMap = emptyGridMap();
  const interferenceMap = new Map<string, string[]>();
  items.forEach((item) => {
    const { x: xPosition, width, y: yPosition, height } = item.position;
    for (let y = yPosition; y < yPosition + height; y++) {
      for (let x = xPosition; x < xPosition + width; x++) {
        const slotCoord = `${y}_${x}`;
        const slotItems = [...(gridMap.get(slotCoord) || []), item.id];
        if (slotItems.length > 1) {
          interferenceMap.set(slotCoord, slotItems);
        }
        gridMap.set(slotCoord, slotItems);
      }
    }
  });

  return { gridMap, interferenceMap };
};

export const checkInterference = (items: PositionedItem[]) => {
  const { interferenceMap } = getGridMap(items);

  return interferenceMap.size > 0;
};

const emptyGridMap = () => {
  const gridMap = new Map<string, string[]>();
  for (let y = 1; y <= GRID_HEIGHT; y++) {
    for (let x = 1; x <= GRID_WIDTH; x++) {
      gridMap.set(`${y}_${x}`, []);
    }
  }
  return gridMap;
};

export const mapItems = (
  positionItems: PositionedItem[]
): { slots: Slot[]; items: PositionedItem[] } => {
  // const gridMap = emptyGridMap();
  // const interferenceMap = new Map<string, string[]>();
  // positionItems.forEach((item) => {
  //   const { xPosition, width, yPosition, height } = item.position;
  //   for (let y = yPosition; y < yPosition + height; y++) {
  //     for (let x = xPosition; x < xPosition + width; x++) {
  //       const slotCoord = `${y}_${x}`;
  //       const slotItems = [...(gridMap.get(slotCoord) || []), item.item.id];
  //       if (slotItems.length > 1) {
  //         interferenceMap.set(slotCoord, slotItems);
  //       }
  //       gridMap.set(slotCoord, slotItems);
  //     }
  //   }
  // });
  const { gridMap } = getGridMap(positionItems);

  const slots: Slot[] = [];
  const items: PositionedItem[] = [];

  // create a slot for each item
  positionItems.forEach((item) => {
    slots.push({
      slotId: `${item.position.y}_${item.position.x}`,
      position: item.position,
    });
    items.push({ ...item, slotId: `${item.position.y}_${item.position.x}` });
  });

  // create a slot for each empty part of gridMap
  gridMap.forEach((slotItems, slotCoord) => {
    if (slotItems.length === 0) {
      const [y, x] = slotCoord.split('_').map(Number) as [YPosition, XPosition];
      slots.push({
        slotId: slotCoord,
        position: { x, width: 1, y, height: 1 },
      });
    }
  });

  return { slots, items };
};

export const getSlotItems = (
  items: PositionedItem[]
): SlotItem<PositionedItem>[] => {
  const { slots, items: itemsWithSlotId } = mapItems(items);
  return mapItemsIntoSlots({ items: itemsWithSlotId, slots });
};
