import { useCallback, useMemo } from 'react';

import {
  CustomRoleFilterEntity,
  CustomRoleFilterValue,
  SelectionCategories,
} from '../../../engine/filters.model';
import {
  deleteFilter,
  getSingleFilterStateSync,
  upsertFilter,
} from '../../../engine/filters.repository';
import {
  getTaxonomySelection,
  isCustomRoleFilterValue,
} from '../../../engine/selection-lists/convert-custom-role-taxonomy';
import { CustomRoleTaxonomyId } from '../../../engine/selection-lists/types';
import { useFetchCustomRoleTaxonomy } from '../../../engine/selection-lists/use-fetch-custom-role-taxonomy';
import { TreeData } from '../../tree';
import {
  findEntityPath,
  roleLevelIds,
} from '../../tree/utils/findEntityInTaxonomy';
import { FilterDataProvider } from '../types';
import { NestedFilterItem } from '../utils/get-filter-items-from-ids';

export const useCustomRoleFilter = (
  customRoleTaxonomyId: CustomRoleTaxonomyId
): FilterDataProvider<NestedFilterItem> => {
  const customRoleTaxonomyData =
    useFetchCustomRoleTaxonomy(customRoleTaxonomyId);

  /*
   * Nested tree data
   */
  const treeData = useMemo(() => {
    if (!customRoleTaxonomyData) {
      return [];
    }

    return customRoleTaxonomyData;
  }, [customRoleTaxonomyData]);

  /*
   * Active filter ids
   */
  const activeIds = useMemo(() => {
    const activeCustomRoleFilter = getSingleFilterStateSync(
      SelectionCategories.CUSTOM_ROLE
    );

    if (
      !activeCustomRoleFilter?.value ||
      !customRoleTaxonomyData ||
      !customRoleTaxonomyId
    ) {
      return [];
    }

    if (!isCustomRoleFilterValue(activeCustomRoleFilter.value)) {
      return [];
    }

    const customRoleFilter = activeCustomRoleFilter.value;
    if (
      !customRoleFilter.entities ||
      !Array.isArray(customRoleFilter.entities)
    ) {
      return [];
    }

    return customRoleFilter.entities
      .filter(
        (entity): entity is CustomRoleFilterEntity =>
          entity !== null && entity !== undefined
      )
      .map((entity) => {
        const { id, levelId } = entity;
        if (!id || !levelId) return null;

        const level = roleLevelIds.indexOf(levelId as any);
        if (level === -1) return null;

        const path = findEntityPath(customRoleTaxonomyData, id, level);
        return path ? path[path.length - 1] : null;
      })
      .filter(Boolean) as string[];
  }, [customRoleTaxonomyData, customRoleTaxonomyId]);

  /*
   * Apply filter selection
   */
  const applySelectedIds = useCallback(
    (selections: string[]) => {
      if (selections.length === 0) {
        deleteFilter(SelectionCategories.CUSTOM_ROLE);
        return;
      }

      const selectedEntities: CustomRoleFilterEntity[] = selections
        .map((id) => getNestedItem(id.split('_'), customRoleTaxonomyData ?? []))
        .map((item) => {
          if (!item?.children) return item;
          const { children, ...rest } = item;
          return rest;
        })
        .map((item): CustomRoleFilterEntity | null => {
          const ids = item?.id.split('_');
          const lastId = ids?.[ids.length - 1];
          const depth = ids?.length;

          if (depth === undefined || !lastId) return null;
          const roleLevelId = roleLevelIds[depth - 1];

          return { id: lastId, levelId: roleLevelId, label: item?.name ?? '' };
        })
        .filter((item) => item !== null);

      const taxonomyId = getTaxonomySelection(customRoleTaxonomyId);

      const customRoleFilterValue: CustomRoleFilterValue = {
        taxonomyId,
        entities: selectedEntities,
      };

      upsertFilter(SelectionCategories.CUSTOM_ROLE, {
        selectionListId: SelectionCategories.CUSTOM_ROLE,
        isMulti: false,
        value: customRoleFilterValue,
      });
    },
    [customRoleTaxonomyData, customRoleTaxonomyId]
  );

  return {
    treeData,
    activeIds,
    applySelectedIds,
  };
};

/**
 * Helper to find a nested item by ID path
 */
const getNestedItem = (ids: string[], nestedData: TreeData[]) => {
  if (ids.length === 0) return null;
  if (ids.length === 1) return nestedData.find((item) => item.id === ids[0]);

  return getNestedItem(
    [`${ids[0]}_${ids[1]}`, ...ids.slice(2)],
    nestedData.find((item) => item.id === ids[0])?.children ?? []
  );
};
