import {
  Button,
  Divider,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  IconButton,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  useDisclosure,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiBookmark, FiSave, FiTrash } from 'react-icons/fi';

import { PositionedItem } from '../config-provider';
import styles from './dashboard-bookmark-menu.module.css';

type DashboardBookmark = {
  name: string;
  items: PositionedItem[];
  timestamp: string;
};

type DashboardBookmarkMenuProps = {
  items: PositionedItem[];
  setItems: (items: PositionedItem[]) => void;
};
export const DashboardBookmarkMenu = ({
  items,
  setItems,
}: DashboardBookmarkMenuProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [input, setInput] = useState('');
  const [isError, setIsError] = useState(false);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
    setIsError(false);
  };

  const handleSubmit = () => {
    if (input.length === 0) {
      setIsError(true);
      return;
    }

    // Store dashboard layout in localStorage
    const dashboardBookmark: DashboardBookmark = {
      name: input,
      items: items,
      timestamp: new Date().toISOString(),
    };

    try {
      // Get existing bookmarks or initialize empty array
      const existingBookmarks = JSON.parse(
        localStorage.getItem('dashboardBookmarks') || '[]'
      ) as DashboardBookmark[];

      if (existingBookmarks.some((b) => b.name === input)) {
        throw new Error('Bookmark name already exists');
      }

      // Add new bookmark
      existingBookmarks.push(dashboardBookmark);

      // Save back to localStorage
      localStorage.setItem(
        'dashboardBookmarks',
        JSON.stringify(existingBookmarks)
      );

      setBookmarks(existingBookmarks);

      // Clear input after successful save
      setInput('');
    } catch (err) {
      console.error('Error saving dashboard bookmark:', err);
      setIsError(true);
    }
  };

  const [bookmarks, setBookmarks] = useState<DashboardBookmark[]>([]);

  useEffect(() => {
    const bookmarks = JSON.parse(
      localStorage.getItem('dashboardBookmarks') || '[]'
    );
    setBookmarks(bookmarks);
  }, []);

  const handleSelectBookmark = (bookmark: DashboardBookmark) => {
    if (bookmark?.items) {
      setItems(bookmark?.items);
      onClose();
    }
  };

  const [filteredBookmarks, setFilteredBookmarks] =
    useState<DashboardBookmark[]>(bookmarks);
  const [searchTerm, setSearchTerm] = useState('');
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    if (searchTerm.length === 0) {
      setFilteredBookmarks(bookmarks);
    } else {
      setFilteredBookmarks(
        bookmarks.filter((bookmark) =>
          bookmark.name.toLowerCase().includes(searchTerm)
        )
      );
    }
  }, [bookmarks, searchTerm]);

  const handleUpdateBookmark = (bookmark: DashboardBookmark) => {
    const updatedBookmarks = bookmarks.map((b) => {
      if (b.name === bookmark.name) {
        return { ...b, items: items };
      }
      return b;
    });
    setBookmarks(updatedBookmarks);
    localStorage.setItem(
      'dashboardBookmarks',
      JSON.stringify(updatedBookmarks)
    );
  };

  const handleDeleteBookmark = (bookmark: DashboardBookmark) => {
    const updatedBookmarks = bookmarks.filter((b) => b.name !== bookmark.name);
    setBookmarks(updatedBookmarks);
    localStorage.setItem(
      'dashboardBookmarks',
      JSON.stringify(updatedBookmarks)
    );
  };

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>
        <IconButton
          icon={<FiBookmark />}
          aria-label="Save selections"
          variant="outline"
          color="#2D426A"
          height="24px"
          border="1px solid #2D426A00"
          _hover={{
            border: '1px solid #2D426A77',
            transition: 'border-color 200ms',
          }}
        />
      </PopoverTrigger>
      <PopoverContent>
        <PopoverHeader>Dashboard Bookmarks</PopoverHeader>
        <PopoverBody paddingBottom={'16px'}>
          <FormControl isInvalid={isError}>
            <FormLabel fontSize="sm">Name</FormLabel>
            <Flex>
              <Input value={input} onChange={handleInputChange} size="sm" />
              <Button
                size="sm"
                marginLeft={'4px'}
                colorScheme="blue"
                onClick={handleSubmit}
              >
                Save
              </Button>
            </Flex>
            {isError && <FormErrorMessage>Name is required</FormErrorMessage>}
          </FormControl>
          <Divider marginTop={'8px'} marginBottom={'16px'} />
          <Input
            placeholder="Search"
            size="sm"
            onChange={handleSearch}
            value={searchTerm}
          />
          <div className={styles.bookmarkList}>
            {filteredBookmarks.map((bookmark) => {
              return (
                <div key={bookmark.name} className={styles.bookmarkItem}>
                  <div
                    className={styles.bookmarkItemName}
                    onClick={() => handleSelectBookmark(bookmark)}
                  >
                    {bookmark.name}
                  </div>
                  <IconButton
                    className={styles.bookmarkItemSaveButton}
                    display="none"
                    icon={<FiSave />}
                    aria-label="Save"
                    variant="outline"
                    color="#2D426A"
                    minWidth="24px"
                    width="24px"
                    height="24px"
                    border="1px solid #2D426A00"
                    _hover={{
                      border: '1px solid #2D426A77',
                      transition: 'border-color 200ms',
                    }}
                    onClick={() => handleUpdateBookmark(bookmark)}
                  />
                  <IconButton
                    className={styles.bookmarkItemDeleteButton}
                    display="none"
                    icon={<FiTrash />}
                    aria-label="Delete"
                    variant="outline"
                    color="#2D426A"
                    minWidth="24px"
                    width="24px"
                    height="24px"
                    border="1px solid #2D426A00"
                    _hover={{
                      border: '1px solid #2D426A77',
                      transition: 'border-color 200ms',
                    }}
                    onClick={() => handleDeleteBookmark(bookmark)}
                  />
                </div>
              );
            })}
            {filteredBookmarks.length === 0 && (
              <div className={styles.noBookmarks}>No bookmarks found</div>
            )}
          </div>
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
