import { CompanyResultItem, SchoolItem, TreeItem } from '@revelio/filtering';

import { AiSearchState } from './td-filter-provider';
import {
  CompanySearchFilters,
  FreeTextFilters,
  SchoolSearchFilters,
  TreeFilters,
  isFreeTextFilterState,
  isTreeFilterState,
} from './types';

interface BaseFilterState {
  id: string;
}

export interface TalentDiscoveryCompanySearchFilterState
  extends BaseFilterState {
  name: CompanySearchFilters;
  companyResultItems?: Record<string, CompanyResultItem>;
  isCurrent?: boolean;
}

export interface TalentDiscoveryFreeTextState extends BaseFilterState {
  name: FreeTextFilters;
  text: string[];
  isCurrent?: boolean;
}

export interface TalentDiscoveryRangeFilterState extends BaseFilterState {
  name: string;
  start_value?: number;
  end_value?: number;
  isCurrent?: boolean;
  isCurrency?: boolean;
  symbol?: string;
}

export interface TalentDiscoverySchoolSearchFilterState
  extends BaseFilterState {
  name: SchoolSearchFilters;
  schoolResultItems: Record<string, SchoolItem>;
}

export interface TalentDiscoveryTreeFilterState extends BaseFilterState {
  name: TreeFilters;
  treeItems?: Record<string, TreeItem>;
  isCurrent?: boolean;
}

export type TalentDiscoveryFilterStates =
  | TalentDiscoveryCompanySearchFilterState
  | TalentDiscoveryFreeTextState
  | TalentDiscoveryRangeFilterState
  | TalentDiscoverySchoolSearchFilterState
  | TalentDiscoveryTreeFilterState;

export enum TalentDiscoverySortCol {
  SORT_BY_ATTRITION = 'SORT_BY_ATTRITION',
  SORT_BY_GROWTHYOY = 'SORT_BY_GROWTH_YOY',
  SORT_BY_HEADCOUNT = 'SORT_BY_HEADCOUNT',
  SORT_BY_HIRING = 'SORT_BY_HIRING',
  SORT_BY_INFLOW = 'SORT_BY_INFLOW',
  SORT_BY_OUTFLOW = 'SORT_BY_OUTFLOW',
  SORT_BY_SALARY = 'SORT_BY_SALARY',
  SORT_BY_TENURE = 'SORT_BY_TENURE',
}

export type TalentDiscoverySorting = {
  sort_by: TalentDiscoverySortCol;
  num_col: number;
  ascending: boolean;
};

export interface TalentDiscoveryFilterState {
  page: number;
  filters?: TalentDiscoveryFilterStates[];
  sorting: TalentDiscoverySorting | undefined;
}

interface RemoveSubFilterAction {
  type: 'REMOVE_SUB_FILTER';
  filterId: string;
}

interface RemovePrimaryFilterAction {
  type: 'REMOVE_PRIMARY_FILTER';
}

interface UpdatePageAction {
  type: 'UPDATE_PAGE';
  page: number;
}

interface UpdateSortingAction {
  type: 'UPDATE_SORTING';
  sorting: TalentDiscoverySorting | undefined;
}

interface AddRangeFilter {
  type: 'ADD_RANGE_FILTER';
  name: string;
  start_value: number | undefined;
  end_value: number | undefined;
  isCurrent?: boolean;
  isCurrency?: boolean;
}

interface RemoveRangeFilter {
  type: 'REMOVE_RANGE_FILTER';
  name: string;
}

interface AddTreeFilterAction {
  type: 'ADD_TREE_FILTER';
  name: TreeFilters;
  treeItems?: Record<string, TreeItem>;
  isCurrent?: boolean;
  id: string | null;
}

interface RemoveTreeFilterAction {
  type: 'REMOVE_TREE_FILTER';
  name: TreeFilters;
  id: string;
}

interface AddCompanySearchFilterAction {
  type: 'ADD_SEARCH_FILTER';
  name: CompanySearchFilters;
  companyResultItems: Record<string, CompanyResultItem>;
  isCurrent?: boolean;
}

interface RemoveCompanySearchFilterAction {
  type: 'REMOVE_SEARCH_FILTER';
  name: CompanySearchFilters;
}

interface AddSchoolDetailSearchFilterAction {
  type: 'ADD_SCHOOL_DETAIL_SEARCH_FILTER';
  name: SchoolSearchFilters;
  schoolResultItems: Record<string, SchoolItem>;
}

interface RemoveSchoolDetailSearchFilterAction {
  type: 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER';
  name: SchoolSearchFilters;
}

interface SetFiltersAction {
  type: 'OVERWRITE_FILTERS';
  filters: TalentDiscoveryFilterState['filters'];
}

interface AddFreeTextFiltersAction {
  type: 'ADD_FREE_TEXT_FILTER';
  name: FreeTextFilters;
  text: string[];
  id: string | null;
  isCurrent?: boolean;
}

interface RemoveFreeTextFilterAction {
  type: 'REMOVE_FREE_TEXT_FILTER';
  name: FreeTextFilters;
  id: string;
}

export type TalentDiscoveryFilterAction =
  | AddCompanySearchFilterAction
  | AddFreeTextFiltersAction
  | AddRangeFilter
  | AddSchoolDetailSearchFilterAction
  | AddTreeFilterAction
  | RemoveCompanySearchFilterAction
  | RemoveFreeTextFilterAction
  | RemovePrimaryFilterAction
  | RemoveRangeFilter
  | RemoveSchoolDetailSearchFilterAction
  | RemoveSubFilterAction
  | RemoveTreeFilterAction
  | SetFiltersAction
  | UpdatePageAction
  | UpdateSortingAction;

export const defaultTalentDiscoveryFilters: TalentDiscoveryFilterStates[] = [
  {
    name: 'skill',
    id: '174222521704820',
    treeItems: {
      'skill_k3000.1579': {
        item: {
          id: '1579',
          shortName: 'Machine Learning',
          longName: 'Machine Learning',
          label: 'Machine Learning',
          skill_k25: '1',
          skill_k50: '3',
          skill_k75: '11',
          skill_k700: '22',
          skill_k2500: '1290',
          topSkills: 'Machine Learning, Neural Networks, Numpy, Graph Theory',
          __typename: 'SkillRefInfo',
        },
        id: 'skill_k3000.1579',
        children: [],
        selectionListId: 'skill_k3000',
        closeness_score: 8.569061098350962e-12,
      },
    },
    isCurrent: false,
    isAiGenerated: true,
  } as TalentDiscoveryTreeFilterState,
  {
    name: 'role',
    id: '1742225217245',
    isCurrent: true,
    treeItems: {
      'role_k150.107': {
        item: {
          id: '107',
          shortName: 'Data Scientist',
          longName: 'Data Scientist',
          label: 'Data Scientist',
          topCleanedTitles: [
            'Data Scientist',
            'Business Intelligence Manager',
            'Business Intelligence Analyst',
            'Data Science Intern',
            'Analytics Manager',
            'Data Analytics Intern',
            'Machine Learning Engineer',
            'Business Data Analyst',
            'Business Analytics Manager',
            'Analytics Consultant',
            'Master Inventor',
            'Marketing Analytics Manager',
            'Operations Research Analyst',
            'Decision Scientist',
            'Algorithm Engineer',
            'Marketing Data Analyst',
            'Optimization Manager',
            'Modeling',
            'Decision Support Analyst',
            'Data Science Consultant',
            'Digital Analytics Manager',
            'Computer Vision Engineer',
            'Data Analytics Consultant',
            'Healthcare Analyst',
            'Artificial Intelligence Intern',
            'Machine Learning Researcher',
            'Market Data Analyst',
            'Big Data Intern',
            'Director',
            'Business Intelligence & Analytics',
            'Manager',
            'Strategy & Analytics',
          ],
          topRawTitles: [
            'Data Scientist',
            'Business Intelligence Analyst',
            'Data Science Intern',
            'Senior Data Scientist',
            'Machine Learning Engineer',
            'Business Data Analyst',
            'Business Intelligence Manager',
            'Analytics Consultant',
            'Business Intelligence Specialist',
            'Senior Business Intelligence Analyst',
            'Data Scientist Intern',
            'Lead Data Scientist',
            'Analytics Manager',
            'Business Intelligence Intern',
            'Operations Research Analyst',
            'Data Analytics Intern',
            'Algorithm Engineer',
            'Business Intelligence',
            'Inventor',
            'Marketing Data Analyst',
            'Data Science Fellow',
            'Junior Data Scientist',
            'Analytics Intern',
            'Data Science Consultant',
            'Decision Support Analyst',
            'Principal Data Scientist',
            'Computer Vision Engineer',
            'Data Science Manager',
            'Machine Learning Researcher',
            'Decision Scientist',
          ],
          job_category: '2',
          role_k50: '28',
          __typename: 'RoleRefInfo',
        },
        id: 'role_k150.107',
        children: [],
        selectionListId: 'role_k150',
        closeness_score: 1.3925144747641449e-42,
      },
    },
    isAiGenerated: true,
  } as TalentDiscoveryTreeFilterState,
  {
    name: 'company',
    isCurrent: true,
    companyResultItems: {
      '233459': {
        primary_name: 'Google LLC',
        company_id: '',
        rcid: 233459,
        raw_emp_count: 715682,
        has_subsidiaries: true,
        primary_identifiers: {
          primary_name: 'Google LLC',
          primary_website: 'google.com',
          primary_ticker: '',
          primary_linkedin_url: 'http://linkedin.com/company/google',
          country: 'California',
          state: 'san jose metropolitan area (california)',
          city: 'Mountain View',
        },
      },
      '350953': {
        primary_name: 'Microsoft Corp.',
        company_id: '',
        rcid: 350953,
        raw_emp_count: 1030767,
        has_subsidiaries: true,
        primary_identifiers: {
          primary_name: 'Microsoft Corp.',
          primary_website: 'microsoft.com',
          primary_ticker: 'MSFT',
          primary_linkedin_url: 'http://linkedin.com/company/microsoft',
          country: 'Washington',
          state: 'seattle metropolitan area',
          city: 'Redmond',
        },
      },
      '434122': {
        primary_name: 'Cohere Ltd.',
        company_id: '',
        rcid: 434122,
        raw_emp_count: 1442,
        has_subsidiaries: true,
        primary_identifiers: {
          primary_name: 'Cohere Ltd.',
          primary_website: 'coherestudio.co',
          primary_ticker: '',
          primary_linkedin_url: 'http://linkedin.com/company/cohere_co',
          country: 'Pennsylvania',
          state: 'philadelphia metropolitan area',
          city: 'Philadelphia',
        },
      },
      '576626': {
        primary_name: 'OpenAI OpCo LLC',
        company_id: '',
        rcid: 576626,
        raw_emp_count: 5591,
        has_subsidiaries: true,
        primary_identifiers: {
          primary_name: 'OpenAI OpCo LLC',
          primary_website: 'openai.com',
          primary_ticker: '',
          primary_linkedin_url: 'http://linkedin.com/company/openai',
          country: 'California',
          state: 'san francisco metropolitan area',
          city: 'San Francisco',
        },
      },
      '744990': {
        primary_name: 'Anthropic PBC',
        company_id: '',
        rcid: 744990,
        raw_emp_count: 1292,
        has_subsidiaries: false,
        primary_identifiers: {
          primary_name: 'Anthropic PBC',
          primary_website: 'anthropic.com',
          primary_ticker: '',
          primary_linkedin_url: 'http://linkedin.com/company/anthropicresearch',
          country: 'California',
          state: 'san francisco metropolitan area',
          city: 'San Francisco',
        },
      },
    },
    id: '1742225217420',
    isAiGenerated: true,
  } as TalentDiscoveryCompanySearchFilterState,
];

export const defaultAiSearchState: AiSearchState = {
  prompt:
    'Find me data scientists at OpenAI and their top competitors with skills in machine learning',
  uuid: '9f0ade61-7dc1-4592-a2f6-213339bde0db',
  isCorrect: null,
  response: {
    filters: {
      name: null,
      company: [
        {
          name: 'OpenAI',
          relevantPromptText: 'at OpenAI',
          isCurrent: true,
        },
        {
          name: 'Anthropic',
          relevantPromptText: 'top competitors',
          isCurrent: true,
        },
        {
          name: 'Google',
          relevantPromptText: 'top competitors',
          isCurrent: true,
        },
        {
          name: 'Microsoft',
          relevantPromptText: 'top competitors',
          isCurrent: true,
        },
        {
          name: 'Cohere',
          relevantPromptText: 'top competitors',
          isCurrent: true,
        },
      ],
      industry: null,
      highest_degree: null,
      ethnicity: null,
      flight_risk: null,
      gender: null,
      geography: null,
      prestige: null,
      remote_suitability: null,
      role: [
        {
          name: 'Data Scientist',
          relevantPromptText: 'data scientists',
          isCurrent: true,
        },
      ],
      school: null,
      seniority: null,
      skill: [
        [
          {
            name: 'Machine Learning',
            relevantPromptText: 'skills in machine learning',
          },
        ],
      ],
      keywords: null,
      salary_total: null,
      titles: null,
      graduation_year: null,
    },
    unknownFilters: [],
  },
};

export const initialTalentDiscoveryFilterState: TalentDiscoveryFilterState = {
  page: 1,
  filters: [],
  sorting: undefined,
};

const removeTreeItemChildren = (
  treeItems: Record<string, TreeItem> | undefined
): Record<string, TreeItem> | undefined => {
  if (!treeItems) {
    return undefined;
  }

  const newTreeItems = Object.entries(treeItems).reduce<
    Record<string, TreeItem>
  >((accumulator, [key, treeItem]) => {
    const { children, ...rest } = treeItem;
    accumulator[key] = { ...rest, children: [] };

    return accumulator;
  }, {});

  return newTreeItems;
};

export const talentDiscoveryFilterReducer = (
  state: TalentDiscoveryFilterState,
  action: TalentDiscoveryFilterAction
): TalentDiscoveryFilterState => {
  switch (action.type) {
    case 'OVERWRITE_FILTERS':
      return {
        page: state.page,
        filters: action.filters,
        sorting: undefined,
      };

    case 'ADD_RANGE_FILTER':
      return {
        ...state,
        filters: [
          ...(state.filters || []).filter(
            (filter) => filter.name !== action.name
          ),
          {
            id: `${Date.now()}`,
            name: action.name,
            start_value: action.start_value,
            end_value: action.end_value,
            isCurrent: action.isCurrent,
            isCurrency: action.isCurrency,
          },
        ],
      };
    case 'REMOVE_RANGE_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => filter.name !== action.name),
      };
    case 'ADD_TREE_FILTER': {
      const existingFilterIndex = state.filters?.findIndex(
        (filter) => isTreeFilterState(filter) && filter.id === action.id
      );

      // replace filters
      if (action.id === null) {
        return {
          ...state,
          filters: [
            ...(state.filters || []).filter(
              (filter) => filter.name !== action.name
            ),
            {
              name: action.name,
              treeItems: removeTreeItemChildren(action.treeItems),
              isCurrent: action.isCurrent,
              id: `${Date.now()}`,
            },
          ],
        };
      }

      if (existingFilterIndex !== undefined && existingFilterIndex !== -1) {
        // Update existing filter
        const updatedFilters = [...(state.filters || [])];
        updatedFilters[existingFilterIndex] = {
          id: action.id,
          name: action.name,
          treeItems: removeTreeItemChildren(action.treeItems),
          isCurrent: action.isCurrent,
        };
        return {
          ...state,
          filters: updatedFilters,
        };
      } else {
        // Add new filter
        return {
          ...state,
          filters: [
            ...(state.filters || []),
            {
              id: action.id,
              name: action.name,
              treeItems: removeTreeItemChildren(action.treeItems),
              isCurrent: action.isCurrent,
            },
          ],
        };
      }
    }
    case 'REMOVE_TREE_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => {
          if (isTreeFilterState(filter)) {
            return filter.id !== action.id;
          }
          return true;
        }),
      };
    case 'ADD_SEARCH_FILTER':
      return {
        ...state,
        filters: [
          ...(state.filters?.filter((filter) => filter.name !== action.name) ||
            []),
          {
            id: `${Date.now()}`,
            name: action.name,
            companyResultItems: action.companyResultItems,
            isCurrent: action.isCurrent,
          },
        ],
      };
    case 'REMOVE_SEARCH_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => filter.name !== action.name),
      };
    case 'ADD_SCHOOL_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: [
          ...(state.filters?.filter((filter) => filter.name !== action.name) ||
            []),
          {
            id: `${Date.now()}`,
            name: action.name,
            schoolResultItems: action.schoolResultItems,
          },
        ],
      };
    case 'REMOVE_SCHOOL_DETAIL_SEARCH_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => filter.name !== action.name),
      };
    case 'UPDATE_PAGE':
      return {
        ...state,
        page: action.page,
      };
    case 'UPDATE_SORTING':
      return {
        ...state,
        sorting: action.sorting,
      };
    case 'ADD_FREE_TEXT_FILTER': {
      const existingFilterIndex = state.filters?.findIndex(
        (filter) => isFreeTextFilterState(filter) && filter.id === action.id
      );

      //replace filters
      if (action.id === null) {
        return {
          ...state,
          filters: [
            ...(state.filters || []).filter(
              (filter) => filter.name !== action.name
            ),
            {
              name: action.name,
              text: action.text,
              id: `${Date.now()}`,
              ...(typeof action.isCurrent === 'boolean'
                ? { isCurrent: action.isCurrent }
                : {}),
            },
          ],
        };
      }

      if (existingFilterIndex !== undefined && existingFilterIndex !== -1) {
        // Update existing filter
        const updatedFilters = [...(state.filters || [])];
        updatedFilters[existingFilterIndex] = {
          id: action.id,
          name: action.name,
          text: action.text,
          ...(typeof action.isCurrent === 'boolean'
            ? { isCurrent: action.isCurrent }
            : {}),
        };
        return {
          ...state,
          filters: updatedFilters,
        };
      } else {
        // Add new filter
        return {
          ...state,
          filters: [
            ...(state.filters || []),
            {
              id: action.id,
              name: action.name,
              text: action.text,
              ...(typeof action.isCurrent === 'boolean'
                ? { isCurrent: action.isCurrent }
                : {}),
            },
          ],
        };
      }
    }
    case 'REMOVE_FREE_TEXT_FILTER':
      return {
        ...state,
        filters: state.filters?.filter((filter) => {
          if (isFreeTextFilterState(filter)) {
            return filter.id !== action.id;
          }
          return true;
        }),
      };
    default:
      return state;
  }
};
