import {
  CompositionV2DataQuery,
  PostingsDataQuery,
  TransitionDataQuery,
} from '@revelio/data-access';
import { StackedBarChartHorizontal } from '@revelio/replots';

import { MetricValues, SecondaryDimensionValues } from '../config-provider';
import { isGroupedBarMetric } from '../config-provider/utils';
import { ChartNotSupported } from './chart-not-supported';
import { getStackedBarData, getTransitionStackedBarData } from './utils';

type StackedBarProps = {
  data:
    | CompositionV2DataQuery
    | PostingsDataQuery
    | TransitionDataQuery
    | undefined;
  secondaryDimension: SecondaryDimensionValues | undefined;
  metric: MetricValues;
  stopResizeMonitoring?: boolean;
};

const isCompositionV2Data = (
  data:
    | CompositionV2DataQuery
    | PostingsDataQuery
    | TransitionDataQuery
    | undefined
): data is CompositionV2DataQuery => {
  return data !== undefined && 'composition_v2' in data;
};

const isTransitionData = (
  data:
    | CompositionV2DataQuery
    | PostingsDataQuery
    | TransitionDataQuery
    | undefined
): data is TransitionDataQuery => {
  return data !== undefined && 'transitions2D' in data;
};

export const StackedBar = ({
  data,
  secondaryDimension,
  metric,
  stopResizeMonitoring,
}: StackedBarProps) => {
  const isGroupedBar = isGroupedBarMetric({ metric, secondaryDimension });
  if (!isGroupedBar) {
    return <ChartNotSupported />;
  }

  const stackedBarChartData = (() => {
    if (!data || !secondaryDimension) return [];
    if (isCompositionV2Data(data)) {
      return getStackedBarData({
        entities: data.composition_v2?.entities ?? [],
        plotName: secondaryDimension,
        formatLabel:
          secondaryDimension === 'skills'
            ? (label) => label.split('/').shift() ?? label
            : undefined,
      });
    }

    if (isTransitionData(data)) {
      const transitionMetrics = data.transitions2D?.[0]?.metrics;
      if (!transitionMetrics) return [];

      return getTransitionStackedBarData({
        transitionMetrics,
        secondaryDimension,
      });
    }

    return [];
  })();

  return (
    <StackedBarChartHorizontal
      data={stackedBarChartData}
      hideLegend={stopResizeMonitoring}
    />
  );
};
