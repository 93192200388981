import { TalentDiscoveryAiSearchFilterType } from '@revelio/data-access';
import {
  SelectionCategories,
  SelectionList,
  findSelectionListItemByLabel,
} from '@revelio/filtering';

interface SkipSelectionListConfig {
  selectionListId: SelectionCategories | string;
}

const GRANULARITY_PREFERENCES = {
  rics: ['k10', 'k50', 'k400'],
  skill: ['k75', 'k700', 'k3000'],
  role: ['job_category', 'k150', 'k1500'],
} as const;

export const getGranularityPreference = (selectionListId: string) => {
  if (
    selectionListId.startsWith('role_') ||
    selectionListId.startsWith('job_category')
  ) {
    return GRANULARITY_PREFERENCES.role;
  }

  if (selectionListId.startsWith('rics_')) {
    return GRANULARITY_PREFERENCES.rics;
  }

  if (selectionListId.startsWith('skill_')) {
    return GRANULARITY_PREFERENCES.skill;
  }

  return null;
};

/**
 * Finds matching tree items across selection lists for a given filter label
 */
export const findMatchingTreeItems = (
  aiFilterSearchFilterLabel: TalentDiscoveryAiSearchFilterType,
  relevantSelectionLists: SelectionList[],
  skipSelectionList?: SkipSelectionListConfig
) => {
  // First find all matches across selection lists
  const allMatches = relevantSelectionLists
    .map((selectionList) => {
      if (skipSelectionList?.selectionListId === selectionList.id) {
        return null;
      }

      const match = findSelectionListItemByLabel({
        labelToFind: aiFilterSearchFilterLabel.name,
        selectionList: selectionList,
      });

      return match && match.length > 0 ? match[0] : null;
    })
    .filter((item): item is NonNullable<typeof item> => item !== null);

  if (allMatches.length === 0) {
    return [];
  }

  // Find the best score
  const scores = allMatches.map((m) => m.closeness_score ?? 1);
  const bestScore = Math.min(...scores);

  // Get matches that are very close to the best score
  const closeMatches = allMatches.filter(
    (match) => Math.abs((match.closeness_score ?? 1) - bestScore) <= 0.0001
  );

  // If we have multiple close matches, check if they're from a hierarchical selection list
  if (closeMatches.length > 1) {
    // Get the type of hierarchy we're dealing with (if any)
    const firstMatch = closeMatches[0];
    const granularityPreference =
      firstMatch && getGranularityPreference(firstMatch.selectionListId);

    if (granularityPreference) {
      // Try to find a match for each granularity level in order of preference
      for (const granularity of granularityPreference) {
        const matchAtGranularity = closeMatches.find((m) =>
          m.selectionListId.includes(granularity)
        );
        if (matchAtGranularity) {
          return [matchAtGranularity];
        }
      }
    }
  }

  // If no granularity preference applies, return top match
  return closeMatches;
};

/**
 * Gets the closest tree item from a list of matching tree items based on closeness score
 */
export const getClosestTreeItem = (
  matchedTreeItems: ReturnType<typeof findMatchingTreeItems>
) => {
  return matchedTreeItems.sort(
    (a, b) =>
      //lower closeness score is better, defaulting to 100 so values with missing scores are not picked
      (a.closeness_score || 100) - (b.closeness_score || 100)
  )[0];
};
