import React from 'react';

import { roleBranchLabels } from '../../../engine/filters.constants';
import { CustomRoleTaxonomyId } from '../../../engine/selection-lists/types';
import { BasePopoutTreeFilter } from '../BasePopoutTreeFilter';
import { BasePopoutTreeCommonProps } from '../types';
import { useCustomRoleFilter } from './use-custom-role-filter';

export interface CustomRolePopoutTreeFilterProps
  extends BasePopoutTreeCommonProps {
  taxonomyId: CustomRoleTaxonomyId;
}

export const CustomRolePopoutTreeFilter: React.FC<
  CustomRolePopoutTreeFilterProps
> = ({
  taxonomyId,
  filterLabel,
  maxSelections,
  minSelections,
  disabledLevels,
  disallowMultiLevelSelect,
  sortSuggestedSearch,
  branches,
  isOpen,
  onClose,
  className,
}) => {
  const { treeData, activeIds, applySelectedIds } =
    useCustomRoleFilter(taxonomyId);

  return (
    <BasePopoutTreeFilter
      treeData={treeData}
      activeIds={activeIds}
      onSubmit={applySelectedIds}
      filterLabel={filterLabel}
      maxSelections={maxSelections}
      minSelections={minSelections}
      disabledLevels={disabledLevels}
      branches={branches || roleBranchLabels}
      disallowMultiLevelSelect={disallowMultiLevelSelect}
      sortSuggestedSearch={sortSuggestedSearch}
      isOpen={isOpen}
      onClose={onClose}
      className={className}
    />
  );
};
