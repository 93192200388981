import { SecondaryDimensionValues } from '../types';
import {
  CompositionMetricValues,
  MetricValues,
  PostingMetricValues,
  SentimentMetricValues,
  TransitionMetricValues,
  VolumeMetricValues,
} from '../types/metric';

export type BarMetricValues =
  | CompositionMetricValues
  | PostingMetricValues
  | SentimentMetricValues;

export type LineMetricValues =
  | CompositionMetricValues
  | PostingMetricValues
  | SentimentMetricValues;

export type SankeyMetricValues = TransitionMetricValues;

export type DistributionMetricValues = 'pay_distribution';

export type StackedBarMetricValues = 'headcount';

const barMetricValues: Record<BarMetricValues, boolean> = {
  headcount: true,
  growth_rate: true,
  hiring_rate: true,
  attrition_rate: true,
  tenure: true,
  salary: true,
  posting_salary: true,
  time_to_fill: true,
  postings_active: true,
  postings_new: true,
  postings_removed: true,
  overall: true,
  business_outlook: true,
  career_growth: true,
  compensation: true,
  benefits: true,
  culture: true,
  work_life_balance: true,
  diversity_and_inclusion: true,
  leadership: true,
};

export const isBarMetric = (metric: MetricValues): metric is BarMetricValues =>
  !!barMetricValues[metric as BarMetricValues];

const lineMetricValues = barMetricValues;
export const isLineMetric = (
  metric: MetricValues
): metric is LineMetricValues => !!lineMetricValues[metric as LineMetricValues];

export const isSankeyMetric = (
  metric: MetricValues
): metric is SankeyMetricValues => metric === 'transitions';

export const isVolumeMetric = (
  metric: MetricValues
): metric is VolumeMetricValues => metric === 'postings_volume';

export const isDistributionMetric = (
  metric: MetricValues
): metric is DistributionMetricValues => metric === 'pay_distribution';

export const isStackedBarMetric = (
  metric: MetricValues
): metric is StackedBarMetricValues => metric === 'headcount';

export type GroupedBarMetricValues =
  | Extract<CompositionMetricValues, 'headcount'>
  | Extract<PostingMetricValues, 'postings_active'>
  | TransitionMetricValues;
export type GroupedBarSecondaryDimensionValues = SecondaryDimensionValues;

export const groupedBarOptions: Map<
  GroupedBarMetricValues,
  GroupedBarSecondaryDimensionValues[]
> = new Map([
  [
    'headcount',
    [
      'rics',
      'geography',
      'role',
      'skills',
      'gender',
      'ethnicity',
      'education',
      'seniority',
    ],
  ],
  ['postings_active', ['role']],
  [
    'transitions',
    ['role', 'geography', 'seniority', 'gender', 'ethnicity', 'education'],
  ],
]);

export const isGroupedBarMetric = ({
  metric,
  secondaryDimension,
}: {
  metric: MetricValues;
  secondaryDimension: SecondaryDimensionValues | undefined;
}) => {
  if (!secondaryDimension) return false;

  const secondaryDimensionValues = groupedBarOptions.get(
    metric as GroupedBarMetricValues
  );
  return secondaryDimensionValues?.includes(secondaryDimension);
};
