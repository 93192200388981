import { useMemo } from 'react';
import { CombinedError, useQuery } from 'urql';

import { PrimaryView, TimeFrameView } from '@revelio/core';
import { CompositionV2DataQuery } from '@revelio/data-access';
import {
  COMPOSITION_GET_DATA_V2,
  FilterItem,
  FilterName,
  LocalSelectionCategories,
  SelectionCategories,
  useActiveColors,
  useActiveFiltersState,
  useAllFiltersState,
  useRoleTaxonomySetting,
  useSelectionListsData,
} from '@revelio/filtering';

import { useIsSlowLoad } from '../../../../../reveliolabs-dashboard/src/hooks/open-replay';
import { transformFiltersToCompositionV2 } from '../utils';
import { useGlobalLoadController } from './use-global-load-controller';
import { useIsQueryReady } from './use-is-query-ready';

const selectionListsIds = [
  SelectionCategories.SKILL_K75,
  SelectionCategories.SKILL_K700,
  SelectionCategories.SKILL_K3000,
  SelectionCategories.REGION,
  SelectionCategories.COUNTRY,
  SelectionCategories.METRO_AREA,
  SelectionCategories.JOB_CATEGORY,
  SelectionCategories.ROLE_K50,
  SelectionCategories.ROLE_K500,
  SelectionCategories.INDUSTRY,
  SelectionCategories.RICS_K10,
  SelectionCategories.RICS_K50,
  SelectionCategories.RICS_K400,
];

type DataFetch = {
  lineColors: string[];
  data: CompositionV2DataQuery | undefined;
  loading: boolean;
  error: CombinedError | undefined;
  isQueryReady: boolean;
};
export const useCompositionDataFetchV2 = ({
  view,
  timeframe,
  primaryFilters,
}: {
  view: PrimaryView;
  timeframe: TimeFrameView;
  primaryFilters: FilterName[];
}): DataFetch => {
  const isSnapshot = timeframe === TimeFrameView.SNAPSHOT;

  const activeFilters = useActiveFiltersState();
  const allFilters = useAllFiltersState();

  /** Get all filters relevant for query */
  const filters = useMemo(() => {
    const snapshotDateFilter = allFilters.find(
      (filter) => filter.id === SelectionCategories.SNAPSHOT_DATE
    );
    const dateRangeFilter = allFilters.find(
      (filter) => filter.id === SelectionCategories.DATE_RANGE
    );
    const dateFilter = isSnapshot ? snapshotDateFilter : dateRangeFilter;

    return [...activeFilters, ...(dateFilter ? [dateFilter] : [])];
  }, [activeFilters, allFilters, isSnapshot]);

  /** Convert filters into gql variables */
  const selectionListsData = useSelectionListsData(selectionListsIds);
  const { isEnabled: isCustomRoleTaxonomyEnabled } = useRoleTaxonomySetting();

  const queryFilters = useMemo(() => {
    return transformFiltersToCompositionV2({
      view,
      isSnapshot,
      filters,
      selectionListsData,
      isCustomRoleTaxonomyEnabled,
    });
  }, [
    view,
    filters,
    selectionListsData,
    isSnapshot,
    isCustomRoleTaxonomyEnabled,
  ]);

  /** Fetch data */
  const isQueryReady = useIsQueryReady({
    filters,
    primaryFilters,
    timeframe,
    view,
  });

  const [{ data, fetching, error, ...restProps }] = useQuery({
    query: COMPOSITION_GET_DATA_V2,
    variables: queryFilters,
    pause: !isQueryReady,
  });

  const fetchOptions = restProps?.operation?.context?.fetchOptions as {
    headers: { ['x-request-id']: string | undefined };
  };

  useIsSlowLoad(
    'COMPOSITION_GET_DATA_V2',
    fetching,
    fetchOptions?.headers?.['x-request-id']
  );

  /** Handle fetch and chart load state */
  const hasFetchBeenCalled = fetching || !!data || !!error;
  const loading = fetching || !hasFetchBeenCalled;
  useGlobalLoadController(loading);

  const sortedData = useMemo(() => {
    const primaryEntities = allFilters.find(
      (filter) => filter.id === LocalSelectionCategories.PRIMARY_ENTITIES
    )?.value as FilterItem[];

    return {
      ...data,
      composition: data?.composition_v2
        ? [...data.composition_v2.entities].sort((a, b) => {
            const indexA = primaryEntities.findIndex(
              (primaryEntity) =>
                primaryEntity.id === a?.metadata?.id?.toString()
            );
            const indexB = primaryEntities.findIndex(
              (primaryEntity) =>
                primaryEntity.id === b?.metadata?.id?.toString()
            );
            return indexA - indexB;
          })
        : undefined,
    };
  }, [data, allFilters]);

  const { colorMap } = useActiveColors(primaryFilters as SelectionCategories[]);
  const lineColors =
    sortedData.composition?.map((entity) => {
      return colorMap[entity?.metadata?.short_name ?? ''];
    }) ?? [];

  return {
    lineColors,
    data: sortedData,
    loading,
    error,
    isQueryReady,
  };
};
