/* eslint-disable no-nested-ternary */
import {
  Box,
  Flex,
  Tag,
  TagCloseButton,
  TagLabel,
  useToast,
} from '@chakra-ui/react';
import { get, isUndefined, replace, startCase, truncate } from 'lodash';
import debounce from 'lodash/debounce';

import { authStore } from '@revelio/auth';
import { Views } from '@revelio/core';

import { ViewTypes } from '../../data-api/data-api.model';
import { metricModeDefault } from '../../engine/filters.constants';
import { getProviderDefault } from '../../engine/filters.deepLinks';
import {
  FilterTypes,
  LocalSelectionCategories,
  SelectFilter,
  SelectionCategories,
} from '../../engine/filters.model';
import {
  deleteFilters,
  removeSelectFilterValue,
  upsertFilter,
} from '../../engine/filters.repository';
import { MAX_START_DATE } from '../date-range/helpers';
import { SelectionCategoryLabelOverrides } from '../tree/nested-tree/node-submenu/node-submenu';
import { pluralize } from '../utils/pluralize';
import { AggregateChip } from './aggregate-chip/aggregate-chip';
import { DateLabel, handleRemoveSnapshotDateFilter } from './date-chip';
import { DateRangeChip, handleRemoveDateRangeFilter } from './date-range-chip';

export interface FilterChipProps {
  filterItem: any;
  filterName: SelectionCategories;
  variant?: string;
  color?: string;
  isSingleChip?: boolean;
  isMin?: boolean;
  min?: number;
  isPrimaryChip?: boolean;
  propsView?: Views;
  viewType?: ViewTypes;
  showGranularity?: boolean;
  isAggregateChip?: boolean;
  isDisabled?: boolean;
}

export function FilterChip({
  filterItem,
  filterName,
  variant,
  color,
  isSingleChip = true,
  isMin,
  min,
  isPrimaryChip = false,
  propsView,
  viewType,
  showGranularity = false,
  isAggregateChip = false,
  isDisabled = false,
  ...rest
}: FilterChipProps) {
  const toast = useToast();

  // closing the provider filter chip resets to default
  const handleCloseProviderFilter = debounce(
    (e) => {
      e.stopPropagation();
      const userHasWebsitePostings = authStore.getValue().user?.linkup_postings;
      const providerDefault = getProviderDefault(
        !!userHasWebsitePostings
      ).provider;
      upsertFilter(LocalSelectionCategories.PROVIDER, {
        ...providerDefault,
        isUserSubmitted: false,
      });
    },
    400,
    { leading: true }
  );

  // closing the metric mode filter chip resets to default
  const handleCloseMetricModeFilter = debounce(
    (e) => {
      e.stopPropagation();

      upsertFilter<SelectFilter>(LocalSelectionCategories.METRIC_MODE, {
        isMulti: false,
        type: FilterTypes.SELECT,
        selectionListId: LocalSelectionCategories.METRIC_MODE,
        value: metricModeDefault.value,
        isUserSubmitted: false,
      });
    },
    400,
    { leading: true }
  );

  const handleRemoveFilter = debounce(
    () => {
      return isMin
        ? toast({
            position: 'top-right',
            status: 'warning',
            title: `${min} ${min === 1 ? 'Selection' : 'Selections'} Required`,
            description: `At least ${min} ${
              min === 1 ? 'Selection is' : 'Selections are'
            } required.`,
            isClosable: true,
            variant: 'subtle',
          })
        : removeSelectFilterValue(filterName, filterItem.id);
    },
    400,
    { leading: true }
  );

  const handleRemoveEntireFilter = debounce(
    (e) => {
      e?.stopPropagation && e.stopPropagation();

      const linkedFilters = filterItem.linkedFilters;

      const linkedFilterNames =
        Array.isArray(linkedFilters) && !isUndefined(linkedFilters)
          ? linkedFilters
          : get(filterItem.linkedFilters, 'filters');

      const filtersToDelete = linkedFilterNames || [filterName];

      return deleteFilters(filtersToDelete);
    },
    400,
    { leading: true }
  );

  const formatVal = (val: number, category: SelectionCategories) => {
    if (
      [
        SelectionCategories.HIRING_RATE,
        SelectionCategories.ATTRITION_RATE,
        SelectionCategories.GROWTH_RATE,
      ].includes(category)
    ) {
      return `${val.toLocaleString()}%`;
    } else if ([SelectionCategories.AVERAGE_TENURE].includes(category)) {
      return `${val.toLocaleString()} years`;
    } else if ([SelectionCategories.AVERAGE_SALARY].includes(category)) {
      return `$${val.toLocaleString()}`;
    } else {
      return val.toLocaleString();
    }
  };

  const onClickClose = (() => {
    if (!isSingleChip && isPrimaryChip) {
      return handleRemoveFilter;
    }

    if (
      [
        SelectionCategories.DATE_RANGE,
        SelectionCategories.DATE_RANGE_FULL,
      ].includes(filterName) &&
      !isAggregateChip
    ) {
      return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        handleRemoveDateRangeFilter(e, {
          propsView,
          viewType,
          dateKey: filterName as
            | SelectionCategories.DATE_RANGE
            | SelectionCategories.DATE_RANGE_FULL,
        });
    }

    if (
      LocalSelectionCategories.PROVIDER ===
      (filterName as unknown as LocalSelectionCategories)
    ) {
      return handleCloseProviderFilter;
    }

    if (
      LocalSelectionCategories.METRIC_MODE ===
      (filterName as unknown as LocalSelectionCategories)
    ) {
      return handleCloseMetricModeFilter;
    }

    if ([SelectionCategories.SNAPSHOT_DATE].includes(filterName)) {
      return (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) =>
        handleRemoveSnapshotDateFilter(e, { propsView, viewType });
    }

    return handleRemoveEntireFilter;
  })();

  return (
    <Tag
      data-testid={`filter-chip-${filterName}`}
      variant={isDisabled ? 'filterChipDisabled' : variant}
      as="button"
      size={variant === 'companyChip' ? 'sm' : 'md'}
      cursor={isDisabled ? 'default' : 'pointer'}
    >
      <TagLabel
        opacity={isDisabled ? 0.5 : 1}
        pointerEvents={isDisabled ? 'none' : 'auto'}
        userSelect="none"
        textAlign="left"
      >
        {isAggregateChip ? (
          <AggregateChip filterItem={filterItem} />
        ) : filterName === SelectionCategories.SNAPSHOT_DATE ? (
          <DateLabel formattedDate={filterItem.value} />
        ) : [
            SelectionCategories.DATE_RANGE,
            SelectionCategories.DATE_RANGE_FULL,
          ].includes(filterName) ? (
          <DateRangeChip
            formattedStartDate={filterItem.value.startDate}
            formattedEndDate={filterItem.value.endDate}
          />
        ) : isSingleChip && filterName === SelectionCategories.INDUSTRY ? (
          <>
            {replace(startCase(filterItem.label), 'Amp', '&')}
            {` \u2022 ${
              filterItem.value.length > 1 ? 'is one of' : 'is'
            } \u2022 `}
            <Box as="span" color="lightBlue.600" fontWeight="semibold">
              {filterItem.value
                .map((v: any) => {
                  return startCase(
                    filterName === SelectionCategories.INDUSTRY
                      ? v.shortName
                      : v.label
                  );
                })
                .join(', ')}
            </Box>
          </>
        ) : !isSingleChip && isPrimaryChip ? (
          <Flex alignItems="center">
            {color && (
              <Flex
                h="8px"
                w="8px"
                bg={color}
                borderRadius="2px"
                mr="5px"
              ></Flex>
            )}

            {filterItem?.shortName}
          </Flex>
        ) : [
            SelectionCategories.HIRING_RATE,
            SelectionCategories.HEADCOUNT,
            SelectionCategories.ATTRITION_RATE,
            SelectionCategories.GROWTH_RATE,
            SelectionCategories.AVERAGE_TENURE,
            SelectionCategories.AVERAGE_SALARY,
          ].includes(filterName) ? (
          <>
            <Box as="span" color="lightBlue.600" fontWeight="semibold">
              {startCase(filterItem.label)}
            </Box>
            {` \u2022 for `}
            <Box as="span" color="lightBlue.600" fontWeight="semibold">
              {Object.values(filterItem.value.employeeTypes).every(
                (t: any) => t.length === 0
              )
                ? 'All Employees '
                : Object.keys(filterItem.value.employeeTypes)
                    .filter((f) => filterItem.value.employeeTypes[f].length > 0)
                    .map((k: string, i: number) =>
                      filterItem.value.employeeTypes[k].length === 0
                        ? null
                        : filterItem.value.employeeTypes[k].length === 1
                          ? filterItem.value.employeeTypes[k][0].shortName
                          : `${
                              filterItem.value.employeeTypes[k].length
                            } ${pluralize(startCase(k))}`
                    )
                    .join(', ')}
            </Box>
            {filterItem.value.date && (
              <>
                {` \u2022 ${
                  filterItem.value.date.filterName === 'Max'
                    ? 'since '
                    : 'over the '
                }
              `}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {filterItem.value.date.filterName === 'Max'
                    ? new Date(
                        Number(MAX_START_DATE.split('-')[0]),
                        Number(MAX_START_DATE.split('-')[1]) - 1
                      ).toLocaleDateString('en-US', {
                        month: 'short',
                        year: 'numeric',
                      })
                    : filterItem.value.date.label}
                </Box>
              </>
            )}

            {` \u2022 `}
            {filterItem.value.opValue.filterName === 'between' ? (
              <>
                {'from '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.startValue, filterName)}`}
                </Box>
                {' to '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.endValue, filterName)}`}
                </Box>
              </>
            ) : filterItem.value.opValue.filterName === 'less' ? (
              <>
                {filterItem.value.opValue.label}{' '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.endValue, filterName)}`}
                </Box>
              </>
            ) : (
              <>
                {filterItem.value.opValue.label}{' '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.startValue, filterName)}`}
                </Box>
              </>
            )}
          </>
        ) : [SelectionCategories.COUNT].includes(filterName) ? (
          <>
            <Box as="span" color="lightBlue.600" fontWeight="semibold">
              {startCase(filterItem.label)}
            </Box>
            {filterItem.value.date && (
              <>
                {` \u2022 ${
                  filterItem.value.date.filterName === 'Max'
                    ? 'since '
                    : 'over the '
                }
            `}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {filterItem.value.date.filterName === 'Max'
                    ? new Date(
                        Number(MAX_START_DATE.split('-')[0]),
                        Number(MAX_START_DATE.split('-')[1]) - 1
                      ).toLocaleDateString('en-US', {
                        month: 'short',
                        year: 'numeric',
                      })
                    : filterItem.value.date.label}
                </Box>
              </>
            )}

            {` \u2022 `}
            {filterItem.value.opValue.filterName === 'between' ? (
              <>
                {'from '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.startValue, filterName)}`}
                </Box>
                {' to '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.endValue, filterName)}`}
                </Box>
              </>
            ) : filterItem.value.opValue.filterName === 'less' ? (
              <>
                {filterItem.value.opValue.label}{' '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.endValue, filterName)}`}
                </Box>
              </>
            ) : (
              <>
                {filterItem.value.opValue.label}{' '}
                <Box as="span" color="lightBlue.600" fontWeight="semibold">
                  {`${formatVal(filterItem.value.startValue, filterName)}`}
                </Box>
              </>
            )}
          </>
        ) : (
          <>
            {getFilterChipLabel(filterItem)}
            <Box as="span" color="lightBlue.600" fontWeight="semibold">
              {(() => {
                const filterValues = getFilterItemValues(filterItem);

                const MAX_DISPLAY_COUNT = 3;
                const displayedValues = filterValues
                  ?.slice(0, MAX_DISPLAY_COUNT)
                  .map((val: any) => {
                    return truncate(val?.shortName || val?.label, {
                      length: 100,
                    });
                  });

                const additionalCount =
                  filterValues && filterValues.length > MAX_DISPLAY_COUNT
                    ? ` +${filterValues.length - MAX_DISPLAY_COUNT}`
                    : '';
                const granularity = showGranularity
                  ? ` (${getFilterItemGranularity(filterItem)})`
                  : '';

                return `${displayedValues?.join(', ')}${additionalCount}${granularity}`;
              })()}
            </Box>
          </>
        )}
      </TagLabel>

      <TagCloseButton
        as={Box}
        cursor="pointer"
        onClick={onClickClose}
        data-testid="clear-filter-chip"
      />
    </Tag>
  );
}

function getFilterChipLabel(filterItem: any) {
  if (filterItem.id === LocalSelectionCategories.DATA_METRIC) {
    return `${replace(startCase(filterItem.label), 'Amp', '&')} \u2022 is \u2022 `;
  }

  if (filterItem.id === SelectionCategories.CUSTOM_ROLE) {
    return `Role \u2022 is${
      filterItem.value.entities.length > 1 ? ' one of' : ''
    } \u2022 `;
  }

  return `${replace(startCase(filterItem.label), 'Amp', '&')} \u2022 is${
    filterItem.value.length > 1 ? ' one of' : ''
  } \u2022 `;
}

function getFilterItemValues(filterItem: any) {
  if (filterItem.value.entities) {
    return filterItem.value.entities;
  }

  if (filterItem.isMulti) {
    return filterItem.value;
  }

  if (filterItem.value && typeof filterItem.value === 'object') {
    return [filterItem.value];
  }

  return null;
}

function getFilterItemGranularity(filterItem: any) {
  if (filterItem.value.entities) {
    const levelId = get(filterItem?.value?.entities?.[0], 'levelId');
    return (
      SelectionCategoryLabelOverrides[levelId] || levelId?.split('_')?.pop()
    );
  }

  return (
    SelectionCategoryLabelOverrides[get(filterItem, 'selectionListId')] ||
    get(filterItem, 'selectionListId')
  );
}

export default FilterChip;
