import { useRef, useState } from 'react';

import {
  TalentDiscoveryAiFilterSearchResponse,
  TalentDiscoveryAiSearchFilterType,
} from '@revelio/data-access';
import { RevelioTooltip } from '@revelio/layout';

interface Props {
  prompt: string;
  response: TalentDiscoveryAiFilterSearchResponse;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
}

export const ResponseHighlightedOverlay = ({
  prompt,
  response,
  textAreaRef,
}: Props) => {
  const segments = getHighlightedSegments({ prompt, response });
  const [hoveredPromptIndex, setHoveredPromptIndex] = useState<number | null>(
    null
  );
  const overlayRef = useRef<HTMLSpanElement>(null);

  // Handle clicks on the entire overlay area
  const handleOverlayClick = (e: React.MouseEvent<HTMLSpanElement>) => {
    // Stop event propagation to prevent the parent Box's click handler from firing
    e.stopPropagation();

    if (!textAreaRef.current || !overlayRef.current) return;

    // Find the element that was clicked
    const targetElement = document.elementFromPoint(e.clientX, e.clientY);
    if (!targetElement || !overlayRef.current.contains(targetElement)) return;

    // Check if we clicked on a highlighted element with a data attribute
    let element: HTMLElement | null = targetElement as HTMLElement;
    while (
      element &&
      !element.dataset.highlightStartIndex &&
      element !== overlayRef.current
    ) {
      element = element.parentElement;
    }

    let position = 0;

    if (element && element.dataset.highlightStartIndex) {
      // We clicked on a highlighted element - calculate position based on data attribute
      position = parseInt(element.dataset.highlightStartIndex, 10);

      // Add approximate offset based on click position
      const rect = element.getBoundingClientRect();
      const clickOffset = e.clientX - rect.left;
      const approxCharPosition = Math.round(
        clickOffset / (rect.width / (element.textContent?.length || 1))
      );
      position += Math.min(
        approxCharPosition,
        element.textContent?.length || 1
      );
    } else {
      // For plain text spans, find its position by counting previous text
      let textBefore = 0;

      // Find all text spans and highlighted spans
      const allTextElements = Array.from(
        overlayRef.current.querySelectorAll('span')
      ).filter((span) => !span.querySelector('span')); // Exclude container spans like tooltip wrappers

      // Calculate position by summing up text content lengths
      for (const span of allTextElements) {
        if (span === targetElement || span.contains(targetElement)) {
          // This is the clicked span - estimate position within this span
          const rect = span.getBoundingClientRect();
          const clickOffset = e.clientX - rect.left;
          const approxCharPosition = Math.round(
            clickOffset / (rect.width / (span.textContent?.length || 1))
          );
          position =
            textBefore +
            Math.min(approxCharPosition, span.textContent?.length || 1);
          break;
        }

        textBefore += span.textContent?.length || 0;
      }
    }

    // Ensure position is within bounds
    position = Math.max(0, Math.min(position, prompt.length));

    // Focus the textarea and set cursor position
    textAreaRef.current.focus();
    textAreaRef.current.setSelectionRange(position, position);
  };

  // This will take the segments and create a combined rendering approach
  const renderHighlightedText = () => {
    // Sort segments by start index to process them in order
    const sortedSegments = [...segments].sort(
      (a, b) => a.startIndex - b.startIndex
    );

    const result = [];
    let lastIndex = 0;

    sortedSegments.forEach((segment, idx) => {
      // Add any text before this segment
      if (segment.startIndex > lastIndex) {
        result.push(
          <span key={`plain-${lastIndex}`} style={{ cursor: 'text' }}>
            {prompt.substring(lastIndex, segment.startIndex)}
          </span>
        );
      }

      // Determine highlight type for test data attribute
      let highlightType = 'unmatched';
      if (segment.isMatched) {
        highlightType = 'matched';
      } else if (segment.isPartialMatch) {
        highlightType = 'partial-match';
      }

      const highlightText = prompt.substring(
        segment.startIndex,
        segment.endIndex
      );

      const style = {
        color: getTextColor(segment.isMatched, !!segment.isPartialMatch),
        borderBottom: getBorderBottom(
          segment.isMatched,
          !!segment.isPartialMatch
        ),
        cursor: 'text',
        fontWeight: '400',
      };

      const highlightElement = (
        <span
          key={`highlight-${idx}`}
          data-testid={`ai-highlight-${highlightType}`}
          data-highlight-text={highlightText}
          data-highlight-start-index={segment.startIndex}
          style={style}
          onMouseOver={() => setHoveredPromptIndex(segment.startIndex)}
          onMouseLeave={() => setHoveredPromptIndex(null)}
        >
          {highlightText}
        </span>
      );

      // Wrap in tooltip if there's an explanation
      if (segment.explanation) {
        result.push(
          <RevelioTooltip
            key={`tooltip-${idx}`}
            label={segment.explanation}
            isDisabled={!segment.explanation}
            isOpen={hoveredPromptIndex === segment.startIndex}
          >
            {highlightElement}
          </RevelioTooltip>
        );
      } else {
        result.push(highlightElement);
      }

      lastIndex = segment.endIndex;
    });

    // Add any remaining text
    if (lastIndex < prompt.length) {
      result.push(
        <span key={`plain-${lastIndex}`} style={{ cursor: 'text' }}>
          {prompt.substring(lastIndex)}
        </span>
      );
    }

    return result;
  };

  return (
    <span
      ref={overlayRef}
      onClick={handleOverlayClick}
      style={{ userSelect: 'none' }}
      data-testid="ai-highlight-overlay"
    >
      {renderHighlightedText()}
    </span>
  );
};

interface HighlightInfo {
  text: string; // only for debugging purposes to make it more readable when logging segments
  isMatched: boolean;
  isPartialMatch?: boolean; // New flag for partially matched segments
  startIndex: number;
  endIndex: number;
  explanation?: string;
}

const getHighlightedSegments = ({
  prompt,
  response,
}: {
  prompt: string;
  response: TalentDiscoveryAiFilterSearchResponse;
}): HighlightInfo[] => {
  const segments: HighlightInfo[] = [];
  const matchedPromptTexts = new Set<string>();

  // Helper function to process a single filter
  const processFilter = ({
    relevantPromptText,
  }: TalentDiscoveryAiSearchFilterType) => {
    const index = prompt
      .toLowerCase()
      .indexOf(relevantPromptText.toLowerCase());

    if (
      index !== -1 &&
      !segments.find((segment) => segment.text === relevantPromptText)
    ) {
      segments.push({
        text: relevantPromptText,
        isMatched: true,
        startIndex: index,
        endIndex: index + relevantPromptText.length,
      });

      // Track matched prompt texts to detect partial matches later
      matchedPromptTexts.add(relevantPromptText.toLowerCase());
    }
  };

  // Process all known filters
  Object.entries(response.filters).forEach(([filterType, filterValue]) => {
    if (!filterValue) return;

    if (Array.isArray(filterValue)) {
      // Handle nested arrays (like skills and keywords)
      if (filterValue.length > 0 && Array.isArray(filterValue[0])) {
        (filterValue as TalentDiscoveryAiSearchFilterType[][]).forEach(
          (filterGroup) => {
            filterGroup.forEach(processFilter);
          }
        );
      } else {
        // Handle regular arrays
        filterValue.forEach((v) =>
          processFilter(v as TalentDiscoveryAiSearchFilterType)
        );
      }
    } else {
      // Handle single filters (like RangeFilter)
      processFilter(filterValue as TalentDiscoveryAiSearchFilterType);
    }
  });

  // Handle unknown filters
  response.unknownFilters?.forEach(({ relevantPromptText, explanation }) => {
    const index = prompt
      .toLowerCase()
      .indexOf(relevantPromptText.toLowerCase());

    if (index !== -1) {
      // Check if this is a partial match (exists in both filters and unknownFilters)
      const isPartialMatch = matchedPromptTexts.has(
        relevantPromptText.toLowerCase()
      );

      const existingSegmentIndex = segments.findIndex(
        (segment) => segment.text === relevantPromptText
      );
      if (existingSegmentIndex === -1) {
        segments.push({
          text: relevantPromptText,
          isMatched: false,
          isPartialMatch, // Set the partial match flag
          explanation: explanation,
          startIndex: index,
          endIndex: index + relevantPromptText.length,
        });
      } else {
        segments[existingSegmentIndex] = {
          ...segments[existingSegmentIndex],
          isMatched: false,
          isPartialMatch: true,
          explanation: explanation,
        };
      }
    }
  });

  return segments;
};

// Helper functions to avoid nested ternaries
const getBorderBottom = (
  isMatched: boolean,
  isPartialMatch: boolean
): string | undefined => {
  if (isMatched) {
    return undefined;
  }
  return isPartialMatch ? '1px solid #FF9633' : '1px solid #EE002B';
};

const getTextColor = (isMatched: boolean, isPartialMatch: boolean): string => {
  if (isMatched) {
    return '#16BD5E';
  }
  return isPartialMatch ? '#FF9633' : '#EE002B';
};
