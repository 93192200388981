import {
  Box,
  Button,
  ButtonGroup,
  Flex,
  HTMLChakraProps,
  IconButton,
  Textarea,
} from '@chakra-ui/react';
import { addYears } from 'date-fns';
import { noop } from 'lodash';
import LZString from 'lz-string';
import { useEffect, useRef, useState } from 'react';
import { FiThumbsDown, FiThumbsUp } from 'react-icons/fi';
import { useLocation } from 'react-router';
import { useCookie } from 'react-use';
import { useClient } from 'urql';

import { getAuthDashMetaCsrfToken } from '@revelio/auth';
import { TalentDiscoveryAiFilterSearchResponse } from '@revelio/data-access';
import {
  ALL_SUPPORTED_FILTERS,
  LocalSelectionCategories,
  SelectionCategories,
  useSelectionLists,
} from '@revelio/filtering';

import { loadAllTalentDiscoveryStateFromUrl } from '../../../useSyncTalentDiscoveryFIltersWithUrl';
import { useTalentDiscoveryFilter } from '../../td-filter-provider';
import {
  aiFilterSearchFeedbackRequest,
  aiFilterSearchRequest,
} from './ai-filter-search-api-request';
import { deserialiseApiToFilterState } from './deserialise-api-response/deserialise-api-response';
import { ResponseHighlightedOverlay } from './response-highlighted-overlay';
import SpeechInput from './speech-input';

export const AiFilterSearch = ({ closeMenu }: { closeMenu: () => void }) => {
  const {
    aiSearchState,
    setAiSearchState,
    isAiSearchLoading,
    setIsAiSearchLoading,
    abortControllerRef,
    setDefaultAiSearchSet,
  } = useTalentDiscoveryFilter();
  const csrfToken = getAuthDashMetaCsrfToken();
  const location = useLocation();
  const {
    dispatch,
    setAiGeneratedFilterIds,
    state,
    aiGeneratedFilterIds,
    defaultAiSearchSet,
  } = useTalentDiscoveryFilter();
  const gqlClient = useClient();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout>();

  const selectionLists = useSelectionLists([
    ...ALL_SUPPORTED_FILTERS,
    SelectionCategories.RICS_K400,
    LocalSelectionCategories.FLIGHT_RISK,
    LocalSelectionCategories.PRESTIGE,
    LocalSelectionCategories.REMOTE_SUITABILITY,
  ]);

  const handleTranscriptComplete = (transcript: string) => {
    setAiSearchState((prevState) => ({
      ...prevState,
      prompt: prevState.prompt
        ? `${prevState.prompt} ${transcript}`
        : transcript,
      response: null,
      uuid: '',
    }));
  };

  const handleSubmit = async () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    setDefaultAiSearchSet(false);
    if (defaultAiSearchSet && aiSearchState.uuid) {
      setText('');
      setAiSearchState((prevState) => ({
        ...prevState,
        prompt: '',
        response: null,
        uuid: '',
      }));
      textAreaRef.current?.focus();
      setIsFocused(true);
      return;
    }
    abortControllerRef.current = new AbortController();

    try {
      setIsAiSearchLoading(true);
      closeMenu();
      const { response, uuid } = await aiFilterSearchRequest({
        prompt: aiSearchState.prompt,
        csrfToken: csrfToken || '',
        signal: abortControllerRef.current.signal,
      });

      const { filterState: talentDiscoveryFilters, unknownFilters } =
        await deserialiseApiToFilterState(response, selectionLists, gqlClient);

      setAiGeneratedFilterIds(
        talentDiscoveryFilters.map((filter) => filter.id)
      );

      const stringifiedFilterState = JSON.stringify(talentDiscoveryFilters);

      const deeplink = `?filters=${LZString.compressToEncodedURIComponent(
        stringifiedFilterState
      )}`;

      if (deeplink !== location.search) {
        loadAllTalentDiscoveryStateFromUrl(
          dispatch,
          noop, // TODO: currently no support for columns in ai filter search
          new URLSearchParams(deeplink)
        );
      }

      setAiSearchState((prevState) => ({
        ...prevState,
        uuid,
        isCorrect: null,
        response: {
          ...response,
          unknownFilters: [
            ...(response.unknownFilters || []),
            ...unknownFilters,
          ],
        },
      }));
    } catch (error) {
      // Only log error if it's not an abort error
      if (error instanceof Error && error.name !== 'AbortError') {
        console.error(error);
      }
    } finally {
      setIsAiSearchLoading(false);
      abortControllerRef.current = undefined;
    }
  };

  const handleIsAiSearchCorrect = ({ isCorrect }: { isCorrect: boolean }) => {
    if (isCorrect === aiSearchState.isCorrect) {
      return;
    }

    aiFilterSearchFeedbackRequest({
      uuid: aiSearchState.uuid,
      isCorrect,
      csrfToken: csrfToken || '',
    });
    setAiSearchState((prevState) => ({
      ...prevState,
      isCorrect,
    }));
  };

  const areAiFiltersUntouched =
    state.filters?.every((filter) =>
      aiGeneratedFilterIds.includes(filter.id)
    ) &&
    aiGeneratedFilterIds.every((id) =>
      state.filters?.some((filter) => filter.id === id)
    );

  const showingHighlighedOverlay =
    aiSearchState.response && !isFocused && areAiFiltersUntouched;

  useEffect(() => {
    if (!showingHighlighedOverlay && textAreaRef.current) {
      textAreaRef.current.focus();
    }
  }, [showingHighlighedOverlay]);

  const fullText = aiSearchState.prompt;
  const [text, setText] = useState('');
  const [isAnimating, setIsAnimating] = useState(false);
  const [aiWriterDone, updateAiWriterDone] = useCookie('ai-writer');

  useEffect(() => {
    if (aiWriterDone !== null || !defaultAiSearchSet) {
      setText(fullText);
      return;
    }

    const initialDelay = 250;
    const writeSpeed = 50;
    setTimeout(() => {
      setIsAnimating(true);
      let index = 0;
      intervalRef.current = setInterval(() => {
        if (index < fullText.length) {
          setText(fullText.slice(0, index + 1));
          index++;
        } else {
          if (intervalRef.current) {
            clearInterval(intervalRef.current);
          }
          updateAiWriterDone('true', { expires: addYears(new Date(), 1) });
          setIsAnimating(false);
        }
      }, writeSpeed);
    }, initialDelay);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [aiWriterDone, fullText, defaultAiSearchSet, updateAiWriterDone]);

  const handleTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newValue = e.target.value;
    setText(newValue);
    setAiSearchState((prevState) => ({
      ...prevState,
      prompt: newValue,
      response: null,
      uuid: '',
    }));
  };

  return (
    <>
      <Box
        position="relative"
        data-testid={`ai-filter-animation-${isAnimating ? 'running' : 'complete'}`}
      >
        <Textarea
          spellCheck={!showingHighlighedOverlay} // clashes with highlighted overlay underlining
          ref={textAreaRef}
          value={isAnimating ? text : aiSearchState.prompt}
          isDisabled={isAiSearchLoading}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={handleTextChange}
          placeholder={
            !defaultAiSearchSet
              ? "e.g. Find me data scientists at OpenAI and their top competitors with skills in machine learning'"
              : ''
          }
          height="65px"
          flex={1}
          colorScheme="green"
          width="475px"
          resize="none"
          padding="8px 6px"
          style={{
            color: showingHighlighedOverlay ? 'transparent' : '#2D426A',
            caretColor: '#2D426A',
          }}
          {...COMMON_AI_TEXT_STYLES}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSubmit();
            }
          }}
        />
        <Box position="absolute" right="1px" bottom="1px" zIndex={1000}>
          <SpeechInput
            onTranscriptComplete={handleTranscriptComplete}
            onEnter={handleSubmit}
            onStartListening={() => {
              setAiSearchState((prevState) => ({
                ...prevState,
                prompt: '',
                response: null,
                uuid: '',
              }));
            }}
            textAreaRef={textAreaRef}
          />
        </Box>
        {showingHighlighedOverlay && (
          <Box
            cursor="text"
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            overflow="auto"
            padding="9px 7px"
            onClick={(e) => {
              if (e.target === e.currentTarget && textAreaRef.current) {
                textAreaRef.current.focus();
                const endPosition = aiSearchState.prompt.length;
                textAreaRef.current.setSelectionRange(endPosition, endPosition);
              }
            }}
            {...COMMON_AI_TEXT_STYLES}
          >
            <ResponseHighlightedOverlay
              prompt={text}
              response={
                aiSearchState.response as TalentDiscoveryAiFilterSearchResponse
              }
              textAreaRef={textAreaRef}
            />
          </Box>
        )}
      </Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex>
          {aiSearchState.uuid && (
            <>
              <IconButton
                aria-label="Thumbs up"
                icon={<FiThumbsUp />}
                onClick={() =>
                  handleIsAiSearchCorrect({
                    isCorrect: true,
                  })
                }
                size="sm"
                variant="ghost"
                colorScheme={
                  aiSearchState.isCorrect === true ? 'green' : 'gray'
                }
              />
              <IconButton
                aria-label="Thumbs down"
                icon={<FiThumbsDown />}
                onClick={() =>
                  handleIsAiSearchCorrect({
                    isCorrect: false,
                  })
                }
                size="sm"
                variant="ghost"
                colorScheme={aiSearchState.isCorrect === false ? 'red' : 'gray'}
              />
            </>
          )}
        </Flex>
        <ButtonGroup spacing={4}>
          {!defaultAiSearchSet && (
            <Button
              variant="link"
              size="sm"
              fontSize="xs"
              fontWeight={600}
              colorScheme="gray"
              onClick={closeMenu}
            >
              Cancel
            </Button>
          )}
          <Button
            data-testid="filter-popover-submit"
            isDisabled={!aiSearchState.prompt}
            colorScheme="green"
            variant="solid"
            size="sm"
            fontSize="sm"
            onClick={handleSubmit}
          >
            {defaultAiSearchSet && aiSearchState.uuid
              ? 'Try your own search'
              : 'Search'}
          </Button>
        </ButtonGroup>
      </Flex>
    </>
  );
};

const COMMON_AI_TEXT_STYLES: HTMLChakraProps<'div'> &
  HTMLChakraProps<'textarea'> = {
  fontSize: '14px',
  lineHeight: '17px',
};
