import React from 'react';

import { SelectionCategories } from '../../../engine/filters.model';
import { BasePopoutTreeFilter } from '../BasePopoutTreeFilter';
import { BasePopoutTreeCommonProps } from '../types';
import { useSelectionListFilter } from './use-selection-list-filter';

export interface SecondaryPopoutTreeFilterProps
  extends BasePopoutTreeCommonProps {
  selectionListIds: SelectionCategories[];
}

export const SecondaryPopoutTreeFilter: React.FC<
  SecondaryPopoutTreeFilterProps
> = ({
  selectionListIds,
  filterLabel,
  maxSelections,
  minSelections,
  disabledLevels,
  disallowMultiLevelSelect,
  sortSuggestedSearch,
  isOpen,
  branches,
  onClose,
  className,
}) => {
  const { treeData, activeIds, applySelectedIds } =
    useSelectionListFilter(selectionListIds);

  return (
    <div className={className}>
      <BasePopoutTreeFilter
        treeData={treeData}
        activeIds={activeIds}
        onSubmit={applySelectedIds}
        filterLabel={filterLabel}
        maxSelections={maxSelections}
        minSelections={minSelections}
        disabledLevels={disabledLevels}
        branches={branches}
        disallowMultiLevelSelect={disallowMultiLevelSelect}
        sortSuggestedSearch={sortSuggestedSearch}
        isOpen={isOpen}
        onClose={onClose}
      />
    </div>
  );
};
