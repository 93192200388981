import { Text, useDisclosure, useToast } from '@chakra-ui/react';
import classNames from 'classnames';

import { D3ChartNames } from '@revelio/d3';
import { FormatType } from '@revelio/replots';

import { PlotCard } from '../../../../../shared/components';
import { Topic } from '../../topics-subfilter/topics';
import { TopicsSubFilter } from '../../topics-subfilter/topics-subfilter';
import { getSentimentDataDownload } from '../../utils/get-sentiment-data-download';
import { EffectChart } from './effect-chart';
import styles from './sentiment-effects-charts.module.css';
import { PlotGrid } from './types';

type TopicPlotCardProps = {
  plotGrid: PlotGrid;
  selectedTopics: Topic[];
  onSubmit: (topics: Topic[]) => void;
  minValue: number;
  maxValue: number;
  colors?: string[];
  title?: string;
  singleEntityView?: boolean;
};

export const TopicPlotCard = ({
  plotGrid,
  selectedTopics,
  onSubmit,
  minValue,
  maxValue,
  colors,
  title,
  singleEntityView,
}: TopicPlotCardProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();

  const menuProps = {
    onExport: () => {
      const success = getSentimentDataDownload({
        plotGrid,
      });

      if (!success) {
        toast({
          title: 'Error',
          description: 'Failed to download data',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
      }
    },
    plotShare: {
      data:
        plotGrid.chartType === 'bar'
          ? plotGrid.data.map((d, i) => ({
              ...d,
              color: colors?.[i % colors.length],
            }))
          : plotGrid.data,
      chartConfig: {
        chartType:
          plotGrid.chartType === 'bar'
            ? D3ChartNames.ReplotsBarChart
            : D3ChartNames.ReplotsLineChart,
        chartProps: {
          format: FormatType.PERCENTAGE,
          colors: colors,
        },
      },
    },
  };

  return (
    <PlotCard
      className={classNames(styles.plotCard, {
        [styles.plotCardOpen]: isOpen,
      })}
      title={title || plotGrid.topic.label}
      info={
        <Text fontSize="xs" color="text.secondary">
          {`Sentiment scores are derived from both the free text and the star ratings 
              associated with employee reviews, and normalized so that 50% is the median 
              score amongst peer companies. Scores above 50% indicate positive sentiment, 
              while those below 50% indicate negative sentiment. Sample size includes all
              reviews with star ratings predictive of ${plotGrid.topic.label} sentiment, 
              as well as all reviews that contain a positive or negative signal for 
              ${plotGrid.topic.label} sentiment in their free text.`}
        </Text>
      }
      menu={['expand', 'download-png', 'generate-link', 'download-data']}
      topRight={
        <TopicsSubFilter
          placeholderText="Topics"
          selectedTopics={selectedTopics}
          onSubmit={onSubmit}
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
        />
      }
      {...menuProps}
    >
      <EffectChart
        colors={colors}
        minDataValue={minValue}
        maxDataValue={maxValue}
        singleEntityView={singleEntityView}
        {...plotGrid}
      />
    </PlotCard>
  );
};
