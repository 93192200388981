import {
  SelectionListIdNames,
  SelectionListItem,
  SelectionListItems,
} from '../../../engine/filters.model';

export type NestedFilterItem = SelectionListItem & {
  selectionListId: SelectionListIdNames;
};

export function getNestedFilterItemsFromIds(
  selections: string[],
  selectionLists: SelectionListItems[]
): NestedFilterItem[] {
  const entities: NestedFilterItem[] = [];

  for (const id of selections) {
    const splitIds = id.split('_');
    const splitIdsLength = splitIds.length;
    const lastId = splitIds[splitIdsLength - 1];

    if (!lastId) continue;

    const selectionList = selectionLists[splitIdsLength - 1];
    if (!selectionList) continue;

    const selectedItem = selectionList.value.find((item) => item.id === lastId);

    if (!selectedItem) continue;

    entities.push({
      ...selectedItem,
      selectionListId: selectionList.id,
    });
  }

  return entities;
}
